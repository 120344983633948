import { InjectionToken } from '@angular/core';
import { isFunction, isString } from '../utils/guards';
export var ApiHostToken = new InjectionToken('ApiHostToken');
export var ApiPrefixToken = new InjectionToken('ApiPrefixToken');
var authenticationEndpointPrefix = 'oauth';
/**
 * Set host on all request urls if the user provided it through ApiUrlConfigToken.
 * @param host
 * @param prefix
 */
var ApiUrlInterceptor = /** @class */ (function () {
    function ApiUrlInterceptor(host, prefix) {
        this.host = host;
        this.prefix = prefix;
    }
    ApiUrlInterceptor.prototype.intercept = function (req, next) {
        var requestUrl = req.url;
        // If the request is for a local file, we do not want to prepend anything
        if (/^file:\/\//.test(requestUrl)) {
            return next.handle(req);
        }
        if (requestUrl.endsWith('graphql')) {
            return next.handle(req);
        }
        // Set the host and prefix for API.
        // NOTE: The host and prefix can be a string or a function that evaluates to a string.
        var providedHost = getValue(this.host);
        var prefix = requestUrl
            .replace(/^\/+/g, '')
            .startsWith(authenticationEndpointPrefix)
            ? ''
            : getValue(this.prefix);
        var _a = new URL("" + (providedHost ||
            (location.protocol || 'http:') + "//" + (location.host || 'localhost')) + prefix), protocol = _a.protocol, host = _a.host, pathname = _a.pathname;
        // Strip the pathname from the req url if provided host contains it.
        if (requestUrl.startsWith(pathname)) {
            requestUrl = requestUrl.substr(pathname.length);
        }
        var path = "" + pathname + requestUrl;
        // Create the new url and
        // NOTE: Make sure there aren't double slashes
        var url = (protocol + "//" + host + path).replace(/([^:]\/)\/+/g, '$1');
        // Set the new url for the request
        req = req.clone({ url: url });
        return next.handle(req);
    };
    return ApiUrlInterceptor;
}());
export { ApiUrlInterceptor };
function getValue(value) {
    if (isFunction(value)) {
        return value();
    }
    else if (isString(value)) {
        return value;
    }
    return '';
}
