import { EventEmitter } from '@angular/core';
function isPositive(value) {
    return parseInt(value, 10) > 0;
}
var TablePaginationComponent = /** @class */ (function () {
    function TablePaginationComponent() {
        this.paginate = new EventEmitter();
        this.label = {
            of: 'of'
        };
    }
    Object.defineProperty(TablePaginationComponent.prototype, "limit", {
        get: function () {
            return this.$limit;
        },
        set: function (limit) {
            if (!isNaN(limit) && !isNaN(this.$limit) && limit !== this.$limit) {
                // Find closest page from previous min.
                this.page = Math.floor((this.page * this.$limit - this.$limit + limit) /
                    (isPositive(limit) ? limit : 1));
                this.$limit = limit;
                this.emit();
            }
            this.$limit = limit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TablePaginationComponent.prototype, "total", {
        get: function () {
            return this.$total;
        },
        set: function (total) {
            if (!isNaN(total) && total !== this.$total) {
                if (this.page > this.pages()) {
                    this.last();
                }
            }
            this.$total = total;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TablePaginationComponent.prototype, "min", {
        get: function () {
            return isPositive(this.total)
                ? this.page * this.limit - this.limit + 1
                : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TablePaginationComponent.prototype, "max", {
        get: function () {
            return this.hasNext() ? this.page * this.limit : this.total;
        },
        enumerable: true,
        configurable: true
    });
    TablePaginationComponent.prototype.pages = function () {
        return isPositive(this.total)
            ? Math.ceil(this.total / (isPositive(this.limit) ? this.limit : 1))
            : 1;
    };
    TablePaginationComponent.prototype.first = function () {
        this.page = 1;
        this.emit();
    };
    TablePaginationComponent.prototype.last = function () {
        this.page = this.pages();
        this.emit();
    };
    TablePaginationComponent.prototype.hasPrevious = function () {
        return this.page > 1;
    };
    TablePaginationComponent.prototype.previous = function () {
        this.page--;
        this.emit();
    };
    TablePaginationComponent.prototype.hasNext = function () {
        return this.page * this.limit < this.total;
    };
    TablePaginationComponent.prototype.next = function () {
        this.page++;
        this.emit();
    };
    TablePaginationComponent.prototype.emit = function () {
        this.paginate.emit({
            page: this.page,
            limit: this.limit
        });
    };
    return TablePaginationComponent;
}());
export { TablePaginationComponent };
