import { NgModule } from '@angular/core';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpClientModule } from '@angular/common/http';
// tslint:disable-next-line:no-submodule-imports
import { DefaultOptions } from 'apollo-client/ApolloClient';
import { KOMPOST_ENDPOINT } from 'env';

const graphqlEndpoint = `${KOMPOST_ENDPOINT}/graphql`;

// more info available here https://www.apollographql.com/docs/react/api/apollo-client.html#ApolloClient.constructor
const defaultOptions: DefaultOptions = {
    query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
    }
};

@NgModule({
    imports: [HttpClientModule, HttpLinkModule, ApolloModule]
})
export class GraphQLModule {
    constructor(apollo: Apollo, httpLink: HttpLink) {
        apollo.create({
            // InMemoryCache is a default cache option, description here https://www.apollographql.com/docs/react/advanced/caching.html
            // cache mechanism is literally turned off by providing defaultOptions property with currently used configuration
            // because fetch queries were not updating after new items being created via REST
            // which is probably one of drawbacks of using GraphQL for querying data without using mutations for resource manipulation
            // in case of using mutations, we could take advantage of refetchQueries method when performing given mutation
            // more info here https://www.apollographql.com/docs/react/essentials/mutations.html
            cache: new InMemoryCache(),
            link: httpLink.create({ uri: graphqlEndpoint }),
            defaultOptions
        });
    }
}
