import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { isFunction, isString } from '../utils/guards';

export const ApiHostToken = new InjectionToken<ApiHost>('ApiHostToken');
export type ApiHost = string | StringAggregate;

export const ApiPrefixToken = new InjectionToken<ApiPrefix>('ApiPrefixToken');
export type ApiPrefix = string | StringAggregate;

export type StringAggregate = () => string;

const authenticationEndpointPrefix = 'oauth';

/**
 * Set host on all request urls if the user provided it through ApiUrlConfigToken.
 * @param host
 * @param prefix
 */
@Injectable()
export class ApiUrlInterceptor implements HttpInterceptor {
    constructor(
        @Optional() @Inject(ApiHostToken) public host: any,
        @Optional() @Inject(ApiPrefixToken) public prefix: any
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let requestUrl = req.url;
        // If the request is for a local file, we do not want to prepend anything
        if (/^file:\/\//.test(requestUrl)) {
            return next.handle(req);
        }

        if (requestUrl.endsWith('graphql')) {
            return next.handle(req);
        }

        // Set the host and prefix for API.
        // NOTE: The host and prefix can be a string or a function that evaluates to a string.
        const providedHost = getValue(this.host);
        const prefix = requestUrl
            .replace(/^\/+/g, '')
            .startsWith(authenticationEndpointPrefix)
            ? ''
            : getValue(this.prefix);

        const { protocol, host, pathname } = new URL(
            `${
                providedHost ||
                `${location.protocol || 'http:'}//${
                    location.host || 'localhost'
                }`
            }${prefix}`
        );

        // Strip the pathname from the req url if provided host contains it.
        if (requestUrl.startsWith(pathname)) {
            requestUrl = requestUrl.substr(pathname.length);
        }

        const path = `${pathname}${requestUrl}`;
        // Create the new url and
        // NOTE: Make sure there aren't double slashes
        const url = `${protocol}//${host}${path}`.replace(/([^:]\/)\/+/g, '$1');

        // Set the new url for the request
        req = req.clone({ url });
        return next.handle(req);
    }
}

function getValue(value: any): string {
    if (isFunction(value)) {
        return value();
    } else if (isString(value)) {
        return value;
    }
    return '';
}
