import * as tslib_1 from "tslib";
import { flatten, isJsObject } from 'infarm-core';
import { Buffer } from 'buffer';
export function serializeUUID(resourceName, uuid) {
    return Buffer.from(resourceName + ':' + uuid).toString('base64');
}
export function isConnection(childObject) {
    return isJsObject(childObject) && 'edges' in childObject;
}
export function processNode(node) {
    var e_1, _a;
    try {
        for (var _b = tslib_1.__values(Object.keys(node)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var key = _c.value;
            var childObject = node[key];
            if (isConnection(childObject)) {
                node[key] = formatConnection(childObject);
            }
            else if (isJsObject(childObject)) {
                node[key] = processNode(childObject);
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return node;
}
export function formatConnection(connection) {
    var edges = connection.edges;
    return edges.map(function (edge) { return edge.node; }).map(function (node) { return processNode(node); });
}
export function formatConnections(connections) {
    return flatten(connections.map(function (connection) { return formatConnection(connection); }));
}
export function handleMutationResponse(response, mutationName) {
    var data = response.data;
    return Object.values(data[mutationName])[0];
}
