import { ControlType, FieldBase, FieldBaseOptions } from './base';

export interface TextFieldOptions extends FieldBaseOptions<string> {
    maxLength?: number | null;
}

export class TextField extends FieldBase<string> {
    readonly type: string;
    maxLength?: number | null;

    constructor(options: TextFieldOptions = {}) {
        Object.assign(options, {
            controlType: ControlType.Text
        });
        super(options);
        this.maxLength = options.maxLength || null;
        this.type = 'text';
    }
}
