import * as tslib_1 from "tslib";
// tslint:disable: max-classes-per-file
import { NgZone, Optional, Provider, SkipSelf } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { Auth, isJsObject, sleep } from 'infarm-core';
import { FarmingUnitCrudService } from './services/crud-service/farming-unit-crud.service';
var FarmingUnitStatusStreamManager = /** @class */ (function () {
    function FarmingUnitStatusStreamManager(zone, farmingUnitService) {
        this.zone = zone;
        this.farmingUnitService = farmingUnitService;
        this.streams = new Map();
        this.stop = false;
    }
    FarmingUnitStatusStreamManager.prototype.startStatusCheckLoop = function (frequency, farmingUnit, next) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var wait, status_1, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        wait = frequency || 5;
                        _a.label = 1;
                    case 1:
                        if (!!this.stop) return [3 /*break*/, 7];
                        status_1 = void 0;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.farmingUnitService.status(farmingUnit)];
                    case 3:
                        status_1 = _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        // In case of error,
                        // just set the response to null.
                        status_1 = null;
                        return [3 /*break*/, 5];
                    case 5:
                        next(status_1);
                        // Let's wait now
                        return [4 /*yield*/, sleep(wait * 1000)];
                    case 6:
                        // Let's wait now
                        _a.sent();
                        return [3 /*break*/, 1];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    FarmingUnitStatusStreamManager.prototype.create = function (farmingUnit, frequency) {
        var _this = this;
        // TODO: This will be a WebSocket stream when the backend is ready
        var source = Observable.create(function (observer) {
            _this.stop = false;
            // Initiate stream
            // Run the check loop outside Angular's zone
            _this.zone.runOutsideAngular(function () {
                // NOTE: We emit the updates in a zone, to ensure that components will pick up on changes.
                _this.startStatusCheckLoop(frequency, farmingUnit, function (status) {
                    return _this.zone.run(function () {
                        // If we had a failed request,
                        // send an empty status instead of failing the pipeline.
                        var st = getStatus(status);
                        observer.next(st);
                    });
                });
            });
            return function () {
                // Stop check
                _this.stop = true;
            };
        });
        var status = publishReplay(1)(source).pipe(refCount());
        var stream = new FarmingUnitStatusStream(status);
        return stream;
    };
    return FarmingUnitStatusStreamManager;
}());
export { FarmingUnitStatusStreamManager };
export function FARMING_UNIT_STATUS_STREAM_MANAGER_PROVIDER_FACTORY(parentFactory, zone, auth) {
    return (parentFactory ||
        new FarmingUnitStatusStreamManager(zone, new FarmingUnitCrudService(auth)));
}
export var FARMING_UNIT_STATUS_STREAM_MANAGER_PROVIDER = {
    // If there is already a FarmingUnitStatusStreamManager available, use that.
    // Otherwise, provide a new one.
    provide: FarmingUnitStatusStreamManager,
    useFactory: FARMING_UNIT_STATUS_STREAM_MANAGER_PROVIDER_FACTORY,
    deps: [
        [new Optional(), new SkipSelf(), FarmingUnitStatusStreamManager],
        NgZone,
        Auth
    ]
};
var FarmingUnitStatusStream = /** @class */ (function () {
    function FarmingUnitStatusStream(source) {
        this.source = source;
        this.dosingEnabled = this.source.pipe(map(function (_a) {
            var dosingEnabled = _a.dosingEnabled;
            return dosingEnabled;
        }));
        this.isOnline = this.source.pipe(map(function (_a) {
            var isOnline = _a.isOnline;
            return isOnline;
        }));
        this.isDosing = this.source.pipe(map(function (_a) {
            var isDosing = _a.isDosing;
            return isDosing;
        }));
        this.isNight = this.source.pipe(map(function (_a) {
            var isNight = _a.isNight;
            return isNight;
        }));
        this.inMaintenance = this.source.pipe(map(function (_a) {
            var inMaintenance = _a.inMaintenance;
            return inMaintenance;
        }));
        this.inSync = this.source.pipe(map(function (_a) {
            var inSync = _a.inSync;
            return inSync;
        }));
    }
    return FarmingUnitStatusStream;
}());
export { FarmingUnitStatusStream };
function getStatus(status) {
    if (!isJsObject(status)) {
        return {
            dosingEnabled: false,
            isOnline: false,
            isDosing: false,
            inMaintenance: false,
            inSync: false,
            inNight: false
        };
    }
    return status;
}
