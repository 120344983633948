import { Injectable } from '@angular/core';
import { FarmingUnit } from 'infarm-core';

@Injectable()
export class FarmingUnitService {
    async assignGrower(growerId: number, farmingUnitId: number): Promise<void> {
        const farmingUnit = (await FarmingUnit.fetch(
            farmingUnitId
        )) as FarmingUnit;
        await farmingUnit.update({
            grower: growerId
        });
    }
}
