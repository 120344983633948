import * as tslib_1 from "tslib";
import { LocationGroupsService } from './services/location-groups.service';
import { GraphqlService } from './graphql/graphql.service';
import { lightLocationGroupsList, locationGroupsList } from './graphql/queries';
var LocationGroupsResolver = /** @class */ (function () {
    function LocationGroupsResolver(locationGroupsService) {
        this.locationGroupsService = locationGroupsService;
    }
    LocationGroupsResolver.prototype.resolve = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.locationGroupsService.getLocationGroups()];
            });
        });
    };
    return LocationGroupsResolver;
}());
export { LocationGroupsResolver };
// tslint:disable-next-line:max-classes-per-file
var LocationGroupsFlatResolver = /** @class */ (function () {
    function LocationGroupsFlatResolver(graphqlService) {
        this.graphqlService = graphqlService;
    }
    LocationGroupsFlatResolver.prototype.resolve = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var allGroupsPaginator;
            return tslib_1.__generator(this, function (_a) {
                allGroupsPaginator = this.graphqlService.makePaginator('locationGroups', locationGroupsList);
                return [2 /*return*/, allGroupsPaginator.fetchAllEdges()];
            });
        });
    };
    return LocationGroupsFlatResolver;
}());
export { LocationGroupsFlatResolver };
// tslint:disable-next-line:max-classes-per-file
var LightLocationGroupsFlatResolver = /** @class */ (function () {
    function LightLocationGroupsFlatResolver(graphqlService) {
        this.graphqlService = graphqlService;
    }
    LightLocationGroupsFlatResolver.prototype.resolve = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var allGroupsPaginator;
            return tslib_1.__generator(this, function (_a) {
                allGroupsPaginator = this.graphqlService.makePaginator('locationGroups', lightLocationGroupsList);
                return [2 /*return*/, allGroupsPaginator.fetchAllEdges()];
            });
        });
    };
    return LightLocationGroupsFlatResolver;
}());
export { LightLocationGroupsFlatResolver };
