import * as tslib_1 from "tslib";
import { Auth, Farm } from 'infarm-core';
import { apiRequest } from '../utils';
import { API_HOST } from 'env';
// tslint:disable: max-classes-per-file
var FarmResolver = /** @class */ (function () {
    function FarmResolver() {
    }
    FarmResolver.prototype.resolve = function (route) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var farmId, skip, farm, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        farmId = findFarmId(route);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        skip = [
                            'farmingUnits',
                            'createdBy',
                            'locationGroups'
                        ];
                        return [4 /*yield*/, Farm.fetch(farmId, { skip: skip })];
                    case 2:
                        farm = _a.sent();
                        return [2 /*return*/, farm];
                    case 3:
                        err_1 = _a.sent();
                        return [2 /*return*/, null];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return FarmResolver;
}());
export { FarmResolver };
// TODO: Add tests and move this somewhere else
export function findFarmId(route) {
    var farmId = route.params.farmId;
    if (farmId === undefined) {
        var parent_1 = route.parent;
        // Search for the controllerId by looking up the route tree.
        while (parent_1) {
            var farmId_1 = parent_1.params.farmId;
            if (farmId_1 !== undefined) {
                return parseInt(farmId_1, 10);
            }
            parent_1 = parent_1.parent;
        }
    }
    return parseInt(farmId, 10);
}
export function findFarmUuid(route) {
    var farmId = route.params.farmId;
    if (farmId === undefined) {
        var parent_2 = route.parent;
        // Search for the controllerId by looking up the route tree.
        while (parent_2) {
            var farmId_2 = parent_2.params.farmId;
            if (farmId_2 !== undefined) {
                return farmId_2;
            }
            parent_2 = parent_2.parent;
        }
    }
    return farmId;
}
var ApiFarmResolver = /** @class */ (function () {
    function ApiFarmResolver(auth) {
        this.auth = auth;
    }
    ApiFarmResolver.prototype.resolve = function (route) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var farmId, token, farm;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        farmId = Number.parseInt(route.params.farmId, 10);
                        return [4 /*yield*/, this.auth.token()];
                    case 1:
                        token = _a.sent();
                        return [4 /*yield*/, apiRequest(API_HOST + "/internal/api/farm/" + farmId, token, 'GET')];
                    case 2:
                        farm = _a.sent();
                        return [2 /*return*/, farm.json()];
                }
            });
        });
    };
    return ApiFarmResolver;
}());
export { ApiFarmResolver };
