import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DocumentTitleService } from '../document-title.service';

@Injectable({
    providedIn: 'root'
})
export class ViewTitleService {
    // Observable sources
    private titleSource: Subject<string> = new Subject();
    private subtitleSource: Subject<SafeHtml> = new Subject();

    // Observable streams
    title: Observable<string> = this.titleSource.asObservable(); // tslint:disable-line:member-ordering
    subtitle: Observable<SafeHtml> = this.subtitleSource.asObservable(); // tslint:disable-line:member-ordering

    constructor(
        private documentTitle: DocumentTitleService,
        private sanitizer: DomSanitizer
    ) {}

    set(title: string, subtitle?: string): void {
        this.titleSource.next(title);
        this.documentTitle.set(title);

        const safeHtmlSubtitle = this.sanitizer.bypassSecurityTrustHtml(
            subtitle ? subtitle : ''
        );
        this.subtitleSource.next(safeHtmlSubtitle);
    }
}
