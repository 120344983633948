import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult, OperationVariables } from 'apollo-client';
import { PaginatorConfig, PaginatorService } from './paginator.service';
import { MAX_GRAPHQL_PAGE_SIZE } from './utils';
import { DocumentNode } from 'graphql';

function makePaginatorConfig(
    connectionName: string,
    query: DocumentNode,
    extraQueryParameters: any
): PaginatorConfig {
    const queryParameters = {
        numberOfItems: MAX_GRAPHQL_PAGE_SIZE
    };
    Object.assign(queryParameters, extraQueryParameters);
    const paginatorConfig: PaginatorConfig = {
        query,
        connectionName,
        queryParameters
    };
    return paginatorConfig;
}

@Injectable()
export class GraphqlService {
    farmConnectionPaginator: PaginatorService;
    constructor(private apollo: Apollo) {}

    makePaginator(
        connectionName: string,
        query: DocumentNode,
        extraQueryParameters?: any
    ) {
        const paginatorConfig = makePaginatorConfig(
            connectionName,
            query,
            extraQueryParameters
        );
        this.farmConnectionPaginator = new PaginatorService(
            this.apollo,
            paginatorConfig
        );
        return this.farmConnectionPaginator;
    }

    async query(
        query: DocumentNode,
        variables: OperationVariables = null
    ): Promise<ApolloQueryResult<any>> {
        return this.apollo
            .query({
                query,
                variables
            })
            .toPromise();
    }

    async mutate(mutation: DocumentNode, variables: any): Promise<any> {
        return this.apollo.mutate({ mutation, variables }).toPromise();
    }
}
