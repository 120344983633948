import { Item, Route } from '@infarm/potion-client';

export interface JSONSchema {
    [key: string]: any;
}

export class InfarmItem extends Item {
    static schema: () => Promise<JSONSchema> = Route.GET<JSONSchema>('/schema');

    toHtmlString(): string {
        return this.toString();
    }
    toString(): string {
        return `${this.id}`;
    }
}
