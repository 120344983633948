import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Farm, FarmView, isJsObject } from 'infarm-core';
import { findFarmId } from './farm.resolver';

@Injectable()
export class FarmViewResolver implements Resolve<FarmView | null> {
    async resolve(route: ActivatedRouteSnapshot): Promise<FarmView | null> {
        const farm = findFarmView(route);
        if (isJsObject(farm)) {
            return farm;
        } else {
            try {
                const farm = await Farm.manage({ farm: findFarmId(route) });
                return farm;
            } catch (err) {
                return null;
            }
        }
    }
}

export function findFarmView(
    snapshot: ActivatedRouteSnapshot
): FarmView | null {
    let current: any = snapshot;
    while (current) {
        const { data } = current;
        if (isJsObject(data) && isJsObject(data.farm)) {
            return data.farm as FarmView;
        }
        current = current.parent;
    }
    return null;
}
