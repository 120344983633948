import * as tslib_1 from "tslib";
import { CurrentUser, User } from 'infarm-core';
import { processNode, serializeUUID } from '../graphql/relay-utils';
import { userQuery } from '../graphql/queries';
import { UserRole } from '../graphql/interfaces/shared';
import { GraphqlService } from '../graphql/graphql.service';
import { usersAssignedToRoleQuery } from '../graphql/queries/user.query';
import { RolesResolver } from '../roles.resolver';
var UsersService = /** @class */ (function () {
    function UsersService(graphqlService, currentUser, rolesResolver) {
        this.graphqlService = graphqlService;
        this.currentUser = currentUser;
        this.rolesResolver = rolesResolver;
    }
    UsersService.prototype.getLoggedInUser = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.currentUser.change.subscribe(function (user) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var graphqlUser;
                return tslib_1.__generator(this, function (_a) {
                    try {
                        if (user) {
                            graphqlUser = getGraphQlUser(user, this.graphqlService);
                            resolve(graphqlUser);
                        }
                    }
                    catch (error) {
                        reject(error);
                    }
                    return [2 /*return*/];
                });
            }); });
        });
    };
    UsersService.prototype.resolveLocationGroupAwareGrowers = function (locationGroups) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var availableRoles, growerRole, paginator;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.rolesResolver.resolve()];
                    case 1:
                        availableRoles = _a.sent();
                        growerRole = availableRoles.find(function (role) { return role.name === UserRole.Grower; });
                        if (!growerRole) {
                            return [2 /*return*/, Promise.resolve([])];
                        }
                        paginator = this.graphqlService.makePaginator('users', usersAssignedToRoleQuery, {
                            roleUuid: growerRole.uuid,
                            locationGroupUuids: locationGroups.map(function (group) { return group.uuid; })
                        });
                        return [2 /*return*/, paginator.fetchAllEdges()];
                }
            });
        });
    };
    return UsersService;
}());
export { UsersService };
export function getGraphQlUser(userView, graphqlService) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var actualUser, id, graphqlUser, processed, mappedRoles;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, User.fetch(userView.id)];
                case 1:
                    actualUser = (_a.sent());
                    id = serializeUUID('User', actualUser.uuid);
                    return [4 /*yield*/, graphqlService.query(userQuery, { id: id })];
                case 2:
                    graphqlUser = _a.sent();
                    processed = processNode(graphqlUser.data.node);
                    mappedRoles = actualUser.roles.map(
                    // tslint:disable-next-line:no-object-literal-type-assertion
                    function (role) { return ({ name: role.name, uuid: role.uuid, id: role.id }); });
                    processed.roles = mappedRoles;
                    return [2 /*return*/, processed];
            }
        });
    });
}
