import * as tslib_1 from "tslib";
import { InfarmItem } from './infarm-item';
var RecipeItem = /** @class */ (function (_super) {
    tslib_1.__extends(RecipeItem, _super);
    function RecipeItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RecipeItem;
}(InfarmItem));
export { RecipeItem };
