import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { catchError } from 'rxjs/operators';

import { HttpError } from './http-error';

/**
 * Capture http errors and push them upstream
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private error: HttpError) {}
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError(err => {
                this.error.next(err);
                return ErrorObservable.create(err);
            })
        );
    }
}
