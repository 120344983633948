import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Auth, Farm } from 'infarm-core';
import { apiRequest } from '../utils';
import { API_HOST } from 'env';

// tslint:disable: max-classes-per-file

@Injectable()
export class FarmResolver implements Resolve<Farm | null> {
    async resolve(route: ActivatedRouteSnapshot): Promise<Farm | null> {
        const farmId = findFarmId(route);
        try {
            const skip: string[] = [
                'farmingUnits',
                'createdBy',
                'locationGroups'
            ];
            const farm = await Farm.fetch<Farm>(farmId, { skip });
            return farm;
        } catch (err) {
            return null;
        }
    }
}

// TODO: Add tests and move this somewhere else
export function findFarmId(route: ActivatedRouteSnapshot): number {
    const { farmId } = route.params;

    if (farmId === undefined) {
        let parent = route.parent;
        // Search for the controllerId by looking up the route tree.
        while (parent) {
            const { farmId } = parent.params;
            if (farmId !== undefined) {
                return parseInt(farmId, 10);
            }
            parent = parent.parent;
        }
    }

    return parseInt(farmId, 10);
}

export function findFarmUuid(route: ActivatedRouteSnapshot): string {
    const { farmId } = route.params;
    if (farmId === undefined) {
        let parent = route.parent;
        // Search for the controllerId by looking up the route tree.
        while (parent) {
            const { farmId } = parent.params;
            if (farmId !== undefined) {
                return farmId;
            }
            parent = parent.parent;
        }
    }
    return farmId;
}

@Injectable()
export class ApiFarmResolver implements Resolve<Farm> {
    constructor(private auth: Auth) {}

    async resolve(route: ActivatedRouteSnapshot): Promise<Farm> {
        const farmId = Number.parseInt(route.params.farmId, 10);
        const token = await this.auth.token();
        const farm = await apiRequest(
            `${API_HOST}/internal/api/farm/${farmId}`,
            token,
            'GET'
        );
        return farm.json();
    }
}
