import { Constructor } from './mixins/ctor';
import { ArchivableItem } from './mixins/archivable-item';
import { ItemWithName, ItemWithNameType } from './mixins/item-with-name';
import { UpdatedAt, UpdatedAtType } from './mixins/updated-at';
import { InfarmItem } from './mixins/infarm-item';

export const Base: Constructor<ItemWithNameType> &
    Constructor<UpdatedAtType> &
    typeof InfarmItem = ItemWithName(UpdatedAt(InfarmItem));

export class SectorConfiguration extends ArchivableItem(Base) {
    endProductId: number;
    trayTypeId?: number;
    sectorId?: number;
    harvestDayOfWeek?: number;
    harvestStartTime?: number;
    harvestEndTime?: number;
    plantingPeriod?: PlantingPeriod;
}

export interface PlantingPeriod {
    startTime: Date;
    endTime: Date;
}
