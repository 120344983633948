import {
    Injectable,
    OnDestroy,
    Optional,
    Provider,
    SkipSelf
} from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';

import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { animationFrame } from 'rxjs/scheduler/animationFrame';
import { map } from 'rxjs/operators';

@Injectable()
export class MqService implements OnDestroy {
    private changeSource: ReplaySubject<MediaChange[]> = new ReplaySubject(1);
    change: Observable<MediaChange[]> = this.changeSource.asObservable(); // tslint:disable-line: member-ordering

    private sub: Subscription;

    constructor(public media: MediaObserver) {
        this.sub = this.media.asObservable().subscribe(change => {
            animationFrame.schedule(() => {
                this.changeSource.next(change);
            });
        });
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    isActive(query: string): Observable<boolean> {
        return this.change.pipe(map(() => this.media.isActive(query)));
    }
}

export function MQ_SERVICE_PROVIDER_FACTORY(
    parentFactory: MqService,
    media: MediaObserver
): MqService {
    return parentFactory || new MqService(media);
}

export const MQ_SERVICE_PROVIDER: Provider = {
    // If there is already a MqService available, use that.
    // Otherwise, provide a new one.
    provide: MqService,
    useFactory: MQ_SERVICE_PROVIDER_FACTORY,
    deps: [[new Optional(), new SkipSelf(), MqService], MediaObserver]
};
