import { Injectable } from '@angular/core';
import { CurrentUser, LocationGroup, User, UserView } from 'infarm-core';
import { processNode, serializeUUID } from '../graphql/relay-utils';
import { userQuery } from '../graphql/queries';
import {
    Role,
    User as GraphqlUser,
    UserRole
} from '../graphql/interfaces/shared';
import { GraphqlService } from '../graphql/graphql.service';
import { usersAssignedToRoleQuery } from '../graphql/queries/user.query';
import { RolesResolver } from '../roles.resolver';

@Injectable()
export class UsersService {
    constructor(
        private graphqlService: GraphqlService,
        private currentUser: CurrentUser,
        private rolesResolver: RolesResolver
    ) {}

    getLoggedInUser(): Promise<GraphqlUser> {
        return new Promise<GraphqlUser>((resolve, reject) => {
            this.currentUser.change.subscribe(async user => {
                try {
                    if (user) {
                        const graphqlUser = getGraphQlUser(
                            user,
                            this.graphqlService
                        );
                        resolve(graphqlUser);
                    }
                } catch (error) {
                    reject(error);
                }
            });
        });
    }

    async resolveLocationGroupAwareGrowers(
        locationGroups: LocationGroup[]
    ): Promise<User[]> {
        const availableRoles = await this.rolesResolver.resolve();
        const growerRole = availableRoles.find(
            role => role.name === UserRole.Grower
        );
        if (!growerRole) {
            return Promise.resolve([]);
        }
        const paginator = this.graphqlService.makePaginator(
            'users',
            usersAssignedToRoleQuery,
            {
                roleUuid: growerRole.uuid,
                locationGroupUuids: locationGroups.map(group => group.uuid)
            }
        );
        return paginator.fetchAllEdges();
    }
}

export async function getGraphQlUser(
    userView: UserView,
    graphqlService: GraphqlService
): Promise<GraphqlUser> {
    const actualUser = (await User.fetch(userView.id)) as User;
    const id = serializeUUID('User', actualUser.uuid);
    const graphqlUser = await graphqlService.query(userQuery, { id });
    const processed: GraphqlUser = processNode(graphqlUser.data.node);
    const mappedRoles = actualUser.roles.map(
        // tslint:disable-next-line:no-object-literal-type-assertion
        role => ({ name: role.name, uuid: role.uuid, id: role.id } as Role)
    );
    processed.roles = mappedRoles;
    return processed;
}
