import * as tslib_1 from "tslib";
import { GraphqlService } from '../graphql/graphql.service';
import { UserRole } from '../graphql/interfaces/shared';
import { usersAssignedToRoleQuery } from '../graphql/queries/user.query';
import { RolesResolver } from '../roles.resolver';
var GrowersResolver = /** @class */ (function () {
    function GrowersResolver(graphqlService, rolesResolver) {
        this.graphqlService = graphqlService;
        this.rolesResolver = rolesResolver;
    }
    GrowersResolver.prototype.resolve = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var availableRoles, growerRole, paginator, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.rolesResolver.resolve()];
                    case 1:
                        availableRoles = _a.sent();
                        growerRole = availableRoles.find(function (role) { return role.name === UserRole.Grower; });
                        paginator = this.graphqlService.makePaginator('users', usersAssignedToRoleQuery, {
                            roleUuid: growerRole.uuid
                        });
                        return [2 /*return*/, paginator.fetchAllEdges()];
                    case 2:
                        err_1 = _a.sent();
                        return [2 /*return*/, []];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return GrowersResolver;
}());
export { GrowersResolver };
