import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageStorage, ImageUploadResponse, visible } from 'infarm-core';
import { ToastService } from '../toast.service';

@Component({
    selector: 'in-image-upload',
    templateUrl: 'image-upload.component.html',
    styleUrls: ['image-upload.component.scss'],
    animations: [visible]
})
export class ImageUploadComponent {
    @Input() resourceId: number;
    @Output() upload: EventEmitter<ImageUploadResponse> = new EventEmitter<
        ImageUploadResponse
    >();
    files: FileList | null;
    isUploading: boolean = false;

    constructor(
        private imageStorage: ImageStorage,
        private toast: ToastService
    ) {}

    updateFiles(event: Event): void {
        const { files } = event.srcElement as HTMLInputElement;
        this.files = files;
    }

    async uploadFiles(): Promise<void> {
        if (!this.files || !this.files.length) {
            return;
        }
        this.isUploading = true;
        try {
            const response = await this.imageStorage
                .upload(this.files[0], {
                    endProductId: this.resourceId
                })
                .toPromise();
            this.toast.push('Image was successfully uploaded', 'Ok');
            this.upload.emit(response);
            this.isUploading = false;
        } catch (err) {
            this.toast.push('Upload failed', 'Retry');
            this.isUploading = false;
        }
    }
}
