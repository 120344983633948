import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'infarm-core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

function nameExists(_: string): ValidatorFn {
    return (control: FormControl): { [key: string]: any } | null => {
        const selectedGrower = control.value.name || control.value;
        if (
            !!this.growers.length &&
            this.growers.map(grower => grower.name).indexOf(selectedGrower) ===
                -1
        ) {
            return { invalid: { value: control.value } };
        }
        return null;
    };
}

@Component({
    selector: 'in-grower-auto-select',
    templateUrl: './grower-auto-select.component.html'
})
export class GrowerAutoSelectComponent implements OnInit {
    @Input() growersSubject = new Subject<User[]>();
    @Input() isGrowerRequired: boolean = false;
    @Input() defaultGrowerSubject = new Subject<User>();
    growers: User[] = [];
    defaultGrower: User;
    @Output() validSelection = new EventEmitter();
    @Output() selectedGrower = new EventEmitter();

    growerAutoComplete: FormControl = new FormControl();
    filteredGrowers: Observable<User[]>;

    ngOnInit(): void {
        const scopedGrowerValidator = nameExists.bind(this);
        this.growersSubject.subscribe(growers => {
            this.growers = growers;
            this.filteredGrowers = this.growerAutoComplete.valueChanges.pipe(
                startWith(''),
                map(value => this.filterGrowers(value, this.growers))
            );
        });
        this.defaultGrowerSubject.subscribe(defaultGrower => {
            this.defaultGrower = defaultGrower;
            const validators = [scopedGrowerValidator()];
            if (this.isGrowerRequired) {
                validators.push(Validators.required);
            }
            this.growerAutoComplete = new FormControl(
                this.defaultGrower || '',
                validators
            );
        });
    }

    displayGrower(grower: User) {
        return grower ? grower.name : '';
    }

    forceSelection(e?: any) {
        const isSelectionValid = this.growerAutoComplete.valid;
        const selectedValue = e
            ? e.option.value
            : this.growerAutoComplete.value;
        if (
            isSelectionValid &&
            selectedValue &&
            selectedValue.pk === undefined &&
            typeof selectedValue === 'string'
        ) {
            const grower = this.growers.find(
                grower => grower.name === selectedValue
            );
            if (grower) {
                this.growerAutoComplete.setValue(grower);
                this.selectedGrower.emit(grower);
            }
        } else {
            this.selectedGrower.emit(selectedValue);
        }
        this.validSelection.emit(isSelectionValid);
    }

    filterGrowers(value: any, growers: User[]): User[] {
        return value
            ? growers.filter(grower =>
                  (grower.name || grower.email)
                      .toLowerCase()
                      .includes(value.name ? value.name.toLowerCase() : value)
              )
            : this.growers;
    }
}
