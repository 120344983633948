import {
    Injectable,
    OnDestroy,
    Optional,
    Provider,
    SkipSelf
} from '@angular/core';

import {
    BehaviorSubject,
    Observable,
    of as ofObservable,
    Subscription
} from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { User, UserView } from '../api/resources/user';
import { Auth } from '../auth/auth';
import { isJsObject } from '../utils/guards';

@Injectable()
export class CurrentUser implements OnDestroy {
    private changeSource: BehaviorSubject<any> = new BehaviorSubject(null);
    change: Observable<UserView | null> = this.changeSource.asObservable(); // tslint:disable-line: member-ordering
    isSuperAdmin: Observable<boolean> = this.change.pipe(
        filter((user: UserView | null) => user !== null),
        map((user: UserView | null) => isJsObject(user) && user.isSuperAdmin)
    ); // tslint:disable-line: member-ordering

    private subs: Subscription[] = [];

    constructor(auth: Auth) {
        this.subs.push(
            ...[
                auth.change
                    .pipe(
                        switchMap(token =>
                            token ? User.me() : ofObservable(null)
                        ),
                        catchError(() => ofObservable(null))
                    )
                    .subscribe((user: UserView | null) => {
                        if (user) {
                            this.changeSource.next(user);
                        }
                    })
            ]
        );
    }

    ngOnDestroy(): void {
        for (const sub of this.subs) {
            sub.unsubscribe();
        }
    }
}

export function CURRENT_USER_PROVIDER_FACTORY(
    parentFactory: CurrentUser,
    auth: Auth
): CurrentUser {
    return parentFactory || new CurrentUser(auth);
}

export const CURRENT_USER_PROVIDER: Provider = {
    // If there is already a CurrentUser available, use that.
    // Otherwise, provide a new one.
    provide: CurrentUser,
    useFactory: CURRENT_USER_PROVIDER_FACTORY,
    deps: [[new Optional(), new SkipSelf(), CurrentUser], Auth]
};
