import { isInfarmItem, isString } from 'infarm-core';
var Option = /** @class */ (function () {
    function Option(infarmItem) {
        this.item = infarmItem;
    }
    Object.defineProperty(Option.prototype, "key", {
        get: function () {
            if (isInfarmItem(this.item)) {
                return this.item.id;
            }
            if (this.item.uuid) {
                return this.item.uuid;
            }
            else if (isString(this.item)) {
                return this.item;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Option.prototype, "name", {
        get: function () {
            if (isInfarmItem(this.item)) {
                return this.item.toString();
            }
            if (this.item.uuid) {
                return this.item.name;
            }
            else if (isString(this.item)) {
                return this.item;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Option.prototype, "value", {
        get: function () {
            return this.item;
        },
        enumerable: true,
        configurable: true
    });
    return Option;
}());
export { Option };
