import * as tslib_1 from "tslib";
import { GraphqlService } from './graphql/graphql.service';
import { usersQuery } from './graphql/queries/user.query';
import { UsersService } from './services/current-user-graphql-service';
var UsersResolver = /** @class */ (function () {
    function UsersResolver(graphqlService) {
        this.graphqlService = graphqlService;
    }
    UsersResolver.prototype.resolve = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paginator;
            return tslib_1.__generator(this, function (_a) {
                paginator = this.graphqlService.makePaginator('users', usersQuery);
                return [2 /*return*/, paginator.fetchAllEdges()];
            });
        });
    };
    return UsersResolver;
}());
export { UsersResolver };
// tslint:disable-next-line:max-classes-per-file
var CurrentUserGraphqResolver = /** @class */ (function () {
    function CurrentUserGraphqResolver(currentUser) {
        this.currentUser = currentUser;
    }
    CurrentUserGraphqResolver.prototype.resolve = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.currentUser.getLoggedInUser()];
            });
        });
    };
    return CurrentUserGraphqResolver;
}());
export { CurrentUserGraphqResolver };
