import { UpdateableRecipeItem } from './mixins/updated-at';
export class TankFlushingRecipe extends UpdateableRecipeItem {
    freq: number; // frequency
    ecSp: number; // Target EC
    phSp: number; // Target pH
    wlevSp: number; // Min water level
    frmt: number; // Fresh water pump time
    drmt: number; // Flush pump time
    updatedAt: Date;
}
