import { User } from './user';
import { Role } from './role';
import { LocationGroup } from './location-group';
import { Item } from '@infarm/potion-client';

export class Assignment extends Item {
    id: number;
    user: User;
    role: Role;
    locationGroup: LocationGroup;
}
