import * as tslib_1 from "tslib";
import { UpdateableInfarmItem } from './mixins/updated-at';
import { ItemWithName } from './mixins/item-with-name';
export var Base = ItemWithName(UpdateableInfarmItem);
var RoleSet = /** @class */ (function (_super) {
    tslib_1.__extends(RoleSet, _super);
    function RoleSet() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RoleSet;
}(Base));
export { RoleSet };
