import { Component, Inject, Optional } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { InfarmItem, isJsObject, omap } from 'infarm-core';

import { Action, ResourceType } from '../../resources';
import { FieldBase } from '../fields/base';
import { TextField } from '../fields/text';

import { ToastService } from '../../toast.service';
import {
    FieldConfig,
    FieldControlService,
    fields,
    FieldsService,
    FormService
} from '../shared';
import { applyFormControlValues } from '../../utils';

// TODO: Cleanup around here (and the whole dynamic form stuff)
@Component({
    selector: 'in-dynamic-form-dialog',
    template: `
        <mat-dialog-content *ngIf="fields">${fields}</mat-dialog-content>
        <mat-card-actions align="end">
            <button (click)="abort()" mat-button>
                Cancel
            </button>
            <button
                (click)="submit()"
                [disabled]="disabled"
                mat-button
                color="primary"
            >
                Save
            </button>
        </mat-card-actions>
    `,
    styleUrls: ['./dynamic-form-dialog.component.scss']
})
export class DynamicFormDialogComponent {
    resourceType: ResourceType;
    resource?: InfarmItem;
    config?: {
        [key: string]: FieldConfig;
    };

    form: FormGroup = this.fcs.toFormGroup([]);
    fields: any[];
    successMessage: string;
    skipFields?: { [key: string]: string[] };
    errorMessage: string;
    get disabled(): boolean {
        return this.form.pristine || !this.form.valid;
    }

    get sanitize(): boolean {
        return !this.resource;
    }

    constructor(
        public dialogRef: MatDialogRef<DynamicFormDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: DynamicFormDialogData,
        private fcs: FieldControlService,
        private fs: FormService,
        private toast: ToastService,
        fields: FieldsService
    ) {
        Object.assign(this, data);

        const config: any = {};
        const resourceType = this.resourceType.type as typeof InfarmItem;

        // Prefill fields' value with the values from the resource
        if (this.resource instanceof InfarmItem) {
            Object.assign(
                config,
                omap(
                    this.resource,
                    key => key,
                    value => ({ value })
                )
            );
        }
        // Override config (if provided)
        if (isJsObject(this.config)) {
            Object.assign(config, this.config);
        }

        resourceType
            .schema()
            .then(schema =>
                fields.fromSchema(
                    schema,
                    Action.Edit,
                    config,
                    {},
                    this.skipFields
                )
            )
            .then(fields => {
                this.fcs.updateFormGroup(this.form, fields);
                this.fields = fields;
            });
    }

    renderAsTextarea(field: any): boolean {
        return (
            field instanceof TextField &&
            field.type === 'text' &&
            (typeof field.maxLength !== 'number' || field.maxLength > 250)
        );
    }
    fieldClass(field: FieldBase<any>): string {
        return `${field.controlType}-field`;
    }

    submit(): void {
        let payload = this.fs.parse(this.fields, this.sanitize);
        payload = applyFormControlValues(payload, this.form);
        // We don't want to submit if we have an invalid form
        if (!this.disabled) {
            this.save(payload);
        }
    }
    abort(): void {
        this.dialogRef.close();
    }

    async save(payload: any): Promise<void> {
        if (this.resource instanceof InfarmItem) {
            try {
                const infarmItem = await this.resource.update(payload);
                this.dialogRef.close(infarmItem);
                this.toast.push(this.successMessage);
            } catch (e) {
                this.toast.push(this.errorMessage);
            }
        } else {
            const resource: InfarmItem = Reflect.construct(
                this.resourceType.type,
                [payload]
            );
            try {
                const infarmItem = await resource.save();
                this.dialogRef.close(infarmItem);
                this.toast.push(this.successMessage);
            } catch (e) {
                this.toast.push(this.errorMessage);
            }
        }
    }
}

export interface DynamicFormDialogData {
    resourceType: ResourceType;
    resource?: InfarmItem | null;
    config?: {
        [key: string]: FieldConfig;
    };
    successMessage?: string;
    errorMessage?: string;
    skipFields?: { [key: string]: string[] };
}
