import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ColumnParserService } from './column-parser.service';
import { Column } from './column';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'in-column-value',
    templateUrl: './column-value.component.html'
})
export class ColumnValueComponent {
    html: string;
    constructor(private parser: ColumnParserService) {}
    @Input()
    set value(column: Column) {
        this.html = this.parser.parse(column);
    }
}
