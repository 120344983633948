import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

export interface TablePaginationEvent {
    page: number;
    limit: number;
}

function isPositive(value: string | number): boolean {
    return parseInt(value as string, 10) > 0;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'in-table-pagination',
    templateUrl: './table-pagination.component.html',
    styleUrls: ['./table-pagination.component.scss']
})
export class TablePaginationComponent {
    @Input() page: number;
    @Input() disabled: boolean;
    @Output() paginate: EventEmitter<TablePaginationEvent> = new EventEmitter<
        TablePaginationEvent
    >();

    label: { [key: string]: string } = {
        of: 'of'
    };

    private $limit: number;
    private $total: number;

    @Input()
    set limit(limit: number) {
        if (!isNaN(limit) && !isNaN(this.$limit) && limit !== this.$limit) {
            // Find closest page from previous min.
            this.page = Math.floor(
                (this.page * this.$limit - this.$limit + limit) /
                    (isPositive(limit) ? limit : 1)
            );
            this.$limit = limit;
            this.emit();
        }
        this.$limit = limit;
    }
    get limit(): number {
        return this.$limit;
    }

    @Input()
    set total(total: number) {
        if (!isNaN(total) && total !== this.$total) {
            if (this.page > this.pages()) {
                this.last();
            }
        }
        this.$total = total;
    }
    get total(): number {
        return this.$total;
    }

    get min(): number {
        return isPositive(this.total)
            ? this.page * this.limit - this.limit + 1
            : 0;
    }

    get max(): number {
        return this.hasNext() ? this.page * this.limit : this.total;
    }

    pages(): number {
        return isPositive(this.total)
            ? Math.ceil(this.total / (isPositive(this.limit) ? this.limit : 1))
            : 1;
    }

    first(): void {
        this.page = 1;
        this.emit();
    }

    last(): void {
        this.page = this.pages();
        this.emit();
    }

    hasPrevious(): boolean {
        return this.page > 1;
    }
    previous(): void {
        this.page--;
        this.emit();
    }

    hasNext(): boolean {
        return this.page * this.limit < this.total;
    }
    next(): void {
        this.page++;
        this.emit();
    }

    private emit(): void {
        this.paginate.emit({
            page: this.page,
            limit: this.limit
        });
    }
}
