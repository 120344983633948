import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    daysOfWeek,
    dayToInt,
    Farm,
    HarvestRound,
    HarvestRoundView,
    isJsObject,
    omit,
    toTime
} from 'infarm-core';

import {
    HarvestRoundDialogService,
    HarvestRoundObject,
    HarvestRoundPartialView,
    isHarvestRoundObject,
    isHarvestRoundView
} from '../harvest-round-dialog';
import { ToastService } from '../toast.service';
import { getErrorMessage } from '../utils';

@Component({
    selector: 'in-harvest-round',
    templateUrl: './harvest-round.component.html',
    styleUrls: ['./harvest-round.component.scss']
})
export class HarvestRoundComponent {
    @Output() set: EventEmitter<HarvestRoundPartialView[]> = new EventEmitter();
    @Input() farm: Farm;

    private $rounds: any[] = [];
    @Input()
    set rounds(rounds: any) {
        this.$rounds = rounds;
    }

    constructor(
        private harvestRoundDialog: HarvestRoundDialogService,
        private toast: ToastService
    ) {}

    trackByIdentifier(
        _: number,
        round: HarvestRoundObject | HarvestRoundView
    ): string | null {
        if (isJsObject(round)) {
            if (isHarvestRoundObject(round)) {
                return round.uid;
            } else if (isHarvestRoundView(round)) {
                return `${round.id}`;
            }
        }
        return null;
    }

    async edit(object: HarvestRoundObject | HarvestRoundView): Promise<void> {
        const round = await this.harvestRoundDialog.open({ object });
        if (isJsObject(round)) {
            if (isHarvestRoundView(round) && this.farm instanceof Farm) {
                try {
                    const roundResource = await HarvestRound.fetch<
                        HarvestRound
                    >(round.id);
                    await roundResource.update({
                        dayOfWeek: dayToInt(round.dayOfWeek),
                        startTime: round.startTime,
                        endTime: round.endTime
                    });
                    const index = this.getRounds<HarvestRoundView>().findIndex(
                        existingRound => existingRound.id === round.id
                    );
                    if (index !== -1) {
                        this.$rounds.splice(
                            index,
                            1,
                            toHarvestRoundView(roundResource)
                        );
                    }
                } catch (err) {
                    this.toast.push(
                        'Failed to update harvest round, please check your input.'
                    );
                }
            } else if (isHarvestRoundObject(round)) {
                const index = this.getRounds<HarvestRoundObject>().findIndex(
                    existingRound => existingRound.uid === round.uid
                );
                if (index !== -1) {
                    this.$rounds.splice(index, 1, round);
                    this.emit();
                }
            }
        }
    }

    async remove(
        evt: Event,
        object: HarvestRoundObject | HarvestRoundView
    ): Promise<void> {
        if (evt) {
            evt.preventDefault();
            evt.stopPropagation();
        }
        if (isHarvestRoundView(object) && this.farm instanceof Farm) {
            try {
                const round = await HarvestRound.fetch(object.id);
                await round.destroy();
                const index = this.getRounds<HarvestRoundView>().findIndex(
                    existingRound => existingRound.id === object.id
                );
                if (index !== -1) {
                    this.$rounds.splice(index, 1);
                }
            } catch (err) {
                this.toast.push(
                    'Failed to delete harvest round, please try again later.'
                );
            }
        } else if (isHarvestRoundObject(object)) {
            const index = this.getRounds<HarvestRoundObject>().findIndex(
                existingRound => existingRound.uid === object.uid
            );
            if (index !== -1) {
                this.$rounds.splice(index, 1);
            }
            this.emit();
        }
    }

    async add(): Promise<void> {
        const round = await this.harvestRoundDialog.open();
        if (isJsObject(round)) {
            if (this.farm instanceof Farm) {
                const rawRoundResource = new HarvestRound({
                    dayOfWeek: dayToInt(round.dayOfWeek),
                    startTime: round.startTime,
                    endTime: round.endTime,
                    farm: this.farm
                });
                try {
                    const roundResource = await rawRoundResource.save();
                    this.$rounds.push(toHarvestRoundView(roundResource));
                } catch (err) {
                    this.toast.push(getErrorMessage(err));
                }
            } else if (isHarvestRoundObject(round)) {
                this.$rounds.push(round);
                this.emit();
            }
        }
    }

    time(ms: number): string {
        return toTime(ms);
    }

    getRounds<T>(): T[] {
        return this.$rounds;
    }

    private emit(): void {
        this.set.emit(
            this.getRounds<HarvestRoundObject>().map(round =>
                omit(round, ['uid'])
            ) as HarvestRoundPartialView[]
        );
    }
}

export function toHarvestRoundView(round: HarvestRound): HarvestRoundView {
    return {
        dayOfWeek: daysOfWeek()[round.dayOfWeek],
        startTime: round.startTime,
        endTime: round.endTime,
        id: Number(round.id)
    };
}
