import { PotionResources } from '@infarm/potion-client';
import { potionPrefix, prefix } from './constants';
import { Assignment } from './resources/assignment';
import { Address, ClimateRecipe, Controller, EcRecipe, EndProduct, FanRecipe, Farm, FarmingUnit, GrowingMedium, HarvestRecord, HarvestRound, HourlySchedule, Image, LightRecipe, LocationGroup, Mission, MissionImage, MissionRecord, Organization, PhRecipe, RecipeSchedule, RecipeSet, Role, RoleSet, Sector, SectorConfiguration, SeedingRecord, ShrinkageReason, TankFlushingRecipe, TrayType, User, Visit, VisitImage, VisitRecord, WaterRefillingRecipe } from './resources/index';
export var INFARM_POTION_RESOURCES = {
    '/address': Address,
    '/assignment': Assignment,
    '/climate_recipe': ClimateRecipe,
    '/controller': Controller,
    '/ec_recipe': EcRecipe,
    '/end_product': EndProduct,
    '/farm': Farm,
    '/fan_recipe': FanRecipe,
    '/farming_unit': FarmingUnit,
    '/growing_medium': GrowingMedium,
    '/harvest_record': HarvestRecord,
    '/harvest_round': HarvestRound,
    '/hourly_schedule': HourlySchedule,
    '/image': Image,
    '/light_recipe': LightRecipe,
    '/location_group': LocationGroup,
    '/mission': Mission,
    '/mission_image': MissionImage,
    '/mission_record': MissionRecord,
    '/seeding_record': SeedingRecord,
    '/organization': Organization,
    '/ph_recipe': PhRecipe,
    '/wrf_recipe': WaterRefillingRecipe,
    '/wfl_recipe': TankFlushingRecipe,
    '/recipe_schedule': RecipeSchedule,
    '/recipe_set': RecipeSet,
    '/role': Role,
    '/role_set': RoleSet,
    '/sector': Sector,
    '/sector_configuration': SectorConfiguration,
    '/shrinkage_reason': ShrinkageReason,
    '/tray_type': TrayType,
    '/user': User,
    '/visit': Visit,
    '/visit_image': VisitImage,
    '/visit_record': VisitRecord
};
var ɵ0 = {
    prefix: potionPrefix
}, ɵ1 = prefix;
var ApiModule = /** @class */ (function () {
    function ApiModule() {
    }
    return ApiModule;
}());
export { ApiModule };
/**
 * Constants
 */
export { colors, potionPrefix, prefix } from './constants';
/**
 * Interceptors
 */
export { HttpErrorInterceptor } from './http-error-interceptor';
export { HttpError } from './http-error';
export { PostInterceptor } from './post-interceptor';
export { ApiHostToken, ApiPrefixToken, ApiUrlInterceptor } from './url-interceptor';
/**
 * Potion Resources
 */
export { 
// Mixins
ArchivableItem, CreatedAt, InfarmItemWithName, InfarmItem, ItemWithAddresses, ItemWithAuthor, ItemWithName, NoteItem, RecipeItem, UpdatedAt, UpdateableInfarmItem, UpdateableRecipeItem, UpdateableRecordItem, WaterRefillingRecipe, TankFlushingRecipe, SectorTaskType, SeedingRecord, ShrinkageState, ShrinkageReason, 
// Address
Address, ClimateRecipe, 
// Controller
Controller, 
// Ec recipe
EcRecipe, EndProduct, 
// Farm
Farm, FarmCategory, OrganizationView, FarmingUnitPreview, 
// Farming unit
FarmingUnit, Calibration, EcCalibration, PhCalibration, GrowingMedium, HarvestRecord, 
// Harvest route
HarvestRound, harvestRoundAsString, HourlySchedule, Image, LightRecipe, FanRecipe, Mission, MissionRecord, 
// Location groups
LocationGroup, Assignment, Organization, 
// Ph recipe
PhRecipe, 
// Recipe schedule
RecipeSchedule, RecipeSet, Role, RoleSet, Sector, SectorConfiguration, TrayType, TrayVariant, 
// User
User, Visit, VisitRecord, VisitImage, MissionImage } from './resources/index';
export { ɵ0, ɵ1 };
