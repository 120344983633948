import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLinkWithHref } from '@angular/router';
import { LocationStrategy } from '@angular/common';
var ScheduleRouterLinkDirective = /** @class */ (function (_super) {
    tslib_1.__extends(ScheduleRouterLinkDirective, _super);
    function ScheduleRouterLinkDirective(angularRouter, route, locationStrategy) {
        var _this = _super.call(this, angularRouter, route, locationStrategy) || this;
        _this.angularRouter = angularRouter;
        _this.queryParams = {};
        route.queryParams.subscribe(function (queryParams) {
            _this.queryParams = queryParams;
        });
        return _this;
    }
    ScheduleRouterLinkDirective.prototype.ngOnInit = function () {
        this.href = this.scheduleHref;
    };
    ScheduleRouterLinkDirective.prototype.onClick = function (button, ctrlKey, metaKey, shiftKey) {
        // we don't want to react to click when shift (selection modifier) is pressed
        if (shiftKey) {
            return false;
            // want to open the link in new tab when modifier was used
        }
        else if (metaKey || ctrlKey) {
            event.stopPropagation();
            // this query params modification shall be removed if ever https://github.com/infarm/farmboard/pull/235 or similar mechanism gets merged
            var hrefWithParams = this.scheduleHref + this.constructQueryStringFromParams();
            window.open(hrefWithParams, '_blank');
            return false;
            // or we want to navigate to appropriate location
        }
        else {
            this.angularRouter.navigate([this.href.replace('/#', '')], {
                queryParams: this.queryParams
            });
            return false;
        }
    };
    ScheduleRouterLinkDirective.prototype.constructQueryStringFromParams = function () {
        var _this = this;
        return Object.keys(this.queryParams).reduce(function (accumulator, current) {
            return accumulator + (current + "=" + _this.queryParams[current] + "&");
        }, Object.keys(this.queryParams).length ? '?' : '');
    };
    return ScheduleRouterLinkDirective;
}(RouterLinkWithHref));
export { ScheduleRouterLinkDirective };
