import {
    Injectable,
    NgZone,
    Optional,
    Provider,
    SkipSelf
} from '@angular/core';
import { parseAsync } from '../utils/json';

@Injectable()
export class Storage {
    /**
     * Get an item from local storage
     * @param key
     * @param [zone] - If provided, it will run the action in an NgZone
     */
    static async get<T = any>(key: string, zone?: NgZone): Promise<T> {
        const json: any = window.localStorage.getItem(uniqueKey(key));
        if (json) {
            try {
                const data = await parseAsync(json);
                return data;
            } catch (e) {} // tslint:disable-line: no-empty
        }
        if (zone instanceof NgZone) {
            return zone.run(() => json);
        }
        return json;
    }

    constructor(private zone: NgZone) {}

    /**
     * Set an item in local storage
     * @param key
     * @param data
     */
    set<T = any>(key: string, data: T): this {
        return this.zone.run<this>(() => {
            window.localStorage.setItem(uniqueKey(key), JSON.stringify(data));
            return this;
        });
    }

    /**
     * Get an item from local storage
     * @param key
     */
    async get<T = any>(key: string): Promise<T> {
        return Storage.get(key, this.zone);
    }

    /**
     * Remove an item from local storage
     * @param key
     */
    remove(key: string): this {
        return this.zone.run<this>(() => {
            window.localStorage.removeItem(uniqueKey(key));
            return this;
        });
    }

    /**
     * Clear all in local storage
     * NOTE: Only removes items that have the namespace
     */
    clear(): this {
        return this.zone.run<this>(() => {
            for (const key in localStorage) {
                if (key.startsWith(storageKey)) {
                    localStorage.removeItem(key);
                }
            }
            return this;
        });
    }
}

// We namespace all keys to avoid conflicts with other 3rd party lib
const storageKey = '@infarm';
export function uniqueKey(key: string): string {
    return `${storageKey}:${key}`;
}

export function STORAGE_PROVIDER_FACTORY(
    parentFactory: Storage,
    zone: NgZone
): Storage {
    return parentFactory || new Storage(zone);
}

export const STORAGE_PROVIDER: Provider = {
    // If there is already a Storage available, use that.
    // Otherwise, provide a new one.
    provide: Storage,
    useFactory: STORAGE_PROVIDER_FACTORY,
    deps: [[new Optional(), new SkipSelf(), Storage], NgZone]
};
