import { InfarmItem } from './infarm-item';
import { isString } from '../../../utils/guards';

export class NoteItem extends InfarmItem {
    title?: string;
    text: string;
    imageUrl?: string;

    toString(): string {
        if (isString(this.title)) {
            return this.title;
        }
        return super.toString();
    }
}
