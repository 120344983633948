import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import gql from 'graphql-tag';
import { LocationGroup } from '../interfaces';
import { FetchResult } from 'apollo-link';

@Injectable({
    providedIn: 'root'
})
export class LocationGroupMutators {
    // TODO investigate graphql partials or template snippets so we are not repeating so many strings

    changeGroupNameMutation = gql`
        mutation UpdateLocationGroup($input: UpdateLocationGroupInput!) {
            updateLocationGroup(input: $input) {
                locationGroup {
                    uuid
                    name
                    level
                    parent {
                        uuid
                    }
                }
            }
        }
    `;

    createGroupMutation = gql`
        mutation CreateLocationGroup($input: CreateLocationGroupInput!) {
            createLocationGroup(input: $input) {
                locationGroup {
                    uuid
                    name
                    level
                    parent {
                        uuid
                        name
                        level
                    }
                    children {
                        edges {
                            node {
                                uuid
                                name
                                level
                            }
                        }
                    }
                }
            }
        }
    `;

    constructor(private apollo: Apollo) {}

    createLocationGroup(
        variables: any
    ): Observable<FetchResult<LocationGroup>> {
        return this.apollo.mutate({
            mutation: this.createGroupMutation,
            variables: {
                input: variables
            }
        });
    }

    updateLocationGroup(
        variables: any
    ): Observable<FetchResult<LocationGroup>> {
        return this.apollo.mutate({
            mutation: this.changeGroupNameMutation,
            variables: {
                input: variables
            }
        });
    }
}
