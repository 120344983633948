import { Injectable } from '@angular/core';
import { isNumber, isString, JSONSchema, sort } from 'infarm-core';
import { Potion, QueryParams } from '@infarm/potion-client';

import { isDate } from 'moment';

import {
    Action,
    isAnyOf,
    isPrimitive,
    PropertyType,
    Schema,
    toLabel
} from '../../resources/schema';

import { ProgressService } from '../../progress.service';

import {
    CheckboxField,
    DateField,
    NumberField,
    Option,
    SelectField,
    TextField
} from '../fields';

export interface FieldConfig {
    hide?: boolean;
    skip?: boolean;
    required?: boolean;
    value?: any;
    filter?(option: Option): boolean;
}

@Injectable()
export class FieldsService {
    private query: QueryParams = {
        perPage: 2500
    };

    constructor(private potion: Potion, private progress: ProgressService) {}

    async fromSchema(
        json: any,
        forAction: Action = Action.Create,
        fieldsConfig: { [key: string]: FieldConfig } = {},
        queryMap?: { [key: string]: QueryParams },
        skipOptions?: { [key: string]: string[] }
    ): Promise<any[]> {
        // Signal work start
        this.progress.show();

        const schema = new Schema(json);
        const fields: any[] = [];
        const entries = Array.from(
            schema.properties(forAction).entries()
        ).filter(([key]) => {
            const fieldConfig: FieldConfig = fieldsConfig[key];
            if (fieldConfig) {
                return !fieldConfig.skip;
            }
            return true;
        });

        for (const [key, validation] of entries) {
            const { type } = validation;
            const fieldConfig = fieldsConfig[key] || {};
            const { value } = fieldConfig;
            const skip = skipOptions ? skipOptions[key] : [];
            const fieldRequired = fieldConfig[key]
                ? fieldConfig[key].required
                : false;
            const hide = fieldsConfig[key] ? fieldsConfig[key].hide : false;
            const required = !isAnyOf(type, PropertyType.Null) || fieldRequired;
            const fieldOptions: any = {
                label: toLabel(key),
                value: validation.default,
                hide,
                required,
                key
            };

            if (queryMap) {
                const query = queryMap[key];
                if (query !== undefined) {
                    Object.assign(this.query, query);
                } else {
                    this.query = {
                        perPage: 2500
                    };
                }
            }

            if (value !== undefined) {
                Object.assign(fieldOptions, { value });
            }

            // TODO: we need validation (min, max, max length, etc.)
            if (isPrimitive(type) && validation.hasOwnProperty('enum')) {
                const { value } = fieldOptions;
                const options = (toOption(validation.enum) as Option[]).filter(
                    fieldConfig.filter || noop
                );

                // If no option was provided from config or by default from schema,
                // set the first available option as selected.
                if (value === undefined && options.length) {
                    Object.assign(fieldOptions, {
                        value: fromOption(options[0])
                    });
                }

                fields.push(
                    new SelectField({
                        ...fieldOptions,
                        options
                    })
                );
            } else if (isAnyOf(type, PropertyType.String)) {
                const { maxLength } = validation;
                fields.push(
                    new TextField({
                        ...fieldOptions,
                        maxLength
                    })
                );
            } else if (
                isAnyOf(type, [PropertyType.Number, PropertyType.Integer])
            ) {
                fields.push(new NumberField(fieldOptions));
            } else if (isAnyOf(type, PropertyType.Boolean)) {
                fields.push(new CheckboxField(fieldOptions));
            } else if (isAnyOf(type, PropertyType.Array)) {
                const { items } = validation;
                const { $ref = null }: { [key: string]: any } =
                    items.properties || {};

                // If the type on the schema for this property is an Array and the `items.properties` contains a `$ref`,
                // it means this field/property is a reference to a collection of Potion resources.
                if (isAnyOf(items.type, PropertyType.Object) && $ref) {
                    const { value } = fieldOptions;

                    try {
                        const resource = findResourceByRefPattern(
                            $ref,
                            this.potion
                        );
                        const items = await resource.query(this.query, {
                            skip
                        });
                        const options = (toOption(items) as Option[]).filter(
                            fieldConfig.filter || noop
                        );

                        // If no option was provided from config or by default from schema,
                        // set the first available option as selected (if the field is required).
                        if (value === undefined && options.length && required) {
                            Object.assign(fieldOptions, {
                                value: fromOption([options[0]])
                            });
                        }

                        fields.push(
                            new SelectField({
                                ...fieldOptions,
                                multiple: true,
                                options
                            })
                        );
                    } catch (e) {
                        this.progress.hide();
                        throw e;
                    }
                }
            } else if (isAnyOf(type, PropertyType.Object)) {
                const { properties } = validation;
                const { $ref = null, $date = null }: { [key: string]: any } =
                    properties || {};

                if ($date) {
                    const { value } = fieldOptions;
                    fields.push(
                        new DateField({
                            ...fieldOptions,
                            value:
                                isString(value) || isNumber(value)
                                    ? new Date(value as any)
                                    : isDate(value)
                                    ? value
                                    : new Date()
                        })
                    );
                } else if ($ref) {
                    // If the type on the schema for this property is an Object and the `properties` contains a `$ref`,
                    // it means this field/property is a reference to a Potion resource.
                    const { value } = fieldOptions;

                    try {
                        const resource = findResourceByRefPattern(
                            $ref,
                            this.potion
                        );
                        const items = await resource.query(this.query, {
                            skip
                        });
                        const options = sort(
                            (toOption(items) as Option[]).filter(
                                fieldConfig.filter || noop
                            ),
                            option => option.name
                        );

                        // If no option was provided from config or by default from schema,
                        // set the first available option as selected.
                        if (value === undefined && options.length) {
                            Object.assign(fieldOptions, {
                                value: fromOption(options[0])
                            });
                        }

                        fields.push(
                            new SelectField({
                                ...fieldOptions,
                                options
                            })
                        );
                    } catch (e) {
                        this.progress.hide();
                        throw e;
                    }
                }
            }
        }

        this.progress.hide();

        return fields;
    }
}

/**
 * Try to find the matching Potion resource based on a schema $ref pattern.
 * @param {JSONSchema} ref
 * @param {Potion} potion
 */
export function findResourceByRefPattern(ref: JSONSchema, potion: Potion): any {
    const { resources, prefix } = potion;

    let { pattern } = ref;
    // If Potion has a prefix,
    // we need to remove it from the pattern,
    // otherwise we cannot match to any resource path as all resource paths do not include the prefix.
    if (prefix) {
        pattern = pattern.replace(
            // Escape the forward slash so we can find it in the `$ref` pattern.
            prefix.replace(/\//g, '\\/'),
            ''
        );
    }

    // Create the regex based on the schema `$ref` pattern.
    const regex = new RegExp(pattern);
    // Find matching path for the regex in all registered Potion resources.
    const match = Object.keys(resources)
        // Append a resource id to the resource path.
        // All patterns expect a path to a specific resource,
        // not a path to all the resources.
        .find(path => regex.test(`${path}/0`));

    // If we could not find a match we'll throw as it's highly likely the dev forgot to register the resource in Potion
    if (!match) {
        throw new TypeError(
            `Unable to find a resource that matches ${pattern}`
        );
    }

    return resources[match];
}

export function fromOption(option: Option | Option[]): any {
    if (Array.isArray(option)) {
        return option.map(item => fromOption(item) as Option);
    }
    return option.value;
}
export function toOption(item: any): Option | Option[] {
    if (Array.isArray(item)) {
        return item.map(item => toOption(item) as Option);
    }
    return new Option(item);
}

function noop(option: any): any {
    return option;
}
