import * as tslib_1 from "tslib";
import { UpdateableInfarmItem } from './mixins/updated-at';
import { Route } from '@infarm/potion-client';
var RecipeSchedule = /** @class */ (function (_super) {
    tslib_1.__extends(RecipeSchedule, _super);
    function RecipeSchedule() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.setFromTimeArray = Route.POST('/set_from_time_array');
        return _this;
    }
    return RecipeSchedule;
}(UpdateableInfarmItem));
export { RecipeSchedule };
