import * as tslib_1 from "tslib";
import { ArchivableItem } from './mixins/archivable-item';
import { ItemWithAddresses } from './mixins/item-with-addresses';
import { ItemWithName } from './mixins/item-with-name';
export var Base = ItemWithName(ItemWithAddresses);
var Mission = /** @class */ (function (_super) {
    tslib_1.__extends(Mission, _super);
    function Mission() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Mission;
}(ArchivableItem(Base)));
export { Mission };
