import { from as fromPromise, Observable, of as ofObservable } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';

import { InfarmItem } from './mixins/infarm-item';
import { Farm } from './farm';
import { FarmingUnit } from './farming-unit';
import { User } from './user';

export class Image extends InfarmItem {
    static categories(): Observable<string[]> {
        const source = fromPromise(Image.schema()).pipe(
            map(({ properties }: any) => properties),
            map(({ category }: any) => category.enum),
            map(categories =>
                categories.filter((category: any) => category !== null)
            ),
            catchError(() => ofObservable([]))
        );
        return publishReplay<string[]>()(source).pipe(refCount());
    }

    author?: User;
    createdAt?: Date;
    farm?: Farm;
    farmingUnit?: FarmingUnit;

    fileName: string;
    imageUrl?: string;
    category?: 'plant' | 'POS' | 'technical issue' | 'misc';
    description?: string;
}
