import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { FarmingUnit } from 'infarm-core';

@Injectable()
export class FarmingUnitResolver implements Resolve<FarmingUnit | null> {
    async resolve(route: ActivatedRouteSnapshot): Promise<FarmingUnit | null> {
        const unitId = findUnitId(route);
        // NOTE: If {runGuardsAndResolvers} is set to 'always',
        // we need to bust the cache.
        try {
            const unit = await FarmingUnit.fetch<FarmingUnit>(unitId, {
                cache:
                    route.routeConfig === null ||
                    route.routeConfig.runGuardsAndResolvers !== 'always',
                skip: ['createdBy', 'grower', 'farmingUnits', 'farm']
            });
            return unit;
        } catch (err) {
            return null;
        }
    }
}

// TODO: Add tests an move to somewhere else (it's commonly used)
export function findUnitId(route: ActivatedRouteSnapshot): number {
    const { unitId } = route.params;

    if (unitId === undefined) {
        let routeParent = route.parent;
        // Search for the unitId by looking up the route tree.
        while (routeParent) {
            const { unitId } = routeParent.params;
            if (unitId !== undefined) {
                return parseInt(unitId, 10);
            }
            routeParent = routeParent.parent;
        }
    }

    return parseInt(unitId, 10);
}
