import * as tslib_1 from "tslib";
import { MatDialogRef } from '@angular/material';
import { InfarmItem, isJsObject, omap } from 'infarm-core';
import { Action } from '../../resources';
import { TextField } from '../fields/text';
import { ToastService } from '../../toast.service';
import { FieldConfig, FieldControlService, FieldsService, FormService } from '../shared';
import { applyFormControlValues } from '../../utils';
// TODO: Cleanup around here (and the whole dynamic form stuff)
var DynamicFormDialogComponent = /** @class */ (function () {
    function DynamicFormDialogComponent(dialogRef, data, fcs, fs, toast, fields) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.data = data;
        this.fcs = fcs;
        this.fs = fs;
        this.toast = toast;
        this.form = this.fcs.toFormGroup([]);
        Object.assign(this, data);
        var config = {};
        var resourceType = this.resourceType.type;
        // Prefill fields' value with the values from the resource
        if (this.resource instanceof InfarmItem) {
            Object.assign(config, omap(this.resource, function (key) { return key; }, function (value) { return ({ value: value }); }));
        }
        // Override config (if provided)
        if (isJsObject(this.config)) {
            Object.assign(config, this.config);
        }
        resourceType
            .schema()
            .then(function (schema) {
            return fields.fromSchema(schema, Action.Edit, config, {}, _this.skipFields);
        })
            .then(function (fields) {
            _this.fcs.updateFormGroup(_this.form, fields);
            _this.fields = fields;
        });
    }
    Object.defineProperty(DynamicFormDialogComponent.prototype, "disabled", {
        get: function () {
            return this.form.pristine || !this.form.valid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DynamicFormDialogComponent.prototype, "sanitize", {
        get: function () {
            return !this.resource;
        },
        enumerable: true,
        configurable: true
    });
    DynamicFormDialogComponent.prototype.renderAsTextarea = function (field) {
        return (field instanceof TextField &&
            field.type === 'text' &&
            (typeof field.maxLength !== 'number' || field.maxLength > 250));
    };
    DynamicFormDialogComponent.prototype.fieldClass = function (field) {
        return field.controlType + "-field";
    };
    DynamicFormDialogComponent.prototype.submit = function () {
        var payload = this.fs.parse(this.fields, this.sanitize);
        payload = applyFormControlValues(payload, this.form);
        // We don't want to submit if we have an invalid form
        if (!this.disabled) {
            this.save(payload);
        }
    };
    DynamicFormDialogComponent.prototype.abort = function () {
        this.dialogRef.close();
    };
    DynamicFormDialogComponent.prototype.save = function (payload) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var infarmItem, e_1, resource, infarmItem, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.resource instanceof InfarmItem)) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.resource.update(payload)];
                    case 2:
                        infarmItem = _a.sent();
                        this.dialogRef.close(infarmItem);
                        this.toast.push(this.successMessage);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        this.toast.push(this.errorMessage);
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 9];
                    case 5:
                        resource = Reflect.construct(this.resourceType.type, [payload]);
                        _a.label = 6;
                    case 6:
                        _a.trys.push([6, 8, , 9]);
                        return [4 /*yield*/, resource.save()];
                    case 7:
                        infarmItem = _a.sent();
                        this.dialogRef.close(infarmItem);
                        this.toast.push(this.successMessage);
                        return [3 /*break*/, 9];
                    case 8:
                        e_2 = _a.sent();
                        this.toast.push(this.errorMessage);
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    return DynamicFormDialogComponent;
}());
export { DynamicFormDialogComponent };
