import * as tslib_1 from "tslib";
import { omap } from 'infarm-core';
import { apiRequest } from '../../utils';
import { API_HOST } from 'env';
import { toCamelCase, toSnakeCase } from '@infarm/potion-client';
var CrudService = /** @class */ (function () {
    function CrudService(name, auth) {
        this.auth = auth;
        this.root = API_HOST + "/internal/api";
        this.endpoint = '';
        var snakeCaseName = toSnakeCase(name);
        this.endpoint = this.root + "/" + snakeCaseName;
    }
    CrudService.prototype.delete = function (item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.auth.token()];
                    case 1:
                        token = _a.sent();
                        return [4 /*yield*/, apiRequest(this.endpoint + "/" + item.id, token, 'DELETE')];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, response.ok];
                }
            });
        });
    };
    CrudService.prototype.update = function (id, payload) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.auth.token()];
                    case 1:
                        token = _a.sent();
                        return [4 /*yield*/, apiRequest(this.endpoint + "/" + id, token, 'PATCH', JSON.stringify(payload))];
                    case 2:
                        response = _a.sent();
                        if (response.status === 200) {
                            return [2 /*return*/, response.json()];
                        }
                        else {
                            throw {
                                message: 'Error: Edit not saved'
                            };
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CrudService.prototype.create = function (payload) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, formattedPayload, response, jsoned, message;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.auth.token()];
                    case 1:
                        token = _a.sent();
                        formattedPayload = omap(payload, toSnakeCase);
                        return [4 /*yield*/, apiRequest("" + this.endpoint, token, 'POST', JSON.stringify(formattedPayload))];
                    case 2:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 3:
                        jsoned = _a.sent();
                        if (response.ok) {
                            jsoned.id = Number.parseInt(jsoned.$uri.split('/')[4], 10);
                            return [2 /*return*/, omap(jsoned, toCamelCase)];
                        }
                        else {
                            message = jsoned.message;
                            throw Error(message);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return CrudService;
}());
export { CrudService };
