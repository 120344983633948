import * as tslib_1 from "tslib";
import { ItemWithName } from './mixins/item-with-name';
import { UpdateableInfarmItem } from './mixins/updated-at';
import { Route } from '@infarm/potion-client';
export var Base = ItemWithName(UpdateableInfarmItem);
var EndProduct = /** @class */ (function (_super) {
    tslib_1.__extends(EndProduct, _super);
    function EndProduct() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EndProduct.prototype.toString = function () {
        return this.internalCode + "-" + this.name;
    };
    EndProduct.view = Route.GET('/catalogue');
    return EndProduct;
}(Base));
export { EndProduct };
