import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { isInfarmItem } from 'infarm-core';
import { MultipleSelect } from '../multiple-select';
import { ItemsTable } from './items-table';
var ItemsTableComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ItemsTableComponent, _super);
    function ItemsTableComponent() {
        var _this = _super.call(this) || this;
        _this.select = new EventEmitter();
        _this.paginate = new EventEmitter();
        _this.table = new ItemsTable();
        _this.setSources(_this.table.rows);
        return _this;
    }
    Object.defineProperty(ItemsTableComponent.prototype, "items", {
        set: function (items) {
            if (Array.isArray(items) && items.every(function (item) { return isInfarmItem(item); })) {
                this.table.update(items);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ItemsTableComponent.prototype, "schema", {
        set: function (schema) {
            if (schema) {
                this.table.setSchema(schema);
            }
        },
        enumerable: true,
        configurable: true
    });
    ItemsTableComponent.prototype.trackByRow = function (_, row) {
        return row.id;
    };
    ItemsTableComponent.prototype.trackByColumn = function (_, column) {
        return column.key;
    };
    return ItemsTableComponent;
}(MultipleSelect));
export { ItemsTableComponent };
