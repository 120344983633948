// tslint:disable: max-classes-per-file member-ordering
import { FetchOptions, Route } from '@infarm/potion-client';

import { from as fromPromise, Observable, of as ofObservable } from 'rxjs';
import { catchError, map, publishReplay, refCount, take } from 'rxjs/operators';

import { capitalize } from '../../utils/string';

import { Constructor } from './mixins/ctor';
import { ArchivableItem } from './mixins/archivable-item';
import { ItemWithName, ItemWithNameType } from './mixins/item-with-name';
import { UpdatedAt, UpdatedAtType } from './mixins/updated-at';

import { Controller } from './controller';
import { Farm } from './farm';
import { RecipeSet } from './recipe-set';
import { User } from './user';
import { FarmingUnitView } from './schemas/farming';
import { InfarmItem } from './mixins/infarm-item';

// TODO: Remove when https://github.com/Microsoft/TypeScript/issues/15870 is fixed
export const Base: Constructor<ItemWithNameType> &
    Constructor<UpdatedAtType> &
    typeof InfarmItem = ItemWithName(UpdatedAt(InfarmItem));

export class FarmingUnit extends ArchivableItem(Base) {
    /***
     * Farmbrain remote maintenance functionality
     */
    maintenance: (params?: any) => Promise<boolean> = Route.POST<boolean>(
        '/maintenance'
    );

    /**
     * Unit view
     */
    static view: (
        params: FarmingUnitViewParams,
        options?: FetchOptions
    ) => Promise<FarmingUnitView> = Route.GET<FarmingUnitView>('/view');

    /**
     * Dosing & Calibration
     */
    // TODO: Rename to a more appropriate name (calibrationOptions)
    static calibrationOptions(calibrationUrl: string): Observable<string[]> {
        const source = fromPromise(FarmingUnit.schema()).pipe(
            map(({ links }: any) => links),
            map(links =>
                links.find(({ href }: any) => href.includes(calibrationUrl))
            ),
            map(({ schema }) => schema),
            map(({ properties }) => properties),
            map(({ command }: any) => command.enum),
            catchError(() => ofObservable([]))
        );
        return publishReplay<string[]>()(source).pipe(refCount());
    }
    // TODO: Get the schema types from somewhere and use them
    static doseCommands(): Observable<DoseCommand[]> {
        const source = fromPromise(FarmingUnit.schema()).pipe(
            map(({ links }: any) => links),
            map(links =>
                links.find(({ href }: any) =>
                    href.includes('farming_unit/dose')
                )
            ),
            map(({ schema }) => schema),
            map(({ properties }) => properties),
            map(({ command }: any) => command.enum),
            map(commands =>
                commands.map((key: string) => ({
                    key,
                    name: capitalize(key.replace('_', ' '), true)
                }))
            ),
            catchError(() => ofObservable([]))
        );
        return publishReplay<DoseCommand[]>()(source).pipe(refCount());
    }
    private static calibrateEc: (
        params: CalibrateFarmingUnitParams,
        options?: FetchOptions
    ) => Promise<any> = Route.POST<any>('/calibrate_ec');
    private static calibratePh: (
        params: CalibrateFarmingUnitParams,
        options?: FetchOptions
    ) => Promise<any> = Route.POST<any>('/calibrate_ph');
    private static $dose: (
        params: DoseFarmingUnitParams,
        options?: FetchOptions
    ) => Promise<any> = Route.POST<any>('/dose');
    static dose(
        farmingUnit: FarmingUnitType,
        command: DoseCommand,
        value: number
    ): Promise<boolean> {
        return FarmingUnit.$dose({
            farmingUnit,
            value,
            command: command.key
        });
    }
    static calibrate(
        farmingUnit: FarmingUnitType,
        calibration: EcCalibration | PhCalibration
    ): Promise<boolean> {
        if (calibration instanceof EcCalibration) {
            return FarmingUnit.calibrateEc({
                ...calibration.params,
                farmingUnit
            });
        } else if (calibration instanceof PhCalibration) {
            return FarmingUnit.calibratePh({
                ...calibration.params,
                farmingUnit
            });
        }

        return Promise.reject(null);
    }

    /***
     * Farmbrain remote reboot functionality for maintenance team
     */
    static reset: (
        params: FarmingUnitRouteParams
    ) => Promise<boolean> = Route.POST<boolean>('/reset');

    farm: Farm;

    firstPlantingDate: Date;
    numColumns: number;
    numLevels: number;

    notes?: string;

    controllers?: Controller[];
    events: Event[];
    recipeSet?: RecipeSet;

    inMaintenance: boolean;
    hasHeater?: boolean;
    hasFlowRegulator?: boolean;

    grower: User;
}

/**
 * Route params interfaces
 */
export type FarmingUnitType = FarmingUnit | number | string;

export interface FarmingUnitRouteParams {
    farmingUnit: FarmingUnitType;
}

export type FarmingUnitViewParams = FarmingUnitRouteParams;

export interface CalibrateFarmingUnitParams extends FarmingUnitRouteParams {
    command: string;
    value: number | string;
}
export interface DoseFarmingUnitParams extends FarmingUnitRouteParams {
    command: string;
    value: number;
}

/**
 * Unit status route interfaces
 */
export interface FarmingUnitStatusView {
    dosingEnabled: boolean;
    isOnline: boolean;
    isDosing: boolean;
    isNight: boolean;
    inMaintenance: boolean;
    inSync: boolean;
}

export interface FarmingUnitObservation {
    [key: string]: any;
    dosingType: string;
    controllerId: number;
    sensorName: string;
    name: string;
    label: string;
    address: number;
    value: number;
    time: Date;
}

/**
 * Dosing
 */
export interface DoseCommand {
    name: string;
    key: string;
}

/**
 * Calibration
 */
export interface CalibrationParams {
    command: string;
    value: number | string;
}

export class Calibration {
    readonly options: Observable<string[]>;
    command: string;
    value: number | string;

    get params(): CalibrationParams {
        return {
            command: this.command,
            value: this.value
        };
    }
}

export class EcCalibration extends Calibration {
    command: string = '';
    readonly options: Observable<string[]> = FarmingUnit.calibrationOptions(
        '/calibrate_ec'
    );

    constructor() {
        super();
        // Set a default command
        this.options
            .pipe(
                take(1),
                map(options => options.slice(0).reverse().pop())
            )
            .subscribe((value: string | undefined) => {
                if (value) {
                    this.command = value;
                }
            });
    }
}

export class PhCalibration extends Calibration {
    command: string;
    readonly options: Observable<string[]> = FarmingUnit.calibrationOptions(
        '/calibrate_ph'
    );

    constructor() {
        super();
        // Set a default command
        this.options
            .pipe(
                take(1),
                map(options => options.slice(0).reverse().pop())
            )
            .subscribe((value: string | undefined) => {
                if (value) {
                    this.command = value;
                }
            });
    }
}
