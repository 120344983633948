export type Comparator<T> = (a: T, b: T) => number;

const algorithm: Map<string, Comparator<any>> = new Map<
    string,
    (a: any, b: any) => number
>([
    ['boolean', booleanCompare],
    ['number', numberCompare],
    ['string', stringCompare],
    // In case of an object,
    // we might be able compare the values if the object is a Date.
    ['object', objectCompare],
    // When the typeof value is undefined or function, we cannot really compare them,
    // so we just return the original array.
    ['undefined', noop],
    ['function', noop]
]);

export type Predicate<T, R> = (value: T) => R;
// Check https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort.
// NOTE: This fn does not mutate the contents of the original array.
export function sort<T, R>(items: T[], predicate?: Predicate<T, R>): T[] {
    const clone = items.slice(0);
    if (typeof predicate === 'function') {
        return clone.sort(compareWithPredicate<T, R>(predicate));
    }
    return clone.sort(compareWithoutPredicate);
}

export function compareWithoutPredicate(a: any, b: any): number {
    const compare = algorithm.get(typeof a) as Comparator<any>;
    return compare(a, b);
}
export function compareWithPredicate<T, R>(
    predicate: Predicate<T, R>
): (a: any, b: any) => number {
    return (a: any, b: any): number => {
        a = predicate(a);
        const compare = algorithm.get(typeof a) as Comparator<T>;
        b = predicate(b);
        return compare(a, b);
    };
}

function booleanCompare(a: boolean, b: boolean): number {
    return a === b ? 0 : a ? -1 : 1;
}
function stringCompare(a: string, b: string): number {
    // Convert a to a string (just in case is null or undefined).
    return `${a}`.localeCompare(b);
}
function numberCompare(a: number, b: number): number {
    return a - b;
}
function dateCompare(a: Date, b: Date): number {
    return numberCompare(a.getTime(), b.getTime());
}
function objectCompare(a: any, b: any): number {
    if (a instanceof Date && b instanceof Date) {
        return dateCompare(a, b);
    }
    return noop();
}
function noop(): number {
    return 0;
}
