import { flatten, isJsObject } from 'infarm-core';
import { Buffer } from 'buffer';
import { FetchResult } from 'apollo-link';

export function serializeUUID(resourceName: string, uuid: string): string {
    return Buffer.from(resourceName + ':' + uuid).toString('base64');
}

export function isConnection(childObject: any): boolean {
    return isJsObject(childObject) && 'edges' in childObject;
}

export function processNode(node: any): any {
    for (const key of Object.keys(node)) {
        const childObject: any = node[key];
        if (isConnection(childObject)) {
            node[key] = formatConnection(childObject);
        } else if (isJsObject(childObject)) {
            node[key] = processNode(childObject);
        }
    }
    return node;
}

export function formatConnection(connection: Connection): any[] {
    const edges: Edge[] = connection.edges;
    return edges.map(edge => edge.node).map(node => processNode(node));
}

export function formatConnections(connections: Connection[]) {
    return flatten(connections.map(connection => formatConnection(connection)));
}

export function handleMutationResponse<T>(
    response: FetchResult<any>,
    mutationName: string
): T {
    const { data } = response;
    return Object.values(data[mutationName])[0] as T;
}

export interface Connection {
    edges: Edge[];
}

export interface Edge {
    node: any;
}
