import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
    POTION_CONFIG,
    POTION_RESOURCES,
    PotionModule,
    PotionResources
} from '@infarm/potion-client';

import { potionPrefix, prefix } from './constants';
import { HTTP_ERROR_PROVIDER, HttpError } from './http-error';
import { HttpErrorInterceptor } from './http-error-interceptor';
import { PostInterceptor } from './post-interceptor';
import { ApiPrefixToken, ApiUrlInterceptor } from './url-interceptor';
import { Assignment } from './resources/assignment';

import {
    Address,
    ClimateRecipe,
    Controller,
    EcRecipe,
    EndProduct,
    FanRecipe,
    Farm,
    FarmingUnit,
    GrowingMedium,
    HarvestRecord,
    HarvestRound,
    HourlySchedule,
    Image,
    LightRecipe,
    LocationGroup,
    Mission,
    MissionImage,
    MissionRecord,
    Organization,
    PhRecipe,
    RecipeSchedule,
    RecipeSet,
    Role,
    RoleSet,
    Sector,
    SectorConfiguration,
    SeedingRecord,
    ShrinkageReason,
    TankFlushingRecipe,
    TrayType,
    User,
    Visit,
    VisitImage,
    VisitRecord,
    WaterRefillingRecipe
} from './resources/index';

export const INFARM_POTION_RESOURCES: PotionResources = {
    '/address': Address,
    '/assignment': Assignment,
    '/climate_recipe': ClimateRecipe,
    '/controller': Controller,
    '/ec_recipe': EcRecipe,
    '/end_product': EndProduct,
    '/farm': Farm,
    '/fan_recipe': FanRecipe,
    '/farming_unit': FarmingUnit,
    '/growing_medium': GrowingMedium,
    '/harvest_record': HarvestRecord,
    '/harvest_round': HarvestRound,
    '/hourly_schedule': HourlySchedule,
    '/image': Image,
    '/light_recipe': LightRecipe,
    '/location_group': LocationGroup,
    '/mission': Mission,
    '/mission_image': MissionImage,
    '/mission_record': MissionRecord,
    '/seeding_record': SeedingRecord,
    '/organization': Organization,
    '/ph_recipe': PhRecipe,
    '/wrf_recipe': WaterRefillingRecipe,
    '/wfl_recipe': TankFlushingRecipe,
    '/recipe_schedule': RecipeSchedule,
    '/recipe_set': RecipeSet,
    '/role': Role,
    '/role_set': RoleSet,
    '/sector': Sector,
    '/sector_configuration': SectorConfiguration,
    '/shrinkage_reason': ShrinkageReason,
    '/tray_type': TrayType,
    '/user': User,
    '/visit': Visit,
    '/visit_image': VisitImage,
    '/visit_record': VisitRecord
};

@NgModule({
    imports: [HttpClientModule, PotionModule],
    exports: [HttpClientModule, PotionModule],
    providers: [
        // Potion
        {
            provide: POTION_CONFIG,
            useValue: {
                prefix: potionPrefix
            }
        },
        {
            provide: POTION_RESOURCES,
            useValue: INFARM_POTION_RESOURCES,
            multi: true
        },
        // Config
        {
            provide: ApiPrefixToken,
            useValue: prefix
        },
        // HttpClient interceptors
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiUrlInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: PostInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
            deps: [HttpError]
        },
        HTTP_ERROR_PROVIDER
    ]
})
export class ApiModule {}

/**
 * Constants
 */
export { colors, potionPrefix, prefix } from './constants';

/**
 * Interceptors
 */
export { HttpErrorInterceptor } from './http-error-interceptor';
export { HttpError } from './http-error';
export { PostInterceptor } from './post-interceptor';
export {
    ApiHost,
    ApiHostToken,
    ApiPrefix,
    ApiPrefixToken,
    ApiUrlInterceptor,
    StringAggregate
} from './url-interceptor';

/**
 * Potion Resources
 */
export {
    // Mixins
    ArchivableItem,
    CreatedAt,
    InfarmItemWithName,
    InfarmItem,
    JSONSchema,
    ItemWithAddresses,
    ItemWithAddressesType,
    ItemWithAuthor,
    ItemWithAuthorType,
    ItemWithName,
    ItemWithNameType,
    NoteItem,
    RecipeItem,
    UpdatedAt,
    UpdatedAtType,
    UpdateableInfarmItem,
    UpdateableRecipeItem,
    UpdateableRecordItem,
    // Schemas
    FarmingUnitView,
    ScheduledRecipeView,
    RecipesView,
    LightRecipeView,
    LightRecipeScheduleView,
    LightScheduleItem,
    HourlyScheduleView,
    WaterRecipeView,
    EcRecipeView,
    WaterRefillingRecipe,
    TankFlushingRecipe,
    PhRecipeView,
    BaseRecipe,
    EndProductCatalogView,
    GrowingMediumView,
    SectorTaskType,
    SectorConfigurationView,
    SectorConfigurationsView,
    SectorView,
    PlannedSeedingRound,
    SeedingRecord,
    ShrinkageState,
    ShrinkageReason,
    TrayTypeView,
    // Address
    Address,
    AddressType,
    AddressView,
    ClimateRecipe,
    // Controller
    Controller,
    // Ec recipe
    EcRecipe,
    EndProduct,
    // Farm
    Farm,
    FarmCategory,
    FarmRouteParams,
    FarmViewParams,
    FarmNames,
    FarmManageParams,
    GeneralOverview,
    AssetPreview,
    OrganizationView,
    FarmPreview,
    FarmView,
    FarmingUnitPreview,
    // Farming unit
    FarmingUnit,
    FarmingUnitType,
    FarmingUnitRouteParams,
    FarmingUnitViewParams,
    CalibrateFarmingUnitParams,
    DoseFarmingUnitParams,
    FarmingUnitStatusView,
    FarmingUnitObservation,
    DoseCommand,
    Calibration,
    CalibrationParams,
    EcCalibration,
    PlantingPeriod,
    PhCalibration,
    GrowingMedium,
    HarvestRecord,
    // Harvest route
    HarvestRound,
    HarvestRoundView,
    DayOfWeek,
    harvestRoundAsString,
    HourlySchedule,
    Image,
    LightRecipe,
    FanRecipe,
    Mission,
    MissionRecord,
    // Location groups
    LocationGroup,
    Assignment,
    Organization,
    // Ph recipe
    PhRecipe,
    // Recipe schedule
    RecipeSchedule,
    RecipeScheduleType,
    RecipeSet,
    Role,
    RoleSet,
    Sector,
    SectorConfiguration,
    Task,
    TrayType,
    TrayVariant,
    // User
    User,
    UserRouteParams,
    ResetPasswordParams,
    SetPasswordParams,
    UserView,
    Visit,
    VisitRecord,
    VisitImage,
    MissionImage,
    TankFlushingRecipeView,
    WaterRefillingRecipeView
} from './resources/index';
