import * as tslib_1 from "tslib";
import { findFarmUuid } from './farm.resolver';
import { utc } from 'moment';
import { GraphqlService } from '../graphql/graphql.service';
import { THREE_WEEKS_IN_DAYS, toShortDate } from '../utils';
import { MAX_GRAPHQL_PAGE_SIZE } from '../graphql/utils';
import { sectorConfigurations } from '../graphql/queries';
import { processNode, serializeUUID } from '../graphql/relay-utils';
var SectorConfigurationsViewResolver = /** @class */ (function () {
    function SectorConfigurationsViewResolver(graphql) {
        this.graphql = graphql;
        this.monday = 1;
    }
    SectorConfigurationsViewResolver.prototype.resolve = function (route) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var graphqlId, date, lower, upper, queryResult, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        graphqlId = serializeUUID('Farm', findFarmUuid(route));
                        date = route.queryParams.date;
                        lower = this.getLowerBounds(date);
                        upper = this.getUpperBounds(date);
                        return [4 /*yield*/, this.graphql.query(sectorConfigurations, {
                                id: graphqlId,
                                plantingPeriodOverlapping: {
                                    lower: lower,
                                    upper: upper,
                                    bounds: '[)'
                                },
                                numberOfItems: MAX_GRAPHQL_PAGE_SIZE
                            })];
                    case 1:
                        queryResult = _a.sent();
                        return [2 /*return*/, processNode(queryResult.data.node)];
                    case 2:
                        err_1 = _a.sent();
                        return [2 /*return*/, null];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SectorConfigurationsViewResolver.prototype.getLowerBounds = function (date) {
        var moment = date ? utc(parseInt(date, 10)) : utc();
        var lower = moment
            .days(this.monday - THREE_WEEKS_IN_DAYS)
            .startOf('day');
        return toShortDate(lower.toDate());
    };
    SectorConfigurationsViewResolver.prototype.getUpperBounds = function (date) {
        var extraTwoWeeks = 14;
        var moment = date ? utc(parseInt(date, 10)) : utc();
        var upper = moment
            .days(this.monday + THREE_WEEKS_IN_DAYS + extraTwoWeeks)
            .startOf('day');
        return toShortDate(upper.toDate());
    };
    return SectorConfigurationsViewResolver;
}());
export { SectorConfigurationsViewResolver };
