import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { GraphqlService } from './graphql/graphql.service';
import { usersQuery } from './graphql/queries/user.query';
import { UsersService } from './services/current-user-graphql-service';
import { User } from './graphql/interfaces/shared';
import { Assignment } from 'infarm-core';

@Injectable()
export class UsersResolver implements Resolve<UserWithAssignments[]> {
    constructor(private graphqlService: GraphqlService) {}
    async resolve(): Promise<UserWithAssignments[]> {
        const paginator = this.graphqlService.makePaginator(
            'users',
            usersQuery
        );
        return paginator.fetchAllEdges();
    }
}

// tslint:disable-next-line:max-classes-per-file
@Injectable()
export class CurrentUserGraphqResolver implements Resolve<User> {
    constructor(private currentUser: UsersService) {}

    async resolve(): Promise<User> {
        return this.currentUser.getLoggedInUser();
    }
}

export interface UserWithAssignments extends User {
    unassignedRoles: Assignment[];
    assignments: any[];
}

export interface UserWithPendingAssignments {
    user: UserWithAssignments;
    pendingAssignments: Assignment[];
}
