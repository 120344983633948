import * as tslib_1 from "tslib";
import { isNumber, isString, sort } from 'infarm-core';
import { Potion } from '@infarm/potion-client';
import { isDate } from 'moment';
import { Action, isAnyOf, isPrimitive, PropertyType, Schema, toLabel } from '../../resources/schema';
import { ProgressService } from '../../progress.service';
import { CheckboxField, DateField, NumberField, Option, SelectField, TextField } from '../fields';
var FieldsService = /** @class */ (function () {
    function FieldsService(potion, progress) {
        this.potion = potion;
        this.progress = progress;
        this.query = {
            perPage: 2500
        };
    }
    FieldsService.prototype.fromSchema = function (json, forAction, fieldsConfig, queryMap, skipOptions) {
        if (forAction === void 0) { forAction = Action.Create; }
        if (fieldsConfig === void 0) { fieldsConfig = {}; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var schema, fields, entries, entries_1, entries_1_1, _a, key, validation, type, fieldConfig, value, skip, fieldRequired, hide, required, fieldOptions, query, value_1, options, maxLength, items, _b, $ref, value_2, resource, items_1, options, e_1, properties, _c, _d, $ref, _e, $date, value_3, value_4, resource, items, options, e_2, e_3_1;
            var e_3, _f;
            return tslib_1.__generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        // Signal work start
                        this.progress.show();
                        schema = new Schema(json);
                        fields = [];
                        entries = Array.from(schema.properties(forAction).entries()).filter(function (_a) {
                            var _b = tslib_1.__read(_a, 1), key = _b[0];
                            var fieldConfig = fieldsConfig[key];
                            if (fieldConfig) {
                                return !fieldConfig.skip;
                            }
                            return true;
                        });
                        _g.label = 1;
                    case 1:
                        _g.trys.push([1, 18, 19, 20]);
                        entries_1 = tslib_1.__values(entries), entries_1_1 = entries_1.next();
                        _g.label = 2;
                    case 2:
                        if (!!entries_1_1.done) return [3 /*break*/, 17];
                        _a = tslib_1.__read(entries_1_1.value, 2), key = _a[0], validation = _a[1];
                        type = validation.type;
                        fieldConfig = fieldsConfig[key] || {};
                        value = fieldConfig.value;
                        skip = skipOptions ? skipOptions[key] : [];
                        fieldRequired = fieldConfig[key]
                            ? fieldConfig[key].required
                            : false;
                        hide = fieldsConfig[key] ? fieldsConfig[key].hide : false;
                        required = !isAnyOf(type, PropertyType.Null) || fieldRequired;
                        fieldOptions = {
                            label: toLabel(key),
                            value: validation.default,
                            hide: hide,
                            required: required,
                            key: key
                        };
                        if (queryMap) {
                            query = queryMap[key];
                            if (query !== undefined) {
                                Object.assign(this.query, query);
                            }
                            else {
                                this.query = {
                                    perPage: 2500
                                };
                            }
                        }
                        if (value !== undefined) {
                            Object.assign(fieldOptions, { value: value });
                        }
                        if (!(isPrimitive(type) && validation.hasOwnProperty('enum'))) return [3 /*break*/, 3];
                        value_1 = fieldOptions.value;
                        options = toOption(validation.enum).filter(fieldConfig.filter || noop);
                        // If no option was provided from config or by default from schema,
                        // set the first available option as selected.
                        if (value_1 === undefined && options.length) {
                            Object.assign(fieldOptions, {
                                value: fromOption(options[0])
                            });
                        }
                        fields.push(new SelectField(tslib_1.__assign({}, fieldOptions, { options: options })));
                        return [3 /*break*/, 16];
                    case 3:
                        if (!isAnyOf(type, PropertyType.String)) return [3 /*break*/, 4];
                        maxLength = validation.maxLength;
                        fields.push(new TextField(tslib_1.__assign({}, fieldOptions, { maxLength: maxLength })));
                        return [3 /*break*/, 16];
                    case 4:
                        if (!isAnyOf(type, [PropertyType.Number, PropertyType.Integer])) return [3 /*break*/, 5];
                        fields.push(new NumberField(fieldOptions));
                        return [3 /*break*/, 16];
                    case 5:
                        if (!isAnyOf(type, PropertyType.Boolean)) return [3 /*break*/, 6];
                        fields.push(new CheckboxField(fieldOptions));
                        return [3 /*break*/, 16];
                    case 6:
                        if (!isAnyOf(type, PropertyType.Array)) return [3 /*break*/, 11];
                        items = validation.items;
                        _b = (items.properties || {}).$ref, $ref = _b === void 0 ? null : _b;
                        if (!(isAnyOf(items.type, PropertyType.Object) && $ref)) return [3 /*break*/, 10];
                        value_2 = fieldOptions.value;
                        _g.label = 7;
                    case 7:
                        _g.trys.push([7, 9, , 10]);
                        resource = findResourceByRefPattern($ref, this.potion);
                        return [4 /*yield*/, resource.query(this.query, {
                                skip: skip
                            })];
                    case 8:
                        items_1 = _g.sent();
                        options = toOption(items_1).filter(fieldConfig.filter || noop);
                        // If no option was provided from config or by default from schema,
                        // set the first available option as selected (if the field is required).
                        if (value_2 === undefined && options.length && required) {
                            Object.assign(fieldOptions, {
                                value: fromOption([options[0]])
                            });
                        }
                        fields.push(new SelectField(tslib_1.__assign({}, fieldOptions, { multiple: true, options: options })));
                        return [3 /*break*/, 10];
                    case 9:
                        e_1 = _g.sent();
                        this.progress.hide();
                        throw e_1;
                    case 10: return [3 /*break*/, 16];
                    case 11:
                        if (!isAnyOf(type, PropertyType.Object)) return [3 /*break*/, 16];
                        properties = validation.properties;
                        _c = properties || {}, _d = _c.$ref, $ref = _d === void 0 ? null : _d, _e = _c.$date, $date = _e === void 0 ? null : _e;
                        if (!$date) return [3 /*break*/, 12];
                        value_3 = fieldOptions.value;
                        fields.push(new DateField(tslib_1.__assign({}, fieldOptions, { value: isString(value_3) || isNumber(value_3)
                                ? new Date(value_3)
                                : isDate(value_3)
                                    ? value_3
                                    : new Date() })));
                        return [3 /*break*/, 16];
                    case 12:
                        if (!$ref) return [3 /*break*/, 16];
                        value_4 = fieldOptions.value;
                        _g.label = 13;
                    case 13:
                        _g.trys.push([13, 15, , 16]);
                        resource = findResourceByRefPattern($ref, this.potion);
                        return [4 /*yield*/, resource.query(this.query, {
                                skip: skip
                            })];
                    case 14:
                        items = _g.sent();
                        options = sort(toOption(items).filter(fieldConfig.filter || noop), function (option) { return option.name; });
                        // If no option was provided from config or by default from schema,
                        // set the first available option as selected.
                        if (value_4 === undefined && options.length) {
                            Object.assign(fieldOptions, {
                                value: fromOption(options[0])
                            });
                        }
                        fields.push(new SelectField(tslib_1.__assign({}, fieldOptions, { options: options })));
                        return [3 /*break*/, 16];
                    case 15:
                        e_2 = _g.sent();
                        this.progress.hide();
                        throw e_2;
                    case 16:
                        entries_1_1 = entries_1.next();
                        return [3 /*break*/, 2];
                    case 17: return [3 /*break*/, 20];
                    case 18:
                        e_3_1 = _g.sent();
                        e_3 = { error: e_3_1 };
                        return [3 /*break*/, 20];
                    case 19:
                        try {
                            if (entries_1_1 && !entries_1_1.done && (_f = entries_1.return)) _f.call(entries_1);
                        }
                        finally { if (e_3) throw e_3.error; }
                        return [7 /*endfinally*/];
                    case 20:
                        this.progress.hide();
                        return [2 /*return*/, fields];
                }
            });
        });
    };
    return FieldsService;
}());
export { FieldsService };
/**
 * Try to find the matching Potion resource based on a schema $ref pattern.
 * @param {JSONSchema} ref
 * @param {Potion} potion
 */
export function findResourceByRefPattern(ref, potion) {
    var resources = potion.resources, prefix = potion.prefix;
    var pattern = ref.pattern;
    // If Potion has a prefix,
    // we need to remove it from the pattern,
    // otherwise we cannot match to any resource path as all resource paths do not include the prefix.
    if (prefix) {
        pattern = pattern.replace(
        // Escape the forward slash so we can find it in the `$ref` pattern.
        prefix.replace(/\//g, '\\/'), '');
    }
    // Create the regex based on the schema `$ref` pattern.
    var regex = new RegExp(pattern);
    // Find matching path for the regex in all registered Potion resources.
    var match = Object.keys(resources)
        // Append a resource id to the resource path.
        // All patterns expect a path to a specific resource,
        // not a path to all the resources.
        .find(function (path) { return regex.test(path + "/0"); });
    // If we could not find a match we'll throw as it's highly likely the dev forgot to register the resource in Potion
    if (!match) {
        throw new TypeError("Unable to find a resource that matches " + pattern);
    }
    return resources[match];
}
export function fromOption(option) {
    if (Array.isArray(option)) {
        return option.map(function (item) { return fromOption(item); });
    }
    return option.value;
}
export function toOption(item) {
    if (Array.isArray(item)) {
        return item.map(function (item) { return toOption(item); });
    }
    return new Option(item);
}
function noop(option) {
    return option;
}
