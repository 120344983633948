import * as tslib_1 from "tslib";
import { Route } from '@infarm/potion-client';
import { ArchivableItem } from './mixins/archivable-item';
import { UpdatedAt } from './mixins/updated-at';
import { InfarmItem } from './mixins/infarm-item';
// TODO: Remove when https://github.com/Microsoft/TypeScript/issues/15870 is fixed
export var Base = UpdatedAt(InfarmItem);
var User = /** @class */ (function (_super) {
    tslib_1.__extends(User, _super);
    function User() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    User.prototype.toString = function () {
        var familyName = this.familyName;
        return "" + this.firstName + (familyName ? " " + familyName : '');
    };
    /**
     * Grower list
     */
    User.growers = Route.GET('/growers');
    /**
     * Get current user-name.
     */
    User.me = Route.GET('/me');
    User.setRoleSet = Route.POST('/me');
    User.setPassword = Route.POST('/set_password');
    User.resetPassword = Route.POST('/reset_password');
    User.changePassword = Route.POST('/change_password');
    return User;
}(ArchivableItem(Base)));
export { User };
