import * as tslib_1 from "tslib";
import { Token } from './token';
import { HttpClient } from '@angular/common/http';
var Jwt = /** @class */ (function () {
    function Jwt(http) {
        this.http = http;
    }
    Jwt.prototype.refreshJwtToken = function (refreshToken) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.http
                            .get('/auth/refresh', {
                            headers: {
                                accept: 'application/json',
                                authorization: "Bearer " + refreshToken
                            },
                            withCredentials: true
                        })
                            .subscribe(function (data) {
                            return resolve(new Token(data.access_token, data.refresh_token));
                        }, reject);
                    })];
            });
        });
    };
    return Jwt;
}());
export { Jwt };
