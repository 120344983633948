import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth } from 'infarm-core';
import { apiRequest } from '../utils';
import { setLoginRedirect } from '@infarm/auth';
var LayoutComponent = /** @class */ (function () {
    function LayoutComponent(router, route, auth) {
        var _this = this;
        this.router = router;
        this.route = route;
        this.auth = auth;
        this.logoutHandler = function (event) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(event.detail && event.detail.suppressRedirect)) return [3 /*break*/, 1];
                        // Logout event has been triggered from within the app, don't do anything
                        return [2 /*return*/];
                    case 1:
                        // Logout event came from the outside, handle it like a manual logout
                        setLoginRedirect(window.location.href, '#/login');
                        return [4 /*yield*/, this.auth.logout()];
                    case 2:
                        _a.sent();
                        this.router.navigateByUrl('/login');
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.API_HOST = this.route.snapshot.data.API_HOST;
    }
    LayoutComponent.prototype.ngOnInit = function () {
        this.getKompostVersion();
        window.addEventListener('logout', this.logoutHandler);
    };
    LayoutComponent.prototype.ngOnDestroy = function () {
        window.removeEventListener('logout', this.logoutHandler);
    };
    LayoutComponent.prototype.getKompostVersion = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, response, json, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.auth.token()];
                    case 1:
                        token = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 5, , 6]);
                        return [4 /*yield*/, apiRequest(this.API_HOST + "/internal/healthcheck", token, 'GET')];
                    case 3:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 4:
                        json = _a.sent();
                        this.kompostVersion = json.GIT_HASH;
                        return [3 /*break*/, 6];
                    case 5:
                        e_1 = _a.sent();
                        this.kompostVersion = 'unknown';
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    LayoutComponent.prototype.forceScheduleNavigation = function () {
        var _this = this;
        // this forces the schedule page to refresh by redirecting to an (empty) route
        // without forcing a reload of the schedule component, the visits overlap
        this.router
            .navigateByUrl('/empty', { skipLocationChange: true })
            .then(function () {
            _this.router.navigate(['/schedule']);
        });
    };
    return LayoutComponent;
}());
export { LayoutComponent };
