import * as tslib_1 from "tslib";
import { from as fromPromise, of as ofObservable } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { InfarmItem } from './mixins/infarm-item';
var Image = /** @class */ (function (_super) {
    tslib_1.__extends(Image, _super);
    function Image() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Image.categories = function () {
        var source = fromPromise(Image.schema()).pipe(map(function (_a) {
            var properties = _a.properties;
            return properties;
        }), map(function (_a) {
            var category = _a.category;
            return category.enum;
        }), map(function (categories) {
            return categories.filter(function (category) { return category !== null; });
        }), catchError(function () { return ofObservable([]); }));
        return publishReplay()(source).pipe(refCount());
    };
    return Image;
}(InfarmItem));
export { Image };
