import gql from 'graphql-tag';
export const endProductsQuery = gql`
    query PaginatedEndProducts(
        $numberOfItems: Int!
        $cursorAfter: String
        $isActive: Boolean
    ) {
        endProducts(
            first: $numberOfItems
            after: $cursorAfter
            isActive: $isActive
            sort: id_asc
        ) {
            pageInfo {
                endCursor
                hasNextPage
            }
            edges {
                node {
                    id
                    pk
                    uuid
                    active
                    name
                    internalCode
                    propagationDays
                    propagationTolerance
                    weight
                    backupPercent
                    description
                    recommendedTrayType {
                        id
                        pk
                        uuid
                        name
                        stages
                        throughput
                        tracks
                    }
                }
            }
        }
    }
`;
