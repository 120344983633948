import * as tslib_1 from "tslib";
// tslint:disable: max-classes-per-file member-ordering
import { Route } from '@infarm/potion-client';
import { from as fromPromise, of as ofObservable } from 'rxjs';
import { catchError, map, publishReplay, refCount, take } from 'rxjs/operators';
import { capitalize } from '../../utils/string';
import { ArchivableItem } from './mixins/archivable-item';
import { ItemWithName } from './mixins/item-with-name';
import { UpdatedAt } from './mixins/updated-at';
import { InfarmItem } from './mixins/infarm-item';
// TODO: Remove when https://github.com/Microsoft/TypeScript/issues/15870 is fixed
export var Base = ItemWithName(UpdatedAt(InfarmItem));
var FarmingUnit = /** @class */ (function (_super) {
    tslib_1.__extends(FarmingUnit, _super);
    function FarmingUnit() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /***
         * Farmbrain remote maintenance functionality
         */
        _this.maintenance = Route.POST('/maintenance');
        return _this;
    }
    /**
     * Dosing & Calibration
     */
    // TODO: Rename to a more appropriate name (calibrationOptions)
    FarmingUnit.calibrationOptions = function (calibrationUrl) {
        var source = fromPromise(FarmingUnit.schema()).pipe(map(function (_a) {
            var links = _a.links;
            return links;
        }), map(function (links) {
            return links.find(function (_a) {
                var href = _a.href;
                return href.includes(calibrationUrl);
            });
        }), map(function (_a) {
            var schema = _a.schema;
            return schema;
        }), map(function (_a) {
            var properties = _a.properties;
            return properties;
        }), map(function (_a) {
            var command = _a.command;
            return command.enum;
        }), catchError(function () { return ofObservable([]); }));
        return publishReplay()(source).pipe(refCount());
    };
    // TODO: Get the schema types from somewhere and use them
    FarmingUnit.doseCommands = function () {
        var source = fromPromise(FarmingUnit.schema()).pipe(map(function (_a) {
            var links = _a.links;
            return links;
        }), map(function (links) {
            return links.find(function (_a) {
                var href = _a.href;
                return href.includes('farming_unit/dose');
            });
        }), map(function (_a) {
            var schema = _a.schema;
            return schema;
        }), map(function (_a) {
            var properties = _a.properties;
            return properties;
        }), map(function (_a) {
            var command = _a.command;
            return command.enum;
        }), map(function (commands) {
            return commands.map(function (key) { return ({
                key: key,
                name: capitalize(key.replace('_', ' '), true)
            }); });
        }), catchError(function () { return ofObservable([]); }));
        return publishReplay()(source).pipe(refCount());
    };
    FarmingUnit.dose = function (farmingUnit, command, value) {
        return FarmingUnit.$dose({
            farmingUnit: farmingUnit,
            value: value,
            command: command.key
        });
    };
    FarmingUnit.calibrate = function (farmingUnit, calibration) {
        if (calibration instanceof EcCalibration) {
            return FarmingUnit.calibrateEc(tslib_1.__assign({}, calibration.params, { farmingUnit: farmingUnit }));
        }
        else if (calibration instanceof PhCalibration) {
            return FarmingUnit.calibratePh(tslib_1.__assign({}, calibration.params, { farmingUnit: farmingUnit }));
        }
        return Promise.reject(null);
    };
    /**
     * Unit view
     */
    FarmingUnit.view = Route.GET('/view');
    FarmingUnit.calibrateEc = Route.POST('/calibrate_ec');
    FarmingUnit.calibratePh = Route.POST('/calibrate_ph');
    FarmingUnit.$dose = Route.POST('/dose');
    /***
     * Farmbrain remote reboot functionality for maintenance team
     */
    FarmingUnit.reset = Route.POST('/reset');
    return FarmingUnit;
}(ArchivableItem(Base)));
export { FarmingUnit };
var Calibration = /** @class */ (function () {
    function Calibration() {
    }
    Object.defineProperty(Calibration.prototype, "params", {
        get: function () {
            return {
                command: this.command,
                value: this.value
            };
        },
        enumerable: true,
        configurable: true
    });
    return Calibration;
}());
export { Calibration };
var EcCalibration = /** @class */ (function (_super) {
    tslib_1.__extends(EcCalibration, _super);
    function EcCalibration() {
        var _this = _super.call(this) || this;
        _this.command = '';
        _this.options = FarmingUnit.calibrationOptions('/calibrate_ec');
        // Set a default command
        _this.options
            .pipe(take(1), map(function (options) { return options.slice(0).reverse().pop(); }))
            .subscribe(function (value) {
            if (value) {
                _this.command = value;
            }
        });
        return _this;
    }
    return EcCalibration;
}(Calibration));
export { EcCalibration };
var PhCalibration = /** @class */ (function (_super) {
    tslib_1.__extends(PhCalibration, _super);
    function PhCalibration() {
        var _this = _super.call(this) || this;
        _this.options = FarmingUnit.calibrationOptions('/calibrate_ph');
        // Set a default command
        _this.options
            .pipe(take(1), map(function (options) { return options.slice(0).reverse().pop(); }))
            .subscribe(function (value) {
            if (value) {
                _this.command = value;
            }
        });
        return _this;
    }
    return PhCalibration;
}(Calibration));
export { PhCalibration };
