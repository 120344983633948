import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { toUrlEncoded } from '../utils/object';

/**
 * Handle POST requests with form urlencoded content type.
 */
@Injectable()
export class PostInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const headers = req.headers;
        if (
            req.method === 'POST' &&
            headers.has('content-type') &&
            headers.get('content-type') === 'application/x-www-form-urlencoded'
        ) {
            req = req.clone({
                body: toUrlEncoded(req.body)
            });
        }
        return next.handle(req);
    }
}
