import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { PreloadSelectedModulesOnly } from 'app-core';
import { AuthGuard } from 'app-shared';

import { API_HOST, isStaging } from '../env';
import { LayoutComponent } from 'app-shared/layout/layout.component';
import { EmptyComponent } from 'app-shared/empty-component/empty-component.component';
import { APP_BASE_HREF } from '@angular/common';

// Check https://angular.io/docs/ts/latest/guide/router.html for more info about the Router and routing in general.
// For lazy loading check: http://angularjs.blogspot.ch/2016/08/angular-2-rc5-ngmodules-lazy-loading.html, https://github.com/angular/angular/pull/10705.
export const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        data: {
            isStaging: isStaging(),
            API_HOST
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'location-group',
                canLoad: [AuthGuard]
            },
            {
                path: 'farm',
                loadChildren: () =>
                    import('./farm/farm.module').then(
                        module => module.FarmsModule
                    ),
                canLoad: [AuthGuard]
            },
            {
                path: 'nurseries',
                loadChildren: () =>
                    import('./nurseries/nurseries.module').then(
                        module => module.NurseriesModule
                    ),
                canLoad: [AuthGuard]
            },
            {
                path: 'schedule',
                loadChildren: () =>
                    import('./schedule/schedule.module').then(
                        module => module.ScheduleModule
                    ),
                canLoad: [AuthGuard]
            },
            {
                path: 'end-product-catalog',
                loadChildren: () =>
                    import(
                        './end-product-catalog/end-product-catalog.module'
                    ).then(module => module.ProductCatalogModule),
                canLoad: [AuthGuard]
            },
            {
                path: 'resources',
                loadChildren: () =>
                    import('./resources/resources.module').then(
                        module => module.ResourcesModule
                    ),
                canLoad: [AuthGuard]
            },
            {
                path: 'recipes',
                loadChildren: () =>
                    import('./recipes/recipes.module').then(
                        module => module.RecipesModule
                    ),
                canLoad: [AuthGuard]
            },
            {
                path: 'location-group',
                loadChildren: () =>
                    import('./location-groups/location-groups.module').then(
                        module => module.LocationGroupsModule
                    ),
                canLoad: [AuthGuard]
            },
            {
                path: 'empty',
                component: EmptyComponent
            }
        ]
    },
    {
        path: '**',
        component: EmptyComponent
    }
];

export const config: ExtraOptions = {
    preloadingStrategy: PreloadSelectedModulesOnly,
    useHash: true
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule],
    providers: [{ provide: APP_BASE_HREF, useValue: '/' }]
})
export class AppRoutingModule {}
