import {
    Directive,
    Input,
    Renderer2,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';

import { DialogService } from '../dialog.service';

import { NotesDialogComponent } from './notes-dialog.component';

@Directive({ selector: '[inShowNotes]' })
export class ShowNotesDirective {
    private $notes: string;
    private hasView = false;
    private clickListener;

    @Input() set inShowNotes(notes: string) {
        if (notes && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
            this.clickListener = this.renderer.listen(
                this.templateRef.elementRef.nativeElement.nextSibling,
                'click',
                () => {
                    this.dialog.open(NotesDialogComponent, {
                        data: { notes: this.notes }
                    });
                }
            );
        } else if (!notes && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
            if (this.clickListener) {
                this.clickListener();
            }
        }
        this.$notes = notes;
    }

    get notes(): string {
        return this.$notes;
    }

    constructor(
        public dialog: DialogService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private renderer: Renderer2
    ) {}
}
