import { Injectable } from '@angular/core';

import { DialogService } from '../dialog.service';
import {
    ConfirmationDialogData,
    ConfirmDialogComponent
} from './confirm-dialog.component';

@Injectable()
export class ConfirmationService {
    constructor(public dialog: DialogService) {}
    async show(data?: ConfirmationDialogData): Promise<boolean> {
        const config = { data, disableClose: true };
        const accept = await this.dialog
            .open(ConfirmDialogComponent, config)
            .afterClosed()
            .toPromise();
        return accept;
    }
}
