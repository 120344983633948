/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../farmboard-legacy/node_modules/@angular/material/button/typings/index.ngfactory";
import * as i2 from "@angular/material/button";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "../../../farmboard-legacy/node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i6 from "@angular/material/icon";
import * as i7 from "./resource-picker.component";
import * as i8 from "../dialog.service";
var styles_ResourcePickerComponent = [];
var RenderType_ResourcePickerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ResourcePickerComponent, data: {} });
export { RenderType_ResourcePickerComponent as RenderType_ResourcePickerComponent };
export function View_ResourcePickerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "button", [["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.pick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(1, 180224, null, 0, i2.MatButton, [i0.ElementRef, i3.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i5.View_MatIcon_0, i5.RenderType_MatIcon)), i0.ɵdid(3, 9158656, null, 0, i6.MatIcon, [i0.ElementRef, i6.MatIconRegistry, [8, null], [2, i6.MAT_ICON_LOCATION], [2, i0.ErrorHandler]], null, null), (_l()(), i0.ɵted(-1, 0, ["arrow_drop_down"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.color; _ck(_v, 1, 0, currVal_2); _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 1).disabled || null); var currVal_1 = (i0.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i0.ɵnov(_v, 3).inline; var currVal_4 = (((i0.ɵnov(_v, 3).color !== "primary") && (i0.ɵnov(_v, 3).color !== "accent")) && (i0.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_3, currVal_4); }); }
export function View_ResourcePickerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "in-resource-picker", [], null, null, null, View_ResourcePickerComponent_0, RenderType_ResourcePickerComponent)), i0.ɵdid(1, 114688, null, 0, i7.ResourcePickerComponent, [i8.DialogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResourcePickerComponentNgFactory = i0.ɵccf("in-resource-picker", i7.ResourcePickerComponent, View_ResourcePickerComponent_Host_0, { color: "color", title: "title", resourceType: "resourceType", resource: "resource", query: "query", placeholder: "placeholder", required: "required", multiple: "multiple" }, { select: "select" }, []);
export { ResourcePickerComponentNgFactory as ResourcePickerComponentNgFactory };
