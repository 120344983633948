import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Address, AddressType, capitalize, compare } from 'infarm-core';

import { DynamicFormDialogService } from '../dynamic-form';
import { ResourceType } from '../resources';

@Component({
    selector: 'in-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss']
})
export class AddressComponent {
    @Input() address: Address | null;
    @Input() type: AddressType = 'shipping';
    @Input() allowSelect: boolean = true;

    @Output() set: EventEmitter<AddressWithType> = new EventEmitter();

    resourceType: ResourceType = ResourceType.findByItem(Address);

    get title(): string {
        return `${capitalize(this.type)} address`;
    }
    get hasAddress(): boolean {
        return this.address instanceof Address;
    }

    constructor(private dynamicFormDialog: DynamicFormDialogService) {}

    select(address: Address | null): void {
        // We only emit the selected address if it's different from the one that was previously selected
        if (!compare(address, this.address)) {
            this.setAddress(address);
        }
    }

    edit(): void {
        this.dynamicFormDialog.open({
            resourceType: this.resourceType,
            resource: this.address,
            config: {
                type: {
                    value: this.type,
                    hide: true
                }
            }
        });
    }
    async add(): Promise<void> {
        const data = {
            resourceType: this.resourceType,
            config: {
                type: {
                    value: this.type,
                    hide: true
                }
            }
        };
        const address = await this.dynamicFormDialog.open(data);
        if (address instanceof Address) {
            this.setAddress(address);
        }
    }

    private setAddress(address: Address | null): void {
        this.address = address;
        this.set.emit({
            address,
            type: this.type
        });
    }
}

export interface AddressWithType {
    address: Address | null | undefined;
    type: AddressType;
}
