import * as tslib_1 from "tslib";
import { UpdateableRecipeItem } from './mixins/updated-at';
var ClimateRecipe = /** @class */ (function (_super) {
    tslib_1.__extends(ClimateRecipe, _super);
    function ClimateRecipe() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ClimateRecipe;
}(UpdateableRecipeItem));
export { ClimateRecipe };
