import * as tslib_1 from "tslib";
import { Controller, FarmingUnit } from 'infarm-core';
import { FarmingUnitViewResolver } from './farming-unit-view.resolver';
import { RecipesResolver } from './recipes.resolver';
import { ApiFarmResolver } from './farm.resolver';
var FarmingUnitAndRecipesResolver = /** @class */ (function () {
    function FarmingUnitAndRecipesResolver(farmingUnitViewResolver, recipesResolver, farmResolver) {
        this.farmingUnitViewResolver = farmingUnitViewResolver;
        this.recipesResolver = recipesResolver;
        this.farmResolver = farmResolver;
    }
    FarmingUnitAndRecipesResolver.prototype.resolve = function (route) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var farmingUnit, recipes, skip, controllers, fetchedUnit, redirectDetails, controller, farm, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        return [4 /*yield*/, this.farmingUnitViewResolver.resolve(route)];
                    case 1:
                        farmingUnit = _a.sent();
                        this.recipesResolver.setFarmingUnitView(farmingUnit);
                        return [4 /*yield*/, this.recipesResolver.resolve()];
                    case 2:
                        recipes = _a.sent();
                        skip = ['farmingUnit', 'createdBy'];
                        return [4 /*yield*/, Controller.query({ where: { farmingUnit: farmingUnit.id } }, { skip: skip })];
                    case 3:
                        controllers = _a.sent();
                        return [4 /*yield*/, FarmingUnit.fetch(farmingUnit.id, {
                                skip: ['controllers', 'createdBy', 'farm', 'grower']
                            })];
                    case 4:
                        fetchedUnit = _a.sent();
                        redirectDetails = {
                            isGen2: fetchedUnit.isGen2,
                            serialNumber: fetchedUnit.serialChamber
                        };
                        controller = resolveMasterController(controllers);
                        return [4 /*yield*/, this.farmResolver.resolve(route)];
                    case 5:
                        farm = _a.sent();
                        return [2 /*return*/, {
                                farmingUnit: farmingUnit,
                                recipes: recipes,
                                controller: controller,
                                farm: farm,
                                redirectDetails: redirectDetails
                            }];
                    case 6:
                        err_1 = _a.sent();
                        return [2 /*return*/, {}];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    return FarmingUnitAndRecipesResolver;
}());
export { FarmingUnitAndRecipesResolver };
export function resolveMasterController(controllers) {
    return controllers.length > 0
        ? controllers.find(function (controller) { return controller.isActive; })
        : undefined;
}
