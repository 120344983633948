import { Injectable } from '@angular/core';
import { Token } from './token';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class Jwt {
    constructor(private http: HttpClient) {}

    async refreshJwtToken(refreshToken: string): Promise<Token> {
        return new Promise((resolve, reject) => {
            this.http
                .get('/auth/refresh', {
                    headers: {
                        accept: 'application/json',
                        authorization: `Bearer ${refreshToken}`
                    },
                    withCredentials: true
                })
                .subscribe(
                    (data: any) =>
                        resolve(
                            new Token(data.access_token, data.refresh_token)
                        ),
                    reject
                );
        });
    }
}
