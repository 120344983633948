import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { take } from 'rxjs/operators';

import { performLoginRedirect } from '@infarm/auth';

import { Auth } from 'infarm-core';

@Injectable()
export class RedirectGuard implements CanActivate {
    constructor(private auth: Auth) {}

    canActivateChild(): Promise<boolean> {
        return this.canActivate();
    }
    canLoad(): Promise<boolean> {
        return this.canActivate();
    }

    async canActivate(): Promise<boolean> {
        const token = await this.auth.change.pipe(take(1)).toPromise();
        if (token) {
            performLoginRedirect('#/');
            return false;
        } else {
            return true;
        }
    }
}
