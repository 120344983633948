import * as tslib_1 from "tslib";
import { UpdateableRecipeItem } from './mixins/updated-at';
var EcRecipe = /** @class */ (function (_super) {
    tslib_1.__extends(EcRecipe, _super);
    function EcRecipe() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EcRecipe;
}(UpdateableRecipeItem));
export { EcRecipe };
