import { Injectable } from '@angular/core';

import { DialogService } from '../../dialog.service';
import {
    DynamicFormDialogComponent,
    DynamicFormDialogData
} from './dynamic-form-dialog.component';

@Injectable()
export class DynamicFormDialogService {
    constructor(private dialogService: DialogService) {}

    open(data: DynamicFormDialogData): Promise<any> {
        return new Promise(resolve => {
            const defaultSuccessMessage = 'Your request was processed';
            const defaultErrorMessage = 'Your request could not be processed';
            data.successMessage =
                data.successMessage === undefined
                    ? defaultSuccessMessage
                    : data.successMessage;
            data.errorMessage =
                data.errorMessage === undefined
                    ? defaultErrorMessage
                    : data.errorMessage;
            this.dialogService
                .open(DynamicFormDialogComponent, { data })
                .afterClosed()
                .subscribe(result => {
                    resolve(result);
                });
        });
    }
}
