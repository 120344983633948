import * as tslib_1 from "tslib";
import { FarmingUnit } from 'infarm-core';
var FarmingUnitService = /** @class */ (function () {
    function FarmingUnitService() {
    }
    FarmingUnitService.prototype.assignGrower = function (growerId, farmingUnitId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var farmingUnit;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, FarmingUnit.fetch(farmingUnitId)];
                    case 1:
                        farmingUnit = (_a.sent());
                        return [4 /*yield*/, farmingUnit.update({
                                grower: growerId
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return FarmingUnitService;
}());
export { FarmingUnitService };
