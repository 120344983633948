import * as tslib_1 from "tslib";
import { Item } from '@infarm/potion-client';
var Assignment = /** @class */ (function (_super) {
    tslib_1.__extends(Assignment, _super);
    function Assignment() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Assignment;
}(Item));
export { Assignment };
