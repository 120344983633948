import * as tslib_1 from "tslib";
import { Auth, omap } from 'infarm-core';
import { CrudService } from './crud.service';
import { apiRequest } from '../../utils';
import { API_HOST } from 'env';
import { toCamelCase } from '@infarm/potion-client';
var FarmingUnitCrudService = /** @class */ (function (_super) {
    tslib_1.__extends(FarmingUnitCrudService, _super);
    function FarmingUnitCrudService(auth) {
        return _super.call(this, 'FarmingUnit', auth) || this;
    }
    FarmingUnitCrudService.prototype.status = function (farmingUnitId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, response, jsoned, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.auth.token()];
                    case 1:
                        token = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 5, , 6]);
                        return [4 /*yield*/, apiRequest(API_HOST + "/internal/api/farming_unit/status?farming_unit=" + farmingUnitId, token, 'GET')];
                    case 3:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 4:
                        jsoned = _a.sent();
                        return [2 /*return*/, omap(jsoned, toCamelCase)];
                    case 5:
                        e_1 = _a.sent();
                        throw Error("An error happened when retrieving the status of the farm " + farmingUnitId);
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    return FarmingUnitCrudService;
}(CrudService));
export { FarmingUnitCrudService };
