import * as tslib_1 from "tslib";
// tslint:disable: max-classes-per-file
import { Subscriber } from 'rxjs';
/**
 * Emits the first item from an array.
 *
 * @return {MonoTypeOperatorFunction<T>} An fn that returns an Observable that emits the first item from the source (if an array).
 * @method firstFromArray
 */
export function firstFromArray() {
    return function firstFromArrayFunction(source) {
        return source.lift(new FirstFromArrayOperator());
    };
}
var FirstFromArrayOperator = /** @class */ (function () {
    function FirstFromArrayOperator() {
    }
    FirstFromArrayOperator.prototype.call = function (subscriber, source) {
        return source.subscribe(new FirstFromArraySubscriber(subscriber));
    };
    return FirstFromArrayOperator;
}());
export { FirstFromArrayOperator };
var FirstFromArraySubscriber = /** @class */ (function (_super) {
    tslib_1.__extends(FirstFromArraySubscriber, _super);
    function FirstFromArraySubscriber(destination) {
        return _super.call(this, destination) || this;
    }
    FirstFromArraySubscriber.prototype._next = function (value) {
        var destination = this.destination;
        if (Array.isArray(value)) {
            destination.next(value[0]);
        }
        else {
            destination.next(value);
        }
    };
    return FirstFromArraySubscriber;
}(Subscriber));
