import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Controller, FarmingUnit } from 'infarm-core';
import { FarmingUnitViewResolver } from './farming-unit-view.resolver';
import { RecipesResolver } from './recipes.resolver';
import { ApiFarmResolver } from './farm.resolver';

@Injectable()
export class FarmingUnitAndRecipesResolver
    implements Resolve<{ farmingUnit: FarmingUnit; recipes: any }> {
    constructor(
        private farmingUnitViewResolver: FarmingUnitViewResolver,
        private recipesResolver: RecipesResolver,
        private farmResolver: ApiFarmResolver
    ) {}

    async resolve(route: ActivatedRouteSnapshot): Promise<any> {
        try {
            const farmingUnit = await this.farmingUnitViewResolver.resolve(
                route
            );
            this.recipesResolver.setFarmingUnitView(farmingUnit);
            const recipes = await this.recipesResolver.resolve();
            const skip = ['farmingUnit', 'createdBy'];
            const controllers = await Controller.query(
                { where: { farmingUnit: farmingUnit.id } },
                { skip }
            );

            const fetchedUnit = await FarmingUnit.fetch(farmingUnit.id, {
                skip: ['controllers', 'createdBy', 'farm', 'grower']
            });
            const redirectDetails = {
                isGen2: fetchedUnit.isGen2,
                serialNumber: fetchedUnit.serialChamber
            };
            const controller = resolveMasterController(controllers as any);
            const farm = await this.farmResolver.resolve(route);
            return {
                farmingUnit,
                recipes,
                controller,
                farm,
                redirectDetails
            };
        } catch (err) {
            return {};
        }
    }
}

export function resolveMasterController(controllers: Controller[]): Controller {
    return controllers.length > 0
        ? controllers.find(controller => controller.isActive)
        : undefined;
}
