import * as tslib_1 from "tslib";
import { isFunction, isJsObject } from './guards';
/**
 * Object.map()
 * NOTE: This is NOT a recursive fn.
 * NOTE: This will not work with anything that is NOT and Object.
 * @param obj
 * @param keyMapFunction - Transform operation to apply on the key.
 * @param [valueMapFunction] - Transform operation to apply on the value.
 */
export function omap(obj, keyMapFunction, valueMapFunction) {
    if (isJsObject(obj)) {
        return Object.entries(obj)
            .map(function (_a) {
            var _b = tslib_1.__read(_a, 2), key = _b[0], value = _b[1];
            return [
                isFunction(keyMapFunction) ? keyMapFunction(key) : key,
                isFunction(valueMapFunction) ? valueMapFunction(value) : value
            ];
        })
            .reduce(function (a, _a) {
            var _b;
            var _c = tslib_1.__read(_a, 2), key = _c[0], value = _c[1];
            return (tslib_1.__assign({}, a, (_b = {}, _b[key] = value, _b)));
        }, {});
    }
    return obj;
}
/**
 * Convert object to UrlEncoded string
 */
export function toUrlEncoded(object) {
    var e_1, _a;
    var pairs = [];
    try {
        for (var _b = tslib_1.__values(Object.entries(object)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var _d = tslib_1.__read(_c.value, 2), key = _d[0], value = _d[1];
            pairs.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return pairs.join('&');
}
/**
 * Create a new obj, excluding given keys, from another
 * @param obj
 * @param keys
 */
// TODO: Use better type when https://github.com/Microsoft/TypeScript/issues/12215 is implemented
export function omit(obj, keys) {
    return Object.entries(obj)
        .filter(function (_a) {
        var _b = tslib_1.__read(_a, 1), key = _b[0];
        return !keys.includes(key);
    })
        .reduce(function (a, _a) {
        var _b;
        var _c = tslib_1.__read(_a, 2), key = _c[0], value = _c[1];
        return (tslib_1.__assign({}, a, (_b = {}, _b[key] = value, _b)));
    }, {});
}
