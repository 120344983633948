export const prefix = '/internal';
export const potionPrefix = '/internal/api';
export const colors: string[] = [
    '#2B3D2699',
    '#E2582299',
    '#65452299',
    '#8DB60099',
    '#882D1799',
    '#DCD30099',
    '#B3446C99',
    '#F6A60099',
    '#604E9799',
    '#F9937999',
    '#0067A599',
    '#E68FAC99',
    '#00885699',
    '#84848299',
    '#C2B28099',
    '#BE003299',
    '#A1CAF199',
    '#F3840099',
    '#87569299',
    '#F3C30099'
];
