import * as tslib_1 from "tslib";
import { GraphqlService } from '../graphql/graphql.service';
import { trayTypesQuery } from '../graphql/queries/farms.query';
var TrayTypesResolver = /** @class */ (function () {
    function TrayTypesResolver(graphql) {
        this.graphql = graphql;
    }
    TrayTypesResolver.prototype.resolve = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var trayTypes;
            return tslib_1.__generator(this, function (_a) {
                try {
                    trayTypes = this.graphql.makePaginator('trayTypes', trayTypesQuery, {
                        state: TrayTypeState.Active
                    });
                    return [2 /*return*/, trayTypes.fetchAllEdges()];
                }
                catch (err) {
                    return [2 /*return*/, []];
                }
                return [2 /*return*/];
            });
        });
    };
    return TrayTypesResolver;
}());
export { TrayTypesResolver };
var TrayTypeState;
(function (TrayTypeState) {
    TrayTypeState["Active"] = "active";
    TrayTypeState["Decommissioned"] = "decommissioned";
})(TrayTypeState || (TrayTypeState = {}));
