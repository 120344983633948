import * as tslib_1 from "tslib";
import { FormControl, FormGroup, Validators } from '@angular/forms';
var FieldControlService = /** @class */ (function () {
    function FieldControlService() {
    }
    FieldControlService.prototype.updateFormGroup = function (form, fields) {
        var e_1, _a;
        if (fields && fields.length) {
            try {
                for (var fields_1 = tslib_1.__values(fields), fields_1_1 = fields_1.next(); !fields_1_1.done; fields_1_1 = fields_1.next()) {
                    var field = fields_1_1.value;
                    if (!form.contains(field.key)) {
                        form.addControl(field.key, createControl(field));
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (fields_1_1 && !fields_1_1.done && (_a = fields_1.return)) _a.call(fields_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
    };
    FieldControlService.prototype.toFormGroup = function (fields) {
        var e_2, _a;
        var group = {};
        try {
            for (var fields_2 = tslib_1.__values(fields), fields_2_1 = fields_2.next(); !fields_2_1.done; fields_2_1 = fields_2.next()) {
                var field = fields_2_1.value;
                group[field.key] = createControl(field);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (fields_2_1 && !fields_2_1.done && (_a = fields_2.return)) _a.call(fields_2);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return new FormGroup(group);
    };
    return FieldControlService;
}());
export { FieldControlService };
function createControl(field) {
    // https://angular.io/docs/ts/latest/cookbook/form-validation.html#!#reactive
    var validators = [];
    if (field.required) {
        validators.push(Validators.required);
    }
    return new FormControl(typeof field.value === 'boolean' ? !!field.value : field.value || '', validators);
}
