import { Component, Input } from '@angular/core';
import { InfarmItem } from '../../api/resources/mixins/infarm-item';

@Component({
    selector: 'in-item-name',
    template: ` <span *ngIf="item" [innerHtml]="item.toHtmlString()"></span> `,
    styles: [
        `
            :host {
                display: inline-block;
            }
            span {
                display: inline;
            }
        `
    ]
})
export class ItemNameComponent {
    @Input() item: InfarmItem;
}
