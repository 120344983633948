import { Pipe, PipeTransform } from '@angular/core';

// In single-spa, the assets need to be loaded from a dynamic location,
// instead of hard coded to `/assets`. We use webpack public path for this.
// See https://webpack.js.org/guides/public-path/#root

function assetUrl(url: string): string {
    // @ts-ignore
    const publicPath = __webpack_public_path__;
    const publicPathSuffix = publicPath.endsWith('/') ? '' : '/';
    const urlPrefix = url.startsWith('/') ? '' : '/';

    if (!publicPath.startsWith('http')) {
        return `assets${urlPrefix}${url}`;
    }
    return `${publicPath}${publicPathSuffix}assets${urlPrefix}${url}`;
}

@Pipe({ name: 'inAssetUrl' })
export class InfarmAssetUrlPipe implements PipeTransform {
    transform(value: string): string {
        return assetUrl(value);
    }
}
