import { enableProdMode, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Router } from '@angular/router';
import { AppModule } from './app/app.module';
import { isProd, isStaging } from './env';
import singleSpaAngular, {
    getSingleSpaExtraProviders
} from 'single-spa-angular';
import { singleSpaPropsSubject } from './single-spa/single-spa-props';

// Enable prod mode
if (isProd() || isStaging()) {
    enableProdMode();
}

const lifecycles = singleSpaAngular({
    bootstrapFunction: singleSpaProps => {
        singleSpaPropsSubject.next(singleSpaProps);
        return platformBrowserDynamic(
            getSingleSpaExtraProviders()
        ).bootstrapModule(AppModule);
    },
    template: '<in-app />',
    Router,
    NgZone
});

export const bootstrap = lifecycles.bootstrap;
export const mount = lifecycles.mount;
export const unmount = lifecycles.unmount;
