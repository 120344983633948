import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ServicesModule } from '../../services/index';
import { UserNameComponent } from './user-name';

export * from './user-name';

@NgModule({
    imports: [CommonModule, ServicesModule],
    declarations: [UserNameComponent],
    exports: [UserNameComponent]
})
export class UserNameModule {}
