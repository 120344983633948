import { Constructor } from './mixins/ctor';
import { ItemWithName, ItemWithNameType } from './mixins/item-with-name';
import { UpdateableInfarmItem } from './mixins/updated-at';
import { FetchOptions, Route } from '@infarm/potion-client';
import { TrayType, TrayVariant } from './tray-type';
import { GrowingMedium } from './growing-medium';

export const Base: Constructor<ItemWithNameType> &
    typeof UpdateableInfarmItem = ItemWithName(UpdateableInfarmItem);

export class EndProduct extends Base {
    static view: (
        params: EndProductCatalogRouteParams,
        options?: FetchOptions
    ) => Promise<EndProductCatalogView> = Route.GET<EndProductCatalogView>(
        '/catalogue'
    );
    longName: string;
    recommendedTrayType: TrayType;
    internalCode: string;
    weight: number;
    propagationDays: number;
    propagationTolerance: number;
    recommendedGrowingMedium: GrowingMedium;
    description?: string;
    backupPercent: number;
    seedingNotes?: string;
    imgUrl?: string;
    active: boolean;
    variant: TrayVariant;
    perStageDuration: number;
    toString(): string {
        return `${this.internalCode}-${this.name}`;
    }
}

export interface EndProductCatalogView {
    id: number;
    name: string;
    imageUrl: string;
    propagationDays: number;
    weight: number;
    backupPercent: number;
}

export interface EndProductCatalogRouteParams {
    endProduct: string | number;
}
