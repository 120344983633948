import { Directive, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLinkWithHref } from '@angular/router';
import { LocationStrategy } from '@angular/common';

@Directive({
    selector: '[inScheduleLink]'
})
export class ScheduleRouterLinkDirective extends RouterLinkWithHref
    implements OnInit {
    @Input('inScheduleLink') scheduleHref: string;
    queryParams: any = {};

    constructor(
        private angularRouter: Router,
        route: ActivatedRoute,
        locationStrategy: LocationStrategy
    ) {
        super(angularRouter, route, locationStrategy);
        route.queryParams.subscribe(queryParams => {
            this.queryParams = queryParams;
        });
    }

    ngOnInit(): void {
        this.href = this.scheduleHref;
    }

    onClick(
        button: number,
        ctrlKey: boolean,
        metaKey: boolean,
        shiftKey: boolean
    ) {
        // we don't want to react to click when shift (selection modifier) is pressed
        if (shiftKey) {
            return false;
            // want to open the link in new tab when modifier was used
        } else if (metaKey || ctrlKey) {
            event.stopPropagation();
            // this query params modification shall be removed if ever https://github.com/infarm/farmboard/pull/235 or similar mechanism gets merged
            const hrefWithParams =
                this.scheduleHref + this.constructQueryStringFromParams();
            window.open(hrefWithParams, '_blank');
            return false;
            // or we want to navigate to appropriate location
        } else {
            this.angularRouter.navigate([this.href.replace('/#', '')], {
                queryParams: this.queryParams
            });
            return false;
        }
    }

    constructQueryStringFromParams(): string {
        return Object.keys(this.queryParams).reduce(
            (accumulator, current) =>
                accumulator + `${current}=${this.queryParams[current]}&`,
            Object.keys(this.queryParams).length ? '?' : ''
        );
    }
}
