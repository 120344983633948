import * as tslib_1 from "tslib";
import { isString } from './guards';
/**
 * Check if a value is valid JSON
 * @returns Parsed object or undefined.
 */
export function isJSON(value) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var obj;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(isString(value) && value.length > 0)) return [3 /*break*/, 2];
                    return [4 /*yield*/, parseAsync(value)];
                case 1:
                    obj = _a.sent();
                    return [2 /*return*/, obj];
                case 2: return [2 /*return*/];
            }
        });
    });
}
/**
 * Parse JSON strings async (and not in the main thread)
 * @param jsonStr
 */
export function parseAsync(jsonStr) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var res, obj, err_1;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    res = new Response(jsonStr);
                    return [4 /*yield*/, res.json()];
                case 1:
                    obj = _a.sent();
                    return [2 /*return*/, obj];
                case 2:
                    err_1 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
