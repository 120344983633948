import { Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { DialogService } from '../dialog.service';
import { NotesDialogComponent } from './notes-dialog.component';
var ShowNotesDirective = /** @class */ (function () {
    function ShowNotesDirective(dialog, templateRef, viewContainer, renderer) {
        this.dialog = dialog;
        this.templateRef = templateRef;
        this.viewContainer = viewContainer;
        this.renderer = renderer;
        this.hasView = false;
    }
    Object.defineProperty(ShowNotesDirective.prototype, "inShowNotes", {
        set: function (notes) {
            var _this = this;
            if (notes && !this.hasView) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.hasView = true;
                this.clickListener = this.renderer.listen(this.templateRef.elementRef.nativeElement.nextSibling, 'click', function () {
                    _this.dialog.open(NotesDialogComponent, {
                        data: { notes: _this.notes }
                    });
                });
            }
            else if (!notes && this.hasView) {
                this.viewContainer.clear();
                this.hasView = false;
                if (this.clickListener) {
                    this.clickListener();
                }
            }
            this.$notes = notes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShowNotesDirective.prototype, "notes", {
        get: function () {
            return this.$notes;
        },
        enumerable: true,
        configurable: true
    });
    return ShowNotesDirective;
}());
export { ShowNotesDirective };
