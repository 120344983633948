import gql from 'graphql-tag';
import { MAX_GRAPHQL_PAGE_SIZE } from '../utils';
export const locationGroupsList = gql`
    query locationGroupsList($numberOfItems: Int!, $cursorAfter: String) {
        locationGroups(first: $numberOfItems, after: $cursorAfter) {
            pageInfo {
                endCursor
                hasNextPage
            }
            edges {
                node {
                    uuid
                    id
                    name
                    pk
                    level
                    parent {
                        id
                        uuid
                        name
                        children(first: ${MAX_GRAPHQL_PAGE_SIZE}, sort: name_asc) {
                            edges {
                                node {
                                    uuid
                                    name
                                }
                            }
                        }
                    }
                    children(first: ${MAX_GRAPHQL_PAGE_SIZE}, sort: name_asc) {
                        edges {
                            node {
                                uuid
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const lightLocationGroupsList = gql`
    query lightLocationGroupsList($numberOfItems: Int!, $cursorAfter: String) {
        locationGroups(first: $numberOfItems, after: $cursorAfter) {
            pageInfo {
                endCursor
                hasNextPage
            }
            edges {
                node {
                    uuid
                    id
                    name
                    pk
                    level
                }
            }
        }
    }
`;

export const locationGroup = gql`
    query locationGroupById($id: ID!) {
        node(id: $id) {
            ... on LocationGroup {
                uuid
                pk
                name
                level
                id
                farms(first: ${MAX_GRAPHQL_PAGE_SIZE}, sort: name_asc) {
                    pageInfo {
                        endCursor
                        hasNextPage
                    }
                    edges {
                        node {
                            name
                            id
                            pk
                            uuid
                            category
                            farmingUnits {
                                edges {
                                    node {
                                        pk
                                        name
                                        farmId
                                    }
                                }
                            }
                            organization {
                                id
                                name
                            }
                        }
                    }
                }
                children {
                    edges {
                        node {
                            uuid
                            name
                        }
                    }
                }
                parent {
                    id
                    uuid
                    name
                    children {
                        edges {
                            node {
                                uuid
                                name
                            }
                        }
                    }
                }
                    assignments(first: ${MAX_GRAPHQL_PAGE_SIZE}) {
                    pageInfo {
                        endCursor
                        hasNextPage
                    }
                    edges {
                        node {
                            uuid
                            user {
                                uuid
                                name
                            }
                            role {
                                uuid
                                name
                            }
                            locationGroup {
                                uuid
                                name
                                level
                            }
                        }
                    }
                }
            }
        }
    }
`;
export const rolesQuery = gql`
    {
        roles {
            pageInfo {
                endCursor
                hasNextPage
            }
            edges {
                node {
                    uuid
                    name
                    id
                    pk
                    isGlobal
                }
            }
        }
    }
`;

export const locationGroupsListWithFarms = gql`
    query LocationGroupList($numberOfItems: Int!, $cursorAfter: String) {
        locationGroups(first: $numberOfItems, after: $cursorAfter) {
            pageInfo {
                endCursor
                hasNextPage
            }
            edges {
                node {
                    id
                    pk
                    uuid
                    name
                    level
                    parent {
                        id
                        uuid
                        name
                    }
                    farms(first: ${MAX_GRAPHQL_PAGE_SIZE}, sort: name_asc) {
                        pageInfo {
                            endCursor
                            hasNextPage
                        }
                        edges {
                            node {
                                name
                                id
                                uuid
                                pk
                                category
                                organization {
                                    id
                                    name
                                    uuid
                                    pk
                                    uuid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const assignment = gql`
    query assignmentQuery($id: ID!) {
        node(id: $id) {
            ... on Assignment {
                uuid
                role {
                    name
                    uuid
                }
                user {
                    name
                    uuid
                }
                locationGroup {
                    name
                    uuid
                }
            }
        }
    }
`;

export const filteredLocationGroupsQuery = gql`
    query getFilteredLocationGroups(
        $numberOfItems: Int
        $locationGroupNameIcontains: String
        $farmNameIcontains: String
        $organizationNameIcontains: String
        $farmCategory: String
    ) {
        locationGroups(
            first: $numberOfItems
            sort: name_asc
            locationGroupNameIcontains: $locationGroupNameIcontains
        ) {
            pageInfo {
                endCursor
                hasNextPage
            }
            edges {
                node {
                    id
                    pk
                    uuid
                    name
                    level
                    parent {
                        id
                        uuid
                        name
                    }
                    name
                    farms(
                        first: $numberOfItems
                        sort: name_asc
                        farmNameIcontains: $farmNameIcontains
                        organizationNameIcontains: $organizationNameIcontains
                        category: $farmCategory
                    ) {
                        pageInfo {
                            endCursor
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                pk
                                uuid
                                name
                                category
                                organization {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const filteredLocationGroupsWithFarmingUnitsQuery = gql`
    query getFilteredLocationGroups(
        $numberOfItems: Int
        $locationGroupNameIcontains: String
        $farmNameIcontains: String
        $organizationNameIcontains: String
        $farmCategory: String
        $farmingUnitNameIcontains: String
    ) {
        locationGroups(
            first: $numberOfItems
            sort: name_asc
            locationGroupNameIcontains: $locationGroupNameIcontains
            farmingUnitNameIcontains: $farmingUnitNameIcontains
        ) {
            pageInfo {
                endCursor
                hasNextPage
            }
            edges {
                node {
                    id
                    pk
                    uuid
                    name
                    level
                    parent {
                        id
                        uuid
                        name
                    }
                    name
                    farms(
                        first: $numberOfItems
                        sort: name_asc
                        farmNameIcontains: $farmNameIcontains
                        farmingUnitNameIcontains: $farmingUnitNameIcontains
                        organizationNameIcontains: $organizationNameIcontains
                        category: $farmCategory
                    ) {
                        pageInfo {
                            endCursor
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                pk
                                uuid
                                name
                                category
                                organization {
                                    id
                                    name
                                }
                                farmingUnits(
                                    first: $numberOfItems
                                    sort: name_asc
                                    farmingUnitNameIcontains: $farmingUnitNameIcontains
                                ) {
                                    pageInfo {
                                        endCursor
                                        hasNextPage
                                    }
                                    edges {
                                        node {
                                            id
                                            pk
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
