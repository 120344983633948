import * as tslib_1 from "tslib";
import { ControlType, FieldBase } from './base';
var DateField = /** @class */ (function (_super) {
    tslib_1.__extends(DateField, _super);
    function DateField(options) {
        if (options === void 0) { options = {}; }
        var _this = this;
        Object.assign(options, {
            controlType: ControlType.Date
        });
        _this = _super.call(this, options) || this;
        _this.type = 'text';
        return _this;
    }
    return DateField;
}(FieldBase));
export { DateField };
