import { Component, OnInit } from '@angular/core';

// EmptyComponent can be used as a redirect destination to force other components to refresh
// for more information see here: https://stackoverflow.com/questions/47813927/how-to-refresh-a-component-in-angular
@Component({
    selector: 'in-empty-route',
    template: ''
})
export class EmptyComponent implements OnInit {
    ngOnInit() {} // tslint:disable-line: no-empty
}
