import * as tslib_1 from "tslib";
import { take } from 'rxjs/operators';
import { performLoginRedirect } from '@infarm/auth';
import { Auth } from 'infarm-core';
var RedirectGuard = /** @class */ (function () {
    function RedirectGuard(auth) {
        this.auth = auth;
    }
    RedirectGuard.prototype.canActivateChild = function () {
        return this.canActivate();
    };
    RedirectGuard.prototype.canLoad = function () {
        return this.canActivate();
    };
    RedirectGuard.prototype.canActivate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.auth.change.pipe(take(1)).toPromise()];
                    case 1:
                        token = _a.sent();
                        if (token) {
                            performLoginRedirect('#/');
                            return [2 /*return*/, false];
                        }
                        else {
                            return [2 /*return*/, true];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return RedirectGuard;
}());
export { RedirectGuard };
