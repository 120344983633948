import { LocationGroupMutators } from '../graphql/mutations/update-location-group.service';
import {
    Assignment,
    Auth,
    flatten,
    LocationGroup,
    Role,
    Token,
    unique
} from 'infarm-core';
import { Subject } from 'rxjs';
import {
    assignment,
    locationGroup,
    locationGroupsListWithFarms
} from '../graphql/queries';
import {
    handleMutationResponse,
    processNode,
    serializeUUID
} from '../graphql/relay-utils';
import { API_HOST } from 'env';
import { Injectable } from '@angular/core';
import { apiRequest } from '../utils';
import { flattenTreeStructure, getRootNodes } from '../location-groups-utils';
import { GraphqlService } from '../graphql/graphql.service';
import { UsersResolver, UserWithAssignments } from '../users.resolver';
import { RolesResolver } from '../roles.resolver';

@Injectable({
    providedIn: 'root'
})
export class LocationGroupsService {
    apiEndpoint = `${API_HOST}/internal/api/location_group`;
    locationGroupModified$ = new Subject<LocationGroup>();
    locationGroupCreated$ = new Subject<LocationGroup>();

    locationGroupsEndpoint = `${API_HOST}/internal/api/location_group`;
    assignmentsEndpoint = `${API_HOST}/internal/api/assignment`;

    cachedToken: Token;

    get token(): Promise<Token> {
        return (async () => {
            if (!this.cachedToken) {
                this.cachedToken = await this.auth.token();
            }
            return this.cachedToken;
        })();
    }

    constructor(
        private graphqlService: GraphqlService,
        private mutators: LocationGroupMutators,
        private auth: Auth,
        private rolesResolver: RolesResolver
    ) {}

    async getLocationGroups(): Promise<LocationGroup[]> {
        const allGroupsPaginator = this.graphqlService.makePaginator(
            'locationGroups',
            locationGroupsListWithFarms
        );
        const allGroups = await allGroupsPaginator.fetchAllEdges();
        // this build tree and tree flattening is to a] sort location groups b] use a function
        // that will be using for building location group tree in one of future stories so that
        // the tree structure is consistent with the list
        const locationGroupsRootNodes = getRootNodes(allGroups);
        let listOfLocationGroups = [];
        for (const locationGroup of locationGroupsRootNodes) {
            listOfLocationGroups.push([...flattenTreeStructure(locationGroup)]);
        }
        listOfLocationGroups = unique(listOfLocationGroups);
        return flatten(listOfLocationGroups);
    }

    async getRoles(): Promise<Role[]> {
        return this.rolesResolver.resolve();
    }

    async getUsers(): Promise<UserWithAssignments[]> {
        const resolver = new UsersResolver(this.graphqlService);
        return resolver.resolve();
    }

    async getLocationGroup(uuid: string): Promise<LocationGroup> {
        const id = serializeUUID('LocationGroup', uuid);
        const group = await this.graphqlService.query(locationGroup, {
            id
        });
        const processed = processNode(group.data);
        return processed.node;
    }

    async createLocationGroup(
        name: string,
        parentId: string
    ): Promise<LocationGroup> {
        const response = await this.mutators
            .createLocationGroup({
                name,
                parentId
            })
            .toPromise();
        const locationGroup = handleMutationResponse<LocationGroup>(
            response,
            'createLocationGroup'
        );
        this.locationGroupCreated$.next(locationGroup);
        return locationGroup;
    }

    async changeLocationGroupName(locationGroup: LocationGroup): Promise<any> {
        const response = await this.mutators
            .updateLocationGroup({
                id: serializeUUID('LocationGroup', locationGroup.uuid),
                name: locationGroup.name
            })
            .toPromise();
        const updatedLocationGroup = handleMutationResponse<LocationGroup>(
            response,
            'updateLocationGroup'
        );
        this.locationGroupModified$.next(updatedLocationGroup);
    }

    async changeLocationGroupParent(
        id: string,
        parentId: string
    ): Promise<any> {
        const response = await this.mutators
            .updateLocationGroup({
                id,
                parentId
            })
            .toPromise();
        const updatedLocationGroup = handleMutationResponse<LocationGroup>(
            response,
            'updateLocationGroup'
        );
        this.locationGroupModified$.next(updatedLocationGroup);
    }

    async createAssignment(
        user: UserWithAssignments,
        locationGroup: LocationGroup,
        role: Role
    ): Promise<Assignment> {
        const request = await apiRequest(
            this.assignmentsEndpoint,
            await this.token,
            'POST',
            JSON.stringify({
                user: user.pk || user.id,
                location_group: locationGroup.pk,
                role: role.pk
            })
        );
        if (!request.ok) {
            throw Error(
                `Unable to create new assignment for ${user.name} as a ${role.name} within ${locationGroup.name} group`
            );
        }
        const assignmentViaRest = await request.json();
        const id = serializeUUID('Assignment', assignmentViaRest.uuid);
        const assignmentViaGraphql = await this.graphqlService.query(
            assignment,
            { id }
        );
        return processNode(assignmentViaGraphql.data.node);
    }

    async deleteAssignment(assignment: Assignment): Promise<void> {
        const request = await apiRequest(
            `${this.assignmentsEndpoint}/${assignment.pk}`,
            await this.token,
            'DELETE'
        );
        if (!request.ok) {
            throw Error(
                `Unable to delete assignment for role ${assignment.role.name}.`
            );
        }
    }
}
