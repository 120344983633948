import { ControlType, FieldBase, FieldBaseOptions } from './base';

export interface CheckboxFieldOptions extends FieldBaseOptions<boolean> {} // tslint:disable-line: no-empty-interface

export class CheckboxField extends FieldBase<boolean> {
    constructor(options: CheckboxFieldOptions = {}) {
        Object.assign(options, {
            controlType: ControlType.Checkbox
        });
        super(options);
    }
}
