import * as tslib_1 from "tslib";
import { NgZone, Optional, Provider, SkipSelf } from '@angular/core';
import { parseAsync } from '../utils/json';
var Storage = /** @class */ (function () {
    function Storage(zone) {
        this.zone = zone;
    }
    /**
     * Get an item from local storage
     * @param key
     * @param [zone] - If provided, it will run the action in an NgZone
     */
    Storage.get = function (key, zone) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var json, data, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        json = window.localStorage.getItem(uniqueKey(key));
                        if (!json) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, parseAsync(json)];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, data];
                    case 3:
                        e_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        if (zone instanceof NgZone) {
                            return [2 /*return*/, zone.run(function () { return json; })];
                        }
                        return [2 /*return*/, json];
                }
            });
        });
    };
    /**
     * Set an item in local storage
     * @param key
     * @param data
     */
    Storage.prototype.set = function (key, data) {
        var _this = this;
        return this.zone.run(function () {
            window.localStorage.setItem(uniqueKey(key), JSON.stringify(data));
            return _this;
        });
    };
    /**
     * Get an item from local storage
     * @param key
     */
    Storage.prototype.get = function (key) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, Storage.get(key, this.zone)];
            });
        });
    };
    /**
     * Remove an item from local storage
     * @param key
     */
    Storage.prototype.remove = function (key) {
        var _this = this;
        return this.zone.run(function () {
            window.localStorage.removeItem(uniqueKey(key));
            return _this;
        });
    };
    /**
     * Clear all in local storage
     * NOTE: Only removes items that have the namespace
     */
    Storage.prototype.clear = function () {
        var _this = this;
        return this.zone.run(function () {
            for (var key in localStorage) {
                if (key.startsWith(storageKey)) {
                    localStorage.removeItem(key);
                }
            }
            return _this;
        });
    };
    return Storage;
}());
export { Storage };
// We namespace all keys to avoid conflicts with other 3rd party lib
var storageKey = '@infarm';
export function uniqueKey(key) {
    return storageKey + ":" + key;
}
export function STORAGE_PROVIDER_FACTORY(parentFactory, zone) {
    return parentFactory || new Storage(zone);
}
export var STORAGE_PROVIDER = {
    // If there is already a Storage available, use that.
    // Otherwise, provide a new one.
    provide: Storage,
    useFactory: STORAGE_PROVIDER_FACTORY,
    deps: [[new Optional(), new SkipSelf(), Storage], NgZone]
};
