import { InfarmItem } from '../api/resources/mixins/infarm-item';

/**
 * Check if a value is a function.}
 */
export function isFunction(value: any): value is (...args: any[]) => any {
    return typeof value === 'function';
}

/**
 * Check if a value is a InfarmItem item.
 */
export function isInfarmItem(value: any): value is InfarmItem {
    return value instanceof InfarmItem;
}

/**
 * Check if a value is a non-null object.
 */
export function isJsObject(value: any): value is { [key: string]: any } {
    return typeof value === 'object' && value !== null;
}

/**
 * Check if a value is a string and empty.
 */
export function isEmptyString(value: any): value is string {
    return isString(value) && /^\s*$/.test(value);
}

/**
 * Check if a value is a string containing only numbers/digits.
 */
export function isStringNumber(value: any): value is string {
    return isString(value) && /^\d+$/.test(value);
}

/**
 * Check if a value is a number.
 */
export function isNumber(value: any): value is number {
    return typeof value === 'number';
}

/**
 * Check if a value is a string.
 */
export function isString(value: any): value is string {
    return typeof value === 'string';
}
