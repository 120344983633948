import * as tslib_1 from "tslib";
import { Route } from '@infarm/potion-client';
import { UpdateableInfarmItem } from './mixins/updated-at';
var HourlySchedule = /** @class */ (function (_super) {
    tslib_1.__extends(HourlySchedule, _super);
    function HourlySchedule() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.setFromTimeArray = Route.POST('/set_from_time_array');
        return _this;
    }
    return HourlySchedule;
}(UpdateableInfarmItem));
export { HourlySchedule };
