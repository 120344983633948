import { Injectable, TemplateRef } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';

@Injectable()
export class DialogService {
    constructor(private dialog: MatDialog) {}

    open<T>(
        componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
        options?: MatDialogConfig
    ): MatDialogRef<T> {
        const config = new MatDialogConfig();
        config.panelClass = 'in-dialog-fit';
        Object.assign(config, options);
        return this.dialog.open(componentOrTemplateRef, config);
    }
}
