import { Injectable, Optional, Provider, SkipSelf } from '@angular/core';
import { compare } from 'infarm-core';
import { Observable, ReplaySubject } from 'rxjs';
import { animationFrame } from 'rxjs/scheduler/animationFrame';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class ProgressService {
    private visibility: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    visible: Observable<boolean> = this.visibility
        .asObservable()
        .pipe(distinctUntilChanged(compare)); // tslint:disable-line: member-ordering

    show(): void {
        animationFrame.schedule(() => {
            this.visibility.next(true);
        });
    }
    hide(): void {
        animationFrame.schedule(() => {
            this.visibility.next(false);
        });
    }
}

export function PROGRESS_SERVICE_PROVIDER_FACTORY(
    parentFactory: ProgressService
): ProgressService {
    return parentFactory || new ProgressService();
}

export const PROGRESS_SERVICE_PROVIDER: Provider = {
    // If there is already a ProgressService available, use that.
    // Otherwise, provide a new one.
    provide: ProgressService,
    useFactory: PROGRESS_SERVICE_PROVIDER_FACTORY,
    deps: [[new Optional(), new SkipSelf(), ProgressService]]
};
