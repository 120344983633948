import { Constructor } from './mixins/ctor';
import { ArchivableItem } from './mixins/archivable-item';
import { ItemWithAddresses } from './mixins/item-with-addresses';
import { ItemWithName, ItemWithNameType } from './mixins/item-with-name';
import { UpdatedAtType } from './mixins/updated-at';
import { Farm } from './farm';

// TODO: Remove when https://github.com/Microsoft/TypeScript/issues/15870 is fixed
export const Base: Constructor<ItemWithNameType> &
    Constructor<UpdatedAtType> &
    typeof ItemWithAddresses = ItemWithName(ItemWithAddresses);

export class Organization extends ArchivableItem(Base) {
    farms: Farm[];
}
