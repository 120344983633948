import { Injectable } from '@angular/core';
import { HarvestRoundView } from 'infarm-core';

import { DialogService } from '../dialog.service';
import {
    HarvestRoundData,
    HarvestRoundDialogComponent,
    HarvestRoundObject
} from './harvest-round-dialog.component';

@Injectable()
export class HarvestRoundDialogService {
    constructor(public dialog: DialogService) {}

    open(
        data?: HarvestRoundData
    ): Promise<HarvestRoundObject | HarvestRoundView | undefined> {
        return this.dialog
            .open(HarvestRoundDialogComponent, { data })
            .afterClosed()
            .toPromise();
    }
}
