import { LocationGroup } from 'infarm-core';
export interface Address {
    line1: string;
    line2: string;
    line3: string;
    city: string;
    postcode: string;
    zip: string;
}

export interface User extends GraphqlResource, PotionResource {
    name: string;
    isAdmin: boolean;
    assignments: Assignment[];
    email: string;
    roles: Role[];
}

export interface Assignment extends GraphqlResource, PotionResource {
    role: Role;
    user: User;
    locationGroup: LocationGroup;
}

export interface Role extends GraphqlResource {
    name: UserRole;
}

export enum UserRole {
    PlantDoctor = 'plant_doctor',
    Grower = 'grower',
    Controller = 'controller',
    Technician = 'technician',
    Guest = 'guest',
    SuperAdmin = 'super_admin',
    NurseryGrower = 'nursery_grower',
    MaintenanceExpert = 'maintenance_expert',
    GroupManager = 'group_manager',
    NurserySupervisor = 'NURSERY_SUPERVISOR',
    FarmPlanner = 'FARM_PLANNER',
    ProductCatalogManager = 'PRODUCT_CATALOG_MANAGER',
    LeadGrower = 'LEAD_GROWER',
    GeneralManager = 'GENERAL_MANAGER',
    OperationsManager = 'operations_manager',
    MaintenanceSupervisor = 'maintenance_supervisor'
}

interface GraphqlResource {
    uuid: string;
    id: string;
}

interface PotionResource {
    pk: string;
}
