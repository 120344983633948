import * as tslib_1 from "tslib";
import { take } from 'rxjs/operators';
import { Auth } from 'infarm-core';
import { announceLogout, setLoginRedirect } from '@infarm/auth';
var AuthGuard = /** @class */ (function () {
    function AuthGuard(auth) {
        this.auth = auth;
    }
    AuthGuard.prototype.canActivateChild = function () {
        return this.canActivate();
    };
    AuthGuard.prototype.canLoad = function () {
        return this.canActivate();
    };
    AuthGuard.prototype.canActivate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.auth.change.pipe(take(1)).toPromise()];
                    case 1:
                        token = _a.sent();
                        if (token) {
                            return [2 /*return*/, true];
                        }
                        else {
                            setLoginRedirect(window.location.href);
                            announceLogout();
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return AuthGuard;
}());
export { AuthGuard };
