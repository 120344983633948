import * as tslib_1 from "tslib";
import { Optional, Provider, SkipSelf } from '@angular/core';
import { from as fromPromise } from 'rxjs';
import { Farm } from 'infarm-core';
var NurseriesService = /** @class */ (function () {
    function NurseriesService() {
        this.items = this.nurseryQuery();
    }
    NurseriesService.prototype.nurseryQuery = function () {
        // TODO: Use pagination instead of a huge page size
        return fromPromise(Farm.query({
            where: { category: 'NURSERY' },
            perPage: 100
        }, {
            skip: [
                'farmingUnits',
                'addresses',
                'category',
                'organization',
                'locationGroup',
                'nursery',
                'location',
                'createdBy'
            ]
        }));
    };
    return NurseriesService;
}());
export { NurseriesService };
export function NURSERIES_SERVICE_PROVIDER_FACTORY(parentFactory) {
    return parentFactory || new NurseriesService();
}
export function routeToNursery(nurseries, navigation) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var id;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!!nurseries.some(function (nursery) {
                        return navigation.router.url.includes("" + nursery.id);
                    })) return [3 /*break*/, 2];
                    id = nurseries[0].id;
                    return [4 /*yield*/, navigation.router.navigate(["./" + id], {
                            relativeTo: navigation.activatedRoute,
                            skipLocationChange: true
                        })];
                case 1:
                    _a.sent();
                    navigation.location.replaceState("/nurseries/" + id);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    });
}
export var NURSERIES_SERVICE_PROVIDER = {
    // If there is already a NurseriesService available, use that.
    // Otherwise, provide a new one.
    provide: NurseriesService,
    useFactory: NURSERIES_SERVICE_PROVIDER_FACTORY,
    deps: [[new Optional(), new SkipSelf(), NurseriesService]]
};
