import * as tslib_1 from "tslib";
import { isString } from '../../../utils/guards';
export function ItemWithName(Base) {
    return /** @class */ (function (_super) {
        tslib_1.__extends(class_1, _super);
        function class_1() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        class_1.prototype.toString = function () {
            if (isString(this.name)) {
                return this.name;
            }
            return _super.prototype.toString.call(this);
        };
        return class_1;
    }(Base));
}
