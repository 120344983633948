import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe can be used to extract the rendering information for an element rendered in the UI (e.g. color, offset)
 * It recieves a map of rendering data objects. The key is the id of the item in the collection.
 * Each object contains the rendering data for the specific UI element and are identified by an id
 * The key parameter indicates which rendering information we are interested in.
 */
@Pipe({
    name: 'inExtractRenderData'
})
export class ExtractRenderDataPipe implements PipeTransform {
    transform(renderDataMap: Map<number, any>, id: number, key: string): any {
        if (renderDataMap) {
            const renderData = renderDataMap.get(id);
            if (renderData) {
                return renderData[key];
            }
        }
    }
}
