import * as tslib_1 from "tslib";
import { readonly, Route } from '@infarm/potion-client';
import { streamify } from '../../utils/streamify';
import { ArchivableItem } from './mixins/archivable-item';
import { ItemWithAddresses } from './mixins/item-with-addresses';
import { ItemWithName } from './mixins/item-with-name';
import { User } from './user';
// TODO: Remove when https://github.com/Microsoft/TypeScript/issues/15870 is fixed
export var Base = ItemWithName(ItemWithAddresses);
var Farm = /** @class */ (function (_super) {
    tslib_1.__extends(Farm, _super);
    function Farm() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Farm.overviewStream = function (zone) {
        var farm = this;
        return streamify(overview, zone, 5);
        function overview() {
            return farm.overview();
        }
    };
    Farm.previewStream = function (zone) {
        var farm = this;
        return streamify(preview, zone, 5, []);
        function preview() {
            return farm.preview();
        }
    };
    Farm.viewStream = function (zone, params) {
        var farm = this;
        return streamify(view, zone, 5);
        function view() {
            return farm.view(params);
        }
    };
    /**
     * Get farm overview.
     */
    // TODO: Switch to web sockets
    Farm.overview = Route.GET('/overview');
    /**
     * Get farms preview.
     */
    // TODO: Switch to web sockets
    // TODO: This is actually the organization view, so maybe move the route to Organization resource instead
    Farm.preview = Route.GET('/preview');
    /**
     * Get a farm's view.
     */
    // TODO: Switch to web sockets
    Farm.view = Route.GET('/view');
    Farm.manage = Route.GET('/manage');
    Farm.sectorConfigurations = Route.GET('/sector_configuration');
    Farm.planSeedingRound = Route.POST('/seeding_plan');
    Farm.plantingPreparation = Route.GET('/planting_preparation');
    tslib_1.__decorate([
        readonly,
        tslib_1.__metadata("design:type", Array)
    ], Farm.prototype, "farmingUnits", void 0);
    tslib_1.__decorate([
        readonly,
        tslib_1.__metadata("design:type", Boolean)
    ], Farm.prototype, "isDeleted", void 0);
    tslib_1.__decorate([
        readonly,
        tslib_1.__metadata("design:type", Date)
    ], Farm.prototype, "createdAt", void 0);
    tslib_1.__decorate([
        readonly,
        tslib_1.__metadata("design:type", Date)
    ], Farm.prototype, "updatedAt", void 0);
    tslib_1.__decorate([
        readonly,
        tslib_1.__metadata("design:type", User)
    ], Farm.prototype, "createdBy", void 0);
    return Farm;
}(ArchivableItem(Base)));
export { Farm };
var OrganizationView = /** @class */ (function () {
    function OrganizationView() {
    }
    return OrganizationView;
}());
export { OrganizationView };
// TODO: Fix https://github.com/angular/angular-cli/issues/2034
var FarmingUnitPreview = /** @class */ (function () {
    function FarmingUnitPreview() {
    }
    return FarmingUnitPreview;
}());
export { FarmingUnitPreview };
export var FarmCategory;
(function (FarmCategory) {
    FarmCategory["Store"] = "STORE";
    FarmCategory["Restaurant"] = "RESTAURANT";
    FarmCategory["Showroom"] = "SHOWROOM";
    FarmCategory["Nursery"] = "NURSERY";
    FarmCategory["Production"] = "PRODUCTION";
    FarmCategory["Distribution"] = "DISTRIBUTION";
    FarmCategory["Other"] = "OTHER";
})(FarmCategory || (FarmCategory = {}));
