import { InfarmItem } from '../api/resources/mixins/infarm-item';
import { isJsObject } from './guards';

/**
 * Compare anything recursively.
 */
export function compare(a: any, b: any): boolean {
    if (typeof a !== typeof b) {
        return false;
    } else if (a instanceof InfarmItem) {
        // NOTE: This is a faster and safer way to compare Potion resources
        return a.equals(b);
    } else if (
        a instanceof Date &&
        b instanceof Date &&
        a.getTime() !== b.getTime()
    ) {
        return false;
    } else if (Array.isArray(a)) {
        if (a.length !== b.length) {
            return false;
        }
        return a.every((item, index) => compare(item, b[index]));
    } else if (isJsObject(a)) {
        if (!isJsObject(b)) {
            return false;
        }
        if (Object.keys(a).length !== Object.keys(b).length) {
            return false;
        }
        for (const [key, obj1Val] of Object.entries(a)) {
            const obj2Val = b[key];
            if (isJsObject(obj1Val) || Array.isArray(obj1Val)) {
                const result = compare(obj1Val, obj2Val);
                if (!result) {
                    return false;
                }
            } else if (obj1Val !== obj2Val) {
                return false;
            }
        }
    } else if (a !== b) {
        return false;
    }

    return true;
}
