/**
 * A decorator that can be used to cache the return of class methods.
 * NOTE: This should be used with methods that are called on objects.
 * @param predicate - A function that determines how to cache the method result based on the arguments passed to the method.
 *
 * @usage
 * interface Bar {
 *     key: string;
 * }
 *
 * class Foo {
 *     @cacheBy((bar) => bar.key)
 *     name(bar: Bar) {}
 * }
 */
export function cacheBy(predicate) {
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var ctor = args[0];
        var descriptor = args[args.length - 1];
        var method = descriptor.value;
        // Cache per ctor and not in global cache.
        if (!ctor.$cache) {
            Object.assign(ctor, { $cache: new WeakMap() });
        }
        // Create a store for each method.
        if (typeof ctor.$cache.get(method) === 'undefined') {
            ctor.$cache.set(method, new Map());
        }
        var store = ctor.$cache.get(method);
        // Change the method to fetch the return value from the cache.
        Object.assign(descriptor, {
            value: function () {
                var key = predicate.apply(this, arguments);
                if (typeof method !== 'undefined' &&
                    typeof store.get(key) === 'undefined') {
                    store.set(key, method.apply(this, arguments));
                }
                return store.get(key);
            }
        });
    };
}
/**
 * A decorator that can be used to cache the return of class methods.
 *
 * @usage
 * class Foo {
 *     @cache
 *     name() {}
 * }
 */
export function cache() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    var descriptor = args[args.length - 1];
    var method = descriptor.value;
    // Change the method to fetch the return value from the cache.
    Object.assign(descriptor, {
        value: function () {
            // Cache per instance and not in global cache.
            if (!this.$cache) {
                Object.assign(this, { $cache: new WeakMap() });
            }
            // Update the cache with the evaluated method's value.
            if (typeof method !== 'undefined' &&
                typeof this.$cache.get(method) === 'undefined') {
                this.$cache.set(method, method.apply(this, arguments));
            }
            return this.$cache.get(method);
        }
    });
}
