import * as tslib_1 from "tslib";
import { capitalize, INFARM_POTION_RESOURCES } from 'infarm-core';
var ResourceType = /** @class */ (function () {
    function ResourceType(key, type) {
        this.key = key;
        this.type = type;
    }
    ResourceType.findByItem = function (item) {
        return this.resourceTypes.find(function (resourceType) { return resourceType.type === item; });
    };
    ResourceType.findByKey = function (key) {
        return this.resourceTypes.find(function (resourceType) { return resourceType.key === key; });
    };
    ResourceType.types = function () {
        return this.resourceTypes.slice(0);
    };
    ResourceType.create = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return Reflect.construct(this, args);
    };
    Object.defineProperty(ResourceType.prototype, "name", {
        get: function () {
            return capitalize(this.key.replace(/-/g, ' '), true);
        },
        enumerable: true,
        configurable: true
    });
    ResourceType.resourceTypes = Object.entries(INFARM_POTION_RESOURCES)
        .map(function (_a) {
        var _b = tslib_1.__read(_a, 2), url = _b[0], item = _b[1];
        return [
            url
                .replace(/\//g, '') // Remove slashes
                .replace(/_/g, '-'),
            item
        ];
    })
        .map(function (_a) {
        var _b = tslib_1.__read(_a, 2), key = _b[0], type = _b[1];
        return ResourceType.create(key, type);
    });
    return ResourceType;
}());
export { ResourceType };
