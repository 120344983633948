import { localeData } from 'moment';
import { isNumber, isString } from './guards';

/**
 * Converts ms to time string (3600 -> 01:00)
 * @param time
 */
export function toTime(time: number): string {
    if (isNumber(time)) {
        const m = time % 3600;
        const h = (time - m) / 3600;
        const hours = h === 0.5 ? 0 : Math.ceil(h);
        const minutes = m / 60;
        return `${toTimeFormat(hours)}:${toTimeFormat(minutes)}`;
    }
    return '';
}

export function toTimeFormat(num: number): string {
    return num > 9 ? `${num}` : `0${num}`;
}

/**
 * Converts a time string to ms (01:00 -> 3600)
 * @param time
 */
export function fromTime(time: string): number {
    if (isString(time)) {
        const [hours, minutes]: number[] = time
            .split(':')
            .map(value => parseInt(value, 10));
        return hours * 60 * 60 + minutes * 60;
    }
    return NaN;
}

/**
 * Get the week days
 */
export function daysOfWeek(): string[] {
    let days = localeData().weekdays();
    const sunday = days[0];
    days = days.slice(1);
    days.push(sunday);
    return days;
}

/**
 * Get index for a specific day of weel
 * @param day
 */
export function dayToInt(day: string): number {
    const days = daysOfWeek();
    return days.indexOf(day);
}
