import * as tslib_1 from "tslib";
import { localeData } from 'moment';
import { isNumber, isString } from './guards';
/**
 * Converts ms to time string (3600 -> 01:00)
 * @param time
 */
export function toTime(time) {
    if (isNumber(time)) {
        var m = time % 3600;
        var h = (time - m) / 3600;
        var hours = h === 0.5 ? 0 : Math.ceil(h);
        var minutes = m / 60;
        return toTimeFormat(hours) + ":" + toTimeFormat(minutes);
    }
    return '';
}
export function toTimeFormat(num) {
    return num > 9 ? "" + num : "0" + num;
}
/**
 * Converts a time string to ms (01:00 -> 3600)
 * @param time
 */
export function fromTime(time) {
    if (isString(time)) {
        var _a = tslib_1.__read(time
            .split(':')
            .map(function (value) { return parseInt(value, 10); }), 2), hours = _a[0], minutes = _a[1];
        return hours * 60 * 60 + minutes * 60;
    }
    return NaN;
}
/**
 * Get the week days
 */
export function daysOfWeek() {
    var days = localeData().weekdays();
    var sunday = days[0];
    days = days.slice(1);
    days.push(sunday);
    return days;
}
/**
 * Get index for a specific day of weel
 * @param day
 */
export function dayToInt(day) {
    var days = daysOfWeek();
    return days.indexOf(day);
}
