import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth, visible } from 'infarm-core';

import { apiRequest } from '../utils';
import { setLoginRedirect } from '@infarm/auth';

@Component({
    selector: 'in-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    animations: [visible]
})
export class LayoutComponent implements OnDestroy, OnInit {
    @Input()
    API_HOST: string;
    kompostVersion: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private auth: Auth
    ) {
        this.API_HOST = this.route.snapshot.data.API_HOST;
    }

    ngOnInit(): void {
        this.getKompostVersion();
        window.addEventListener('logout', this.logoutHandler);
    }

    ngOnDestroy(): void {
        window.removeEventListener('logout', this.logoutHandler);
    }

    async getKompostVersion(): Promise<void> {
        const token = await this.auth.token();
        try {
            const response = await apiRequest(
                `${this.API_HOST}/internal/healthcheck`,
                token,
                'GET'
            );
            const json = await response.json();
            this.kompostVersion = json.GIT_HASH;
        } catch (e) {
            this.kompostVersion = 'unknown';
        }
    }

    forceScheduleNavigation() {
        // this forces the schedule page to refresh by redirecting to an (empty) route
        // without forcing a reload of the schedule component, the visits overlap
        this.router
            .navigateByUrl('/empty', { skipLocationChange: true })
            .then(() => {
                this.router.navigate(['/schedule']);
            });
    }

    private logoutHandler = async (event: CustomEvent): Promise<void> => {
        if (event.detail && event.detail.suppressRedirect) {
            // Logout event has been triggered from within the app, don't do anything
            return;
        } else {
            // Logout event came from the outside, handle it like a manual logout
            setLoginRedirect(window.location.href, '#/login');
            await this.auth.logout();
            this.router.navigateByUrl('/login');
        }
    };
}
