import * as tslib_1 from "tslib";
import { UpdateableRecipeItem } from './mixins/updated-at';
var TankFlushingRecipe = /** @class */ (function (_super) {
    tslib_1.__extends(TankFlushingRecipe, _super);
    function TankFlushingRecipe() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return TankFlushingRecipe;
}(UpdateableRecipeItem));
export { TankFlushingRecipe };
