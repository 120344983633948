import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { ImageStorage, ImageUploadResponse } from 'infarm-core';
import { ToastService } from '../toast.service';
var ImageUploadComponent = /** @class */ (function () {
    function ImageUploadComponent(imageStorage, toast) {
        this.imageStorage = imageStorage;
        this.toast = toast;
        this.upload = new EventEmitter();
        this.isUploading = false;
    }
    ImageUploadComponent.prototype.updateFiles = function (event) {
        var files = event.srcElement.files;
        this.files = files;
    };
    ImageUploadComponent.prototype.uploadFiles = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.files || !this.files.length) {
                            return [2 /*return*/];
                        }
                        this.isUploading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.imageStorage
                                .upload(this.files[0], {
                                endProductId: this.resourceId
                            })
                                .toPromise()];
                    case 2:
                        response = _a.sent();
                        this.toast.push('Image was successfully uploaded', 'Ok');
                        this.upload.emit(response);
                        this.isUploading = false;
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.toast.push('Upload failed', 'Retry');
                        this.isUploading = false;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return ImageUploadComponent;
}());
export { ImageUploadComponent };
