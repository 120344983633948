import gql from 'graphql-tag';

export const sectorConfigurationFragments = {
    sectorConfigurationFields: gql`
        fragment SectorConfigurationFields on SectorConfiguration {
            id
            uuid
            createdAt
            updatedAt
            createdBy {
                name
            }
            updatedBy {
                name
            }
            trayType {
                uuid
                stages
                tracks
                stageMultiplier
                throughput
                name
            }
            endProduct {
                uuid
                internalCode
                name
            }
            operationDayOfTheWeek
            operationStartTime
            plantingPeriod
            sectorOutput
            sector {
                id
                uuid
                number
            }
        }
    `
};
