import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InfarmItem, isInfarmItem } from 'infarm-core';

import { TablePaginationEvent } from '../table-card/table-pagination.component';
import { MultipleSelect } from '../multiple-select';

import { ItemsTable } from './items-table';
import { Row } from './row';
import { Column } from './column';

@Component({
    selector: 'in-items-table',
    templateUrl: './items-table.component.html',
    styleUrls: ['./items-table.component.scss']
})
export class ItemsTableComponent extends MultipleSelect<Row> {
    @Input() selectable: boolean;
    @Output() select: EventEmitter<Row[]> = new EventEmitter<Row[]>();
    @Input() clickable: boolean;
    @Input() paginated: boolean;
    @Output() paginate: EventEmitter<TablePaginationEvent> = new EventEmitter<
        TablePaginationEvent
    >();
    @Input() page: number;
    @Input() total: number;
    @Input() limit: number;

    table: ItemsTable = new ItemsTable();

    constructor() {
        super();
        this.setSources(this.table.rows);
    }

    @Input()
    set items(items: InfarmItem[]) {
        if (Array.isArray(items) && items.every(item => isInfarmItem(item))) {
            this.table.update(items);
        }
    }
    @Input()
    set schema(schema: any) {
        if (schema) {
            this.table.setSchema(schema);
        }
    }

    trackByRow(_: number, row: Row): string {
        return row.id;
    }
    trackByColumn(_: number, column: Column): string {
        return column.key;
    }
}
