
        <mat-dialog-content *ngIf="fields">
    <form class="mat-body-1"
        (submit)="submit($event)"
        [formGroup]="form"
        novalidate>
        <div *ngFor="let field of fields"
            [ngSwitch]="field.controlType"
            [class]="fieldClass(field)"
            [class.in-no-render]="field.hide">
            <ng-container *ngSwitchCase="'text'">
                <ng-template #asInput>
                    <mat-form-field class="in-fix-field-width">
                        <input matInput
                            #input
                            [formControlName]="field.key"
                            [attr.type]="field.type"
                            [type]="field.type"
                            [placeholder]="field.label"
                            [maxlength]="field.maxLength"
                            [required]="field.required">
                        <mat-hint *ngIf="field.maxLength as maxLength" align="end">{{ input?.value.length }} / {{ maxLength }}</mat-hint>
                        <mat-error *ngIf="form.controls[field.key].hasError('required')">Required</mat-error>
                    </mat-form-field>
                </ng-template>

                <mat-form-field *ngIf="renderAsTextarea(field); else asInput" class="in-fix-field-width">
                    <textarea matInput
                        matTextareaAutosize
                        #input
                        [formControlName]="field.key"
                        [placeholder]="field.label"
                        [maxlength]="field.maxLength"
                        [required]="field.required"></textarea>
                        <mat-hint *ngIf="field.maxLength as maxLength" align="end">{{ input?.value.length }} / {{ maxLength }}</mat-hint>
                    <mat-error *ngIf="form.controls[field.key].hasError('required')">Required</mat-error>
                </mat-form-field>
            </ng-container>

            <ng-container *ngSwitchCase="'date'">
                <!-- TODO: Add support for min/max validation (check https://material.angular.io/components/datepicker/overview) -->
                <mat-form-field fxFlex class="in-fix-field-width">
                    <input matInput
                        #input
                        [matDatepicker]="datepicker"
                        [formControlName]="field.key"
                        [attr.type]="field.type"
                        [type]="field.type"
                        [placeholder]="field.label"
                        [required]="field.required">
                    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                    <mat-datepicker #datepicker></mat-datepicker>

                    <mat-hint *ngIf="field.maxLength as maxLength" align="end">{{ input?.value.length }} / {{ maxLength }}</mat-hint>
                    <mat-error *ngIf="form.controls[field.key].hasError('required')">Required</mat-error>
                </mat-form-field>
            </ng-container>
            <div *ngSwitchCase="'select'">
                <mat-form-field class="in-fix-field-width">
                    <mat-select [formControlName]="field.key"
                        [required]="field.required"
                        [placeholder]="field.label"
                        [multiple]="field.multiple">
                        <mat-option *ngIf="!field.multiple && !field.required" [value]="null">None</mat-option>
                        <mat-option *ngFor="let option of field.options" [value]="option.value">{{ option.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div *ngSwitchCase="'checkbox'">
                <mat-checkbox [formControlName]="field.key">{{ field.label }}</mat-checkbox>
            </div>
        </div>
    </form>
</mat-dialog-content>
        <mat-card-actions align="end">
            <button (click)="abort()" mat-button>
                Cancel
            </button>
            <button
                (click)="submit()"
                [disabled]="disabled"
                mat-button
                color="primary"
            >
                Save
            </button>
        </mat-card-actions>
    