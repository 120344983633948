import { Apollo, QueryRef } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';
import { Subscription } from 'rxjs/Subscription';
import { GRAPHQL_PAGE_SIZE } from './utils';
import { formatConnections } from './relay-utils';
import { DocumentNode } from 'graphql';

export class PaginatorService {
    apollo: Apollo;
    entryRef: QueryRef<any>;
    entrySubscription: Subscription;
    cursor: string;
    connectionName: string;
    query: DocumentNode;
    queryParameters: any;

    constructor(apollo: Apollo, paginatorConfig: PaginatorConfig) {
        this.apollo = apollo;
        const { connectionName, query, queryParameters } = paginatorConfig;
        this.connectionName = connectionName;
        this.query = query;
        this.queryParameters = queryParameters;
        this.entryRef = this.createQueryRef();
        if (this.entryRef.valueChanges) {
            this.entrySubscription = this.entryRef.valueChanges.subscribe();
        }
    }

    async loadMore(): Promise<ApolloQueryResult<any>> {
        const numberOfItems = this.queryParameters.numberOfItems
            ? this.queryParameters.numberOfItems
            : GRAPHQL_PAGE_SIZE;
        const queryResult = await this.entryRef.fetchMore({
            variables: {
                numberOfItems,
                cursorAfter: this.cursor
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                const connectionName = this.connectionName;
                if (!fetchMoreResult[connectionName]) {
                    return prev;
                }
                this.cursor = this.getConnectionCursor(fetchMoreResult);
                const returnObject = { ...prev };
                returnObject[connectionName] = [
                    ...prev[connectionName],
                    ...fetchMoreResult[connectionName]
                ];
            }
        });
        return queryResult;
    }

    // TODO getNextPage - not needed yet

    // TODO getLastPage - not needed yet

    async fetchAllPages(results: any[] = []): Promise<any[]> {
        const result = await this.loadMore();
        results.push(result.data);
        if (this.hasNextPage(result)) {
            return this.fetchAllPages(results);
        } else {
            this.resetCursor();
        }
        const connections = results.map(result => result[this.connectionName]);
        return formatConnections(connections);
    }

    async fetchAllEdges(): Promise<any[]> {
        try {
            return await this.fetchAllPages();
        } catch (error) {
            return [];
        }
    }

    private hasNextPage(result: any): boolean {
        return result.data[this.connectionName].pageInfo.hasNextPage;
    }

    private getConnectionCursor(result: ApolloQueryResult<any>): string {
        try {
            return result[this.connectionName].pageInfo.endCursor;
        } catch (error) {
            if (!result[this.connectionName].pageInfo) {
                throw Error(`Do not forget to add pageInfo object to your query, e.g.
                pageInfo {
                    endCursor
                    hasNextPage
                }
                `);
            } else {
                throw error;
            }
        }
    }

    private resetCursor(): void {
        this.cursor = undefined;
    }

    private createQueryRef(): QueryRef<{}, any> {
        const query = this.query;
        const queryParameters = this.queryParameters;
        const queryRef: QueryRef<{}, any> = this.apollo.watchQuery({
            query,
            variables: {
                ...queryParameters
            }
        });
        return queryRef;
    }
}

export interface PaginatorConfig {
    query: DocumentNode;
    queryParameters: any;
    connectionName: string;
}
