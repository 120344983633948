import {
    Injectable,
    NgZone,
    Optional,
    Provider,
    SkipSelf
} from '@angular/core';
import { MatSnackBar } from '@angular/material';

import { BehaviorSubject, Observable } from 'rxjs';
import { skip } from 'rxjs/operators';

@Injectable()
export class RetryService {
    constructor(private snackBar: MatSnackBar, private zone: NgZone) {}

    run<T>(
        fn: (...args: any[]) => Promise<T>,
        message: string = 'Your request was processed',
        times: number = 3,
        args: any[] = []
    ): Observable<T> {
        const snackBar = this.snackBar;
        const zone = this.zone;
        const source = new BehaviorSubject<T>(null as any);
        next();

        return source.asObservable().pipe(skip(1));

        function next(count = 1): void {
            fn(...args).then(
                value => {
                    zone.run(() => {
                        snackBar.open(message, undefined, { duration: 3500 });
                        source.next(value as T);
                        source.complete();
                    });
                },
                () => {
                    zone.run(() => {
                        if (count === times) {
                            snackBar.open(
                                'Sorry, but your request cannot be processed at this time, please try again later or report it',
                                undefined,
                                { duration: 5000 }
                            );
                            source.error(
                                new Error('Failed to run async task.')
                            );
                        } else {
                            snackBar
                                .open(
                                    'Your request could not be processed',
                                    'Retry'
                                )
                                .onAction()
                                .subscribe(() => {
                                    next(++count);
                                });
                        }
                    });
                }
            );
        }
    }
}

export function RETRY_SERVICE_PROVIDER_FACTORY(
    parentFactory: RetryService,
    snackBar: MatSnackBar,
    zone: NgZone
): RetryService {
    return parentFactory || new RetryService(snackBar, zone);
}

export const RETRY_SERVICE_PROVIDER: Provider = {
    // If there is already a RetryService available, use that.
    // Otherwise, provide a new one.
    provide: RetryService,
    useFactory: RETRY_SERVICE_PROVIDER_FACTORY,
    deps: [[new Optional(), new SkipSelf(), RetryService], MatSnackBar, NgZone]
};
