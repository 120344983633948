import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ItemNameComponent } from './item-name';

export * from './item-name';

@NgModule({
    imports: [CommonModule],
    declarations: [ItemNameComponent],
    exports: [ItemNameComponent]
})
export class ItemNameModule {}
