import * as tslib_1 from "tslib";
import { NgZone, Optional, Provider, SkipSelf } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { skip } from 'rxjs/operators';
var RetryService = /** @class */ (function () {
    function RetryService(snackBar, zone) {
        this.snackBar = snackBar;
        this.zone = zone;
    }
    RetryService.prototype.run = function (fn, message, times, args) {
        if (message === void 0) { message = 'Your request was processed'; }
        if (times === void 0) { times = 3; }
        if (args === void 0) { args = []; }
        var snackBar = this.snackBar;
        var zone = this.zone;
        var source = new BehaviorSubject(null);
        next();
        return source.asObservable().pipe(skip(1));
        function next(count) {
            if (count === void 0) { count = 1; }
            fn.apply(void 0, tslib_1.__spread(args)).then(function (value) {
                zone.run(function () {
                    snackBar.open(message, undefined, { duration: 3500 });
                    source.next(value);
                    source.complete();
                });
            }, function () {
                zone.run(function () {
                    if (count === times) {
                        snackBar.open('Sorry, but your request cannot be processed at this time, please try again later or report it', undefined, { duration: 5000 });
                        source.error(new Error('Failed to run async task.'));
                    }
                    else {
                        snackBar
                            .open('Your request could not be processed', 'Retry')
                            .onAction()
                            .subscribe(function () {
                            next(++count);
                        });
                    }
                });
            });
        }
    };
    return RetryService;
}());
export { RetryService };
export function RETRY_SERVICE_PROVIDER_FACTORY(parentFactory, snackBar, zone) {
    return parentFactory || new RetryService(snackBar, zone);
}
export var RETRY_SERVICE_PROVIDER = {
    // If there is already a RetryService available, use that.
    // Otherwise, provide a new one.
    provide: RetryService,
    useFactory: RETRY_SERVICE_PROVIDER_FACTORY,
    deps: [[new Optional(), new SkipSelf(), RetryService], MatSnackBar, NgZone]
};
