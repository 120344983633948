import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'in-editable-mode-switch',
    templateUrl: './editable-mode-switch.component.html'
})
export class EditableModeSwitchComponent {
    @Input()
    mode?: InteractionMode = InteractionMode.Displaying;

    @Input()
    editButtonDisplayed?: boolean = true;
    @Input()
    editButtonDisabled?: boolean = false;
    @Input()
    disabledTooltip: string;
    @Input()
    cantSubmitTooltip: string;
    @Input()
    submitEnabled?: boolean = true;

    @Output()
    save: EventEmitter<{}> = new EventEmitter();

    @Output()
    cancel: EventEmitter<{}> = new EventEmitter();

    @Output()
    modeChange: EventEmitter<InteractionMode> = new EventEmitter<
        InteractionMode
    >();

    toggleMode() {
        this.mode === InteractionMode.Displaying
            ? (this.mode = InteractionMode.Editing)
            : (this.mode = InteractionMode.Displaying);
        this.modeChange.emit(this.mode);
    }

    submitChange() {
        this.toggleMode();
        this.save.emit();
    }

    discardChange() {
        this.toggleMode();
        this.cancel.emit();
    }
}

export enum InteractionMode {
    Displaying = 'displaying',
    Editing = 'editing'
}
