import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';
import { isInfarmItem } from 'infarm-core';

import { isPrimitive } from '../utils';
import { Column } from './column';

@Injectable()
export class ColumnParserService {
    private datePipe: DatePipe;

    constructor(@Inject(LOCALE_ID) locale: string) {
        this.datePipe = new DatePipe(locale);
    }

    parse(column: Column): string {
        return this.toHtmlString(column.value);
    }

    private toHtmlString(value: any): string {
        if (Array.isArray(value)) {
            return `${value.map(value => this.toHtmlString(value)).join(', ')}`;
        } else if (isInfarmItem(value)) {
            return `${value.toHtmlString()}`;
        } else if (isPrimitive(value)) {
            return value === null || value === undefined
                ? '&mdash;'
                : `${value}`;
        } else if (value instanceof Date) {
            return `${this.datePipe.transform(value)}`;
        }
        // TODO(rolandjitsu): handle other objects
        return '&mdash;';
    }
}
