import { ExtraOptions, Routes } from '@angular/router';
import { PreloadSelectedModulesOnly } from 'app-core';
import { AuthGuard } from 'app-shared';
import { API_HOST, isStaging } from '../env';
import { LayoutComponent } from 'app-shared/layout/layout.component';
import { EmptyComponent } from 'app-shared/empty-component/empty-component.component';
var ɵ1 = {
    isStaging: isStaging(),
    API_HOST: API_HOST
}, ɵ2 = function () {
    return import("./farm/farm.module.ngfactory").then(function (module) { return module.FarmsModuleNgFactory; });
}, ɵ3 = function () {
    return import("./nurseries/nurseries.module.ngfactory").then(function (module) { return module.NurseriesModuleNgFactory; });
}, ɵ4 = function () {
    return import("./schedule/schedule.module.ngfactory").then(function (module) { return module.ScheduleModuleNgFactory; });
}, ɵ5 = function () {
    return import("./end-product-catalog/end-product-catalog.module.ngfactory").then(function (module) { return module.ProductCatalogModuleNgFactory; });
}, ɵ6 = function () {
    return import("./resources/resources.module.ngfactory").then(function (module) { return module.ResourcesModuleNgFactory; });
}, ɵ7 = function () {
    return import("./recipes/recipes.module.ngfactory").then(function (module) { return module.RecipesModuleNgFactory; });
}, ɵ8 = function () {
    return import("./location-groups/location-groups.module.ngfactory").then(function (module) { return module.LocationGroupsModuleNgFactory; });
};
// Check https://angular.io/docs/ts/latest/guide/router.html for more info about the Router and routing in general.
// For lazy loading check: http://angularjs.blogspot.ch/2016/08/angular-2-rc5-ngmodules-lazy-loading.html, https://github.com/angular/angular/pull/10705.
export var routes = [
    {
        path: '',
        component: LayoutComponent,
        data: ɵ1,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'location-group',
                canLoad: [AuthGuard]
            },
            {
                path: 'farm',
                loadChildren: ɵ2,
                canLoad: [AuthGuard]
            },
            {
                path: 'nurseries',
                loadChildren: ɵ3,
                canLoad: [AuthGuard]
            },
            {
                path: 'schedule',
                loadChildren: ɵ4,
                canLoad: [AuthGuard]
            },
            {
                path: 'end-product-catalog',
                loadChildren: ɵ5,
                canLoad: [AuthGuard]
            },
            {
                path: 'resources',
                loadChildren: ɵ6,
                canLoad: [AuthGuard]
            },
            {
                path: 'recipes',
                loadChildren: ɵ7,
                canLoad: [AuthGuard]
            },
            {
                path: 'location-group',
                loadChildren: ɵ8,
                canLoad: [AuthGuard]
            },
            {
                path: 'empty',
                component: EmptyComponent
            }
        ]
    },
    {
        path: '**',
        component: EmptyComponent
    }
];
export var config = {
    preloadingStrategy: PreloadSelectedModulesOnly,
    useHash: true
};
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8 };
