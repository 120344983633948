import * as tslib_1 from "tslib";
import { GRAPHQL_PAGE_SIZE } from './utils';
import { formatConnections } from './relay-utils';
var PaginatorService = /** @class */ (function () {
    function PaginatorService(apollo, paginatorConfig) {
        this.apollo = apollo;
        var connectionName = paginatorConfig.connectionName, query = paginatorConfig.query, queryParameters = paginatorConfig.queryParameters;
        this.connectionName = connectionName;
        this.query = query;
        this.queryParameters = queryParameters;
        this.entryRef = this.createQueryRef();
        if (this.entryRef.valueChanges) {
            this.entrySubscription = this.entryRef.valueChanges.subscribe();
        }
    }
    PaginatorService.prototype.loadMore = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var numberOfItems, queryResult;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        numberOfItems = this.queryParameters.numberOfItems
                            ? this.queryParameters.numberOfItems
                            : GRAPHQL_PAGE_SIZE;
                        return [4 /*yield*/, this.entryRef.fetchMore({
                                variables: {
                                    numberOfItems: numberOfItems,
                                    cursorAfter: this.cursor
                                },
                                updateQuery: function (prev, _a) {
                                    var fetchMoreResult = _a.fetchMoreResult;
                                    var connectionName = _this.connectionName;
                                    if (!fetchMoreResult[connectionName]) {
                                        return prev;
                                    }
                                    _this.cursor = _this.getConnectionCursor(fetchMoreResult);
                                    var returnObject = tslib_1.__assign({}, prev);
                                    returnObject[connectionName] = tslib_1.__spread(prev[connectionName], fetchMoreResult[connectionName]);
                                }
                            })];
                    case 1:
                        queryResult = _a.sent();
                        return [2 /*return*/, queryResult];
                }
            });
        });
    };
    // TODO getNextPage - not needed yet
    // TODO getLastPage - not needed yet
    PaginatorService.prototype.fetchAllPages = function (results) {
        if (results === void 0) { results = []; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, connections;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadMore()];
                    case 1:
                        result = _a.sent();
                        results.push(result.data);
                        if (this.hasNextPage(result)) {
                            return [2 /*return*/, this.fetchAllPages(results)];
                        }
                        else {
                            this.resetCursor();
                        }
                        connections = results.map(function (result) { return result[_this.connectionName]; });
                        return [2 /*return*/, formatConnections(connections)];
                }
            });
        });
    };
    PaginatorService.prototype.fetchAllEdges = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.fetchAllPages()];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        error_1 = _a.sent();
                        return [2 /*return*/, []];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PaginatorService.prototype.hasNextPage = function (result) {
        return result.data[this.connectionName].pageInfo.hasNextPage;
    };
    PaginatorService.prototype.getConnectionCursor = function (result) {
        try {
            return result[this.connectionName].pageInfo.endCursor;
        }
        catch (error) {
            if (!result[this.connectionName].pageInfo) {
                throw Error("Do not forget to add pageInfo object to your query, e.g.\n                pageInfo {\n                    endCursor\n                    hasNextPage\n                }\n                ");
            }
            else {
                throw error;
            }
        }
    };
    PaginatorService.prototype.resetCursor = function () {
        this.cursor = undefined;
    };
    PaginatorService.prototype.createQueryRef = function () {
        var query = this.query;
        var queryParameters = this.queryParameters;
        var queryRef = this.apollo.watchQuery({
            query: query,
            variables: tslib_1.__assign({}, queryParameters)
        });
        return queryRef;
    };
    return PaginatorService;
}());
export { PaginatorService };
