import * as tslib_1 from "tslib";
import { parseAsync } from '../utils/json';
import { isJsObject } from '../utils/guards';
/**
 * Use to restore an instance from string obtained by calling Token#serialize.
 */
export function jsonToToken(str) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var obj, accessToken, refreshToken;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, parseAsync(str)];
                case 1:
                    obj = _a.sent();
                    if (isJsObject(obj)) {
                        accessToken = obj.accessToken, refreshToken = obj.refreshToken;
                        return [2 /*return*/, new Token(accessToken, refreshToken)];
                    }
                    return [2 /*return*/, null];
            }
        });
    });
}
export function isToken(value) {
    return value instanceof Token;
}
var Token = /** @class */ (function () {
    /**
     * @param accessToken
     */
    function Token(accessToken, refreshToken) {
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
    }
    /**
     * @param token
     * @returns Confirms if this token is the same as the one that it's compared to
     */
    Token.prototype.equals = function (token) {
        return (!!token &&
            this.accessToken === token.accessToken &&
            this.refreshToken === token.refreshToken);
    };
    /**
     * @returns A string that can be used directly as `Authorization` HTTP header.
     */
    Token.prototype.toString = function () {
        return "Bearer " + this.accessToken;
    };
    /**
     * @returns A string that is safe to use for storing.
     */
    Token.prototype.serialize = function () {
        return JSON.stringify({
            accessToken: this.accessToken,
            refreshToken: this.refreshToken
        });
    };
    return Token;
}());
export { Token };
