import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Farm } from '../graphql/interfaces';
import { findFarmUuid } from './farm.resolver';
import { utc } from 'moment';
import { GraphqlService } from '../graphql/graphql.service';
import { THREE_WEEKS_IN_DAYS, toShortDate } from '../utils';
import { MAX_GRAPHQL_PAGE_SIZE } from '../graphql/utils';
import { sectorConfigurations } from '../graphql/queries';
import { processNode, serializeUUID } from '../graphql/relay-utils';
@Injectable()
export class SectorConfigurationsViewResolver implements Resolve<Farm | null> {
    monday: number = 1;
    constructor(private graphql: GraphqlService) {}
    async resolve(route: ActivatedRouteSnapshot): Promise<Farm | null> {
        try {
            const graphqlId = serializeUUID('Farm', findFarmUuid(route));
            const { date } = route.queryParams;
            const lower = this.getLowerBounds(date);
            const upper = this.getUpperBounds(date);
            const queryResult = await this.graphql.query(sectorConfigurations, {
                id: graphqlId,
                plantingPeriodOverlapping: {
                    lower,
                    upper,
                    bounds: '[)'
                },
                numberOfItems: MAX_GRAPHQL_PAGE_SIZE
            });
            return processNode(queryResult.data.node);
        } catch (err) {
            return null;
        }
    }

    getLowerBounds(date: string): string {
        const moment = date ? utc(parseInt(date, 10)) : utc();
        const lower = moment
            .days(this.monday - THREE_WEEKS_IN_DAYS)
            .startOf('day');
        return toShortDate(lower.toDate());
    }

    getUpperBounds(date: string): string {
        const extraTwoWeeks = 14;
        const moment = date ? utc(parseInt(date, 10)) : utc();
        const upper = moment
            .days(this.monday + THREE_WEEKS_IN_DAYS + extraTwoWeeks)
            .startOf('day');
        return toShortDate(upper.toDate());
    }
}
