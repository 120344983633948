import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { GraphqlService } from '../graphql/graphql.service';
import { trayTypesQuery } from '../graphql/queries/farms.query';
import { TrayType } from '../graphql/interfaces/agro';

@Injectable()
export class TrayTypesResolver implements Resolve<TrayType[]> {
    constructor(private graphql: GraphqlService) {}
    async resolve(): Promise<TrayType[]> {
        try {
            const trayTypes = this.graphql.makePaginator(
                'trayTypes',
                trayTypesQuery,
                {
                    state: TrayTypeState.Active
                }
            );
            return trayTypes.fetchAllEdges();
        } catch (err) {
            return [];
        }
    }
}

enum TrayTypeState {
    Active = 'active',
    Decommissioned = 'decommissioned'
}
