import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { daysOfWeek, dayToInt, Farm, HarvestRound, isJsObject, omit, toTime } from 'infarm-core';
import { HarvestRoundDialogService, isHarvestRoundObject, isHarvestRoundView } from '../harvest-round-dialog';
import { ToastService } from '../toast.service';
import { getErrorMessage } from '../utils';
var HarvestRoundComponent = /** @class */ (function () {
    function HarvestRoundComponent(harvestRoundDialog, toast) {
        this.harvestRoundDialog = harvestRoundDialog;
        this.toast = toast;
        this.set = new EventEmitter();
        this.$rounds = [];
    }
    Object.defineProperty(HarvestRoundComponent.prototype, "rounds", {
        set: function (rounds) {
            this.$rounds = rounds;
        },
        enumerable: true,
        configurable: true
    });
    HarvestRoundComponent.prototype.trackByIdentifier = function (_, round) {
        if (isJsObject(round)) {
            if (isHarvestRoundObject(round)) {
                return round.uid;
            }
            else if (isHarvestRoundView(round)) {
                return "" + round.id;
            }
        }
        return null;
    };
    HarvestRoundComponent.prototype.edit = function (object) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var round, roundResource, index, err_1, index;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.harvestRoundDialog.open({ object: object })];
                    case 1:
                        round = _a.sent();
                        if (!isJsObject(round)) return [3 /*break*/, 8];
                        if (!(isHarvestRoundView(round) && this.farm instanceof Farm)) return [3 /*break*/, 7];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 5, , 6]);
                        return [4 /*yield*/, HarvestRound.fetch(round.id)];
                    case 3:
                        roundResource = _a.sent();
                        return [4 /*yield*/, roundResource.update({
                                dayOfWeek: dayToInt(round.dayOfWeek),
                                startTime: round.startTime,
                                endTime: round.endTime
                            })];
                    case 4:
                        _a.sent();
                        index = this.getRounds().findIndex(function (existingRound) { return existingRound.id === round.id; });
                        if (index !== -1) {
                            this.$rounds.splice(index, 1, toHarvestRoundView(roundResource));
                        }
                        return [3 /*break*/, 6];
                    case 5:
                        err_1 = _a.sent();
                        this.toast.push('Failed to update harvest round, please check your input.');
                        return [3 /*break*/, 6];
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        if (isHarvestRoundObject(round)) {
                            index = this.getRounds().findIndex(function (existingRound) { return existingRound.uid === round.uid; });
                            if (index !== -1) {
                                this.$rounds.splice(index, 1, round);
                                this.emit();
                            }
                        }
                        _a.label = 8;
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    HarvestRoundComponent.prototype.remove = function (evt, object) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var round, index, err_2, index;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (evt) {
                            evt.preventDefault();
                            evt.stopPropagation();
                        }
                        if (!(isHarvestRoundView(object) && this.farm instanceof Farm)) return [3 /*break*/, 6];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, HarvestRound.fetch(object.id)];
                    case 2:
                        round = _a.sent();
                        return [4 /*yield*/, round.destroy()];
                    case 3:
                        _a.sent();
                        index = this.getRounds().findIndex(function (existingRound) { return existingRound.id === object.id; });
                        if (index !== -1) {
                            this.$rounds.splice(index, 1);
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        err_2 = _a.sent();
                        this.toast.push('Failed to delete harvest round, please try again later.');
                        return [3 /*break*/, 5];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        if (isHarvestRoundObject(object)) {
                            index = this.getRounds().findIndex(function (existingRound) { return existingRound.uid === object.uid; });
                            if (index !== -1) {
                                this.$rounds.splice(index, 1);
                            }
                            this.emit();
                        }
                        _a.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    HarvestRoundComponent.prototype.add = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var round, rawRoundResource, roundResource, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.harvestRoundDialog.open()];
                    case 1:
                        round = _a.sent();
                        if (!isJsObject(round)) return [3 /*break*/, 7];
                        if (!(this.farm instanceof Farm)) return [3 /*break*/, 6];
                        rawRoundResource = new HarvestRound({
                            dayOfWeek: dayToInt(round.dayOfWeek),
                            startTime: round.startTime,
                            endTime: round.endTime,
                            farm: this.farm
                        });
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, rawRoundResource.save()];
                    case 3:
                        roundResource = _a.sent();
                        this.$rounds.push(toHarvestRoundView(roundResource));
                        return [3 /*break*/, 5];
                    case 4:
                        err_3 = _a.sent();
                        this.toast.push(getErrorMessage(err_3));
                        return [3 /*break*/, 5];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        if (isHarvestRoundObject(round)) {
                            this.$rounds.push(round);
                            this.emit();
                        }
                        _a.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    HarvestRoundComponent.prototype.time = function (ms) {
        return toTime(ms);
    };
    HarvestRoundComponent.prototype.getRounds = function () {
        return this.$rounds;
    };
    HarvestRoundComponent.prototype.emit = function () {
        this.set.emit(this.getRounds().map(function (round) {
            return omit(round, ['uid']);
        }));
    };
    return HarvestRoundComponent;
}());
export { HarvestRoundComponent };
export function toHarvestRoundView(round) {
    return {
        dayOfWeek: daysOfWeek()[round.dayOfWeek],
        startTime: round.startTime,
        endTime: round.endTime,
        id: Number(round.id)
    };
}
