import * as tslib_1 from "tslib";
import { ControlType, FieldBase } from './base';
var TextField = /** @class */ (function (_super) {
    tslib_1.__extends(TextField, _super);
    function TextField(options) {
        if (options === void 0) { options = {}; }
        var _this = this;
        Object.assign(options, {
            controlType: ControlType.Text
        });
        _this = _super.call(this, options) || this;
        _this.maxLength = options.maxLength || null;
        _this.type = 'text';
        return _this;
    }
    return TextField;
}(FieldBase));
export { TextField };
