import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import {
    ClimateRecipe,
    EcRecipe,
    FarmingUnitView,
    PhRecipe,
    TankFlushingRecipe,
    WaterRefillingRecipe
} from 'infarm-core';

@Injectable()
export class RecipesResolver implements Resolve<any> {
    unitView: FarmingUnitView;
    async resolve(): Promise<any> {
        try {
            const phRecipeView = this.unitView.recipes.water.ph;
            const ecRecipeView = this.unitView.recipes.water.ec;
            const waterRefillingRecipeView = this.unitView.recipes.water.wrf;
            const waterFlushingRecipeView = this.unitView.recipes.water.wfl;
            const climateRecipeView = this.unitView.recipes.climate;
            const phRecipe = phRecipeView
                ? await PhRecipe.fetch(phRecipeView.id, { skip: ['createdBy'] })
                : ({} as any);
            const ecRecipe = ecRecipeView
                ? await EcRecipe.fetch(ecRecipeView.id, { skip: ['createdBy'] })
                : ({} as any);
            const waterRefillingRecipe = waterRefillingRecipeView
                ? await WaterRefillingRecipe.fetch(
                      waterRefillingRecipeView.id,
                      { skip: ['createdBy'] }
                  )
                : (new WaterRefillingRecipe({ enabled: true }) as any);
            const autoFlushingRecipe = waterFlushingRecipeView
                ? await TankFlushingRecipe.fetch(waterFlushingRecipeView.id, {
                      skip: ['createdBy']
                  })
                : (new TankFlushingRecipe({ enabled: true }) as any);
            const climateRecipe = climateRecipeView
                ? await ClimateRecipe.fetch(climateRecipeView.id, {
                      skip: ['createdBy']
                  })
                : (new ClimateRecipe({ enabled: true }) as any);
            return {
                phRecipe,
                ecRecipe,
                waterRefillingRecipe,
                autoFlushingRecipe,
                climateRecipe
            };
        } catch (err) {
            return {};
        }
    }
    setFarmingUnitView(unit: FarmingUnitView): void {
        this.unitView = unit;
    }
}
