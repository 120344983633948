import * as tslib_1 from "tslib";
import { UpdateableInfarmItem } from './mixins/updated-at';
import { ItemWithName } from './mixins/item-with-name';
export var Base = ItemWithName(UpdateableInfarmItem);
var Role = /** @class */ (function (_super) {
    tslib_1.__extends(Role, _super);
    function Role() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Role;
}(Base));
export { Role };
