import * as tslib_1 from "tslib";
import { InfarmItem } from '../api/resources/mixins/infarm-item';
import { isJsObject } from './guards';
/**
 * Compare anything recursively.
 */
export function compare(a, b) {
    var e_1, _a;
    if (typeof a !== typeof b) {
        return false;
    }
    else if (a instanceof InfarmItem) {
        // NOTE: This is a faster and safer way to compare Potion resources
        return a.equals(b);
    }
    else if (a instanceof Date &&
        b instanceof Date &&
        a.getTime() !== b.getTime()) {
        return false;
    }
    else if (Array.isArray(a)) {
        if (a.length !== b.length) {
            return false;
        }
        return a.every(function (item, index) { return compare(item, b[index]); });
    }
    else if (isJsObject(a)) {
        if (!isJsObject(b)) {
            return false;
        }
        if (Object.keys(a).length !== Object.keys(b).length) {
            return false;
        }
        try {
            for (var _b = tslib_1.__values(Object.entries(a)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = tslib_1.__read(_c.value, 2), key = _d[0], obj1Val = _d[1];
                var obj2Val = b[key];
                if (isJsObject(obj1Val) || Array.isArray(obj1Val)) {
                    var result = compare(obj1Val, obj2Val);
                    if (!result) {
                        return false;
                    }
                }
                else if (obj1Val !== obj2Val) {
                    return false;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    else if (a !== b) {
        return false;
    }
    return true;
}
