import * as tslib_1 from "tslib";
import { SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { isNumber, isString } from 'infarm-core';
import { isDate, isMoment } from 'moment';
import { CheckboxField } from '../fields/checkbox';
import { DateField, NumberField, SelectField, TextField } from '../fields';
// TODO: Add unit tests
var FormService = /** @class */ (function () {
    function FormService(sanitizer) {
        this.sanitizer = sanitizer;
    }
    FormService.prototype.parse = function (fields, sanitize) {
        var e_1, _a;
        if (sanitize === void 0) { sanitize = true; }
        var result = {};
        try {
            // Filter out null or blank fields (empty string or string with whitespace).
            for (var fields_1 = tslib_1.__values(fields), fields_1_1 = fields_1.next(); !fields_1_1.done; fields_1_1 = fields_1.next()) {
                var field = fields_1_1.value;
                var key = field.key, value = field.value;
                if (sanitize &&
                    (isNullOrBlankString(value) ||
                        (Array.isArray(value) &&
                            (!value.length ||
                                value.every(function (v) { return isNullOrBlankString(v); }))))) {
                    continue;
                }
                result[key] = parse(this.sanitizer, field, value);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (fields_1_1 && !fields_1_1.done && (_a = fields_1.return)) _a.call(fields_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return result;
    };
    return FormService;
}());
export { FormService };
function isNullOrBlankString(value) {
    return (value === null || typeof value === 'undefined' || isBlankString(value));
}
// TODO: Move to @infarm/core
function isBlankString(value) {
    return isString(value) && /^\s*$/.test(value);
}
var allowedCharacterCodes = /&#(19[2-9]|2[0-9][0-9]|3[0-8][0-9]);/g;
// Allows umlauts and accents that get purged by the angular DomSanitizer, see here for extra info: https://en.wikipedia.org/wiki/List_of_Unicode_characters
function allowCharactersWithAccents(text) {
    return text.replace(allowedCharacterCodes, function (match) {
        var charCode = Number.parseInt(match.replace(/&#/, '0').replace(';', ''), 10);
        return String.fromCharCode(charCode);
    });
}
function parse(sanitizer, field, value) {
    if (field instanceof SelectField &&
        field.multiple &&
        !Array.isArray(value)) {
        return [value];
    }
    // If we have a text field, check if it's a string;
    // if it's not a string convert it to one or to null if it's an empty string.
    if (field instanceof TextField) {
        if (isBlankString(value) || value === null) {
            return null;
        }
        var sanitized = sanitizer.sanitize(SecurityContext.HTML, value);
        return allowCharactersWithAccents(sanitized); // Sanitize input while retaining some special characters.
    }
    if (field instanceof CheckboxField) {
        return !!value;
    }
    if (field instanceof DateField) {
        if (isMoment(value)) {
            // Potion wants Date objects
            return value.toDate();
        }
        else if (isDate(value)) {
            return value;
        }
    }
    if (field instanceof NumberField) {
        if (isNumber(value)) {
            return isNaN(value) ? null : value;
        }
        else if (isBlankString(value)) {
            return null;
        }
        var num = parseFloat(value);
        if (isNaN(num)) {
            return null;
        }
        return num;
    }
    return value;
}
