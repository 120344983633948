const environment = globalThis.env || {};

export const getGoogleClientId = () => {
    return environment.GOOGLE_CLIENT_ID;
};

export const API_HOST = environment.KOMPOST_ENDPOINT;
export const KOMPOST_ENDPOINT = environment.KOMPOST_ENDPOINT;
export const FAAS_MIDDLEWARE = environment.FAAS_MIDDLEWARE;

export function isStaging(): boolean {
    return environment.NAME === 'staging';
}

export function isProd(): boolean {
    return environment.NAME === 'production';
}

export const GRAFANA_EMBED_ROOT: string =
    'https://infarm.grafana.net/d-solo/py1dPvBiz/farm-dashboard';
export const GRAFANA_DASHBOARD_ROOT: string =
    'https://infarm.grafana.net/d/py1dPvBiz/farm-dashboard';

export const PARTICLE_ROOT: string = 'https://console.particle.io';
