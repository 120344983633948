import * as tslib_1 from "tslib";
import { endProductsQuery } from '../graphql/queries/end-products.query';
import { GraphqlService } from '../graphql/graphql.service';
var EndProductsResolver = /** @class */ (function () {
    function EndProductsResolver(graphqlService) {
        this.graphqlService = graphqlService;
    }
    EndProductsResolver.prototype.resolve = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paginator;
            return tslib_1.__generator(this, function (_a) {
                try {
                    paginator = this.graphqlService.makePaginator('endProducts', endProductsQuery, {
                        isActive: true
                    });
                    return [2 /*return*/, paginator.fetchAllEdges()];
                }
                catch (err) {
                    return [2 /*return*/, []];
                }
                return [2 /*return*/];
            });
        });
    };
    return EndProductsResolver;
}());
export { EndProductsResolver };
