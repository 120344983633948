import { UpdateableInfarmItem } from './mixins/updated-at';
import { FetchOptions, Route } from '@infarm/potion-client';

export type RecipeScheduleType = 'Temperature' | 'Light';

export class RecipeSchedule extends UpdateableInfarmItem {
    setFromTimeArray: (
        params?: any,
        options?: FetchOptions
    ) => Promise<any[]> = Route.POST<any[]>('/set_from_time_array');
}
