import { Constructor } from './ctor';
import { InfarmItem } from './infarm-item';
import { RecipeItem } from './recipe-item';
import { User } from '../user';
import { RecordItem } from './record-item';

// TODO: Remove when https://github.com/Microsoft/TypeScript/issues/15870 is fixed
export const UpdateableInfarmItem: Constructor<UpdatedAtType> &
    typeof InfarmItem = UpdatedAt(InfarmItem);
export const UpdateableRecipeItem: Constructor<UpdatedAtType> &
    typeof RecipeItem = UpdatedAt(RecipeItem);
export const UpdateableRecordItem: Constructor<UpdatedAtType> &
    typeof RecordItem = UpdatedAt(RecordItem);

export function UpdatedAt<T extends Constructor>(
    Base: T
): Constructor<UpdatedAtType> & T {
    return class extends Base {
        createdAt: Date;
        createdBy: User;
        updatedAt: Date;
    };
}

export interface UpdatedAtType {
    createdAt: Date;
    createdBy: User;
    updatedAt: Date;
}
