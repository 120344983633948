import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { GRAFANA_EMBED_ROOT } from 'env';

@Injectable()
export class GrafanaEmbedService {
    sharedQueryParams: string = '?refresh=15s&orgId=1&';

    embedTypePanelIdMap: Map<GrafanaEmbedType, number> = new Map<
        GrafanaEmbedType,
        number
    >([
        [GrafanaEmbedType.EcPanel, GrafanaPanelId.EcPanelId],
        [GrafanaEmbedType.PhPanel, GrafanaPanelId.PhPanelId],
        [GrafanaEmbedType.TemperaturePanel, GrafanaPanelId.TemperaturePanelId],
        [GrafanaEmbedType.WaterLevelPanel, GrafanaPanelId.WaterLevelPanelId],
        [
            GrafanaEmbedType.WaterTemperaturePanel,
            GrafanaPanelId.WaterTemperaturePanelId
        ],
        [GrafanaEmbedType.IrrigationPanel, GrafanaPanelId.IrrigationPanelId],
        [GrafanaEmbedType.PhGraph, GrafanaPanelId.PhGraphId],
        [GrafanaEmbedType.EcGraph, GrafanaPanelId.EcGraphId]
    ]);

    constructor(private sanitizer: DomSanitizer) {}

    getPanelEmbed(embedType: GrafanaEmbedType, controllerId: string): SafeHtml {
        const panelId = this.embedTypePanelIdMap.get(embedType);
        const iframeHTML = this.getIFrameHTML(panelId, controllerId);
        const safeEmbed = this.sanitizer.bypassSecurityTrustHtml(iframeHTML);
        return safeEmbed;
    }

    getGraphEmbed(embedType: GrafanaEmbedType, controllerId: string): SafeHtml {
        const panelId = this.embedTypePanelIdMap.get(embedType);
        const iframeHTML = this.getIFrameHTML(panelId, controllerId, {
            width: '100%',
            height: '350'
        });
        const safeEmbed = this.sanitizer.bypassSecurityTrustHtml(iframeHTML);
        return safeEmbed;
    }

    getIFrameHTML(
        panelId: number,
        controllerId: string,
        options: IFrameOptions = {}
    ): string {
        const { width, height } = options;
        const div = document.createElement('div');
        const iframe = document.createElement('iframe');
        iframe.src = `${GRAFANA_EMBED_ROOT}${this.sharedQueryParams}var-coreid=${controllerId}&panelId=${panelId}`;
        iframe.style.marginLeft = 'auto';
        iframe.style.marginRight = 'auto';
        iframe.style.display = 'block';
        iframe.width = width || '225';
        iframe.height = height || '140';
        iframe.frameBorder = '0';
        div.appendChild(iframe);
        return div.innerHTML;
    }
}

interface IFrameOptions {
    height?: string;
    width?: string;
}

export enum GrafanaEmbedType {
    PhPanel = 'PhPanel',
    EcPanel = 'EcPanel',
    TemperaturePanel = 'TemperaturePanel',
    WaterLevelPanel = 'WaterLevelPanel',
    WaterTemperaturePanel = 'WaterTemperaturePanel',
    PhGraph = 'PhGraph',
    EcGraph = 'EcGraph',
    IrrigationPanel = 'IrrigationPanel'
}

export enum GrafanaPanelId {
    PhPanelId = 8,
    EcPanelId = 52,
    TemperaturePanelId = 50,
    WaterLevelPanelId = 51,
    WaterTemperaturePanelId = 58,
    IrrigationPanelId = 70,
    PhGraphId = 61,
    EcGraphId = 62
}
