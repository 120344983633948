import * as tslib_1 from "tslib";
import { FarmingUnit } from 'infarm-core';
import { findUnitId } from './farming-unit.resolver';
var FarmingUnitViewResolver = /** @class */ (function () {
    function FarmingUnitViewResolver() {
    }
    FarmingUnitViewResolver.prototype.resolve = function (route) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var unitView, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, FarmingUnit.view({
                                farmingUnit: findUnitId(route)
                            })];
                    case 1:
                        unitView = _a.sent();
                        return [2 /*return*/, unitView];
                    case 2:
                        err_1 = _a.sent();
                        return [2 /*return*/, null];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return FarmingUnitViewResolver;
}());
export { FarmingUnitViewResolver };
