<span *ngIf="title" mat-dialog-title>{{ title }}</span>

<mat-dialog-content fxLayout="row">
    <mat-form-field class="in-fix-field-width">
        <mat-select
            [(ngModel)]="item"
            [placeholder]="options?.placeholder || 'Choose'"
            [multiple]="options?.multiple"
            fxFlex
        >
            <mat-option
                *ngIf="!options?.multiple && !options?.required"
                [value]="null"
                >None</mat-option
            >
            <mat-option *ngFor="let item of items" [value]="item">
                {{ item.toString() }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>

<mat-card-actions align="end">
    <button mat-button (click)="close()">
        Cancel
    </button>
    <button mat-button (click)="select()" color="primary">
        Select
    </button>
</mat-card-actions>
