import * as tslib_1 from "tslib";
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
var LocationGroupMutators = /** @class */ (function () {
    function LocationGroupMutators(apollo) {
        this.apollo = apollo;
        // TODO investigate graphql partials or template snippets so we are not repeating so many strings
        this.changeGroupNameMutation = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n        mutation UpdateLocationGroup($input: UpdateLocationGroupInput!) {\n            updateLocationGroup(input: $input) {\n                locationGroup {\n                    uuid\n                    name\n                    level\n                    parent {\n                        uuid\n                    }\n                }\n            }\n        }\n    "], ["\n        mutation UpdateLocationGroup($input: UpdateLocationGroupInput!) {\n            updateLocationGroup(input: $input) {\n                locationGroup {\n                    uuid\n                    name\n                    level\n                    parent {\n                        uuid\n                    }\n                }\n            }\n        }\n    "])));
        this.createGroupMutation = gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n        mutation CreateLocationGroup($input: CreateLocationGroupInput!) {\n            createLocationGroup(input: $input) {\n                locationGroup {\n                    uuid\n                    name\n                    level\n                    parent {\n                        uuid\n                        name\n                        level\n                    }\n                    children {\n                        edges {\n                            node {\n                                uuid\n                                name\n                                level\n                            }\n                        }\n                    }\n                }\n            }\n        }\n    "], ["\n        mutation CreateLocationGroup($input: CreateLocationGroupInput!) {\n            createLocationGroup(input: $input) {\n                locationGroup {\n                    uuid\n                    name\n                    level\n                    parent {\n                        uuid\n                        name\n                        level\n                    }\n                    children {\n                        edges {\n                            node {\n                                uuid\n                                name\n                                level\n                            }\n                        }\n                    }\n                }\n            }\n        }\n    "])));
    }
    LocationGroupMutators.prototype.createLocationGroup = function (variables) {
        return this.apollo.mutate({
            mutation: this.createGroupMutation,
            variables: {
                input: variables
            }
        });
    };
    LocationGroupMutators.prototype.updateLocationGroup = function (variables) {
        return this.apollo.mutate({
            mutation: this.changeGroupNameMutation,
            variables: {
                input: variables
            }
        });
    };
    LocationGroupMutators.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocationGroupMutators_Factory() { return new LocationGroupMutators(i0.ɵɵinject(i1.Apollo)); }, token: LocationGroupMutators, providedIn: "root" });
    return LocationGroupMutators;
}());
export { LocationGroupMutators };
var templateObject_1, templateObject_2;
