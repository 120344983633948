import * as tslib_1 from "tslib";
import { ArchivableItem } from './mixins/archivable-item';
import { ItemWithAddresses } from './mixins/item-with-addresses';
import { ItemWithName } from './mixins/item-with-name';
// TODO: Remove when https://github.com/Microsoft/TypeScript/issues/15870 is fixed
export var Base = ItemWithName(ItemWithAddresses);
var Organization = /** @class */ (function (_super) {
    tslib_1.__extends(Organization, _super);
    function Organization() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Organization;
}(ArchivableItem(Base)));
export { Organization };
