import { Injectable, Optional, Provider, SkipSelf } from '@angular/core';
import { Farm, FarmPreview, FarmView, flatten } from 'infarm-core';

import { from, from as fromPromise, Observable } from 'rxjs';
import { map, mergeMap, shareReplay } from 'rxjs/operators';

@Injectable()
export class FarmingService {
    unitGroups: Observable<FarmView> = fromPromise(Farm.preview()).pipe(
        map(groups => flatten(groups.map(group => group.farms))),
        mergeMap((groups: FarmPreview[]) => from(groups)),
        mergeMap(farm => Farm.view({ farm: farm.id })),
        shareReplay()
    );
}

export function FARMING_SERVICE_PROVIDER_FACTORY(
    parentFactory: FarmingService
): FarmingService {
    return parentFactory || new FarmingService();
}

export const FARMING_SERVICE_PROVIDER: Provider = {
    // If there is already a FarmingService available, use that.
    // Otherwise, provide a new one.
    provide: FarmingService,
    useFactory: FARMING_SERVICE_PROVIDER_FACTORY,
    deps: [[new Optional(), new SkipSelf(), FarmingService]]
};
