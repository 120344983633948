import * as tslib_1 from "tslib";
import { UpdateableRecordItem } from './mixins/updated-at';
var HarvestRecord = /** @class */ (function (_super) {
    tslib_1.__extends(HarvestRecord, _super);
    function HarvestRecord() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return HarvestRecord;
}(UpdateableRecordItem));
export { HarvestRecord };
