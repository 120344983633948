import * as tslib_1 from "tslib";
import { UpdateableInfarmItem } from './mixins/updated-at';
import { duration } from 'moment';
var HarvestRound = /** @class */ (function (_super) {
    tslib_1.__extends(HarvestRound, _super);
    function HarvestRound() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HarvestRound.prototype.toString = function () {
        return harvestRoundAsString(this);
    };
    return HarvestRound;
}(UpdateableInfarmItem));
export { HarvestRound };
// TODO: Tests these
export function harvestRoundAsString(round) {
    var startTime = duration(round.startTime, 'seconds');
    var endTime = duration(round.endTime, 'seconds');
    return round.dayOfWeek + ", from " + toTime(startTime) + " to " + toTime(endTime);
}
export function toTime(duration) {
    var hours = duration.hours();
    var minutes = duration.minutes();
    return addPrefix(hours) + ":" + addPrefix(minutes);
}
export function addPrefix(num) {
    if (num < 10) {
        return "0" + num;
    }
    return "" + num;
}
