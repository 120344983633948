import {
    ChangeDetectionStrategy,
    Component,
    DoCheck,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { visible } from 'infarm-core';

import { FieldControlService, fields, FormService } from '../shared';
import { applyFormControlValues } from '../../utils';
import { FieldBase } from '../fields/base';
import { TextField } from '../fields/text';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'in-dynamic-form-card',
    template: `
        <mat-card *ngIf="hasFields" [@visible]="hasFields">
            <mat-card-title *ngIf="title?.length">
                <span class="mat-title">{{ title }}</span>
            </mat-card-title>
            <mat-divider
                *ngIf="title?.length"
                class="in-margin-bottom in-fix-align-mq"
            ></mat-divider>
            <mat-card-content>
                <ng-content></ng-content>
                ${fields}
            </mat-card-content>
            <mat-card-actions align="end">
                <button (click)="abort()" mat-button>
                    Cancel
                </button>
                <button
                    (click)="submit()"
                    [disabled]="disabled"
                    mat-button
                    color="primary"
                >
                    Save
                </button>
            </mat-card-actions>
        </mat-card>
    `,
    styleUrls: ['./dynamic-form-card.component.scss'],
    animations: [visible]
})
export class DynamicFormCardComponent implements DoCheck {
    @Input() fields: Array<FieldBase<any>> = [];
    // If sanitization is enabled,
    // null or blank fields will not be included in the emitted value.
    @Input() sanitize = true;

    @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
    @Output() save: EventEmitter<any> = new EventEmitter<any>();

    @Input() title: string;

    @Input() isSaving: boolean = false;

    form: FormGroup = this.fcs.toFormGroup([]);
    get hasFields(): boolean {
        return Array.isArray(this.fields) && this.fields.length > 0;
    }
    get disabled(): boolean {
        return this.form.pristine || !this.form.valid || this.isSaving;
    }

    constructor(private fs: FormService, private fcs: FieldControlService) {}

    ngDoCheck(): void {
        this.fcs.updateFormGroup(this.form, this.fields);
    }

    renderAsTextarea(field: any): boolean {
        return (
            field instanceof TextField &&
            field.type === 'text' &&
            (typeof field.maxLength !== 'number' || field.maxLength > 250)
        );
    }
    fieldClass(field: FieldBase<any>): string {
        return `${field.controlType}-field`;
    }

    submit(): void {
        let payload = this.fs.parse(this.fields, this.sanitize);
        payload = applyFormControlValues(payload, this.form);
        payload = this.correctNumericProperties(payload);
        // We don't want to submit if we have an invalid form
        if (!this.disabled) {
            this.save.emit(payload);
        }
    }

    correctNumericProperties = (payload: any) => {
        for (const propertyName of Object.keys(payload)) {
            const field = this.fields.filter(x => x.key === propertyName)[0];
            if (field && field.type === 'number') {
                payload[propertyName] = Number.parseFloat(
                    payload[propertyName]
                );
            }
        }
        return payload;
    };

    abort(): void {
        this.cancel.emit(true);
    }
}
