import { Pipe, PipeTransform } from '@angular/core';
import { capitalize } from '../utils/string';

@Pipe({
    name: 'inCapitalize',
    pure: true
})
export class InfarmCapitalizePipe implements PipeTransform {
    transform(value: string, args?: string[]): any {
        return capitalize(value, !!args);
    }
}
