export enum PropertyType {
    String = 'string',
    Number = 'number', // Float
    Integer = 'integer',
    Boolean = 'boolean',
    Object = 'object',
    Array = 'array',
    Null = 'null'
}

export enum Action {
    View = 'View',
    Edit = 'Edit',
    Create = 'Create'
}

export class Schema {
    constructor(private json: any) {}

    properties(forAction: Action = Action.View): Map<string, any> {
        const { properties } = this.json;
        const keys = Object.keys(properties)
            // Filter private props that come from Flask Potion (everything that starts with '$').
            .filter(key => !key.startsWith('$'));

        return new Map(
            Object.entries(properties)
                .filter(([key]) => keys.includes(key))
                .filter((entry: any) => {
                    const { readOnly } = entry[1];
                    return (
                        forAction === Action.View ||
                        (forAction === Action.Create && !readOnly) ||
                        (forAction === Action.Edit && !readOnly)
                    );
                })
        );
    }
}

export function toLabel(key: string): string {
    return (
        key
            .replace(/([0-9A-Z])/g, ' $1')
            // Uppercase the first character.
            .replace(/^./, str => str.toUpperCase())
    );
}

export function isPrimitive(propertyType: string | string[]): boolean {
    return isAnyOf(propertyType, [
        PropertyType.String,
        PropertyType.Number,
        PropertyType.Integer,
        PropertyType.Boolean
    ]);
}

export function isAnyOf(
    propertyType: string | string[],
    types: PropertyType | PropertyType[]
): boolean {
    if (Array.isArray(propertyType)) {
        return (propertyType as string[]).some(item => isAnyOf(item, types));
    }

    if (!Array.isArray(types)) {
        types = [types as PropertyType];
    }

    for (const type of types) {
        if (type === propertyType) {
            return true;
        }
    }

    return false;
}
