import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'inUnitPipe'
})
export class UnitPipe implements PipeTransform {
    transform(value: number, unit: string): any {
        switch (unit) {
            case 'milliseconds': {
                return String(value) + 'ms';
            }
            case 'seconds': {
                return String(value) + 's';
            }
            case 'hours': {
                return String(value) + ' hours';
            }
            case 'days': {
                return String(value) + ' days';
            }
            case 'meters': {
                return String(value) + 'm';
            }
            case 'centimeters': {
                return String(value) + 'cm';
            }
            case 'ec': {
                return String(value) + 'µS';
            }
            case 'waterThroughput': {
                return String(value) + ' L/s';
            }
            case 'celsius': {
                return String(value) + ' °C';
            }
            case 'percent': {
                return String(value) + ' %';
            }
            default: {
                return value;
            }
        }
    }
}
