/**
 * Capitalize string
 * @param value
 * @param [eachWord=false] - Capitalize each word in the string
 */
const regex: RegExp = /([^\W_]+[^\s-]*) */g;
export function capitalize(
    value: string | null,
    eachWord?: boolean
): string | null {
    if (!value) {
        return value;
    }
    return eachWord
        ? value.replace(regex, capitalizeWord)
        : capitalizeWord(value);
}

function capitalizeWord(str: string): string {
    return `${str.charAt(0).toUpperCase()}${str.substr(1)}`;
}

/**
 * Generate a random string
 */
export function uid(): string {
    return Math.random().toString(36).substring(7);
}
