import * as tslib_1 from "tslib";
import { InfarmItemWithName } from './mixins/infarm-item-with-name';
var GrowingMedium = /** @class */ (function (_super) {
    tslib_1.__extends(GrowingMedium, _super);
    function GrowingMedium() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return GrowingMedium;
}(InfarmItemWithName));
export { GrowingMedium };
