import { Constructor } from './mixins/ctor';
import { UpdateableInfarmItem } from './mixins/updated-at';
import { ItemWithName, ItemWithNameType } from './mixins/item-with-name';

export const Base: Constructor<ItemWithNameType> &
    typeof UpdateableInfarmItem = ItemWithName(UpdateableInfarmItem);

export class TrayType extends Base {
    stages: number;
    tracks: number;
    stageMultiplier: number;
}

export enum TrayVariant {
    Linear = 'LINEAR',
    Spiral = 'SPIRAL'
}
