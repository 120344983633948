import { Constructor } from './ctor';
import { isString } from '../../../utils/guards';

export function ItemWithName<T extends Constructor>(Base: T) {
    return class extends Base {
        name: string;

        toString(): string {
            if (isString(this.name)) {
                return this.name;
            }
            return super.toString();
        }
    };
}

export interface ItemWithNameType {
    name: string;
    toString(): string;
}
