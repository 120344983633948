import * as tslib_1 from "tslib";
import { ControlType, FieldBase } from './base';
var CheckboxField = /** @class */ (function (_super) {
    tslib_1.__extends(CheckboxField, _super);
    function CheckboxField(options) {
        if (options === void 0) { options = {}; }
        var _this = this;
        Object.assign(options, {
            controlType: ControlType.Checkbox
        });
        _this = _super.call(this, options) || this;
        return _this;
    }
    return CheckboxField;
}(FieldBase));
export { CheckboxField };
