import * as tslib_1 from "tslib";
import { InfarmItem } from './infarm-item';
import { RecipeItem } from './recipe-item';
import { RecordItem } from './record-item';
// TODO: Remove when https://github.com/Microsoft/TypeScript/issues/15870 is fixed
export var UpdateableInfarmItem = UpdatedAt(InfarmItem);
export var UpdateableRecipeItem = UpdatedAt(RecipeItem);
export var UpdateableRecordItem = UpdatedAt(RecordItem);
export function UpdatedAt(Base) {
    return /** @class */ (function (_super) {
        tslib_1.__extends(class_1, _super);
        function class_1() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return class_1;
    }(Base));
}
