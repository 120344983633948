import * as tslib_1 from "tslib";
import { GraphqlService } from './graphql/graphql.service';
import { rolesQuery } from './graphql/queries';
var RolesResolver = /** @class */ (function () {
    function RolesResolver(graphqlService) {
        this.graphqlService = graphqlService;
    }
    RolesResolver.prototype.resolve = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paginator;
            return tslib_1.__generator(this, function (_a) {
                paginator = this.graphqlService.makePaginator('roles', rolesQuery, {
                    isGlobal: false
                });
                return [2 /*return*/, paginator.fetchAllEdges()];
            });
        });
    };
    return RolesResolver;
}());
export { RolesResolver };
