import { isJsObject } from 'infarm-core';

import { BehaviorSubject, Observable } from 'rxjs';

export class Filtering {
    private $change: BehaviorSubject<Filter> = new BehaviorSubject<Filter>({});
    change: Observable<Filter> = this.$change.asObservable(); // tslint:disable-line:member-ordering

    get(): Filter {
        return this.$change.value;
    }
    update(filter?: Filter): this {
        if (isJsObject(filter)) {
            this.$change.next(filter);
        }
        return this;
    }
}

export interface Filter {
    [key: string]: any;
}
