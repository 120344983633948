import { FetchOptions, Route } from '@infarm/potion-client';

import { Constructor } from './mixins/ctor';
import { ArchivableItem } from './mixins/archivable-item';
import { UpdatedAt, UpdatedAtType } from './mixins/updated-at';
import { Organization } from './organization';
import { Role } from './role';
import { InfarmItem } from './mixins/infarm-item';

// TODO: Remove when https://github.com/Microsoft/TypeScript/issues/15870 is fixed
export const Base: Constructor<UpdatedAtType> & typeof InfarmItem = UpdatedAt(
    InfarmItem
);

export class User extends ArchivableItem(Base) {
    /**
     * Grower list
     */
    static growers: (
        params?: any,
        options?: FetchOptions
    ) => Promise<User[]> = Route.GET<User[]>('/growers');

    /**
     * Get current user-name.
     */
    static me: (
        params?: any,
        options?: FetchOptions
    ) => Promise<UserView> = Route.GET<UserView>('/me');
    static setRoleSet: (
        params: SetRoleParams,
        options?: FetchOptions
    ) => Promise<UserView> = Route.POST<UserView>('/me');

    static setPassword: (
        params: SetPasswordParams,
        options?: FetchOptions
    ) => Promise<null> = Route.POST<null>('/set_password');
    static resetPassword: (
        params: ResetPasswordParams,
        options?: FetchOptions
    ) => Promise<boolean> = Route.POST<boolean>('/reset_password');
    static changePassword: (
        params: ChangePasswordParams,
        options?: FetchOptions
    ) => Promise<boolean> = Route.POST<boolean>('/change_password');

    organization: Organization;
    roles?: Role[];

    firstName: string;
    familyName: string;
    email: string;
    isVerified: boolean;

    toString(): string {
        const familyName = this.familyName;
        return `${this.firstName}${familyName ? ` ${familyName}` : ''}`;
    }
}

export interface UserRouteParams {
    user: User | string | number;
}

export type ResetPasswordParams = UserRouteParams;
export type ResetAccessCodeParams = UserRouteParams;

export interface ChangePasswordParams extends UserRouteParams {
    newPassword: string;
}

export interface SetAccessCodeParams extends UserRouteParams {
    currentPassword: string;
    accessCode: string;
}

export interface SetPasswordParams extends UserRouteParams {
    currentPassword: string;
    newPassword: string;
}

export interface SetRoleParams {
    newIdentity?: number;
}

export interface UserView {
    id: number;
    organizationId: number;
    firstName: string;
    familyName: string;
    email: string;
    isVerified: boolean;
    isSuperAdmin: boolean;
    isDeleted: boolean;
    roles: string[];
}
