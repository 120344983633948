/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notes-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "./notes-dialog.component";
var styles_NotesDialogComponent = [i0.styles];
var RenderType_NotesDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotesDialogComponent, data: {} });
export { RenderType_NotesDialogComponent as RenderType_NotesDialogComponent };
export function View_NotesDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Notes"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "mat-body-1 in-notes"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.notes; _ck(_v, 5, 0, currVal_1); }); }
export function View_NotesDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "in-notes-dialog", [], null, null, null, View_NotesDialogComponent_0, RenderType_NotesDialogComponent)), i1.ɵdid(1, 49152, null, 0, i3.NotesDialogComponent, [[2, i2.MAT_DIALOG_DATA]], null, null)], null, null); }
var NotesDialogComponentNgFactory = i1.ɵccf("in-notes-dialog", i3.NotesDialogComponent, View_NotesDialogComponent_Host_0, {}, {}, []);
export { NotesDialogComponentNgFactory as NotesDialogComponentNgFactory };
