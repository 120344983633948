import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { InfarmItem } from 'infarm-core';
import { DialogService } from '../dialog.service';
import { ResourceType } from '../resources';
import { ResourcePickerDialogComponent } from './resource-picker-dialog.component';
var ResourcePickerComponent = /** @class */ (function () {
    function ResourcePickerComponent(dialog) {
        this.dialog = dialog;
        this.select = new EventEmitter();
    }
    ResourcePickerComponent.prototype.ngOnInit = function () {
        var _this = this;
        var resourceType = this.resourceType.type;
        if (this.resource instanceof InfarmItem) {
            this.item = this.resource;
        }
        resourceType.query(this.query).then(function (items) {
            _this.items = items;
        });
    };
    ResourcePickerComponent.prototype.pick = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, resource;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data = {
                            resourceType: this.resourceType,
                            resource: this.resource,
                            title: this.title,
                            options: {
                                placeholder: this.placeholder,
                                required: this.required,
                                multiple: this.multiple
                            }
                        };
                        return [4 /*yield*/, this.dialog
                                .open(ResourcePickerDialogComponent, { data: data, disableClose: true })
                                .afterClosed()
                                .toPromise()];
                    case 1:
                        resource = _a.sent();
                        this.select.emit(resource);
                        return [2 /*return*/];
                }
            });
        });
    };
    return ResourcePickerComponent;
}());
export { ResourcePickerComponent };
