import { ControlType, FieldBase, FieldBaseOptions } from './base';

export interface NumberFieldOptions extends FieldBaseOptions<number> {} // tslint:disable-line: no-empty-interface

export class NumberField extends FieldBase<number> {
    readonly type: string;

    constructor(options: NumberFieldOptions = {}) {
        super({
            ...options,
            controlType: ControlType.Text
        });
        this.type = 'number';
    }
}
