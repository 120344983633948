import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { toCamelCase, toSnakeCase } from '@infarm/potion-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { omap } from '../utils/object';

@Injectable()
export class ImageStorage {
    constructor(private http: HttpClient) {}

    // TODO: validate that the selected file is a JPEG image
    upload(
        image: File | Blob | string,
        options?: ImageUploadOptions
    ): Observable<ImageUploadResponse> {
        const data: FormData = new FormData();
        // If we get a base64 string, convert to a blob.
        const blob =
            image instanceof File || image instanceof Blob
                ? image
                : dataURItoBlob(image as string);
        // NOTE: We need to set the file name, otherwise the stored file cannot be used anywhere.
        data.append('image', blob, filename(blob));

        if (options !== null && typeof options === 'object') {
            for (const [key, value] of Object.entries(options)) {
                // NOTE: Our backend only accepts snakecase params,
                // therefore, we do the conversion for each key.
                data.append(toSnakeCase(key), value);
            }
        }

        return this.http
            .post('/api/image/upload', data, {
                observe: 'response',
                responseType: 'json'
            })
            .pipe(
                map(res => res.body),
                map(json => omap(json, key => toCamelCase(key)))
            );
    }
}

export function dataURItoBlob(dataURI: string): Blob {
    const [metadata, imageURI] = dataURI.split(',');

    // Convert base64/URLEncoded data component to raw binary data held in a string
    const byteString =
        metadata.indexOf('base64') >= 0 ? atob(imageURI) : decodeURI(imageURI);

    // Separate out the mime component
    const mimeType = metadata.split(':')[1].split(';')[0];

    // Write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {
        type: mimeType
    });
}

export function filename(blob: File | Blob): string {
    if (blob instanceof File) {
        // NOTE: We assume that this already has the file extension.
        return blob.name;
    } else {
        // Generate a random string that can be used as file name.
        // Add a JPEG file extension.
        const name = Math.random().toString(36).substring(7);
        return `${name}.jpg`;
    }
}

export interface ImageUploadResponse {
    imageId: number;
    imageUrl: string;
    status: string;
}

export interface ImageUploadOptions {
    farmId?: number;
    farmingUnitId?: number;
    category?: string;
    description?: string;
    endProductId?: number;
}
