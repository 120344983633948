import { MatCheckboxChange } from '@angular/material';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { EventEmitter, Output } from '@angular/core';

export class MultipleSelect<T extends Row> {
    allRows: Observable<T[]>; // tslint:disable-line:member-ordering
    selected: T[] = [];
    @Output() select: EventEmitter<T[]> = new EventEmitter<T[]>();

    setSources(rows: Observable<T[]>) {
        this.allRows = rows;
    }

    isRowSelected(row: T): boolean {
        return this.selectedRowIndex(row) !== -1;
    }

    areAllRowsSelected(): Observable<boolean> {
        const allSelected = this.allRows.pipe(
            take(1),
            map((rows: T[]) => {
                if (rows.length === 0) {
                    return false;
                }
                return rows.every(row => {
                    const rowSelected = this.selected.find(r => {
                        return r.equals(row);
                    });
                    return rowSelected !== undefined;
                });
            })
        );
        return allSelected;
    }

    updateSelection(row: T): void {
        this.toggleRow(row);
        this.publish();
    }

    preventBubble(event: MouseEvent): void {
        // Prevent click event propagation up the DOM.
        // https://developer.mozilla.org/en-US/docs/Web/API/Document_Object_Model/Examples#Example_5:_Event_Propagation
        // We do this because,
        // if {clickable} is enabled,
        // we do not want the click event attached on the table row to also trigger.
        event.stopPropagation();
    }

    selectAllRows(change: MatCheckboxChange): void {
        const { checked } = change;
        this.allRows
            .pipe(
                take(1),
                tap(rows => {
                    rows.map(row => {
                        const index = this.selectedRowIndex(row);
                        if (checked) {
                            if (index === -1) {
                                this.selected.push(row);
                            }
                        } else {
                            if (index !== -1) {
                                this.selected.splice(index, 1);
                            }
                        }
                    });
                })
            )
            .subscribe(() => {
                this.publish();
            });
    }

    selectedRowIndex(row: T): number {
        const index = this.selected.findIndex(r => {
            return r.equals(row);
        });
        return index;
    }

    publish(): void {
        this.select.next(this.selected);
    }

    toggleRow(row: T): void {
        const index = this.selectedRowIndex(row);
        if (index !== -1) {
            this.selected.splice(index, 1);
        } else {
            this.selected.push(row);
        }
    }
}

interface Row {
    equals(T): boolean;
}
