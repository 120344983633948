import * as tslib_1 from "tslib";
import { ArchivableItem } from './mixins/archivable-item';
import { ItemWithName } from './mixins/item-with-name';
import { UpdatedAt } from './mixins/updated-at';
import { InfarmItem } from './mixins/infarm-item';
export var Base = ItemWithName(UpdatedAt(InfarmItem));
var SectorConfiguration = /** @class */ (function (_super) {
    tslib_1.__extends(SectorConfiguration, _super);
    function SectorConfiguration() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return SectorConfiguration;
}(ArchivableItem(Base)));
export { SectorConfiguration };
