import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
    CurrentUser,
    ItemNameModule,
    PipesModule,
    UserNameModule
} from 'infarm-core';

/**
 * Angular Cdk
 */
import { PortalModule } from '@angular/cdk/portal';
import { CdkTableModule } from '@angular/cdk/table';
import { ScrollDispatcher, ScrollDispatchModule } from '@angular/cdk/scrolling';

/**
 * Material
 * Check https://www.npmjs.com/~angular2-material periodically and install/update to the proper versions.
 * Also keep an eye on https://github.com/angular/material2/blob/master/CHANGELOG.md.
 */
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    NativeDateModule
} from '@angular/material';

import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MatMomentDateModule
} from '@angular/material-moment-adapter';

/**
 * Angular Flex Layout
 * Integration with Angular CLI: https://github.com/angular/flex-layout/wiki/Integration-with-Angular-CLI.
 * API: https://github.com/angular/flex-layout/wiki/API-Documentation.
 * Also keep an eye on https://github.com/angular/flex-layout/blob/master/CHANGELOG.md.
 */
import { FlexLayoutModule } from '@angular/flex-layout';

/**
 * Infarm
 */
import { ViewTitleComponent } from './view-title/view-title.component';
import { ColumnValueComponent } from './items-table/column-value.component';
import { ItemsTableComponent } from './items-table/items-table.component';
import { TableCardComponent } from './table-card/table-card.component';
import { TableContainerComponent } from './table-card/table-container.component';
import { DynamicFormCardComponent } from './dynamic-form/dynamic-form-card/dynamic-form-card.component';
import { TablePaginationComponent } from './table-card/table-pagination.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { PaginatedResourcesComponent } from './paginated-resources/paginated-resources.component';
import { UserInitialsComponent } from './user-initials/user-initials.component';
import { AddressComponent } from './address/address.component';
import { HarvestRoundComponent } from './harvest-round/harvest-round.component';
import { ResourceItemsComponent } from './resource-items/resource-items.component';
import { CellValueComponent } from './resource-items/cell-value/cell-value.component';

import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DynamicFormDialogComponent } from './dynamic-form/dynamic-form-dialog/dynamic-form-dialog.component';
import { ResourcePickerDialogComponent } from './resource-picker/resource-picker-dialog.component';
import { ResourcePickerComponent } from './resource-picker/resource-picker.component';
import { HarvestRoundDialogComponent } from './harvest-round-dialog/harvest-round-dialog.component';
import { NotesDialogComponent } from './show-notes/notes-dialog.component';

import { ShowNotesDirective } from './show-notes';

import { AuthGuard } from './auth.guard';
import { RedirectGuard } from './redirect.guard';

import {
    ApiFarmResolver,
    EndProductsResolver,
    FarmingUnitAndRecipesResolver,
    FarmingUnitResolver,
    FarmingUnitViewResolver,
    FarmResolver,
    FarmViewResolver,
    GrowersResolver,
    RecipesResolver,
    SectorConfigurationsViewResolver,
    TrayTypesResolver
} from './resources';

import { EuropeanDateAdapter, INFARM_DATE_FORMATS } from './utils';

import { CellParserService } from './resource-items/cell-value/cell-parser.service';
import { ColumnParserService } from './items-table/column-parser.service';
import { ConfirmationService } from './confirm-dialog/confirmation.service';
import { DialogService } from './dialog.service';
import { FieldControlService } from './dynamic-form/shared/field-control.service';
import { FieldsService } from './dynamic-form/shared/fields.service';
import { FormService } from './dynamic-form/shared/form.service';
import { HarvestRoundDialogService } from './harvest-round-dialog/harvest-round-dialog.service';
import { DynamicFormDialogService } from './dynamic-form/dynamic-form-dialog/dynamic-form-dialog.service';
import { FARMING_SERVICE_PROVIDER } from './farming.service';
import { FARMING_UNIT_STATUS_STREAM_MANAGER_PROVIDER } from './farming-unit-status-stream-manager';
import { GOOGLE_ANALYTICS_SERVICE_PROVIDER } from './ga.service';
import { MQ_SERVICE_PROVIDER } from './mq.service';
import { NAVIGATION_HISTORY_SERVICE_PROVIDER } from './navigation-history.service';
import { PROGRESS_SERVICE_PROVIDER } from './progress.service';
import { RETRY_SERVICE_PROVIDER } from './retry.service';
import { TOAST_SERVICE_PROVIDER } from './toast.service';
import { RecipesService } from './services/recipes.service';
import { GraphqlService } from './graphql/graphql.service';
import { ScheduleRouterLinkDirective } from './schedule-link.directive';

import { GrowerAutoSelectComponent } from './grower-auto-select/grower-auto-select.component';
import { ExtractRenderDataPipe } from './pipes/extract-render-data.pipe';
import { UsersService } from './services/current-user-graphql-service';
import { ListFilterPipe, TimeStringPipe } from './pipes';
import { MatRadioModule } from '@angular/material/radio';
import { RolesResolver } from './roles.resolver';
import { CurrentUserGraphqResolver, UsersResolver } from './users.resolver';
import { LocationGroupsResolver } from './location-groups.resolver';
import { EditableModeSwitchComponent } from './editable-mode-switch/editable-mode-switch.component';
import { LayoutComponent } from './layout/layout.component';
import { ErrorComponent } from './error/error.component';
import { EmptyComponent } from './empty-component/empty-component.component';

/**
 * Modules
 */
export const modules: any[] = [
    // Angular
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // Angular Flex Layout
    FlexLayoutModule,
    // Material 2
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatRadioModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatExpansionModule,
    MatTreeModule,
    NativeDateModule,
    // Angular Cdk
    CdkTableModule,
    PortalModule,
    ScrollDispatchModule,
    MatStepperModule,
    // Infarm
    ItemNameModule,
    PipesModule,
    UserNameModule
];

/**
 * Components
 */
export const components: any[] = [
    EditableModeSwitchComponent,
    ViewTitleComponent,
    ColumnValueComponent,
    ItemsTableComponent,
    TableCardComponent,
    TableContainerComponent,
    DynamicFormCardComponent,
    TablePaginationComponent,
    ImageUploadComponent,
    PaginatedResourcesComponent,
    UserInitialsComponent,
    AddressComponent,
    HarvestRoundComponent,
    ResourceItemsComponent,
    CellValueComponent,
    GrowerAutoSelectComponent,
    LayoutComponent,
    ErrorComponent,
    EmptyComponent
];

/**
 * Entry Components
 */
export const entryComponents: any[] = [
    ConfirmDialogComponent,
    DynamicFormDialogComponent,
    ResourcePickerComponent,
    ResourcePickerDialogComponent,
    HarvestRoundDialogComponent,
    NotesDialogComponent
];

/**
 * Directives
 */
export const directives: any[] = [
    ShowNotesDirective,
    ScheduleRouterLinkDirective
];

/**
 * Pipes
 */
export const pipes: any[] = [
    ExtractRenderDataPipe,
    ListFilterPipe,
    TimeStringPipe
];

/**
 * Guards
 */
export const guards = [AuthGuard, RedirectGuard];

/**
 * Resolvers
 */
export const resolvers: any[] = [
    EndProductsResolver,
    FarmingUnitAndRecipesResolver,
    FarmingUnitResolver,
    FarmingUnitViewResolver,
    FarmResolver,
    ApiFarmResolver,
    FarmViewResolver,
    GrowersResolver,
    RecipesResolver,
    SectorConfigurationsViewResolver,
    TrayTypesResolver,
    UsersResolver,
    CurrentUserGraphqResolver,
    LocationGroupsResolver,
    RolesResolver
];

/**
 * Services
 */
export const services: any[] = [
    CellParserService,
    ColumnParserService,
    ConfirmationService,
    DialogService,
    FieldControlService,
    RecipesService,
    FieldsService,
    FormService,
    GraphqlService,
    HarvestRoundDialogService,
    DynamicFormDialogService,
    FARMING_SERVICE_PROVIDER,
    GOOGLE_ANALYTICS_SERVICE_PROVIDER,
    MQ_SERVICE_PROVIDER,
    NAVIGATION_HISTORY_SERVICE_PROVIDER,
    PROGRESS_SERVICE_PROVIDER,
    RETRY_SERVICE_PROVIDER,
    TOAST_SERVICE_PROVIDER,
    UsersService,
    CurrentUser
];

// NOTE: Do not specify providers for modules that might be imported by a lazy loaded module,
// especially when the provider needs to be a singleton, use `forRoot()` instead.
@NgModule({
    // A list of supporting modules.
    // Specifically, the list of modules whose exported components,
    // directives or pipes are referenced by the component templates declared in this module.
    imports: [...modules],
    // Components/Directives/Pipes
    // Declared classes are visible within the module but invisible to components in a different module unless (a) they are exported from this module and (b) that other module imports this one.
    declarations: [...directives, ...components, ...entryComponents, ...pipes],
    entryComponents: [...entryComponents],
    // Enable the use of Web Components
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    // A list of DI providers that an importing module can use.
    providers: [
        ...services,
        ...guards,
        ...resolvers,
        ScrollDispatcher,
        FARMING_UNIT_STATUS_STREAM_MANAGER_PROVIDER,
        { provide: DateAdapter, useClass: EuropeanDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: INFARM_DATE_FORMATS },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS }
    ],
    // A list of declarations (component, directive,
    // and pipe classes) that an importing module can use.
    exports: [...modules, ...directives, ...components, ...pipes]
})
export class SharedModule {}
