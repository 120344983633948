import * as tslib_1 from "tslib";
import { ClimateRecipe, EcRecipe, PhRecipe, TankFlushingRecipe, WaterRefillingRecipe } from 'infarm-core';
var RecipesResolver = /** @class */ (function () {
    function RecipesResolver() {
    }
    RecipesResolver.prototype.resolve = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var phRecipeView, ecRecipeView, waterRefillingRecipeView, waterFlushingRecipeView, climateRecipeView, phRecipe, _a, ecRecipe, _b, waterRefillingRecipe, _c, autoFlushingRecipe, _d, climateRecipe, _e, err_1;
            return tslib_1.__generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _f.trys.push([0, 16, , 17]);
                        phRecipeView = this.unitView.recipes.water.ph;
                        ecRecipeView = this.unitView.recipes.water.ec;
                        waterRefillingRecipeView = this.unitView.recipes.water.wrf;
                        waterFlushingRecipeView = this.unitView.recipes.water.wfl;
                        climateRecipeView = this.unitView.recipes.climate;
                        if (!phRecipeView) return [3 /*break*/, 2];
                        return [4 /*yield*/, PhRecipe.fetch(phRecipeView.id, { skip: ['createdBy'] })];
                    case 1:
                        _a = _f.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        _a = {};
                        _f.label = 3;
                    case 3:
                        phRecipe = _a;
                        if (!ecRecipeView) return [3 /*break*/, 5];
                        return [4 /*yield*/, EcRecipe.fetch(ecRecipeView.id, { skip: ['createdBy'] })];
                    case 4:
                        _b = _f.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        _b = {};
                        _f.label = 6;
                    case 6:
                        ecRecipe = _b;
                        if (!waterRefillingRecipeView) return [3 /*break*/, 8];
                        return [4 /*yield*/, WaterRefillingRecipe.fetch(waterRefillingRecipeView.id, { skip: ['createdBy'] })];
                    case 7:
                        _c = _f.sent();
                        return [3 /*break*/, 9];
                    case 8:
                        _c = new WaterRefillingRecipe({ enabled: true });
                        _f.label = 9;
                    case 9:
                        waterRefillingRecipe = _c;
                        if (!waterFlushingRecipeView) return [3 /*break*/, 11];
                        return [4 /*yield*/, TankFlushingRecipe.fetch(waterFlushingRecipeView.id, {
                                skip: ['createdBy']
                            })];
                    case 10:
                        _d = _f.sent();
                        return [3 /*break*/, 12];
                    case 11:
                        _d = new TankFlushingRecipe({ enabled: true });
                        _f.label = 12;
                    case 12:
                        autoFlushingRecipe = _d;
                        if (!climateRecipeView) return [3 /*break*/, 14];
                        return [4 /*yield*/, ClimateRecipe.fetch(climateRecipeView.id, {
                                skip: ['createdBy']
                            })];
                    case 13:
                        _e = _f.sent();
                        return [3 /*break*/, 15];
                    case 14:
                        _e = new ClimateRecipe({ enabled: true });
                        _f.label = 15;
                    case 15:
                        climateRecipe = _e;
                        return [2 /*return*/, {
                                phRecipe: phRecipe,
                                ecRecipe: ecRecipe,
                                waterRefillingRecipe: waterRefillingRecipe,
                                autoFlushingRecipe: autoFlushingRecipe,
                                climateRecipe: climateRecipe
                            }];
                    case 16:
                        err_1 = _f.sent();
                        return [2 /*return*/, {}];
                    case 17: return [2 /*return*/];
                }
            });
        });
    };
    RecipesResolver.prototype.setFarmingUnitView = function (unit) {
        this.unitView = unit;
    };
    return RecipesResolver;
}());
export { RecipesResolver };
