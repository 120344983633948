// TODO: Move all these to @infarm/core
// tslint:disable: no-magic-numbers
// tslint:disable: no-duplicate-imports
import { ParamMap } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { NativeDateAdapter } from '@angular/material';
import { Moment } from 'moment';
import {
    FarmNames,
    isString,
    OrganizationView,
    sort,
    Token,
    User
} from 'infarm-core';
import { EndProduct, TrayType } from './graphql/interfaces';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

/**
 * Remove null, undefined, empty strings and arrays props from an object.
 */
// TODO: Remove props with empty objects too
export function removeEmptyProps(object: {
    [key: string]: any;
}): { [key: string]: any } {
    return Object.entries(object)
        .map(([key, value]) => [value, key])
        .filter(([value]) => value !== undefined && value !== null) // Remove any null props
        .filter(([value]) =>
            isString(value) || Array.isArray(value) ? value.length > 0 : true
        ) // Remove props with empty strings or empty arrays
        .reduce((acc: { [key: string]: any }, [value, key]: any[]) => {
            acc[key] = value;
            return acc;
        }, {});
}

// https://developer.mozilla.org/en-US/docs/Glossary/Primitive
// http://stackoverflow.com/questions/18082/validate-decimal-numbers-in-javascript-isnumeric
export function isPrimitive(value: any): boolean {
    if (value === null || value === undefined) {
        return true;
    } else if (isString(value)) {
        return true;
    } else if (typeof value === 'boolean') {
        return true;
    } else if (Number.isNaN(value)) {
        return true;
    } else if (!isNaN(parseFloat(value))) {
        return true;
    }

    return false;
}

export function zip(a: any[], b: any[]): any[][] {
    return a.map((c, i) => [c, b[i]]);
}

export const formatDates = (infarmItem: any): any => ({
    ...infarmItem,
    date: new Date(infarmItem.date)
});

export const getISODate = (date: Date): string => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month < 10 ? `0${month}` : month}-${
        day < 10 ? `0${day}` : day
    }`;
};

export const removeTimezoneFromTimeString = (time: string): string =>
    time.split('+')[0];

export const getTimeStringInMilliseconds = (time: string): number => {
    const timeWithoutTimezone = removeTimezoneFromTimeString(time);
    const times = timeWithoutTimezone.split(':');
    let duration = 0;

    duration += Number(times[0]) * 60 * 60 * 1000;
    if (times[1]) {
        duration += Number(times[1]) * 60 * 1000;
    }
    if (times[2]) {
        duration += Number(times[2]) * 1000;
    }
    return duration;
};

export const getDurationInMilliseconds = (
    startTime: string,
    endTime: string
): number =>
    getTimeStringInMilliseconds(endTime) -
    getTimeStringInMilliseconds(startTime);

export function toPx(value: number): string {
    return `${value}px`;
}

export function fromPx(value: string): number {
    if (value.indexOf('px') !== -1) {
        const px = value.replace('px', '');
        return parseFloat(px);
    }
    return NaN;
}

export function getMoment(queryParamMap: ParamMap): Moment {
    const date = queryParamMap.get('date');
    return date ? moment(parseInt(date, 10)) : moment(new Date());
}

export function getBoundsOfDayForDate(date: string): Date[] {
    const queryParamDate: Moment = date ? moment(parseInt(date, 10)) : moment();
    return [
        momentToUtcDate(queryParamDate.startOf('day')),
        momentToUtcDate(queryParamDate.endOf('day'))
    ];
}

export function momentToUtcDate(moment: Moment): Date {
    return new Date(
        Date.UTC(
            moment.year(),
            moment.month(),
            moment.date(),
            moment.hour(),
            moment.minute(),
            moment.seconds()
        )
    );
}

/**
 * Takes a date and returns a string in YYYY-MM-DD format
 */
export function toShortDate(date: Date) {
    const pad = (day: number) => (day < 10 ? '0' + day : day);
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
        date.getDate()
    )}`;
}

export const THREE_WEEKS_IN_DAYS = 21;

// Promise.all rejects when first promise in the array rejects, we do not want this behavior, hence this method
// https://stackoverflow.com/a/31424853
export function dontRejectOnFailure(
    promise: Promise<any>
): Promise<PerformedPromise> {
    return promise.then(
        resolved => ({ result: resolved, status: PromiseStatus.fulfilled }),
        e => ({ e, status: PromiseStatus.rejected })
    );
}

interface PerformedPromise {
    result?: any;
    status: PromiseStatus;
    error?: any;
}

export enum PromiseStatus {
    fulfilled,
    rejected
}

export function getFarmNames(organisations: OrganizationView[]): FarmNames[] {
    let farms = [];
    for (const organisation of organisations) {
        const farmNames: FarmNames[] = organisation.farms.map(farm => {
            return {
                id: farm.id,
                name: farm.name
            };
        });
        farms = farms.concat(farmNames);
    }
    return [].concat(...farms);
}

export function sortNamedObject(namedObjects: any[]): any[] {
    return sort(namedObjects, namedObject =>
        namedObject && namedObject.name
            ? namedObject.name.toLocaleUpperCase()
            : ''
    );
}

export const dayInMilliseconds = 86400000;
export const maxDate = new Date(8640000000000000);
export const datetimeLocalFormat = 'YYYY-MM-DDTHH:mm';

export const INFARM_DATE_FORMATS = {
    parse: {
        dateInput: { month: 'numeric', year: 'numeric', day: 'numeric' }
    },
    display: {
        dateInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
        monthYearLabel: { year: 'numeric', month: 'numeric' },
        dateA11yLabel: { year: 'numeric', month: 'numeric', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'numeric' }
    }
};

@Injectable()
export class EuropeanDateAdapter extends NativeDateAdapter {
    format(date: Date, displayFormat: object): string {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return this.to2digit(day) + '/' + this.to2digit(month) + '/' + year;
    }

    parse(value: any): Date | null {
        if (typeof value === 'number') {
            return new Date(value);
        }
        const monthDayYear = value.split('/');
        const dateString =
            monthDayYear[1] + '/' + monthDayYear[0] + '/' + monthDayYear[2];
        return value ? new Date(Date.parse(dateString)) : null;
    }

    to2digit(n: number) {
        return ('00' + n).slice(-2);
    }
}

export const range = (start, end) =>
    Array.from({ length: end - start }, (_, k) => k + start);

// TimeSpan can be 1) a time of day or 2) a timestamp
export interface TimeSpan {
    startTime: string | number;
    endTime: string | number;
}

export const isEarlyOverlap = (visitA: TimeSpan, visitB: TimeSpan) =>
    visitA.startTime <= visitB.startTime && visitA.endTime > visitB.startTime;

export const isLateOverlap = (visitA: TimeSpan, visitB: TimeSpan) =>
    visitA.startTime < visitB.endTime && visitA.endTime >= visitB.endTime;

export const isMiddleOverlap = (visitA: TimeSpan, visitB: TimeSpan) =>
    visitA.startTime >= visitB.startTime && visitA.endTime < visitB.endTime;

export const isBroadOverlap = (visitA: TimeSpan, visitB: TimeSpan) =>
    visitA.startTime <= visitB.startTime && visitA.endTime >= visitB.endTime;

export function isTimeOverlapping(a: TimeSpan, b: TimeSpan): boolean {
    return (
        isEarlyOverlap(a, b) ||
        isLateOverlap(a, b) ||
        isMiddleOverlap(a, b) ||
        isBroadOverlap(a, b)
    );
}

export interface DateSpan {
    startTime: Date;
    endTime: Date;
}

export const traysComparer: (first: TrayType, second: TrayType) => number = (
    first: TrayType,
    second: TrayType
) => {
    const trayTypeRegex = /([0-9]{1,2})T([0-9]{1})Sx([0-9]){1}/;
    const [, firstTracks, firstRows, firstMultiplier] = first.name.match(
        trayTypeRegex
    );
    const [, secondTracks, secondRows, secondMultiplier] = second.name.match(
        trayTypeRegex
    );

    return (
        Number.parseInt(firstTracks, 10) - Number.parseInt(secondTracks, 10) ||
        Number.parseInt(firstRows, 10) - Number.parseInt(secondRows, 10) ||
        Number.parseInt(firstMultiplier, 10) -
            Number.parseInt(secondMultiplier, 10)
    );
};

export const endProductsComparer: (
    first: EndProduct,
    second: EndProduct
) => number = (first: EndProduct, second: EndProduct) => {
    return first.internalCode && second.internalCode
        ? first.internalCode.localeCompare(second.internalCode)
        : first.name.trim().localeCompare(second.name.trim());
};

export function sortFarmingUnits(farmingUnits: any[]): any[] {
    return farmingUnits.sort((a, b) => {
        const numbersRegex = /\D/g;
        const lettersRegex = /\d/g;
        const numbersA = Number(
            a.name ? a.name.replace(numbersRegex, '') : Number.MIN_VALUE
        );
        const numbersB = Number(
            b.name ? b.name.replace(numbersRegex, '') : Number.MIN_VALUE
        );
        const lettersA = a.name ? a.name.replace(lettersRegex, '') : '';
        const lettersB = b.name ? b.name.replace(lettersRegex, '') : '';
        if (lettersA === lettersB) {
            return numbersA > numbersB ? 1 : -1;
        }
        return lettersA > lettersB ? 1 : -1;
    });
}

export function applyFormControlValues<T>(item: T, form: FormGroup): T {
    const formControlKeys = Object.keys(form.controls);
    for (const formControlKey of formControlKeys) {
        const formControl = form.get(formControlKey);
        if (formControl) {
            // tslint:disable-next-line:prefer-conditional-expression
            if (typeof formControl.value === 'boolean') {
                item[formControlKey] = !!formControl.value;
            } else if (typeof formControl.value === 'number') {
                item[formControlKey] = formControl.value;
            } else {
                item[formControlKey] = formControl.value
                    ? formControl.value
                    : null;
            }
        }
    }

    return item;
}

/**
 *
 * @param httpError error returned from the server
 *
 * If there is a custom error return that, otherwise return the default server error.
 */
export function getErrorMessage(httpError: HttpErrorResponse): string {
    const { message } = httpError;
    const { error } = httpError;
    if (error) {
        const { message } = error;
        return message;
    }
    return message;
}

export const apiRequest: (
    uri: string,
    token: Token,
    method?: string,
    body?: any
) => Promise<Response> = async (
    uri: string,
    token: Token,
    method = 'GET',
    body?
) =>
    fetch(uri, {
        headers: {
            accept: 'application/json, text/plain, */*',
            authorization: token.toString(),
            'content-type': 'application/json'
        },
        method,
        body
    });

export const emptySelectValue: string = 'None';

export function uniqueOnProperty(array, propertyPredicate) {
    const set = new Set();
    return array.filter(element => {
        const key = propertyPredicate(element);
        const isNew = !set.has(key);
        if (isNew) {
            set.add(key);
        }
        return isNew;
    });
}

export const staleUrlFragment = '/farms';

export const findByUUID = (collection: any[], uuid: string | undefined): any =>
    collection.find(item => item.uuid === uuid);

export const INFARM_EMAIL_REGEXP = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@infarm.com$/;

export function disaplayByNameOrEmail(user: User) {
    return user ? user.name || user.email : undefined;
}
