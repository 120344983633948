import * as tslib_1 from "tslib";
import { InfarmItem } from './infarm-item';
import { ItemWithName } from './item-with-name';
var InfarmItemWithName = /** @class */ (function (_super) {
    tslib_1.__extends(InfarmItemWithName, _super);
    function InfarmItemWithName() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return InfarmItemWithName;
}(ItemWithName(InfarmItem)));
export { InfarmItemWithName };
