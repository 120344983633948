import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'inTimeStringPipe',
    pure: true
})
// return a time string like 10:00:00+00:00 to 10:00
export class TimeStringPipe implements PipeTransform {
    transform(value: string, format?: string): string {
        switch (format) {
            case 'hh': {
                return value.substr(0, 2);
            }
            case 'hh:mm:ss': {
                return value.substr(0, 8);
            }
            case 'hh:mm':
            default: {
                return value.substr(0, 5);
            }
        }
    }
}
