import * as tslib_1 from "tslib";
export var PropertyType;
(function (PropertyType) {
    PropertyType["String"] = "string";
    PropertyType["Number"] = "number";
    PropertyType["Integer"] = "integer";
    PropertyType["Boolean"] = "boolean";
    PropertyType["Object"] = "object";
    PropertyType["Array"] = "array";
    PropertyType["Null"] = "null";
})(PropertyType || (PropertyType = {}));
export var Action;
(function (Action) {
    Action["View"] = "View";
    Action["Edit"] = "Edit";
    Action["Create"] = "Create";
})(Action || (Action = {}));
var Schema = /** @class */ (function () {
    function Schema(json) {
        this.json = json;
    }
    Schema.prototype.properties = function (forAction) {
        if (forAction === void 0) { forAction = Action.View; }
        var properties = this.json.properties;
        var keys = Object.keys(properties)
            // Filter private props that come from Flask Potion (everything that starts with '$').
            .filter(function (key) { return !key.startsWith('$'); });
        return new Map(Object.entries(properties)
            .filter(function (_a) {
            var _b = tslib_1.__read(_a, 1), key = _b[0];
            return keys.includes(key);
        })
            .filter(function (entry) {
            var readOnly = entry[1].readOnly;
            return (forAction === Action.View ||
                (forAction === Action.Create && !readOnly) ||
                (forAction === Action.Edit && !readOnly));
        }));
    };
    return Schema;
}());
export { Schema };
export function toLabel(key) {
    return (key
        .replace(/([0-9A-Z])/g, ' $1')
        // Uppercase the first character.
        .replace(/^./, function (str) { return str.toUpperCase(); }));
}
export function isPrimitive(propertyType) {
    return isAnyOf(propertyType, [
        PropertyType.String,
        PropertyType.Number,
        PropertyType.Integer,
        PropertyType.Boolean
    ]);
}
export function isAnyOf(propertyType, types) {
    var e_1, _a;
    if (Array.isArray(propertyType)) {
        return propertyType.some(function (item) { return isAnyOf(item, types); });
    }
    if (!Array.isArray(types)) {
        types = [types];
    }
    try {
        for (var types_1 = tslib_1.__values(types), types_1_1 = types_1.next(); !types_1_1.done; types_1_1 = types_1.next()) {
            var type = types_1_1.value;
            if (type === propertyType) {
                return true;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (types_1_1 && !types_1_1.done && (_a = types_1.return)) _a.call(types_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return false;
}
