import { parseAsync } from '../utils/json';
import { isJsObject } from '../utils/guards';

/**
 * Use to restore an instance from string obtained by calling Token#serialize.
 */
export async function jsonToToken(str: string): Promise<Token | null> {
    const obj = await parseAsync(str);
    if (isJsObject(obj)) {
        const { accessToken, refreshToken } = obj;
        return new Token(accessToken, refreshToken);
    }
    return null;
}

export function isToken(value: any): value is Token {
    return value instanceof Token;
}

export class Token {
    /**
     * @param accessToken
     */
    constructor(
        readonly accessToken: string | null,
        readonly refreshToken: string
    ) {}

    /**
     * @param token
     * @returns Confirms if this token is the same as the one that it's compared to
     */
    equals(token: any): boolean {
        return (
            !!token &&
            this.accessToken === token.accessToken &&
            this.refreshToken === token.refreshToken
        );
    }

    /**
     * @returns A string that can be used directly as `Authorization` HTTP header.
     */
    toString(): string {
        return `Bearer ${this.accessToken}`;
    }

    /**
     * @returns A string that is safe to use for storing.
     */
    serialize(): string {
        return JSON.stringify({
            accessToken: this.accessToken,
            refreshToken: this.refreshToken
        });
    }
}
