<span mat-dialog-title>{{ data?.title || 'Are you sure?' }}</span>

<mat-dialog-content>
    <span>{{
        data?.message ||
            'If you choose to continue, there will be no way to undo this action.'
    }}</span>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false" class="in-margin-right">
        Cancel
    </button>
    <button mat-button [mat-dialog-close]="true" color="warn">
        Confirm
    </button>
</mat-dialog-actions>
