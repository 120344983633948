import { Injectable } from '@angular/core';
import { LocationGroupsService } from './services/location-groups.service';
import { Resolve } from '@angular/router';
import { LocationGroup } from 'infarm-core';
import { GraphqlService } from './graphql/graphql.service';
import { lightLocationGroupsList, locationGroupsList } from './graphql/queries';

@Injectable()
export class LocationGroupsResolver implements Resolve<LocationGroup[]> {
    constructor(private locationGroupsService: LocationGroupsService) {}

    async resolve(): Promise<LocationGroup[]> {
        return this.locationGroupsService.getLocationGroups();
    }
}

// tslint:disable-next-line:max-classes-per-file
@Injectable()
export class LocationGroupsFlatResolver implements Resolve<LocationGroup[]> {
    constructor(private graphqlService: GraphqlService) {}

    async resolve(): Promise<LocationGroup[]> {
        const allGroupsPaginator = this.graphqlService.makePaginator(
            'locationGroups',
            locationGroupsList
        );
        return allGroupsPaginator.fetchAllEdges();
    }
}

// tslint:disable-next-line:max-classes-per-file
@Injectable()
export class LightLocationGroupsFlatResolver
    implements Resolve<LocationGroup[]> {
    constructor(private graphqlService: GraphqlService) {}

    async resolve(): Promise<LocationGroup[]> {
        const allGroupsPaginator = this.graphqlService.makePaginator(
            'locationGroups',
            lightLocationGroupsList
        );
        return allGroupsPaginator.fetchAllEdges();
    }
}
