var _this = this;
import * as tslib_1 from "tslib";
import { NativeDateAdapter } from '@angular/material';
import { isString, sort } from 'infarm-core';
import * as moment from 'moment';
/**
 * Remove null, undefined, empty strings and arrays props from an object.
 */
// TODO: Remove props with empty objects too
export function removeEmptyProps(object) {
    return Object.entries(object)
        .map(function (_a) {
        var _b = tslib_1.__read(_a, 2), key = _b[0], value = _b[1];
        return [value, key];
    })
        .filter(function (_a) {
        var _b = tslib_1.__read(_a, 1), value = _b[0];
        return value !== undefined && value !== null;
    }) // Remove any null props
        .filter(function (_a) {
        var _b = tslib_1.__read(_a, 1), value = _b[0];
        return isString(value) || Array.isArray(value) ? value.length > 0 : true;
    }) // Remove props with empty strings or empty arrays
        .reduce(function (acc, _a) {
        var _b = tslib_1.__read(_a, 2), value = _b[0], key = _b[1];
        acc[key] = value;
        return acc;
    }, {});
}
// https://developer.mozilla.org/en-US/docs/Glossary/Primitive
// http://stackoverflow.com/questions/18082/validate-decimal-numbers-in-javascript-isnumeric
export function isPrimitive(value) {
    if (value === null || value === undefined) {
        return true;
    }
    else if (isString(value)) {
        return true;
    }
    else if (typeof value === 'boolean') {
        return true;
    }
    else if (Number.isNaN(value)) {
        return true;
    }
    else if (!isNaN(parseFloat(value))) {
        return true;
    }
    return false;
}
export function zip(a, b) {
    return a.map(function (c, i) { return [c, b[i]]; });
}
export var formatDates = function (infarmItem) { return (tslib_1.__assign({}, infarmItem, { date: new Date(infarmItem.date) })); };
export var getISODate = function (date) {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    return year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
};
export var removeTimezoneFromTimeString = function (time) {
    return time.split('+')[0];
};
export var getTimeStringInMilliseconds = function (time) {
    var timeWithoutTimezone = removeTimezoneFromTimeString(time);
    var times = timeWithoutTimezone.split(':');
    var duration = 0;
    duration += Number(times[0]) * 60 * 60 * 1000;
    if (times[1]) {
        duration += Number(times[1]) * 60 * 1000;
    }
    if (times[2]) {
        duration += Number(times[2]) * 1000;
    }
    return duration;
};
export var getDurationInMilliseconds = function (startTime, endTime) {
    return getTimeStringInMilliseconds(endTime) -
        getTimeStringInMilliseconds(startTime);
};
export function toPx(value) {
    return value + "px";
}
export function fromPx(value) {
    if (value.indexOf('px') !== -1) {
        var px = value.replace('px', '');
        return parseFloat(px);
    }
    return NaN;
}
export function getMoment(queryParamMap) {
    var date = queryParamMap.get('date');
    return date ? moment(parseInt(date, 10)) : moment(new Date());
}
export function getBoundsOfDayForDate(date) {
    var queryParamDate = date ? moment(parseInt(date, 10)) : moment();
    return [
        momentToUtcDate(queryParamDate.startOf('day')),
        momentToUtcDate(queryParamDate.endOf('day'))
    ];
}
export function momentToUtcDate(moment) {
    return new Date(Date.UTC(moment.year(), moment.month(), moment.date(), moment.hour(), moment.minute(), moment.seconds()));
}
/**
 * Takes a date and returns a string in YYYY-MM-DD format
 */
export function toShortDate(date) {
    var pad = function (day) { return (day < 10 ? '0' + day : day); };
    return date.getFullYear() + "-" + pad(date.getMonth() + 1) + "-" + pad(date.getDate());
}
export var THREE_WEEKS_IN_DAYS = 21;
// Promise.all rejects when first promise in the array rejects, we do not want this behavior, hence this method
// https://stackoverflow.com/a/31424853
export function dontRejectOnFailure(promise) {
    return promise.then(function (resolved) { return ({ result: resolved, status: PromiseStatus.fulfilled }); }, function (e) { return ({ e: e, status: PromiseStatus.rejected }); });
}
export var PromiseStatus;
(function (PromiseStatus) {
    PromiseStatus[PromiseStatus["fulfilled"] = 0] = "fulfilled";
    PromiseStatus[PromiseStatus["rejected"] = 1] = "rejected";
})(PromiseStatus || (PromiseStatus = {}));
export function getFarmNames(organisations) {
    var e_1, _a;
    var farms = [];
    try {
        for (var organisations_1 = tslib_1.__values(organisations), organisations_1_1 = organisations_1.next(); !organisations_1_1.done; organisations_1_1 = organisations_1.next()) {
            var organisation = organisations_1_1.value;
            var farmNames = organisation.farms.map(function (farm) {
                return {
                    id: farm.id,
                    name: farm.name
                };
            });
            farms = farms.concat(farmNames);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (organisations_1_1 && !organisations_1_1.done && (_a = organisations_1.return)) _a.call(organisations_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return [].concat.apply([], tslib_1.__spread(farms));
}
export function sortNamedObject(namedObjects) {
    return sort(namedObjects, function (namedObject) {
        return namedObject && namedObject.name
            ? namedObject.name.toLocaleUpperCase()
            : '';
    });
}
export var dayInMilliseconds = 86400000;
export var maxDate = new Date(8640000000000000);
export var datetimeLocalFormat = 'YYYY-MM-DDTHH:mm';
export var INFARM_DATE_FORMATS = {
    parse: {
        dateInput: { month: 'numeric', year: 'numeric', day: 'numeric' }
    },
    display: {
        dateInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
        monthYearLabel: { year: 'numeric', month: 'numeric' },
        dateA11yLabel: { year: 'numeric', month: 'numeric', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'numeric' }
    }
};
var EuropeanDateAdapter = /** @class */ (function (_super) {
    tslib_1.__extends(EuropeanDateAdapter, _super);
    function EuropeanDateAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EuropeanDateAdapter.prototype.format = function (date, displayFormat) {
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        return this.to2digit(day) + '/' + this.to2digit(month) + '/' + year;
    };
    EuropeanDateAdapter.prototype.parse = function (value) {
        if (typeof value === 'number') {
            return new Date(value);
        }
        var monthDayYear = value.split('/');
        var dateString = monthDayYear[1] + '/' + monthDayYear[0] + '/' + monthDayYear[2];
        return value ? new Date(Date.parse(dateString)) : null;
    };
    EuropeanDateAdapter.prototype.to2digit = function (n) {
        return ('00' + n).slice(-2);
    };
    return EuropeanDateAdapter;
}(NativeDateAdapter));
export { EuropeanDateAdapter };
export var range = function (start, end) {
    return Array.from({ length: end - start }, function (_, k) { return k + start; });
};
export var isEarlyOverlap = function (visitA, visitB) {
    return visitA.startTime <= visitB.startTime && visitA.endTime > visitB.startTime;
};
export var isLateOverlap = function (visitA, visitB) {
    return visitA.startTime < visitB.endTime && visitA.endTime >= visitB.endTime;
};
export var isMiddleOverlap = function (visitA, visitB) {
    return visitA.startTime >= visitB.startTime && visitA.endTime < visitB.endTime;
};
export var isBroadOverlap = function (visitA, visitB) {
    return visitA.startTime <= visitB.startTime && visitA.endTime >= visitB.endTime;
};
export function isTimeOverlapping(a, b) {
    return (isEarlyOverlap(a, b) ||
        isLateOverlap(a, b) ||
        isMiddleOverlap(a, b) ||
        isBroadOverlap(a, b));
}
export var traysComparer = function (first, second) {
    var trayTypeRegex = /([0-9]{1,2})T([0-9]{1})Sx([0-9]){1}/;
    var _a = tslib_1.__read(first.name.match(trayTypeRegex), 4), firstTracks = _a[1], firstRows = _a[2], firstMultiplier = _a[3];
    var _b = tslib_1.__read(second.name.match(trayTypeRegex), 4), secondTracks = _b[1], secondRows = _b[2], secondMultiplier = _b[3];
    return (Number.parseInt(firstTracks, 10) - Number.parseInt(secondTracks, 10) ||
        Number.parseInt(firstRows, 10) - Number.parseInt(secondRows, 10) ||
        Number.parseInt(firstMultiplier, 10) -
            Number.parseInt(secondMultiplier, 10));
};
export var endProductsComparer = function (first, second) {
    return first.internalCode && second.internalCode
        ? first.internalCode.localeCompare(second.internalCode)
        : first.name.trim().localeCompare(second.name.trim());
};
export function sortFarmingUnits(farmingUnits) {
    return farmingUnits.sort(function (a, b) {
        var numbersRegex = /\D/g;
        var lettersRegex = /\d/g;
        var numbersA = Number(a.name ? a.name.replace(numbersRegex, '') : Number.MIN_VALUE);
        var numbersB = Number(b.name ? b.name.replace(numbersRegex, '') : Number.MIN_VALUE);
        var lettersA = a.name ? a.name.replace(lettersRegex, '') : '';
        var lettersB = b.name ? b.name.replace(lettersRegex, '') : '';
        if (lettersA === lettersB) {
            return numbersA > numbersB ? 1 : -1;
        }
        return lettersA > lettersB ? 1 : -1;
    });
}
export function applyFormControlValues(item, form) {
    var e_2, _a;
    var formControlKeys = Object.keys(form.controls);
    try {
        for (var formControlKeys_1 = tslib_1.__values(formControlKeys), formControlKeys_1_1 = formControlKeys_1.next(); !formControlKeys_1_1.done; formControlKeys_1_1 = formControlKeys_1.next()) {
            var formControlKey = formControlKeys_1_1.value;
            var formControl = form.get(formControlKey);
            if (formControl) {
                // tslint:disable-next-line:prefer-conditional-expression
                if (typeof formControl.value === 'boolean') {
                    item[formControlKey] = !!formControl.value;
                }
                else if (typeof formControl.value === 'number') {
                    item[formControlKey] = formControl.value;
                }
                else {
                    item[formControlKey] = formControl.value
                        ? formControl.value
                        : null;
                }
            }
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (formControlKeys_1_1 && !formControlKeys_1_1.done && (_a = formControlKeys_1.return)) _a.call(formControlKeys_1);
        }
        finally { if (e_2) throw e_2.error; }
    }
    return item;
}
/**
 *
 * @param httpError error returned from the server
 *
 * If there is a custom error return that, otherwise return the default server error.
 */
export function getErrorMessage(httpError) {
    var message = httpError.message;
    var error = httpError.error;
    if (error) {
        var message_1 = error.message;
        return message_1;
    }
    return message;
}
export var apiRequest = function (uri, token, method, body) {
    if (method === void 0) { method = 'GET'; }
    return tslib_1.__awaiter(_this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            return [2 /*return*/, fetch(uri, {
                    headers: {
                        accept: 'application/json, text/plain, */*',
                        authorization: token.toString(),
                        'content-type': 'application/json'
                    },
                    method: method,
                    body: body
                })];
        });
    });
};
export var emptySelectValue = 'None';
export function uniqueOnProperty(array, propertyPredicate) {
    var set = new Set();
    return array.filter(function (element) {
        var key = propertyPredicate(element);
        var isNew = !set.has(key);
        if (isNew) {
            set.add(key);
        }
        return isNew;
    });
}
export var staleUrlFragment = '/farms';
export var findByUUID = function (collection, uuid) {
    return collection.find(function (item) { return item.uuid === uuid; });
};
export var INFARM_EMAIL_REGEXP = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@infarm.com$/;
export function disaplayByNameOrEmail(user) {
    return user ? user.name || user.email : undefined;
}
