// tslint:disable: max-classes-per-file
import { Observable, Operator, Subscriber, UnaryFunction } from 'rxjs';

/**
 * Emits the first item from an array.
 *
 * @return {MonoTypeOperatorFunction<T>} An fn that returns an Observable that emits the first item from the source (if an array).
 * @method firstFromArray
 */
export function firstFromArray<T>(): UnaryFunction<
    Observable<T[]>,
    Observable<T>
> {
    return function firstFromArrayFunction(
        source: Observable<T[]>
    ): Observable<T> {
        return source.lift(new FirstFromArrayOperator<T[], T>());
    };
}

export class FirstFromArrayOperator<T, R> implements Operator<T, R> {
    call(subscriber: Subscriber<R>, source: any): any {
        return source.subscribe(new FirstFromArraySubscriber<R>(subscriber));
    }
}

class FirstFromArraySubscriber<T> extends Subscriber<T> {
    constructor(destination: Subscriber<T>) {
        super(destination);
    }

    protected _next(value: T): void {
        const { destination } = this as any;
        if (Array.isArray(value)) {
            destination.next(value[0]);
        } else {
            destination.next(value);
        }
    }
}
