import { Auth, omap } from 'infarm-core';
import { apiRequest } from '../../utils';
import { API_HOST } from 'env';
import { toCamelCase, toSnakeCase } from '@infarm/potion-client';

export abstract class CrudService {
    root: string = `${API_HOST}/internal/api`;
    endpoint: string = '';

    constructor(name: string, public auth: Auth) {
        const snakeCaseName = toSnakeCase(name);
        this.endpoint = `${this.root}/${snakeCaseName}`;
    }

    async delete(item): Promise<boolean> {
        const token = await this.auth.token();
        const response = await apiRequest(
            `${this.endpoint}/${item.id}`,
            token,
            'DELETE'
        );
        return response.ok;
    }

    async update<T>(id, payload): Promise<T | Response> {
        const token = await this.auth.token();
        const response = await apiRequest(
            `${this.endpoint}/${id}`,
            token,
            'PATCH',
            JSON.stringify(payload)
        );
        if (response.status === 200) {
            return response.json();
        } else {
            throw {
                message: 'Error: Edit not saved'
            };
        }
    }

    async create<T>(payload): Promise<T> {
        const token = await this.auth.token();
        const formattedPayload = omap(payload, toSnakeCase);
        const response = await apiRequest(
            `${this.endpoint}`,
            token,
            'POST',
            JSON.stringify(formattedPayload)
        );
        const jsoned = await response.json();
        if (response.ok) {
            jsoned.id = Number.parseInt(jsoned.$uri.split('/')[4], 10);
            return omap(jsoned, toCamelCase);
        } else {
            const { message } = jsoned;
            throw Error(message);
        }
    }
}
