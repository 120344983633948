import { InfarmItem, isInfarmItem, isString } from 'infarm-core';

export class Option {
    item;
    constructor(infarmItem: InfarmItem | string) {
        this.item = infarmItem;
    }

    get key(): number | string | null {
        if (isInfarmItem(this.item)) {
            return this.item.id;
        }
        if ((this.item as any).uuid) {
            return (this.item as any).uuid;
        } else if (isString(this.item)) {
            return this.item;
        }
        return null;
    }

    get name(): string {
        if (isInfarmItem(this.item)) {
            return this.item.toString();
        }
        if ((this.item as any).uuid) {
            return (this.item as any).name;
        } else if (isString(this.item)) {
            return this.item;
        }
        return '';
    }

    get value(): any {
        return this.item;
    }
}
