import * as tslib_1 from "tslib";
import { ControlType, FieldBase } from './base';
var NumberField = /** @class */ (function (_super) {
    tslib_1.__extends(NumberField, _super);
    function NumberField(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, tslib_1.__assign({}, options, { controlType: ControlType.Text })) || this;
        _this.type = 'number';
        return _this;
    }
    return NumberField;
}(FieldBase));
export { NumberField };
