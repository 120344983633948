import * as tslib_1 from "tslib";
import { ControlType, FieldBase } from './base';
var SelectField = /** @class */ (function (_super) {
    tslib_1.__extends(SelectField, _super);
    function SelectField(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, tslib_1.__assign({}, options, { controlType: ControlType.Select, value: value(options) })) || this;
        _this.options = [];
        // `[attr.multiple]` requires a value of `null` (not `false`) in order to not be rendered.
        _this.multiple = options.multiple || null;
        _this.options = options.options || [];
        return _this;
    }
    return SelectField;
}(FieldBase));
export { SelectField };
function value(options) {
    var value = options.value;
    // Selects with 'multiple' attr need an array as the default value.
    if (options.multiple && !Array.isArray(value)) {
        value = [];
    }
    return value;
}
