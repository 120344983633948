import * as tslib_1 from "tslib";
import { DoCheck, EventEmitter } from '@angular/core';
import { FieldControlService, FormService } from '../shared';
import { applyFormControlValues } from '../../utils';
import { TextField } from '../fields/text';
var DynamicFormCardComponent = /** @class */ (function () {
    function DynamicFormCardComponent(fs, fcs) {
        var _this = this;
        this.fs = fs;
        this.fcs = fcs;
        this.fields = [];
        // If sanitization is enabled,
        // null or blank fields will not be included in the emitted value.
        this.sanitize = true;
        this.cancel = new EventEmitter();
        this.save = new EventEmitter();
        this.isSaving = false;
        this.form = this.fcs.toFormGroup([]);
        this.correctNumericProperties = function (payload) {
            var e_1, _a;
            var _loop_1 = function (propertyName) {
                var field = _this.fields.filter(function (x) { return x.key === propertyName; })[0];
                if (field && field.type === 'number') {
                    payload[propertyName] = Number.parseFloat(payload[propertyName]);
                }
            };
            try {
                for (var _b = tslib_1.__values(Object.keys(payload)), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var propertyName = _c.value;
                    _loop_1(propertyName);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return payload;
        };
    }
    Object.defineProperty(DynamicFormCardComponent.prototype, "hasFields", {
        get: function () {
            return Array.isArray(this.fields) && this.fields.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DynamicFormCardComponent.prototype, "disabled", {
        get: function () {
            return this.form.pristine || !this.form.valid || this.isSaving;
        },
        enumerable: true,
        configurable: true
    });
    DynamicFormCardComponent.prototype.ngDoCheck = function () {
        this.fcs.updateFormGroup(this.form, this.fields);
    };
    DynamicFormCardComponent.prototype.renderAsTextarea = function (field) {
        return (field instanceof TextField &&
            field.type === 'text' &&
            (typeof field.maxLength !== 'number' || field.maxLength > 250));
    };
    DynamicFormCardComponent.prototype.fieldClass = function (field) {
        return field.controlType + "-field";
    };
    DynamicFormCardComponent.prototype.submit = function () {
        var payload = this.fs.parse(this.fields, this.sanitize);
        payload = applyFormControlValues(payload, this.form);
        payload = this.correctNumericProperties(payload);
        // We don't want to submit if we have an invalid form
        if (!this.disabled) {
            this.save.emit(payload);
        }
    };
    DynamicFormCardComponent.prototype.abort = function () {
        this.cancel.emit(true);
    };
    return DynamicFormCardComponent;
}());
export { DynamicFormCardComponent };
