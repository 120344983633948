import { Assignment, LocationGroup, Role, sort } from 'infarm-core';
import { UserWithAssignments } from './users.resolver';

export const RootLocationGroup = {
    name: 'infarm',
    id: 'TG9jYXRpb25Hcm91cDo1MDU0ODBlOC0xMGQ5LTQzNzUtYjhjNy0xMzIxY2M5ZDY3ZWU=',
    level: 1,
    farms: [],
    assignments: [],
    children: [],
    uuid: '505480e8-10d9-4375-b8c7-1321cc9d67ee'
};

export function getRootNodes(locationGroups: LocationGroup[]): LocationGroup[] {
    const allGroupUuids = locationGroups.map(group => group.uuid);
    const rootNodes = sort(
        locationGroups.filter(
            group => !group.parent || !allGroupUuids.includes(group.parent.uuid)
        ),
        group => group.name.toLowerCase()
    );
    assignChildrenToLocationGroups(locationGroups);
    return rootNodes;
}

function assignChildrenToLocationGroups(locationGroups: LocationGroup[]) {
    for (const locationGroup of locationGroups) {
        const identifiedChildren = sort(
            identifyChildren(locationGroup.uuid, locationGroups),
            group => group.name
        );
        locationGroup.children = identifiedChildren;
    }
}

export function flattenTreeStructure(
    locationGroup: LocationGroup
): LocationGroup[] {
    const array = [];
    array.push(locationGroup);
    const hasChildren =
        locationGroup.children && locationGroup.children.length > 0;
    if (hasChildren) {
        for (const child of locationGroup.children) {
            array.push(...flattenTreeStructure(child));
        }
    }
    return array;
}

/**
 * Function that scans an array of location groups and returns children of given locationGroupId
 */
function identifyChildren(
    parentId: string | number,
    locationGroups: LocationGroup[]
): LocationGroup[] {
    return locationGroups.filter(
        group => group.parent && group.parent.uuid === parentId
    );
}

/*
including inherited roles from groups higher in the location groups tree
*/
export function rolesApplicableForLocationGroup(
    asignments: Assignment[],
    locationGroup: LocationGroup,
    allLocationGroups: LocationGroup[]
): Role[] {
    return asignments
        .filter(assignment =>
            hasDistantChild(
                assignment.locationGroup,
                locationGroup,
                allLocationGroups
            )
        )
        .map(assignment => assignment.role);
}

function unassignedRolesForCurrentGroup(
    candidateRoles: Role[],
    allRoles: Role[]
): Role[] {
    return allRoles.filter(
        role =>
            !candidateRoles.some(currentRole => currentRole.uuid === role.uuid)
    );
}

export function getRelevantUserAssignments(
    users: UserWithAssignments[],
    currentLocationGroup: LocationGroup,
    allRoles: Role[],
    allLocationGroups: LocationGroup[]
): UserWithAssignments[] {
    return users.map(user => {
        const rolesForCurrentGroup = rolesApplicableForLocationGroup(
            user.assignments,
            currentLocationGroup,
            allLocationGroups
        );

        const unassignedRolesForUser = unassignedRolesForCurrentGroup(
            rolesForCurrentGroup,
            allRoles
        );
        user.unassignedRoles = sort(
            unassignedRolesForUser.map(role => ({ role })),
            assignment => assignment.role.name
        ) as Assignment[];
        user.assignments = getUniqueRoleAssignmentsForUser(
            user,
            rolesForCurrentGroup,
            currentLocationGroup,
            allLocationGroups
        );
        return user;
    });
}

/**
 * This function first filters assignments property of user to contain only those assignments
 * that are relevant for current location group being selected (i.e. either assignments defined
 * for this location group of for some group in its supertree)
 */
export function getUniqueRoleAssignmentsForUser(
    user: UserWithAssignments,
    userRolesInGroup: Role[],
    currentLocationGroup: LocationGroup,
    allLocationGroups: LocationGroup[]
) {
    const applicableAssignmentsForGroup = assignmentsApplicableForLocationGroup(
        currentLocationGroup,
        user,
        allLocationGroups
    );
    const usersAssignmentsWithinGroup = applicableAssignmentsForGroup.filter(
        assignment =>
            userRolesInGroup
                .map(role => role.uuid)
                .includes(assignment.role.uuid)
    );
    const uniqueAssignments = createUniqueRoleAssignmentsForGroup(
        usersAssignmentsWithinGroup
    );
    return sort(uniqueAssignments, assignment =>
        assignment.role.name.toLowerCase()
    );
}

export function assignmentsApplicableForLocationGroup(
    currentLocationGroup: LocationGroup,
    user: UserWithAssignments,
    allLocationGroups: LocationGroup[]
): Assignment[] {
    return user.assignments.filter(
        assignment =>
            hasDistantChild(
                assignment.locationGroup,
                currentLocationGroup,
                allLocationGroups
            ) || assignment.locationGroup.uuid === currentLocationGroup.uuid
    );
}

/**
 * This method removes assignment duplication in case there are multiple assignments defined with the same role
 * within one subtree (e.g. when having passed assignments for grower in infarm and Germany groups,
 * returned array will only consist of one assignment for grower role in infarm group).
 */
function createUniqueRoleAssignmentsForGroup(
    usersAssignmentsWithinGroup: Assignment[]
): Assignment[] {
    return usersAssignmentsWithinGroup.reduce((accumulator, value) => {
        const alreadyStoredAssignment = accumulator.find(
            assignment => assignment.role.uuid === value.role.uuid
        );
        if (!alreadyStoredAssignment) {
            accumulator.push(value);
        } else {
            if (
                alreadyStoredAssignment.locationGroup.level >
                value.locationGroup.level
            ) {
                const indexOfAssignment = accumulator.indexOf(
                    alreadyStoredAssignment
                );
                accumulator[indexOfAssignment] = value;
            }
        }
        return accumulator;
    }, []);
}

/**
 * Function to identify whether some location group ( @locationGroup ) is a (possibly distant) child of another location group ( @candidateParent )
 * So say we have Infarm > Germany > Berlin > West, then Infarm is a parent of Germany, Berlin and West groups.
 * @param candidateParent represents a location group that is possibly a parent of @locationGroup parameter
 * @param locationGroup is an instance of LocationGroup for which we want to test if @candidateParent is its distant parent or not
 * @param allLocationGroups lookup array for all available location groups
 */
export function hasDistantChild(
    candidateParent: LocationGroup,
    locationGroup: LocationGroup,
    allLocationGroups: LocationGroup[]
): boolean {
    let foundInTree = false;
    let investigatedLocationGroup = locationGroup;
    while (investigatedLocationGroup && !foundInTree) {
        foundInTree = investigatedLocationGroup.uuid === candidateParent.uuid;
        if (!foundInTree) {
            const matchingGroup = (investigatedLocationGroup = allLocationGroups.find(
                group => group.uuid === investigatedLocationGroup.uuid
            ));
            investigatedLocationGroup = matchingGroup
                ? matchingGroup.parent
                : null;
        }
    }

    return foundInTree;
}
