import { Constructor } from './mixins/ctor';
import { ArchivableItem } from './mixins/archivable-item';
import { ItemWithAddresses } from './mixins/item-with-addresses';
import { ItemWithName, ItemWithNameType } from './mixins/item-with-name';
import { UpdatedAtType } from './mixins/updated-at';
import { FarmingUnit } from './schemas/farming';
import { MissionRecord, Task } from './visit';

export const Base: Constructor<ItemWithNameType> &
    Constructor<UpdatedAtType> &
    typeof ItemWithAddresses = ItemWithName(ItemWithAddresses);

export class Mission extends ArchivableItem(Base) {
    id: number;
    visit: number;
    note: string;
    tasks: Task[];
    farmingUnit: FarmingUnit;
    missionRecord: MissionRecord;
}
