import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
/**
 * Feature Modules
 */
import { CoreModule, PreloadSelectedModulesOnly } from 'app-core';
import { SharedModule } from 'app-shared';
import { AppRoutingModule } from './app-routing.module';

/**
 * Components
 */
import { AppComponent } from './app.component';

import { GraphQLModule } from 'app-shared/graphql/graphql.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    imports: [
        BrowserModule,
        CoreModule.forRoot(),
        SharedModule,
        AppRoutingModule,
        GraphQLModule,
        HttpClientModule
    ],
    providers: [PreloadSelectedModulesOnly],
    declarations: [AppComponent],
    // A list of components that can be bootstrapped.
    // Usually there is only one component in this list,
    // the root component of the application.
    bootstrap: [AppComponent]
})
export class AppModule {}
