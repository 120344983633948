import {
    Injectable,
    OnDestroy,
    Optional,
    Provider,
    SkipSelf
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

import { Observable, ReplaySubject, Subscription } from 'rxjs';

@Injectable()
export class NavigationHistoryService implements OnDestroy {
    private events: Array<NavigationStart | NavigationEnd> = [];
    get redirectUrl(): string | null {
        let path = '/';
        const prevEvent: any = this.events[0];
        if (
            (prevEvent instanceof NavigationStart ||
                prevEvent instanceof NavigationEnd) &&
            !prevEvent.url.includes('login')
        ) {
            path = prevEvent.url;
        }

        // If the prev. path is the same as the current one,
        // return null.
        if (this.router.url === path) {
            return null;
        }

        return path;
    }

    // Observable history source
    private historySource: ReplaySubject<any> = new ReplaySubject();
    // Observable history stream
    private history: Observable<any> = this.historySource.asObservable(); // tslint:disable-line:member-ordering

    private sub: Subscription;

    constructor(private router: Router) {
        this.sub = this.history.subscribe(
            (event: NavigationStart | NavigationEnd) => {
                // We only want to keep the last 2 events,
                // so we always remove one before we add a new one.
                // NOTE: The last is always the current navigation event,
                // and the 1st is always the prev.
                this.events.splice(0, this.events.length >= 2 ? 1 : 0); // tslint:disable-line:no-magic-numbers
                // Store current nav. event.
                this.events.push(event);
            }
        );
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    push(event: NavigationStart | NavigationEnd): void {
        this.historySource.next(event);
    }
}

export function NAVIGATION_HISTORY_SERVICE_PROVIDER_FACTORY(
    parentFactory: NavigationHistoryService,
    router: Router
): NavigationHistoryService {
    return parentFactory || new NavigationHistoryService(router);
}

export const NAVIGATION_HISTORY_SERVICE_PROVIDER: Provider = {
    // If there is already a NavigationHistoryService available, use that.
    // Otherwise, provide a new one.
    provide: NavigationHistoryService,
    useFactory: NAVIGATION_HISTORY_SERVICE_PROVIDER_FACTORY,
    deps: [[new Optional(), new SkipSelf(), NavigationHistoryService], Router]
};
