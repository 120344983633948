import {
    Injectable,
    NgZone,
    Optional,
    Provider,
    SkipSelf
} from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, mergeMap } from 'rxjs/operators';

import { announceLogin, announceLogout } from '@infarm/auth';

import { Storage } from '../storage/storage';
import { Jwt } from './jwt';
import { isToken, jsonToToken, Token } from './token';

export const TOKEN_STORAGE_KEY = 'auth_token';

/**
 * NOTE: If a persistent storage for the token is not provided (such as localStorage, cookie, etc.),
 * the token will no longer be available on a page refresh.
 */
@Injectable()
export class Auth {
    /**
     * Retrieve Jwt token from storage.
     * Can be used without injecting the service,
     * but be aware that it only retrieves the token from storage and it does not try to refresh the token.
     */
    static async tokenStatic(zone?: NgZone): Promise<Token | null> {
        const json = await Storage.get(TOKEN_STORAGE_KEY, zone);
        const token = await jsonToToken(json);
        return token;
    }

    // NOTE: We try to retrieve the token here so that the change observable has the right value in case the user is already authenticated
    private tokenPromiseSource: BehaviorSubject<
        AuthToken
    > = new BehaviorSubject<AuthToken>(this.token());
    private tokenSource: Observable<Token | null> = this.tokenPromiseSource
        .asObservable()
        .pipe(
            mergeMap(token =>
                token instanceof Promise ? token : Promise.resolve(token)
            ),
            distinctUntilChanged(tokenCompare)
        );

    readonly change: Observable<Token | null> = this.tokenSource; // tslint:disable-line: member-ordering

    constructor(
        private zone: NgZone,
        private jwt: Jwt,
        private storage: Storage
    ) {}

    /**
     * Retrieve Jwt token from storage
     */
    async token(): Promise<Token | null> {
        return Auth.tokenStatic(this.zone);
    }

    async refreshJwtToken(): Promise<Token> {
        const expiredToken = await this.token();
        const refreshedToken = await this.jwt.refreshJwtToken(
            expiredToken.refreshToken
        );
        const storedToken = new Token(
            refreshedToken.accessToken,
            expiredToken.refreshToken
        );
        return this.storeToken(storedToken);
    }

    /**
     * Remove Jwt token from storage and signal change.
     */
    async logout(): Promise<void> {
        this.storage.remove(TOKEN_STORAGE_KEY);
        this.tokenPromiseSource.next(null);
        announceLogout(true);
    }

    private async storeToken(token: Token | null): Promise<Token | null> {
        this.storage.set(
            TOKEN_STORAGE_KEY,
            isToken(token) ? token.serialize() : null
        );
        const storedToken = JSON.parse(
            await this.storage.get(TOKEN_STORAGE_KEY)
        );
        this.tokenPromiseSource.next(storedToken);
        announceLogin(token ? token.accessToken : null);
        return storedToken;
    }
}

/**
 * Auth providers
 */
export function AUTH_PROVIDER_FACTORY(
    parentFactory: Auth,
    zone: NgZone,
    jwt: Jwt,
    storage: Storage
): Auth {
    return parentFactory || new Auth(zone, jwt, storage);
}
export const AUTH_PROVIDER: Provider = {
    // If there is already a Auth available, use that.
    // Otherwise, provide a new one.
    provide: Auth,
    useFactory: AUTH_PROVIDER_FACTORY,
    deps: [[new Optional(), new SkipSelf(), Auth], NgZone, Jwt, Storage]
};

export function tokenCompare(previous: any, current: any): boolean {
    return (
        previous === current ||
        (current instanceof Token && current.equals(previous)) ||
        (previous instanceof Token && previous.equals(current))
    );
}

export type AuthToken = Promise<Token | null> | Token | null;
