import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { InfarmItem } from 'infarm-core';
import { QueryParams } from '@infarm/potion-client';

import { ResourceType } from '../resources';

@Component({
    selector: 'in-resource-picker-dialog',
    templateUrl: './resource-picker-dialog.component.html',
    styleUrls: ['./resource-picker-dialog.component.scss']
})
export class ResourcePickerDialogComponent implements OnInit {
    items: InfarmItem[];
    options?: ResourcePickerSelectOptions;
    title?: string;

    item: InfarmItem | null;

    constructor(
        public dialogRef: MatDialogRef<ResourcePickerDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: ResourcePickerData
    ) {}

    async ngOnInit(): Promise<void> {
        const resourceType = this.data.resourceType.type as typeof InfarmItem;

        this.options = this.data.options;
        this.title = this.data.title;

        if (this.data.resource instanceof InfarmItem) {
            this.item = this.data.resource;
        }

        const items = (await resourceType.query<InfarmItem>(
            this.data.query
        )) as InfarmItem[];
        this.items = items;
    }

    close(): void {
        this.dialogRef.close(this.data.resource);
    }

    select(): void {
        this.dialogRef.close(this.item);
    }
}

export interface ResourcePickerData {
    resourceType: ResourceType;
    resource?: InfarmItem;
    query?: QueryParams;
    options?: ResourcePickerSelectOptions;
    title?: string;
}

export interface ResourcePickerSelectOptions {
    placeholder: string;
    required?: boolean;
    multiple?: boolean;
}
