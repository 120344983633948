import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {
    daysOfWeek,
    fromTime,
    HarvestRoundView,
    isJsObject,
    toTime,
    uid
} from 'infarm-core';

@Component({
    selector: 'in-harvest-round-dialog',
    templateUrl: './harvest-round-dialog.component.html',
    styleUrls: ['./harvest-round-dialog.component.scss']
})
export class HarvestRoundDialogComponent implements OnInit {
    daysOfWeek: string[] = daysOfWeek();
    dayOfWeek: string = this.daysOfWeek[0];
    startTime: string = toTime(3600 * 9);
    endTime: string = toTime(3600 * 10);

    uid: string;
    id: number;

    constructor(
        private dialogRef: MatDialogRef<HarvestRoundDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: HarvestRoundData
    ) {}

    ngOnInit(): void {
        const data = this.data;
        if (isJsObject(data) && isJsObject(data.object)) {
            const object = data.object;
            this.dayOfWeek = object.dayOfWeek;
            this.startTime = toTime(object.startTime);
            this.endTime = toTime(object.endTime);
            if (isHarvestRoundObject(data.object)) {
                this.uid = data.object.uid;
            } else if (isHarvestRoundView(data.object)) {
                this.id = data.object.id;
            }
        }
    }

    save(): void {
        const round: any = {
            dayOfWeek: this.dayOfWeek,
            startTime: fromTime(this.startTime),
            endTime: fromTime(this.endTime)
        };
        if (this.id) {
            round.id = this.id;
        } else {
            round.uid = this.uid || uid();
        }
        this.dialogRef.close(round);
    }
}

export interface HarvestRoundData {
    object?: HarvestRoundObject | HarvestRoundPartialView;
}

export interface HarvestRoundPartialView {
    dayOfWeek: string;
    startTime: number;
    endTime: number;
}

export interface HarvestRoundObject extends HarvestRoundPartialView {
    uid: string;
}

export function isHarvestRoundObject(obj: any): obj is HarvestRoundObject {
    return obj.hasOwnProperty('uid');
}

export function isHarvestRoundView(obj: any): obj is HarvestRoundView {
    return !obj.hasOwnProperty('uid');
}
