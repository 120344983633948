import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { toCamelCase, toSnakeCase } from '@infarm/potion-client';
import { map } from 'rxjs/operators';
import { omap } from '../utils/object';
var ImageStorage = /** @class */ (function () {
    function ImageStorage(http) {
        this.http = http;
    }
    // TODO: validate that the selected file is a JPEG image
    ImageStorage.prototype.upload = function (image, options) {
        var e_1, _a;
        var data = new FormData();
        // If we get a base64 string, convert to a blob.
        var blob = image instanceof File || image instanceof Blob
            ? image
            : dataURItoBlob(image);
        // NOTE: We need to set the file name, otherwise the stored file cannot be used anywhere.
        data.append('image', blob, filename(blob));
        if (options !== null && typeof options === 'object') {
            try {
                for (var _b = tslib_1.__values(Object.entries(options)), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var _d = tslib_1.__read(_c.value, 2), key = _d[0], value = _d[1];
                    // NOTE: Our backend only accepts snakecase params,
                    // therefore, we do the conversion for each key.
                    data.append(toSnakeCase(key), value);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        return this.http
            .post('/api/image/upload', data, {
            observe: 'response',
            responseType: 'json'
        })
            .pipe(map(function (res) { return res.body; }), map(function (json) { return omap(json, function (key) { return toCamelCase(key); }); }));
    };
    return ImageStorage;
}());
export { ImageStorage };
export function dataURItoBlob(dataURI) {
    var _a = tslib_1.__read(dataURI.split(','), 2), metadata = _a[0], imageURI = _a[1];
    // Convert base64/URLEncoded data component to raw binary data held in a string
    var byteString = metadata.indexOf('base64') >= 0 ? atob(imageURI) : decodeURI(imageURI);
    // Separate out the mime component
    var mimeType = metadata.split(':')[1].split(';')[0];
    // Write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {
        type: mimeType
    });
}
export function filename(blob) {
    if (blob instanceof File) {
        // NOTE: We assume that this already has the file extension.
        return blob.name;
    }
    else {
        // Generate a random string that can be used as file name.
        // Add a JPEG file extension.
        var name_1 = Math.random().toString(36).substring(7);
        return name_1 + ".jpg";
    }
}
