import * as tslib_1 from "tslib";
import { UpdateableRecipeItem } from './mixins/updated-at';
var WaterRefillingRecipe = /** @class */ (function (_super) {
    tslib_1.__extends(WaterRefillingRecipe, _super);
    function WaterRefillingRecipe() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return WaterRefillingRecipe;
}(UpdateableRecipeItem));
export { WaterRefillingRecipe };
