import { ControlType, FieldBase, FieldBaseOptions } from './base';

export interface DateTimeFieldOptions extends FieldBaseOptions<Date> {} // tslint:disable-line: no-empty-interface

export class DateField extends FieldBase<Date> {
    readonly type: string;
    value: Date;

    constructor(options: DateTimeFieldOptions = {}) {
        Object.assign(options, {
            controlType: ControlType.Date
        });
        super(options);
        this.type = 'text';
    }
}
