import {
    APP_INITIALIZER,
    ModuleWithProviders,
    NgModule,
    Optional,
    SkipSelf
} from '@angular/core';
import { MatIconRegistry, MatSnackBarModule } from '@angular/material';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
    ApiHost,
    ApiHostToken,
    ApiModule,
    AuthModule,
    ItemNameModule,
    ServicesModule,
    UserNameModule
} from 'infarm-core';

import { API_HOST } from 'env';
import { DocumentTitleService } from 'app-shared';

/**
 * API Config
 */
export const apiHost: ApiHost = API_HOST;

/**
 * Provider for updating the view title
 */
export function provideViewTitleHandler(
    documentTitle: DocumentTitleService
): any {
    return () => {
        // Subscribe to view title changes and update the document title.
        documentTitle.title.subscribe(title => {
            document.title = title ? `Farmboard - ${title}` : 'Farmboard';
        });
    };
}

/**
 * Add custom SVG icons
 */
export function provideSVGIcons(
    registry: MatIconRegistry,
    sanitizer: DomSanitizer
): any {
    const ns = 'infarm';
    return () => {
        registry
            .addSvgIconInNamespace(
                ns,
                'farm',
                sanitizer.bypassSecurityTrustResourceUrl('/../assets/farm.svg')
            )
            .addSvgIconInNamespace(
                ns,
                'add_location_outline',
                sanitizer.bypassSecurityTrustResourceUrl(
                    '/../assets/add_location_outline.svg'
                )
            )
            .addSvgIconInNamespace(
                ns,
                'plant',
                sanitizer.bypassSecurityTrustResourceUrl('/../assets/plant.svg')
            )
            .addSvgIconInNamespace(
                ns,
                'nursery',
                sanitizer.bypassSecurityTrustResourceUrl(
                    '/../assets/nursery.svg'
                )
            );
    };
}

/**
 * Module with service singletons.
 * Check https://angular.io/docs/ts/latest/guide/ngmodule.html#!#core-module for details why.
 */
@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
        // Material
        MatSnackBarModule,
        // Infarm
        ApiModule,
        AuthModule,
        ItemNameModule,
        UserNameModule,
        ServicesModule
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(
                '@infarm/farmboard#CoreModule has already been loaded. Import it in the AppModule only.'
            );
        }
    }

    // tslint:disable-next-line: member-ordering
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: CoreModule,
            // These services are only going to be created once (one instance for the entire app),
            // not per module import.
            providers: [
                // Infarm
                { provide: ApiHostToken, useValue: apiHost },
                // We don't have any reason for a component in the rendered component tree to know about these (at this time).
                // As such, we'll be using the APP_INITIALIZER multi-collection
                // (which is kind of like a run block in Angular 1.x).
                {
                    provide: APP_INITIALIZER,
                    useFactory: provideViewTitleHandler,
                    deps: [DocumentTitleService],
                    multi: true
                },
                {
                    provide: APP_INITIALIZER,
                    useFactory: provideSVGIcons,
                    deps: [MatIconRegistry, DomSanitizer],
                    multi: true
                }
            ]
        };
    }
}
