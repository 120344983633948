import { Injectable, Optional, Provider, SkipSelf } from '@angular/core';

// Declare the `ga` object so we can access the `window.ga` without the TS compiler complaining.
declare const ga: (action: string, kind: string, value: any) => void;

@Injectable()
export class GoogleAnalyticsService {
    // TODO: we want to do much more here, but for starters we only track page views
    pageview(url: string): void {
        if (!ga) {
            return;
        }
        ga('send', 'pageview', url);
    }
}

export function GOOGLE_ANALYTICS_SERVICE_PROVIDER_FACTORY(
    parentFactory: GoogleAnalyticsService
): GoogleAnalyticsService {
    return parentFactory || new GoogleAnalyticsService();
}

export const GOOGLE_ANALYTICS_SERVICE_PROVIDER: Provider = {
    // If there is already a GoogleAnalyticsService available, use that.
    // Otherwise, provide a new one.
    provide: GoogleAnalyticsService,
    useFactory: GOOGLE_ANALYTICS_SERVICE_PROVIDER_FACTORY,
    deps: [[new Optional(), new SkipSelf(), GoogleAnalyticsService]]
};
