import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Role } from 'infarm-core';
import { GraphqlService } from './graphql/graphql.service';
import { rolesQuery } from './graphql/queries';

@Injectable()
export class RolesResolver implements Resolve<Role[]> {
    constructor(private graphqlService: GraphqlService) {}
    async resolve(): Promise<Role[]> {
        const paginator = this.graphqlService.makePaginator(
            'roles',
            rolesQuery,
            {
                isGlobal: false
            }
        );
        return paginator.fetchAllEdges();
    }
}
