import * as tslib_1 from "tslib";
import { InfarmItem } from './infarm-item';
var RecordItem = /** @class */ (function (_super) {
    tslib_1.__extends(RecordItem, _super);
    function RecordItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RecordItem;
}(InfarmItem));
export { RecordItem };
