import * as tslib_1 from "tslib";
import { LocationGroupMutators } from '../graphql/mutations/update-location-group.service';
import { Auth, flatten, unique } from 'infarm-core';
import { Subject } from 'rxjs';
import { assignment, locationGroup, locationGroupsListWithFarms } from '../graphql/queries';
import { handleMutationResponse, processNode, serializeUUID } from '../graphql/relay-utils';
import { API_HOST } from 'env';
import { apiRequest } from '../utils';
import { flattenTreeStructure, getRootNodes } from '../location-groups-utils';
import { GraphqlService } from '../graphql/graphql.service';
import { UsersResolver } from '../users.resolver';
import { RolesResolver } from '../roles.resolver';
import * as i0 from "@angular/core";
import * as i1 from "../graphql/graphql.service";
import * as i2 from "../graphql/mutations/update-location-group.service";
import * as i3 from "../../infarm-core/auth/auth";
import * as i4 from "../roles.resolver";
var LocationGroupsService = /** @class */ (function () {
    function LocationGroupsService(graphqlService, mutators, auth, rolesResolver) {
        this.graphqlService = graphqlService;
        this.mutators = mutators;
        this.auth = auth;
        this.rolesResolver = rolesResolver;
        this.apiEndpoint = API_HOST + "/internal/api/location_group";
        this.locationGroupModified$ = new Subject();
        this.locationGroupCreated$ = new Subject();
        this.locationGroupsEndpoint = API_HOST + "/internal/api/location_group";
        this.assignmentsEndpoint = API_HOST + "/internal/api/assignment";
    }
    Object.defineProperty(LocationGroupsService.prototype, "token", {
        get: function () {
            var _this = this;
            return (function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var _a;
                return tslib_1.__generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!!this.cachedToken) return [3 /*break*/, 2];
                            _a = this;
                            return [4 /*yield*/, this.auth.token()];
                        case 1:
                            _a.cachedToken = _b.sent();
                            _b.label = 2;
                        case 2: return [2 /*return*/, this.cachedToken];
                    }
                });
            }); })();
        },
        enumerable: true,
        configurable: true
    });
    LocationGroupsService.prototype.getLocationGroups = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var allGroupsPaginator, allGroups, locationGroupsRootNodes, listOfLocationGroups, locationGroupsRootNodes_1, locationGroupsRootNodes_1_1, locationGroup_1;
            var e_1, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        allGroupsPaginator = this.graphqlService.makePaginator('locationGroups', locationGroupsListWithFarms);
                        return [4 /*yield*/, allGroupsPaginator.fetchAllEdges()];
                    case 1:
                        allGroups = _b.sent();
                        locationGroupsRootNodes = getRootNodes(allGroups);
                        listOfLocationGroups = [];
                        try {
                            for (locationGroupsRootNodes_1 = tslib_1.__values(locationGroupsRootNodes), locationGroupsRootNodes_1_1 = locationGroupsRootNodes_1.next(); !locationGroupsRootNodes_1_1.done; locationGroupsRootNodes_1_1 = locationGroupsRootNodes_1.next()) {
                                locationGroup_1 = locationGroupsRootNodes_1_1.value;
                                listOfLocationGroups.push(tslib_1.__spread(flattenTreeStructure(locationGroup_1)));
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (locationGroupsRootNodes_1_1 && !locationGroupsRootNodes_1_1.done && (_a = locationGroupsRootNodes_1.return)) _a.call(locationGroupsRootNodes_1);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        listOfLocationGroups = unique(listOfLocationGroups);
                        return [2 /*return*/, flatten(listOfLocationGroups)];
                }
            });
        });
    };
    LocationGroupsService.prototype.getRoles = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.rolesResolver.resolve()];
            });
        });
    };
    LocationGroupsService.prototype.getUsers = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var resolver;
            return tslib_1.__generator(this, function (_a) {
                resolver = new UsersResolver(this.graphqlService);
                return [2 /*return*/, resolver.resolve()];
            });
        });
    };
    LocationGroupsService.prototype.getLocationGroup = function (uuid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var id, group, processed;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        id = serializeUUID('LocationGroup', uuid);
                        return [4 /*yield*/, this.graphqlService.query(locationGroup, {
                                id: id
                            })];
                    case 1:
                        group = _a.sent();
                        processed = processNode(group.data);
                        return [2 /*return*/, processed.node];
                }
            });
        });
    };
    LocationGroupsService.prototype.createLocationGroup = function (name, parentId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, locationGroup;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.mutators
                            .createLocationGroup({
                            name: name,
                            parentId: parentId
                        })
                            .toPromise()];
                    case 1:
                        response = _a.sent();
                        locationGroup = handleMutationResponse(response, 'createLocationGroup');
                        this.locationGroupCreated$.next(locationGroup);
                        return [2 /*return*/, locationGroup];
                }
            });
        });
    };
    LocationGroupsService.prototype.changeLocationGroupName = function (locationGroup) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, updatedLocationGroup;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.mutators
                            .updateLocationGroup({
                            id: serializeUUID('LocationGroup', locationGroup.uuid),
                            name: locationGroup.name
                        })
                            .toPromise()];
                    case 1:
                        response = _a.sent();
                        updatedLocationGroup = handleMutationResponse(response, 'updateLocationGroup');
                        this.locationGroupModified$.next(updatedLocationGroup);
                        return [2 /*return*/];
                }
            });
        });
    };
    LocationGroupsService.prototype.changeLocationGroupParent = function (id, parentId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, updatedLocationGroup;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.mutators
                            .updateLocationGroup({
                            id: id,
                            parentId: parentId
                        })
                            .toPromise()];
                    case 1:
                        response = _a.sent();
                        updatedLocationGroup = handleMutationResponse(response, 'updateLocationGroup');
                        this.locationGroupModified$.next(updatedLocationGroup);
                        return [2 /*return*/];
                }
            });
        });
    };
    LocationGroupsService.prototype.createAssignment = function (user, locationGroup, role) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, _a, _b, assignmentViaRest, id, assignmentViaGraphql;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = apiRequest;
                        _b = [this.assignmentsEndpoint];
                        return [4 /*yield*/, this.token];
                    case 1: return [4 /*yield*/, _a.apply(void 0, _b.concat([_c.sent(),
                            'POST',
                            JSON.stringify({
                                user: user.pk || user.id,
                                location_group: locationGroup.pk,
                                role: role.pk
                            })]))];
                    case 2:
                        request = _c.sent();
                        if (!request.ok) {
                            throw Error("Unable to create new assignment for " + user.name + " as a " + role.name + " within " + locationGroup.name + " group");
                        }
                        return [4 /*yield*/, request.json()];
                    case 3:
                        assignmentViaRest = _c.sent();
                        id = serializeUUID('Assignment', assignmentViaRest.uuid);
                        return [4 /*yield*/, this.graphqlService.query(assignment, { id: id })];
                    case 4:
                        assignmentViaGraphql = _c.sent();
                        return [2 /*return*/, processNode(assignmentViaGraphql.data.node)];
                }
            });
        });
    };
    LocationGroupsService.prototype.deleteAssignment = function (assignment) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = apiRequest;
                        _b = [this.assignmentsEndpoint + "/" + assignment.pk];
                        return [4 /*yield*/, this.token];
                    case 1: return [4 /*yield*/, _a.apply(void 0, _b.concat([_c.sent(),
                            'DELETE']))];
                    case 2:
                        request = _c.sent();
                        if (!request.ok) {
                            throw Error("Unable to delete assignment for role " + assignment.role.name + ".");
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    LocationGroupsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocationGroupsService_Factory() { return new LocationGroupsService(i0.ɵɵinject(i1.GraphqlService), i0.ɵɵinject(i2.LocationGroupMutators), i0.ɵɵinject(i3.Auth), i0.ɵɵinject(i4.RolesResolver)); }, token: LocationGroupsService, providedIn: "root" });
    return LocationGroupsService;
}());
export { LocationGroupsService };
