// tslint:disable: max-classes-per-file member-ordering no-this-assignment
import { AddressView } from './address';
import { Constructor } from './mixins/ctor';
import { Item, Route } from '@infarm/potion-client';
import { Image } from './image';
import { ArchivableItem } from './mixins/archivable-item';
import { ItemWithAddresses } from './mixins/item-with-addresses';
import { ItemWithName, ItemWithNameType } from './mixins/item-with-name';
import { UpdatedAtType } from './mixins/updated-at';
import { User } from './user';
import { HarvestRecord } from './harvest-record';
import { Mission } from './mission';
import { EndProduct } from './end-product';
import { Sector } from './sector';
import { Farm } from './farm';

export const Base: Constructor<ItemWithNameType> &
    Constructor<UpdatedAtType> &
    typeof ItemWithAddresses = ItemWithName(ItemWithAddresses);

export class Visit extends ArchivableItem(Base) {
    id: number;
    grower: User;
    note: string;
    startTime: string;
    endTime: string;
    date: Date;
    images: VisitImage[];
    static record: (
        params: VisitRecordQueryParams
    ) => Promise<any> = Route.POST<any>('/visit-record');
    farm: Farm;
}

export class MissionRecord extends Item {
    harvestRecords: HarvestRecord[];
    farmingUnitName: string;
    checkIn?: Date;
    checkOut?: Date;
    mission: Mission;
    missionId: number;
    images: MissionImage[];
}

export class VisitRecord extends Item {
    visitId: number;
    note?: string;
    checkIn: Date;
    checkOut: Date;
    farmName: string;
}

export class VisitImage extends Item {
    visitId: number;
    fileName: string;
    imageUrl?: string;
    description?: string;
}

export class MissionImage extends Item {
    missionId: number;
    fileName: string;
    imageUrl?: string;
    description?: string;
}

export enum ShrinkageState {
    NO_SHRINKAGE = 'NO_SHRINKAGE',
    HAS_SHRINKAGE = 'HAS_SHRINKAGE',
    NO_RECORDS = 'NO_RECORDS'
}

export enum HarvestType {
    NO_SHRINKAGE = 'NO_SHRINKAGE',
    HAS_SHRINKAGE = 'HAS_SHRINKAGE'
}

export enum SectorTaskType {
    harvest = 'harvest',
    planting = 'planting'
}

export interface ViewQueryParams {
    grower?: number;
    date?: Date;
    numDays?: number;
    farmCategory: string;
}

export interface VisitRecordQueryParams {
    missionRecords?: MissionRecord[];
    visitId: number;
    checkIn?: Date;
    checkOut?: Date;
    note?: string;
    images?: Image[];
}

export interface AssignedGrower {
    id: number;
    name: string;
}

export interface Task {
    id: string;
    pk: number;
    sectorNumber: number;
    number: number;
    endProduct: EndProduct;
    plantNumber: number;
    sector: Sector;
    taskType: SectorTaskType;
}

export interface VisitFarmInfo {
    id: number;
    name: string;
    category: string;
    address: AddressView;
}
