import { Injectable } from '@angular/core';
import {
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';

import { from, Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { Auth } from './auth';
import { Token } from './token';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private auth: Auth) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (req.headers.has('authorization')) {
            return next.handle(req);
        } else {
            return from(this.auth.token())
                .pipe(
                    mergeMap(token => {
                        return next.handle(addAuthHeader(req, token));
                    })
                )
                .pipe(
                    catchError(err => {
                        if (err.status === 401) {
                            return from(this.auth.refreshJwtToken())
                                .pipe(
                                    mergeMap(token => {
                                        return next.handle(
                                            addAuthHeader(req, token)
                                        );
                                    })
                                )
                                .pipe(
                                    catchError(_ => {
                                        this.auth.logout();
                                        return of(
                                            new HttpResponse({
                                                status: 401,
                                                statusText:
                                                    'Unable to refresh authentication token'
                                            })
                                        );
                                    })
                                );
                        }
                    })
                );
        }
    }
}

function addAuthHeader(
    request: HttpRequest<any>,
    token: Token
): HttpRequest<any> {
    return token
        ? request.clone({
              setHeaders: {
                  authorization: `Bearer ${token.accessToken}`
              }
          })
        : request;
}
