import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'inConversionPipe'
})
export class ConversionPipe implements PipeTransform {
    transform(value: number, unit: string): any {
        switch (unit) {
            case 'hoursToDays': {
                return value / 24;
            }
            default: {
                return value;
            }
        }
    }
}
