import { ColumnParserService } from './column-parser.service';
import { Column } from './column';
var ColumnValueComponent = /** @class */ (function () {
    function ColumnValueComponent(parser) {
        this.parser = parser;
    }
    Object.defineProperty(ColumnValueComponent.prototype, "value", {
        set: function (column) {
            this.html = this.parser.parse(column);
        },
        enumerable: true,
        configurable: true
    });
    return ColumnValueComponent;
}());
export { ColumnValueComponent };
