import { FarmingUnit } from './farming-unit';
import { UpdateableInfarmItem } from './mixins/updated-at';

export class Controller extends UpdateableInfarmItem {
    farmingUnit: FarmingUnit;
    particleId: string;

    dataPullInterval: number; // In seconds
    lastPull?: Date;
    lastSync?: Date;

    isActive: boolean;

    events: Event[];
}
