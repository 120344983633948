import { Item } from '@infarm/potion-client';
import { Farm } from './farm';
import { Assignment } from './assignment';

export class LocationGroup extends Item {
    name: string;
    farms: Farm[];
    children: LocationGroup[];
    // field is nullable because of root location group that does not have a parent
    parent?: LocationGroup;
    level: number;
    assignments: Assignment[];
    uuid: string;
}
