import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

@Injectable()
export class DownloadService {
    private fileNameRegEx = /(Planting-Preparation|seeding-records)_(.*?)_(.*?).xlsx/;

    constructor(private http: HttpClient) {}

    async open(link: string, headers: HttpHeaders) {
        try {
            const response = await this.callServerEndpoint(link, headers);
            const fileName = this.getFileName(response);
            const url = window.URL.createObjectURL(response.body);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        } catch (error) {
            throw error;
        }
    }

    private callServerEndpoint(
        link: string,
        headers: HttpHeaders
    ): Promise<HttpResponse<Blob>> {
        return this.http
            .get(link, {
                responseType: 'blob',
                headers,
                observe: 'response'
            })
            .toPromise();
    }

    private getFileName(response: HttpResponse<Blob>): string {
        const fileNameInfo = response.headers.get('content-disposition');
        const matchInfo = this.fileNameRegEx.exec(fileNameInfo);
        const start = matchInfo.index;
        const end = start + matchInfo[0].length;
        const fileName = fileNameInfo.slice(start, end);
        return fileName;
    }
}
