import gql from 'graphql-tag';
export const userQuery = gql`
    query user($id: ID!) {
        node(id: $id) {
            ... on User {
                uuid
                name
                email
                pk
                id
                assignments {
                    edges {
                        node {
                            uuid
                            pk
                            role {
                                name
                                uuid
                            }
                            locationGroup {
                                name
                                uuid
                                level
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const usersQuery = gql`
    query usersQuery($numberOfItems: Int!, $cursorAfter: String) {
        users(first: $numberOfItems, after: $cursorAfter) {
            pageInfo {
                endCursor
                hasNextPage
            }
            edges {
                node {
                    uuid
                    name
                    email
                    pk
                    id
                    assignments(first: 200) {
                        edges {
                            node {
                                uuid
                                pk
                                role {
                                    name
                                    uuid
                                }
                                locationGroup {
                                    name
                                    uuid
                                    level
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const usersAssignedToRoleQuery = gql`
    query usersAssignedToRoleQuery(
        $numberOfItems: Int!
        $cursorAfter: String
        $roleUuid: UUID
        $locationGroupUuids: [UUID]
    ) {
        users(
            roleUuid: $roleUuid
            first: $numberOfItems
            after: $cursorAfter
            sort: name_asc
            locationGroupUuids: $locationGroupUuids
        ) {
            edges {
                node {
                    name
                    email
                    uuid
                    pk
                }
            }
            pageInfo {
                hasNextPage
                endCursor
            }
        }
    }
`;
