import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    Auth,
    ClimateRecipe,
    EcRecipe,
    FanRecipe,
    FarmingUnit,
    InfarmItem,
    LightRecipe,
    omap,
    PhRecipe,
    RecipeSchedule,
    RecipeSet,
    Token,
    WaterRefillingRecipe
} from 'infarm-core';
import { NavigationHistoryService } from '../navigation-history.service';
import { Item, toSnakeCase } from '@infarm/potion-client';
import { Action } from '../resources/schema';
import { ScheduleType, ScheduleValue } from '../recipes/utils';
import { API_HOST } from 'env';
import { apiRequest } from '../utils';

@Injectable()
export class RecipesService {
    token: Token;
    recipeTypesEndpointMap: Map<string, string> = new Map([
        [RecipeType.pH, 'ph_recipe'],
        [RecipeType.EC, 'ec_recipe'],
        [RecipeType.WaterRefilling, 'wrf_recipe'],
        [RecipeType.TankFlush, 'wfl_recipe'],
        [RecipeType.Climate, 'climate_recipe']
    ]);

    constructor(
        private navigationHistory: NavigationHistoryService,
        private router: Router,
        private route: ActivatedRoute,
        private auth: Auth,
        private location: Location
    ) {
        this.auth.token().then(token => (this.token = token));
    }

    goBackFromEditRecipe() {
        const { redirectUrl } = this.navigationHistory;
        if (redirectUrl) {
            this.router.navigateByUrl(redirectUrl);
        } else {
            this.router.navigate(['../../..'], { relativeTo: this.route });
        }
    }

    async editPhOrEcRecipeWithoutResolvedView(
        farmingUnitId: number,
        recipeType: RecipeType,
        payload: any
    ) {
        const unitView = await FarmingUnit.view({ farmingUnit: farmingUnitId });
        return recipeType === 'pH'
            ? this.editRecipe(unitView.recipes.water.ph.id, recipeType, payload)
            : this.editRecipe(
                  unitView.recipes.water.ec.id,
                  recipeType,
                  payload
              );
    }

    async editRecipe(
        recipeId,
        recipeType: RecipeType,
        payload: any
    ): Promise<Item> {
        return this.firePatchRequest(
            recipeType,
            payload,
            Number.parseInt(recipeId.toString(), 10)
        );
    }

    firePatchRequest: (
        type: RecipeType,
        payload: any,
        recipeId: number
    ) => Promise<Item> = async (
        type: RecipeType,
        payload: any,
        recipeId: number
    ) => {
        const recipeEndpoint = this.recipeTypesEndpointMap.get(type);
        payload = omap(payload, toSnakeCase);
        const updatedRecipeResponse = await fetch(
            `${API_HOST}/internal/api/${recipeEndpoint}/${recipeId}`,
            {
                headers: {
                    accept: 'application/json, text/plain, */*',
                    authorization: this.token.toString(),
                    'content-type': 'application/json'
                },
                body: JSON.stringify(payload),
                method: 'PATCH'
            }
        );

        if (updatedRecipeResponse.ok) {
            return updatedRecipeResponse.json();
        }
        throw Error(
            `Unable to update ${type} recipe, status code: ${updatedRecipeResponse.status}`
        );
    };

    async updateScheduleUsingAjax(scheduleValues: any[], scheduleId: number) {
        return new Promise(async (resolve, reject) => {
            const resourceValues = createScheduleData(scheduleValues);
            try {
                const fetched = await apiRequest(
                    `${API_HOST}/internal/api/recipe_schedule/${scheduleId}/set_from_time_array`,
                    this.token,
                    'POST',
                    JSON.stringify({ values: resourceValues })
                );
                if (fetched.ok) {
                    resolve(fetched);
                } else {
                    reject(fetched.status);
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    saveClimateRecipeUsingAjax = async (
        unit: FarmingUnit,
        payload: ClimateRecipe
    ) => {
        return new Promise(async (resolve, reject) => {
            try {
                const recipeSetId = Number.parseInt(unit.recipeSetId, 10);
                const recipeResponse = await apiRequest(
                    `${API_HOST}/internal/api/recipe_set/${recipeSetId}`,
                    this.token
                );
                const recipeSet = await recipeResponse.json();
                unit.recipeSet = recipeSet;
                let result;
                if (unit.recipeSet.climate_recipe) {
                    const climateRecipeResponse = await apiRequest(
                        `${API_HOST}/internal/api/climate_recipe/${Number.parseInt(
                            unit.recipeSet.climate_recipe.$ref.split('/')[4],
                            10
                        )}`,
                        this.token
                    );
                    const climateRecipe = await climateRecipeResponse.json();
                    climateRecipe.id = unit.recipeSet.climate_recipe.$ref.split(
                        '/'
                    )[4];
                    result = await this.saveRecipe(
                        climateRecipe,
                        crudRecipePayload(payload, RecipeType.Climate),
                        {
                            action: Action.Edit,
                            recipeType: RecipeType.Climate
                        }
                    );
                    resolve(result);
                } else {
                    let createdClimateRecipePayload: any = crudRecipePayload(
                        payload,
                        RecipeType.Climate
                    );
                    createdClimateRecipePayload.recipe_set = {
                        $ref: recipeSet['$uri'] // tslint:disable-line:no-string-literal
                    };
                    createdClimateRecipePayload = omap(
                        createdClimateRecipePayload,
                        toSnakeCase
                    );
                    result = await apiRequest(
                        `${API_HOST}/internal/api/climate_recipe`,
                        this.token,
                        'POST',
                        JSON.stringify(createdClimateRecipePayload)
                    );
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };

    saveWaterRefillingRecipeUsingAjax = async (
        unit: FarmingUnit,
        payload: WaterRefillingRecipe
    ) => {
        return new Promise(async (resolve, reject) => {
            try {
                const recipeSetId = Number.parseInt(unit.recipeSetId, 10);
                const recipeResponse = await apiRequest(
                    `${API_HOST}/internal/api/recipe_set/${recipeSetId}`,
                    this.token
                );
                const recipeSet = await recipeResponse.json();
                unit.recipeSet = recipeSet;
                let result;
                if (unit.recipeSet.wrf_recipe) {
                    const waterRecipeResponse = await apiRequest(
                        `${API_HOST}/internal/api/wrf_recipe/${Number.parseInt(
                            unit.recipeSet.wrf_recipe.$ref.split('/')[4],
                            10
                        )}`,
                        this.token
                    );
                    const waterRecipe = await waterRecipeResponse.json();
                    waterRecipe.id = unit.recipeSet.wrf_recipe.$ref.split(
                        '/'
                    )[4];
                    result = await this.saveRecipe(
                        waterRecipe,
                        crudRecipePayload(payload, RecipeType.WaterRefilling),
                        {
                            action: Action.Edit,
                            recipeType: RecipeType.WaterRefilling
                        }
                    );
                    resolve(result);
                } else {
                    let createdRefillingRecipePayload: any = crudRecipePayload(
                        payload,
                        RecipeType.WaterRefilling
                    );
                    createdRefillingRecipePayload.recipe_set = {
                        $ref: recipeSet['$uri'] // tslint:disable-line:no-string-literal
                    };
                    createdRefillingRecipePayload = omap(
                        createdRefillingRecipePayload,
                        toSnakeCase
                    );
                    result = apiRequest(
                        `${API_HOST}/internal/api/wrf_recipe`,
                        this.token,
                        'POST',
                        JSON.stringify(createdRefillingRecipePayload)
                    );
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };

    async createRecipe(
        recipeSet: RecipeSet,
        recipeType: RecipeType,
        payload: any,
        recipe: InfarmItem
    ): Promise<InfarmItem> {
        if (recipeSet) {
            const recipeOnRecipeSet =
                recipeType === RecipeType.TankFlush
                    ? { wflRecipe: recipe }
                    : { wrfRecipe: recipe };
            Object.assign(recipeSet, recipeOnRecipeSet);
        }
        Object.assign(recipe, { ...payload, recipeSet });
        return recipe.save();
    }

    async saveRecipe(
        recipe: InfarmItem,
        payload,
        config: RecipeConfig
    ): Promise<Item> {
        const { action, recipeSet, recipeType } = config;
        const result =
            action === Action.Edit
                ? await this.editRecipe(recipe.id, recipeType, payload)
                : await this.createRecipe(
                      recipeSet,
                      recipeType,
                      payload,
                      recipe
                  );
        return result;
    }

    createNewSchedule: (
        scheduleType: ScheduleType,
        farmingUnitId: number
    ) => Promise<RecipeSchedule> = async (
        scheduleType: ScheduleType,
        farmingUnitId: number
    ) => {
        return new Promise<RecipeSchedule>(async (resolve, reject) => {
            try {
                const farmingUnit = await apiRequest(
                    `${API_HOST}/internal/api/farming_unit/${farmingUnitId}`,
                    this.token
                );
                if (!farmingUnit.ok) {
                    reject('Cannot fetch farming unit for this schedule');
                }
                const unitContents = await farmingUnit.json();

                const schedule = await apiRequest(
                    `${API_HOST}/internal/api/recipe_schedule`,
                    this.token,
                    'POST',
                    JSON.stringify({ type: 'Fan' })
                );
                const scheduleContents = await schedule.json();

                const fanRecipe = await apiRequest(
                    `${API_HOST}/internal/api/fan_recipe`,
                    this.token,
                    'POST',
                    JSON.stringify({
                        recipe_set: Number.parseInt(
                            unitContents.recipe_set.$ref.split('/')[4],
                            10
                        ),
                        type: scheduleType,
                        enabled: true,
                        fan_intensity_schedule: Number.parseInt(
                            scheduleContents.$uri.split('/')[4],
                            10
                        )
                    })
                );

                if (!fanRecipe.ok) {
                    reject(`Cannot create new ${scheduleType} schedule`);
                }
                await fanRecipe.json();
                scheduleContents.id = Number.parseInt(
                    scheduleContents.$uri.split('/')[4],
                    10
                );
                resolve(scheduleContents);
            } catch (error) {
                reject(error);
            }
        });
    };

    goBackFromWaterRefilling(farmId, farmingUnitId): void {
        const { redirectUrl } = this.navigationHistory;
        if (redirectUrl) {
            this.location.back();
        } else {
            this.router.navigate([
                '/farm/',
                farmId,
                'unit',
                farmingUnitId,
                'recipes'
            ]);
        }
    }
}

export const createScheduleData = (scheduledValues: ScheduleValue[]) => {
    return scheduledValues.map(schedule => {
        const scheduledValue = schedule.value ? schedule.value : 0;
        return [schedule.startTime, convertBooleans(scheduledValue)];
    });
};

export const convertBooleans = (scheduledValue: boolean | number): number => {
    if (scheduledValue === true) {
        return 1;
    } else if (scheduledValue === false) {
        return 0;
    }
    return scheduledValue;
};

export interface RecipeConfig {
    showToast?: boolean;
    recipeSet?: any;
    action: Action;
    recipeType: RecipeType;
    successMessage?: string;
    errorMessage?: string;
}

export enum RecipeType {
    pH = 'pH',
    EC = 'EC',
    WaterRefilling = 'WaterRefilling',
    TankFlush = 'TankFlush',
    Climate = 'Climate',
    Sanitizer = 'Sanitizer'
}

export interface RecipesConfig {
    waterRefillingRecipe?: WaterRefillingRecipe;
    phRecipe?: PhRecipe;
    ecRecipe?: EcRecipe;
    lightSchedule?: RecipeSchedule;
    fanSchedule?: RecipeSchedule;
}

export function crudRecipePayload(recipe: Item, recipeType: RecipeType): any {
    if (recipeType === RecipeType.WaterRefilling) {
        return {
            interval: recipe.interval,
            upperPoint: recipe.upperPoint,
            lowerPoint: recipe.lowerPoint,
            tolerance: recipe.tolerance,
            enabled: !!recipe.enabled
        };
    }
    if (recipeType === RecipeType.TankFlush) {
        return {
            freq: recipe.freq,
            ecSp: recipe.ecSp,
            phSp: recipe.phSp,
            wlevSp: recipe.wlevSp,
            frmt: recipe.frmt,
            drmt: recipe.drmt,
            enabled: !!recipe.enabled
        };
    }
    if (recipeType === RecipeType.Climate) {
        return {
            temp_d: recipe.tempD,
            temp_n: recipe.tempN,
            hum_d: recipe.humD,
            hum_n: recipe.humN,
            mifs: recipe.mifs,
            enabled: !!recipe.enabled
        };
    }
    if (recipeType === RecipeType.pH) {
        return {
            setPoint: recipe.setPoint,
            tolerance: recipe.tolerance,
            interval: recipe.interval,
            pumpTime: recipe.pumpTime,
            enabled: !!recipe.enabled
        };
    }
    if (recipeType === RecipeType.EC) {
        return {
            setPoint: recipe.setPoint,
            tolerance: recipe.tolerance,
            interval: recipe.interval,
            parts: recipe.parts,
            pumpTime: recipe.pumpTime,
            sanitizerDosing: recipe.sanitizerDosing,
            enabled: !!recipe.enabled
        };
    }
}

export const findRecipeForSchedule = async (
    recipeType: string,
    scheduleId: number
): Promise<Item> => {
    const recipe =
        recipeType.toLowerCase() === 'light'
            ? await LightRecipe.query({
                  where: { lightIntensitySchedule: scheduleId }
              })
            : await FanRecipe.query({
                  where: { fanIntensitySchedule: scheduleId }
              });
    return recipe[0];
};
