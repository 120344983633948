import { UpdateableInfarmItem } from './mixins/updated-at';

export type AddressType = 'shipping';

export class Address extends UpdateableInfarmItem {
    line1: string;
    line2?: string;
    line3?: string;
    city: string;
    province?: string;

    postcode: string;
    country: string;

    details?: string;
    type: AddressType;

    toString(): string {
        return `${this.line1},
            ${this.city},
            ${this.postcode} ${this.country}
        `;
    }
}

export interface AddressView {
    line1: string;
    line2: string;
    line3: string;
    city: string;
    postcode: string;
    zip: string;
}
