import { Injectable, Optional, Provider, SkipSelf } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { from as fromPromise, Observable } from 'rxjs';
import { Farm, FarmPreview } from 'infarm-core';

@Injectable()
export class NurseriesService {
    items: Observable<FarmPreview[]> = this.nurseryQuery();
    nurseryQuery(): Observable<FarmPreview[]> {
        // TODO: Use pagination instead of a huge page size
        return fromPromise(
            Farm.query(
                {
                    where: { category: 'NURSERY' },
                    perPage: 100
                },
                {
                    skip: [
                        'farmingUnits',
                        'addresses',
                        'category',
                        'organization',
                        'locationGroup',
                        'nursery',
                        'location',
                        'createdBy'
                    ]
                }
            ) as Promise<FarmPreview[]>
        );
    }
}

export function NURSERIES_SERVICE_PROVIDER_FACTORY(
    parentFactory: NurseriesService
): NurseriesService {
    return parentFactory || new NurseriesService();
}

export async function routeToNursery(
    nurseries: FarmPreview[],
    navigation: Navigation
) {
    // Since we load the nurseries async,
    // we need to redirect to a nursery's page after we load them and check the current url if one is selected.
    if (
        !nurseries.some(nursery =>
            navigation.router.url.includes(`${nursery.id}`)
        )
    ) {
        const id = nurseries[0].id;
        await navigation.router.navigate([`./${id}`], {
            relativeTo: navigation.activatedRoute,
            skipLocationChange: true
        });
        navigation.location.replaceState(`/nurseries/${id}`);
    }
}

interface Navigation {
    router: Router;
    location: Location;
    activatedRoute: ActivatedRoute;
}

export const NURSERIES_SERVICE_PROVIDER: Provider = {
    // If there is already a NurseriesService available, use that.
    // Otherwise, provide a new one.
    provide: NurseriesService,
    useFactory: NURSERIES_SERVICE_PROVIDER_FACTORY,
    deps: [[new Optional(), new SkipSelf(), NurseriesService]]
};

export interface NurseryRoute {
    route: string;
    name: string;
}
