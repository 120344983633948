import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { InfarmItem } from 'infarm-core';
var ResourcePickerDialogComponent = /** @class */ (function () {
    function ResourcePickerDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    ResourcePickerDialogComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var resourceType, items;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        resourceType = this.data.resourceType.type;
                        this.options = this.data.options;
                        this.title = this.data.title;
                        if (this.data.resource instanceof InfarmItem) {
                            this.item = this.data.resource;
                        }
                        return [4 /*yield*/, resourceType.query(this.data.query)];
                    case 1:
                        items = (_a.sent());
                        this.items = items;
                        return [2 /*return*/];
                }
            });
        });
    };
    ResourcePickerDialogComponent.prototype.close = function () {
        this.dialogRef.close(this.data.resource);
    };
    ResourcePickerDialogComponent.prototype.select = function () {
        this.dialogRef.close(this.item);
    };
    return ResourcePickerDialogComponent;
}());
export { ResourcePickerDialogComponent };
