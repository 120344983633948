import * as tslib_1 from "tslib";
import { Farm, isJsObject } from 'infarm-core';
import { findFarmId } from './farm.resolver';
var FarmViewResolver = /** @class */ (function () {
    function FarmViewResolver() {
    }
    FarmViewResolver.prototype.resolve = function (route) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var farm, farm_1, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        farm = findFarmView(route);
                        if (!isJsObject(farm)) return [3 /*break*/, 1];
                        return [2 /*return*/, farm];
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, Farm.manage({ farm: findFarmId(route) })];
                    case 2:
                        farm_1 = _a.sent();
                        return [2 /*return*/, farm_1];
                    case 3:
                        err_1 = _a.sent();
                        return [2 /*return*/, null];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return FarmViewResolver;
}());
export { FarmViewResolver };
export function findFarmView(snapshot) {
    var current = snapshot;
    while (current) {
        var data = current.data;
        if (isJsObject(data) && isJsObject(data.farm)) {
            return data.farm;
        }
        current = current.parent;
    }
    return null;
}
