import { Constructor } from './mixins/ctor';
import { UpdateableInfarmItem } from './mixins/updated-at';
import { ItemWithName, ItemWithNameType } from './mixins/item-with-name';
import { EcRecipe } from './ec-recipe';
import { PhRecipe } from './ph-recipe';
import { WaterRefillingRecipe } from './water-refilling-recipe';
import { ClimateRecipe } from './climate-recipe';
import { LightRecipe } from './light-recipe';
import { TankFlushingRecipe } from './tank-flushing-recipe';

export const Base: Constructor<ItemWithNameType> &
    typeof UpdateableInfarmItem = ItemWithName(UpdateableInfarmItem);

export class RecipeSet extends Base {
    ecRecipe?: EcRecipe;
    phRecipe?: PhRecipe;
    waterRefillingRecipe?: WaterRefillingRecipe;
    climateRecipe?: ClimateRecipe;
    lightRecipe?: LightRecipe;
    tankFlushingRecipe: TankFlushingRecipe;
}
