// tslint:disable: max-classes-per-file
import {
    Injectable,
    NgZone,
    Optional,
    Provider,
    SkipSelf
} from '@angular/core';

import { Observable, Observer } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';

import { Auth, FarmingUnitStatusView, isJsObject, sleep } from 'infarm-core';
import { FarmingUnitCrudService } from './services/crud-service/farming-unit-crud.service';

@Injectable()
export class FarmingUnitStatusStreamManager {
    readonly streams: Map<string, FarmingUnitStatusStream> = new Map();

    stop = false;

    constructor(
        private zone: NgZone,
        private farmingUnitService: FarmingUnitCrudService
    ) {}

    async startStatusCheckLoop(
        frequency: number,
        farmingUnit: number,
        next: (status: FarmingUnitStatusView | null) => void
    ) {
        const wait = frequency || 5;
        // Stop making further requests if unsubscribed.
        while (!this.stop) {
            let status: FarmingUnitStatusView | null;
            try {
                status = await this.farmingUnitService.status(farmingUnit);
            } catch (err) {
                // In case of error,
                // just set the response to null.
                status = null;
            }
            next(status);
            // Let's wait now
            await sleep(wait * 1000);
        }
    }
    create(farmingUnit: number, frequency?: number): FarmingUnitStatusStream {
        // TODO: This will be a WebSocket stream when the backend is ready
        const source: Observable<FarmingUnitStatusView> = Observable.create(
            (observer: Observer<FarmingUnitStatusView>) => {
                this.stop = false;

                // Initiate stream
                // Run the check loop outside Angular's zone
                this.zone.runOutsideAngular(() => {
                    // NOTE: We emit the updates in a zone, to ensure that components will pick up on changes.
                    this.startStatusCheckLoop(
                        frequency,
                        farmingUnit,
                        (status: FarmingUnitStatusView | null) =>
                            this.zone.run(() => {
                                // If we had a failed request,
                                // send an empty status instead of failing the pipeline.
                                const st = getStatus(status);
                                observer.next(st);
                            })
                    );
                });

                return (): void => {
                    // Stop check
                    this.stop = true;
                };
            }
        );

        const status = publishReplay<FarmingUnitStatusView>(1)(source).pipe(
            refCount()
        );
        const stream = new FarmingUnitStatusStream(status);
        return stream;
    }
}

export function FARMING_UNIT_STATUS_STREAM_MANAGER_PROVIDER_FACTORY(
    parentFactory: FarmingUnitStatusStreamManager,
    zone: NgZone,
    auth: Auth
): FarmingUnitStatusStreamManager {
    return (
        parentFactory ||
        new FarmingUnitStatusStreamManager(
            zone,
            new FarmingUnitCrudService(auth)
        )
    );
}

export const FARMING_UNIT_STATUS_STREAM_MANAGER_PROVIDER: Provider = {
    // If there is already a FarmingUnitStatusStreamManager available, use that.
    // Otherwise, provide a new one.
    provide: FarmingUnitStatusStreamManager,
    useFactory: FARMING_UNIT_STATUS_STREAM_MANAGER_PROVIDER_FACTORY,
    deps: [
        [new Optional(), new SkipSelf(), FarmingUnitStatusStreamManager],
        NgZone,
        Auth
    ]
};

export class FarmingUnitStatusStream {
    dosingEnabled: Observable<boolean> = this.source.pipe(
        map(({ dosingEnabled }) => dosingEnabled)
    );
    isOnline: Observable<boolean> = this.source.pipe(
        map(({ isOnline }) => isOnline)
    );
    isDosing: Observable<boolean> = this.source.pipe(
        map(({ isDosing }) => isDosing)
    );
    isNight: Observable<boolean> = this.source.pipe(
        map(({ isNight }) => isNight)
    );
    inMaintenance: Observable<boolean> = this.source.pipe(
        map(({ inMaintenance }) => inMaintenance)
    );
    inSync: Observable<boolean> = this.source.pipe(map(({ inSync }) => inSync));

    constructor(private source: Observable<FarmingUnitStatusView>) {}
}

function getStatus(
    status: FarmingUnitStatusView | null
): FarmingUnitStatusView {
    if (!isJsObject(status)) {
        return {
            dosingEnabled: false,
            isOnline: false,
            isDosing: false,
            inMaintenance: false,
            inSync: false,
            inNight: false
        } as any;
    }
    return status;
}

export type ReadyState = number;
