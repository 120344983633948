<span mat-dialog-title>Harvest Round</span>

<mat-dialog-content>
    <form (ngSubmit)="save()" #hr="ngForm" autocomplete="off" novalidate>
        <div fxLayout="column" fxLayoutGap="16px">
            <mat-form-field class="in-fix-field-width">
                <mat-select [(ngModel)]="dayOfWeek" name="dayOfWeek">
                    <mat-option *ngFor="let day of daysOfWeek" [value]="day">{{
                        day
                    }}</mat-option>
                </mat-select>
            </mat-form-field>

            <div fxLayout="row" fxLayoutGap="16px">
                <mat-form-field fxFlex class="in-fix-field-width">
                    <input
                        matInput
                        #startTimeModel="ngModel"
                        [(ngModel)]="startTime"
                        type="time"
                        name="startTime"
                        placeholder="Start time"
                        required
                    />
                    <mat-error *ngIf="startTimeModel.hasError('required')">
                        Required
                    </mat-error>
                </mat-form-field>

                <mat-form-field fxFlex class="in-fix-field-width">
                    <input
                        matInput
                        #endTimeModel="ngModel"
                        [(ngModel)]="endTime"
                        type="time"
                        name="endTime"
                        placeholder="End time"
                        required
                    />
                    <mat-error *ngIf="endTimeModel.hasError('required')">
                        Required
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="undefined" class="in-margin-right">
        Cancel
    </button>
    <button (click)="save()" mat-button color="primary">
        Save
    </button>
</mat-dialog-actions>
