import * as tslib_1 from "tslib";
import { NgZone, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Auth, HttpError, User } from 'infarm-core';
import { distinctUntilChanged } from 'rxjs/operators';
import { isProd } from '../env';
import { GoogleAnalyticsService, NavigationHistoryService, ProgressService, staleUrlFragment } from 'app-shared';
var ignored401Routes = ['/set_password', '/auth/token'];
var AppComponent = /** @class */ (function () {
    // tslint:disable-next-line: member-ordering
    function AppComponent(zone, router, httpError, auth, ga, navigationHistory, progress) {
        var _this = this;
        this.progress = progress;
        this.router = router;
        /*
            TODO this "User.me" call is a workaround that calls a potion endpoint
            which in the case of an unathenticated user will trigger a redirect
            to the login page. It should be removed as soon as we have a better
            way of dealing with permission errors when hitting graphql resources.
        */
        User.me();
        // If the ApiGateway emits an error,
        // we want to handle that some way.
        // Subscribe to the errors stream coming from the ApiGateway.
        httpError.subscribe(function (error) {
            // If the user made a request that they were not authorized to,
            // it's possible that their session has expired.
            // NOTE: We may want to not act when certain routes triggered the 401.
            var url = error.url;
            if (error.status === 401 &&
                (!url ||
                    !ignored401Routes.some(function (route) { return url.indexOf(route) !== -1; }))) {
                // Before redirect clear the auth token,
                // otherwise the Login will try to refresh it.
                // TODO: Go through all @infarm/core logic and use NgZone where necessary
                zone.run(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, auth.logout()];
                            case 1:
                                _a.sent();
                                return [4 /*yield*/, router.navigateByUrl('/login')];
                            case 2:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); });
            }
        });
        router.events
            .pipe(distinctUntilChanged(function (previous, current) {
            return current instanceof NavigationEnd
                ? previous.url === current.url
                : true;
        }))
            .subscribe(function (event) {
            var isStaleUrl = event.url.indexOf(staleUrlFragment) === 0;
            if (isStaleUrl) {
                _this.redirectStaleUrl(event.url);
            }
            if (isProd()) {
                // Track page views.
                // http://stackoverflow.com/a/39622860/1092007
                ga.pageview(event.urlAfterRedirects);
            }
            // Store the event.
            // NOTE: we use this for handling redirects.
            navigationHistory.push(event);
        });
        router.events.subscribe(function (event) {
            if (event instanceof NavigationStart) {
                _this.progress.show();
            }
            else if (event instanceof NavigationEnd) {
                _this.progress.hide();
            }
        });
    }
    AppComponent.prototype.ngOnDestroy = function () {
        // HACK: For some reason, the app doesn't remove its DOM elements from
        // the document after unmounting - we have to do it manually.
        var element = document.querySelector('in-app');
        element.remove();
    };
    AppComponent.prototype.redirectStaleUrl = function (staleUrl) {
        var redirect = staleUrl.replace('/farms', '');
        this.router.navigateByUrl(redirect);
    };
    return AppComponent;
}());
export { AppComponent };
