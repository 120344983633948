import { capitalize, INFARM_POTION_RESOURCES, InfarmItem } from 'infarm-core';

export class ResourceType {
    static readonly resourceTypes: ResourceType[] = Object.entries(
        INFARM_POTION_RESOURCES
    )
        .map(([url, item]) => [
            url
                .replace(/\//g, '') // Remove slashes
                .replace(/_/g, '-'), // Replace underscore with dash
            item
        ])
        .map(([key, type]) => ResourceType.create(key, type));

    static findByItem(item: typeof InfarmItem): ResourceType {
        return this.resourceTypes.find(
            resourceType => resourceType.type === item
        ) as ResourceType;
    }

    static findByKey(key: string): ResourceType {
        return this.resourceTypes.find(
            resourceType => resourceType.key === key
        ) as ResourceType;
    }

    static types(): ResourceType[] {
        return this.resourceTypes.slice(0);
    }

    static create(...args: any[]): ResourceType {
        return Reflect.construct(this, args as any);
    }

    readonly key: string;
    readonly type: typeof InfarmItem;
    get name(): string | null {
        return capitalize(this.key.replace(/-/g, ' '), true);
    }

    constructor(key: string, type: typeof InfarmItem) {
        this.key = key;
        this.type = type;
    }
}
