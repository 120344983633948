import gql from 'graphql-tag';
import { sectorConfigurationFragments } from '../fragments';

export const sectorConfigurations = gql`
    query Farm(
        $numberOfItems: Int
        $id: ID!
        $plantingPeriodOverlapping: DateRange!
    ) {
        node(id: $id) {
            ... on Farm {
                name
                uuid
                isNursery
                nursery {
                    name
                }
                locationGroup {
                    uuid
                }
                harvestRounds {
                    pageInfo {
                        endCursor
                        hasNextPage
                    }
                    edges {
                        node {
                            uuid
                            endTime
                            startTime
                            dayOfWeek
                        }
                    }
                }
                farmingUnits(first: $numberOfItems) {
                    pageInfo {
                        endCursor
                        hasNextPage
                    }
                    edges {
                        node {
                            uuid
                            name
                            grower {
                                uuid
                                name
                            }
                            sectors(first: $numberOfItems) {
                                pageInfo {
                                    endCursor
                                    hasNextPage
                                }
                                edges {
                                    node {
                                        uuid
                                        number
                                        configurations(
                                            first: $numberOfItems
                                            plantingPeriodOverlapping: $plantingPeriodOverlapping
                                        ) {
                                            pageInfo {
                                                endCursor
                                                hasNextPage
                                            }
                                            edges {
                                                node {
                                                    ...SectorConfigurationFields
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${sectorConfigurationFragments.sectorConfigurationFields}
`;
