import { Injectable } from '@angular/core';
import { Auth, FarmingUnitStatusView, omap } from 'infarm-core';
import { CrudService } from './crud.service';
import { apiRequest } from '../../utils';
import { API_HOST } from 'env';
import { toCamelCase } from '@infarm/potion-client';

@Injectable()
export class FarmingUnitCrudService extends CrudService {
    constructor(auth: Auth) {
        super('FarmingUnit', auth);
    }

    async status(farmingUnitId): Promise<FarmingUnitStatusView> {
        const token = await this.auth.token();
        try {
            const response = await apiRequest(
                `${API_HOST}/internal/api/farming_unit/status?farming_unit=${farmingUnitId}`,
                token,
                'GET'
            );
            const jsoned = await response.json();
            return omap(jsoned, toCamelCase) as FarmingUnitStatusView;
        } catch (e) {
            throw Error(
                `An error happened when retrieving the status of the farm ${farmingUnitId}`
            );
        }
    }
}
