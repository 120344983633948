import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { User } from 'infarm-core';
import { GraphqlService } from '../graphql/graphql.service';
import { UserRole } from '../graphql/interfaces/shared';
import { usersAssignedToRoleQuery } from '../graphql/queries/user.query';
import { RolesResolver } from '../roles.resolver';
@Injectable()
export class GrowersResolver implements Resolve<User[]> {
    constructor(
        private graphqlService: GraphqlService,
        private rolesResolver: RolesResolver
    ) {}
    async resolve(): Promise<User[]> {
        try {
            const availableRoles = await this.rolesResolver.resolve();
            const growerRole = availableRoles.find(
                role => role.name === UserRole.Grower
            );
            const paginator = this.graphqlService.makePaginator(
                'users',
                usersAssignedToRoleQuery,
                {
                    roleUuid: growerRole.uuid
                }
            );
            return paginator.fetchAllEdges();
        } catch (err) {
            return [];
        }
    }
}
