/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/common";
import * as i6 from "@angular/router";
import * as i7 from "./app.component";
import * as i8 from "../../../shared-legacy/infarm-core/api/http-error";
import * as i9 from "../../../shared-legacy/infarm-core/auth/auth";
import * as i10 from "../../../shared-legacy/app-shared/ga.service";
import * as i11 from "../../../shared-legacy/app-shared/navigation-history.service";
import * as i12 from "../../../shared-legacy/app-shared/progress.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: { "animation": [{ type: 7, name: "visible", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: "void => *", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: null, timings: 250 }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: 250 }], options: null }], options: {} }] } });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "navigation-progress mat-progress-bar"], ["color", "accent"], ["mode", "indeterminate"], ["role", "progressbar"]], [[24, "@visible", 0], [1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(1, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { color: [0, "color"], mode: [1, "mode"] }, null)], function (_ck, _v) { var currVal_4 = "accent"; var currVal_5 = "indeterminate"; _ck(_v, 1, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = _v.context.ngIf; var currVal_1 = (((i1.ɵnov(_v, 1).mode === "indeterminate") || (i1.ɵnov(_v, 1).mode === "query")) ? null : i1.ɵnov(_v, 1).value); var currVal_2 = i1.ɵnov(_v, 1).mode; var currVal_3 = i1.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.progress.visible)); _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "in-app", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 180224, null, 0, i7.AppComponent, [i1.NgZone, i6.Router, i8.HttpError, i9.Auth, i10.GoogleAnalyticsService, i11.NavigationHistoryService, i12.ProgressService], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("in-app", i7.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
