import { Injectable, Optional, Provider, SkipSelf } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { isString } from 'infarm-core';

@Injectable()
export class ToastService {
    constructor(private snackBar: MatSnackBar) {}
    // Docs at https://github.com/angular/material2/blob/master/src/lib/snack-bar/README.md.
    push(
        message: string,
        actionLabel?: string,
        config?: MatSnackBarConfig
    ): Promise<any> {
        // NOTE: Observable only emits {complete}.
        return this.snackBar
            .open(
                message,
                actionLabel,
                Object.assign(
                    isString(actionLabel) ? {} : { duration: 3500 },
                    config
                )
            )
            .onAction()
            .toPromise();
    }
}

export function TOAST_SERVICE_PROVIDER_FACTORY(
    parentFactory: ToastService,
    snackBar: MatSnackBar
): ToastService {
    return parentFactory || new ToastService(snackBar);
}

export const TOAST_SERVICE_PROVIDER: Provider = {
    // If there is already a ToastService available, use that.
    // Otherwise, provide a new one.
    provide: ToastService,
    useFactory: TOAST_SERVICE_PROVIDER_FACTORY,
    deps: [[new Optional(), new SkipSelf(), ToastService], MatSnackBar]
};
