import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { FarmingUnit, FarmingUnitView } from 'infarm-core';
import { findUnitId } from './farming-unit.resolver';

@Injectable()
export class FarmingUnitViewResolver
    implements Resolve<FarmingUnitView | null> {
    async resolve(
        route: ActivatedRouteSnapshot
    ): Promise<FarmingUnitView | null> {
        try {
            const unitView = await FarmingUnit.view({
                farmingUnit: findUnitId(route)
            });
            return unitView;
        } catch (err) {
            return null;
        }
    }
}
