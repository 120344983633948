import * as tslib_1 from "tslib";
import { Item, Route } from '@infarm/potion-client';
var InfarmItem = /** @class */ (function (_super) {
    tslib_1.__extends(InfarmItem, _super);
    function InfarmItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InfarmItem.prototype.toHtmlString = function () {
        return this.toString();
    };
    InfarmItem.prototype.toString = function () {
        return "" + this.id;
    };
    InfarmItem.schema = Route.GET('/schema');
    return InfarmItem;
}(Item));
export { InfarmItem };
