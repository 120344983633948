import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var DocumentTitleService = /** @class */ (function () {
    function DocumentTitleService() {
        // Observable string sources
        this.titleSource = new Subject();
        // Observable string streams
        this.title = this.titleSource.asObservable(); // tslint:disable-line:member-ordering
    }
    DocumentTitleService.prototype.set = function (title) {
        this.titleSource.next(title);
    };
    DocumentTitleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DocumentTitleService_Factory() { return new DocumentTitleService(); }, token: DocumentTitleService, providedIn: "root" });
    return DocumentTitleService;
}());
export { DocumentTitleService };
