import { Constructor } from './ctor';
import { InfarmItem } from './infarm-item';
import { UpdatedAt, UpdatedAtType } from './updated-at';
import { Address } from '../address';

export const Base: Constructor<UpdatedAtType> & typeof InfarmItem = UpdatedAt(
    InfarmItem
);

export class ItemWithAddresses extends UpdatedAt(Base) {
    addresses?: Address[];
}

export interface ItemWithAddressesType {
    addresses?: Address[];
}
