import { Column } from './column';
var Row = /** @class */ (function () {
    function Row(keys, item) {
        this.item = item;
        this.columns = [];
        this.columns = keys.map(function (key) { return new Column(key, item); });
    }
    Row.prototype.equals = function (row) {
        return this.item.equals(row.item);
    };
    Object.defineProperty(Row.prototype, "id", {
        get: function () {
            return "" + this.item.uri;
        },
        enumerable: true,
        configurable: true
    });
    return Row;
}());
export { Row };
