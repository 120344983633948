import * as tslib_1 from "tslib";
import { Apollo } from 'apollo-angular';
import { PaginatorService } from './paginator.service';
import { MAX_GRAPHQL_PAGE_SIZE } from './utils';
function makePaginatorConfig(connectionName, query, extraQueryParameters) {
    var queryParameters = {
        numberOfItems: MAX_GRAPHQL_PAGE_SIZE
    };
    Object.assign(queryParameters, extraQueryParameters);
    var paginatorConfig = {
        query: query,
        connectionName: connectionName,
        queryParameters: queryParameters
    };
    return paginatorConfig;
}
var GraphqlService = /** @class */ (function () {
    function GraphqlService(apollo) {
        this.apollo = apollo;
    }
    GraphqlService.prototype.makePaginator = function (connectionName, query, extraQueryParameters) {
        var paginatorConfig = makePaginatorConfig(connectionName, query, extraQueryParameters);
        this.farmConnectionPaginator = new PaginatorService(this.apollo, paginatorConfig);
        return this.farmConnectionPaginator;
    };
    GraphqlService.prototype.query = function (query, variables) {
        if (variables === void 0) { variables = null; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.apollo
                        .query({
                        query: query,
                        variables: variables
                    })
                        .toPromise()];
            });
        });
    };
    GraphqlService.prototype.mutate = function (mutation, variables) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.apollo.mutate({ mutation: mutation, variables: variables }).toPromise()];
            });
        });
    };
    return GraphqlService;
}());
export { GraphqlService };
