import { Injectable, Optional, Provider, SkipSelf } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ReplaySubject, Subscribable, Unsubscribable } from 'rxjs';

@Injectable()
export class HttpError implements Subscribable<HttpErrorResponse> {
    private error: ReplaySubject<HttpErrorResponse> = new ReplaySubject<
        HttpErrorResponse
    >(1);

    subscribe(
        next?: any,
        error?: (error: HttpErrorResponse) => void,
        complete?: () => void
    ): Unsubscribable {
        return this.error.asObservable().subscribe(next, error, complete);
    }

    next(error: HttpErrorResponse): void {
        this.error.next(error);
    }
}

export function HTTP_ERROR_PROVIDER_FACTORY(
    parentFactory: HttpError
): HttpError {
    return parentFactory || new HttpError();
}

export const HTTP_ERROR_PROVIDER: Provider = {
    // If there is already a HttpError available, use that.
    // Otherwise, provide a new one.
    provide: HttpError,
    useFactory: HTTP_ERROR_PROVIDER_FACTORY,
    deps: [[new Optional(), new SkipSelf(), HttpError]]
};
