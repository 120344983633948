import * as tslib_1 from "tslib";
import { UpdateableInfarmItem } from './mixins/updated-at';
import { ItemWithName } from './mixins/item-with-name';
export var Base = ItemWithName(UpdateableInfarmItem);
var TrayType = /** @class */ (function (_super) {
    tslib_1.__extends(TrayType, _super);
    function TrayType() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return TrayType;
}(Base));
export { TrayType };
export var TrayVariant;
(function (TrayVariant) {
    TrayVariant["Linear"] = "LINEAR";
    TrayVariant["Spiral"] = "SPIRAL";
})(TrayVariant || (TrayVariant = {}));
