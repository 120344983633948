import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { endProductsQuery } from '../graphql/queries/end-products.query';
import { GraphqlService } from '../graphql/graphql.service';

@Injectable()
export class EndProductsResolver implements Resolve<any[]> {
    constructor(private graphqlService: GraphqlService) {}
    async resolve(): Promise<any[]> {
        try {
            const paginator = this.graphqlService.makePaginator(
                'endProducts',
                endProductsQuery,
                {
                    isActive: true
                }
            );
            return paginator.fetchAllEdges();
        } catch (err) {
            return [];
        }
    }
}
