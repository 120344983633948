import { Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { DocumentTitleService } from '../document-title.service';
import * as i0 from "@angular/core";
import * as i1 from "../document-title.service";
import * as i2 from "@angular/platform-browser";
var ViewTitleService = /** @class */ (function () {
    function ViewTitleService(documentTitle, sanitizer) {
        this.documentTitle = documentTitle;
        this.sanitizer = sanitizer;
        // Observable sources
        this.titleSource = new Subject();
        this.subtitleSource = new Subject();
        // Observable streams
        this.title = this.titleSource.asObservable(); // tslint:disable-line:member-ordering
        this.subtitle = this.subtitleSource.asObservable(); // tslint:disable-line:member-ordering
    }
    ViewTitleService.prototype.set = function (title, subtitle) {
        this.titleSource.next(title);
        this.documentTitle.set(title);
        var safeHtmlSubtitle = this.sanitizer.bypassSecurityTrustHtml(subtitle ? subtitle : '');
        this.subtitleSource.next(safeHtmlSubtitle);
    };
    ViewTitleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ViewTitleService_Factory() { return new ViewTitleService(i0.ɵɵinject(i1.DocumentTitleService), i0.ɵɵinject(i2.DomSanitizer)); }, token: ViewTitleService, providedIn: "root" });
    return ViewTitleService;
}());
export { ViewTitleService };
