import { enableProdMode, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { isProd, isStaging } from './env';
import singleSpaAngular, { getSingleSpaExtraProviders } from 'single-spa-angular';
import { singleSpaPropsSubject } from './single-spa/single-spa-props';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
// Enable prod mode
if (isProd() || isStaging()) {
    enableProdMode();
}
var ɵ0 = function (singleSpaProps) {
    singleSpaPropsSubject.next(singleSpaProps);
    return __NgCli_bootstrap_2.platformBrowser(getSingleSpaExtraProviders()).bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory);
};
var lifecycles = singleSpaAngular({
    bootstrapFunction: ɵ0,
    template: '<in-app />',
    Router: Router,
    NgZone: NgZone
});
export var bootstrap = lifecycles.bootstrap;
export var mount = lifecycles.mount;
export var unmount = lifecycles.unmount;
export { ɵ0 };
