import { isString } from './guards';

/**
 * Check if a value is valid JSON
 * @returns Parsed object or undefined.
 */
export async function isJSON<T>(value: any): Promise<T | undefined> {
    if (isString(value) && value.length > 0) {
        const obj = await parseAsync(value);
        return obj;
    }
}

/**
 * Parse JSON strings async (and not in the main thread)
 * @param jsonStr
 */
export async function parseAsync<T = any>(
    jsonStr: any
): Promise<T | undefined> {
    try {
        const res = new Response(jsonStr);
        const obj = await res.json();
        return obj;
    } catch (err) {} // tslint:disable-line: no-empty
}
