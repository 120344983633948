import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { Address, capitalize, compare } from 'infarm-core';
import { DynamicFormDialogService } from '../dynamic-form';
import { ResourceType } from '../resources';
var AddressComponent = /** @class */ (function () {
    function AddressComponent(dynamicFormDialog) {
        this.dynamicFormDialog = dynamicFormDialog;
        this.type = 'shipping';
        this.allowSelect = true;
        this.set = new EventEmitter();
        this.resourceType = ResourceType.findByItem(Address);
    }
    Object.defineProperty(AddressComponent.prototype, "title", {
        get: function () {
            return capitalize(this.type) + " address";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddressComponent.prototype, "hasAddress", {
        get: function () {
            return this.address instanceof Address;
        },
        enumerable: true,
        configurable: true
    });
    AddressComponent.prototype.select = function (address) {
        // We only emit the selected address if it's different from the one that was previously selected
        if (!compare(address, this.address)) {
            this.setAddress(address);
        }
    };
    AddressComponent.prototype.edit = function () {
        this.dynamicFormDialog.open({
            resourceType: this.resourceType,
            resource: this.address,
            config: {
                type: {
                    value: this.type,
                    hide: true
                }
            }
        });
    };
    AddressComponent.prototype.add = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, address;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data = {
                            resourceType: this.resourceType,
                            config: {
                                type: {
                                    value: this.type,
                                    hide: true
                                }
                            }
                        };
                        return [4 /*yield*/, this.dynamicFormDialog.open(data)];
                    case 1:
                        address = _a.sent();
                        if (address instanceof Address) {
                            this.setAddress(address);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AddressComponent.prototype.setAddress = function (address) {
        this.address = address;
        this.set.emit({
            address: address,
            type: this.type
        });
    };
    return AddressComponent;
}());
export { AddressComponent };
