import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { isNumber, isString } from 'infarm-core';

import { isDate, isMoment } from 'moment';
import { CheckboxField } from '../fields/checkbox';

import {
    DateField,
    FieldBase,
    NumberField,
    SelectField,
    TextField
} from '../fields';

// TODO: Add unit tests
@Injectable()
export class FormService {
    constructor(private sanitizer: DomSanitizer) {}
    parse(fields: Array<FieldBase<any>>, sanitize: boolean = true): any {
        const result: { [key: string]: any } = {};

        // Filter out null or blank fields (empty string or string with whitespace).
        for (const field of fields) {
            const { key, value } = field;
            if (
                sanitize &&
                (isNullOrBlankString(value) ||
                    (Array.isArray(value) &&
                        (!value.length ||
                            value.every(v => isNullOrBlankString(v)))))
            ) {
                continue;
            }
            result[key] = parse(this.sanitizer, field, value);
        }

        return result;
    }
}

function isNullOrBlankString(value: any): boolean {
    return (
        value === null || typeof value === 'undefined' || isBlankString(value)
    );
}
// TODO: Move to @infarm/core
function isBlankString(value: any): boolean {
    return isString(value) && /^\s*$/.test(value);
}

const allowedCharacterCodes: RegExp = /&#(19[2-9]|2[0-9][0-9]|3[0-8][0-9]);/g;

// Allows umlauts and accents that get purged by the angular DomSanitizer, see here for extra info: https://en.wikipedia.org/wiki/List_of_Unicode_characters
function allowCharactersWithAccents(text: string): string {
    return text.replace(allowedCharacterCodes, match => {
        const charCode = Number.parseInt(
            match.replace(/&#/, '0').replace(';', ''),
            10
        );
        return String.fromCharCode(charCode);
    });
}

function parse(
    sanitizer: DomSanitizer,
    field: FieldBase<any>,
    value: any
): any {
    if (
        field instanceof SelectField &&
        field.multiple &&
        !Array.isArray(value)
    ) {
        return [value];
    }

    // If we have a text field, check if it's a string;
    // if it's not a string convert it to one or to null if it's an empty string.
    if (field instanceof TextField) {
        if (isBlankString(value) || value === null) {
            return null;
        }
        const sanitized = sanitizer.sanitize(SecurityContext.HTML, value);
        return allowCharactersWithAccents(sanitized) as string; // Sanitize input while retaining some special characters.
    }

    if (field instanceof CheckboxField) {
        return !!value;
    }

    if (field instanceof DateField) {
        if (isMoment(value)) {
            // Potion wants Date objects
            return value.toDate();
        } else if (isDate(value)) {
            return value;
        }
    }

    if (field instanceof NumberField) {
        if (isNumber(value)) {
            return isNaN(value) ? null : value;
        } else if (isBlankString(value)) {
            return null;
        }
        const num = parseFloat(value);
        if (isNaN(num)) {
            return null;
        }
        return num;
    }

    return value;
}
