import gql from 'graphql-tag';
import { MAX_GRAPHQL_PAGE_SIZE } from '../utils';

export const farmNamesQuery = gql`
    query FarmNames($numberOfItems: Int!, $cursorAfter: String) {
        farms(first: $numberOfItems, after: $cursorAfter, sort: id_asc) {
            pageInfo {
                endCursor
                hasNextPage
            }
            edges {
                node {
                    pk
                    name
                }
            }
        }
    }
`;

export const farmsQuery = gql`
    query PaginatedFarms($numberOfItems: Int!, $cursorAfter: String) {
        farms(first: $numberOfItems, after: $cursorAfter, sort: id_asc) {
            pageInfo {
                endCursor
                hasNextPage
            }
            edges {
                node {
                    id
                    pk
                    uuid
                    name
                    organizationId
                    farmingUnits(sort: id_asc) {
                        pageInfo {
                            endCursor
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                name
                                pk
                                modelName
                                recipeSetId
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const farmById = gql`
    query FarmById($id: ID!) {
        node(id: $id) {
            ... on Farm {
                name
                locationGroup {
                    uuid
                }
            }
        }
    }
`;

export const organizationsQuery = gql`
    query PaginatedOrganizations($numberOfItems: Int!, $cursorAfter: String) {
        organizations(
            first: $numberOfItems
            after: $cursorAfter
            sort: id_asc
        ) {
            pageInfo {
                endCursor
                hasNextPage
            }
            edges {
                node {
                    pk
                    id
                    uuid
                    name
                    isDeleted
                }
            }
        }
    }
`;

export const farmingUnitsForFarmQuery = gql`
    query farmingUnitsForFarmQuery($id: ID!) {
        node(id: $id) {
            ... on Farm {
                farmingUnits(first: ${MAX_GRAPHQL_PAGE_SIZE}, sort: name_asc) {
                    pageInfo {
                        endCursor
                        hasNextPage
                    }
                    edges {
                        node {
                            pk
                            name
                            farmId
                        }
                    }
                }
            }
        }
    }
`;

export const controllersQuery = gql`
    query ControllersInFarm($id: ID!) {
        node(id: $id) {
            ... on Farm {
                farmingUnits {
                    edges {
                        node {
                            pk
                            controllers(isMaster: true) {
                                edges {
                                    node {
                                        uuid
                                        particleId
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const trayTypesQuery = gql`
    query TrayTypesQuery(
        $numberOfItems: Int!
        $cursorAfter: String
        $state: String
    ) {
        trayTypes(
            first: $numberOfItems
            after: $cursorAfter
            sort: id_asc
            state: $state
        ) {
            pageInfo {
                endCursor
                hasNextPage
            }
            edges {
                node {
                    id
                    pk
                    uuid
                    name
                    stages
                    tracks
                    throughput
                    variant
                }
            }
        }
    }
`;
