import { Injectable } from '@angular/core';
import {
    FormControl,
    FormGroup,
    ValidatorFn,
    Validators
} from '@angular/forms';

import { FieldBase } from '../fields/base';

@Injectable()
export class FieldControlService {
    updateFormGroup(form: FormGroup, fields: Array<FieldBase<any>>): void {
        if (fields && fields.length) {
            for (const field of fields) {
                if (!form.contains(field.key)) {
                    form.addControl(field.key, createControl(field));
                }
            }
        }
    }

    toFormGroup(fields: Array<FieldBase<any>>): FormGroup {
        const group: any = {};
        for (const field of fields) {
            group[field.key] = createControl(field);
        }
        return new FormGroup(group);
    }
}

function createControl(field: FieldBase<any>): FormControl {
    // https://angular.io/docs/ts/latest/cookbook/form-validation.html#!#reactive
    const validators: ValidatorFn[] = [];

    if (field.required) {
        validators.push(Validators.required);
    }

    return new FormControl(
        typeof field.value === 'boolean' ? !!field.value : field.value || '',
        validators
    );
}
