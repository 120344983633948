import { NgModule } from '@angular/core';

import { InfarmCapitalizePipe } from './capitalize';
import { InfarmDefaultPipe } from './default';
import { InfarmAssetUrlPipe } from './assetUrl';

export { InfarmCapitalizePipe } from './capitalize';
export { InfarmDefaultPipe } from './default';
export { InfarmAssetUrlPipe } from './assetUrl';

export const pipes = [
    InfarmCapitalizePipe,
    InfarmDefaultPipe,
    InfarmAssetUrlPipe
];

@NgModule({
    declarations: [...pipes],
    exports: [...pipes]
})
export class PipesModule {}
