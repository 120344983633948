import { Constructor } from './mixins/ctor';
import { UpdatedAt, UpdatedAtType } from './mixins/updated-at';
import { EndProduct } from './end-product';
import { FarmingUnit } from './farming-unit';
import { DayOfWeek } from './harvest-round';
import { TrayType } from './tray-type';
import { InfarmItem } from './mixins/infarm-item';

// TODO: Remove when https://github.com/Microsoft/TypeScript/issues/15870 is fixed
export const Base: Constructor<UpdatedAtType> & typeof InfarmItem = UpdatedAt(
    InfarmItem
);

export class Sector extends Base {
    columnNumber: number;
    levelNumber: number;

    farmingUnit: FarmingUnit;
    endProduct?: EndProduct;
    harvestDayOfWeek?: DayOfWeek;
    harvestStartTime?: number;
    harvestEndTime?: number;
    trayType?: TrayType;
    seedingDay: DayOfWeek;
    firstSeedingDate?: Date;
}
