import * as tslib_1 from "tslib";
import { UpdateableInfarmItem } from './mixins/updated-at';
import { ItemWithName } from './mixins/item-with-name';
export var Base = ItemWithName(UpdateableInfarmItem);
var RecipeSet = /** @class */ (function (_super) {
    tslib_1.__extends(RecipeSet, _super);
    function RecipeSet() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RecipeSet;
}(Base));
export { RecipeSet };
