var _this = this;
import * as tslib_1 from "tslib";
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth, FanRecipe, FarmingUnit, LightRecipe, omap } from 'infarm-core';
import { NavigationHistoryService } from '../navigation-history.service';
import { toSnakeCase } from '@infarm/potion-client';
import { Action } from '../resources/schema';
import { API_HOST } from 'env';
import { apiRequest } from '../utils';
var RecipesService = /** @class */ (function () {
    function RecipesService(navigationHistory, router, route, auth, location) {
        var _this = this;
        this.navigationHistory = navigationHistory;
        this.router = router;
        this.route = route;
        this.auth = auth;
        this.location = location;
        this.recipeTypesEndpointMap = new Map([
            [RecipeType.pH, 'ph_recipe'],
            [RecipeType.EC, 'ec_recipe'],
            [RecipeType.WaterRefilling, 'wrf_recipe'],
            [RecipeType.TankFlush, 'wfl_recipe'],
            [RecipeType.Climate, 'climate_recipe']
        ]);
        this.firePatchRequest = function (type, payload, recipeId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var recipeEndpoint, updatedRecipeResponse;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        recipeEndpoint = this.recipeTypesEndpointMap.get(type);
                        payload = omap(payload, toSnakeCase);
                        return [4 /*yield*/, fetch(API_HOST + "/internal/api/" + recipeEndpoint + "/" + recipeId, {
                                headers: {
                                    accept: 'application/json, text/plain, */*',
                                    authorization: this.token.toString(),
                                    'content-type': 'application/json'
                                },
                                body: JSON.stringify(payload),
                                method: 'PATCH'
                            })];
                    case 1:
                        updatedRecipeResponse = _a.sent();
                        if (updatedRecipeResponse.ok) {
                            return [2 /*return*/, updatedRecipeResponse.json()];
                        }
                        throw Error("Unable to update " + type + " recipe, status code: " + updatedRecipeResponse.status);
                }
            });
        }); };
        this.saveClimateRecipeUsingAjax = function (unit, payload) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var recipeSetId, recipeResponse, recipeSet, result, climateRecipeResponse, climateRecipe, createdClimateRecipePayload, error_1;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 9, , 10]);
                                    recipeSetId = Number.parseInt(unit.recipeSetId, 10);
                                    return [4 /*yield*/, apiRequest(API_HOST + "/internal/api/recipe_set/" + recipeSetId, this.token)];
                                case 1:
                                    recipeResponse = _a.sent();
                                    return [4 /*yield*/, recipeResponse.json()];
                                case 2:
                                    recipeSet = _a.sent();
                                    unit.recipeSet = recipeSet;
                                    result = void 0;
                                    if (!unit.recipeSet.climate_recipe) return [3 /*break*/, 6];
                                    return [4 /*yield*/, apiRequest(API_HOST + "/internal/api/climate_recipe/" + Number.parseInt(unit.recipeSet.climate_recipe.$ref.split('/')[4], 10), this.token)];
                                case 3:
                                    climateRecipeResponse = _a.sent();
                                    return [4 /*yield*/, climateRecipeResponse.json()];
                                case 4:
                                    climateRecipe = _a.sent();
                                    climateRecipe.id = unit.recipeSet.climate_recipe.$ref.split('/')[4];
                                    return [4 /*yield*/, this.saveRecipe(climateRecipe, crudRecipePayload(payload, RecipeType.Climate), {
                                            action: Action.Edit,
                                            recipeType: RecipeType.Climate
                                        })];
                                case 5:
                                    result = _a.sent();
                                    resolve(result);
                                    return [3 /*break*/, 8];
                                case 6:
                                    createdClimateRecipePayload = crudRecipePayload(payload, RecipeType.Climate);
                                    createdClimateRecipePayload.recipe_set = {
                                        $ref: recipeSet['$uri'] // tslint:disable-line:no-string-literal
                                    };
                                    createdClimateRecipePayload = omap(createdClimateRecipePayload, toSnakeCase);
                                    return [4 /*yield*/, apiRequest(API_HOST + "/internal/api/climate_recipe", this.token, 'POST', JSON.stringify(createdClimateRecipePayload))];
                                case 7:
                                    result = _a.sent();
                                    _a.label = 8;
                                case 8:
                                    resolve(result);
                                    return [3 /*break*/, 10];
                                case 9:
                                    error_1 = _a.sent();
                                    reject(error_1);
                                    return [3 /*break*/, 10];
                                case 10: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        }); };
        this.saveWaterRefillingRecipeUsingAjax = function (unit, payload) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var recipeSetId, recipeResponse, recipeSet, result, waterRecipeResponse, waterRecipe, createdRefillingRecipePayload, error_2;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 8, , 9]);
                                    recipeSetId = Number.parseInt(unit.recipeSetId, 10);
                                    return [4 /*yield*/, apiRequest(API_HOST + "/internal/api/recipe_set/" + recipeSetId, this.token)];
                                case 1:
                                    recipeResponse = _a.sent();
                                    return [4 /*yield*/, recipeResponse.json()];
                                case 2:
                                    recipeSet = _a.sent();
                                    unit.recipeSet = recipeSet;
                                    result = void 0;
                                    if (!unit.recipeSet.wrf_recipe) return [3 /*break*/, 6];
                                    return [4 /*yield*/, apiRequest(API_HOST + "/internal/api/wrf_recipe/" + Number.parseInt(unit.recipeSet.wrf_recipe.$ref.split('/')[4], 10), this.token)];
                                case 3:
                                    waterRecipeResponse = _a.sent();
                                    return [4 /*yield*/, waterRecipeResponse.json()];
                                case 4:
                                    waterRecipe = _a.sent();
                                    waterRecipe.id = unit.recipeSet.wrf_recipe.$ref.split('/')[4];
                                    return [4 /*yield*/, this.saveRecipe(waterRecipe, crudRecipePayload(payload, RecipeType.WaterRefilling), {
                                            action: Action.Edit,
                                            recipeType: RecipeType.WaterRefilling
                                        })];
                                case 5:
                                    result = _a.sent();
                                    resolve(result);
                                    return [3 /*break*/, 7];
                                case 6:
                                    createdRefillingRecipePayload = crudRecipePayload(payload, RecipeType.WaterRefilling);
                                    createdRefillingRecipePayload.recipe_set = {
                                        $ref: recipeSet['$uri'] // tslint:disable-line:no-string-literal
                                    };
                                    createdRefillingRecipePayload = omap(createdRefillingRecipePayload, toSnakeCase);
                                    result = apiRequest(API_HOST + "/internal/api/wrf_recipe", this.token, 'POST', JSON.stringify(createdRefillingRecipePayload));
                                    _a.label = 7;
                                case 7:
                                    resolve(result);
                                    return [3 /*break*/, 9];
                                case 8:
                                    error_2 = _a.sent();
                                    reject(error_2);
                                    return [3 /*break*/, 9];
                                case 9: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        }); };
        this.createNewSchedule = function (scheduleType, farmingUnitId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var farmingUnit, unitContents, schedule, scheduleContents, fanRecipe, error_3;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 7, , 8]);
                                    return [4 /*yield*/, apiRequest(API_HOST + "/internal/api/farming_unit/" + farmingUnitId, this.token)];
                                case 1:
                                    farmingUnit = _a.sent();
                                    if (!farmingUnit.ok) {
                                        reject('Cannot fetch farming unit for this schedule');
                                    }
                                    return [4 /*yield*/, farmingUnit.json()];
                                case 2:
                                    unitContents = _a.sent();
                                    return [4 /*yield*/, apiRequest(API_HOST + "/internal/api/recipe_schedule", this.token, 'POST', JSON.stringify({ type: 'Fan' }))];
                                case 3:
                                    schedule = _a.sent();
                                    return [4 /*yield*/, schedule.json()];
                                case 4:
                                    scheduleContents = _a.sent();
                                    return [4 /*yield*/, apiRequest(API_HOST + "/internal/api/fan_recipe", this.token, 'POST', JSON.stringify({
                                            recipe_set: Number.parseInt(unitContents.recipe_set.$ref.split('/')[4], 10),
                                            type: scheduleType,
                                            enabled: true,
                                            fan_intensity_schedule: Number.parseInt(scheduleContents.$uri.split('/')[4], 10)
                                        }))];
                                case 5:
                                    fanRecipe = _a.sent();
                                    if (!fanRecipe.ok) {
                                        reject("Cannot create new " + scheduleType + " schedule");
                                    }
                                    return [4 /*yield*/, fanRecipe.json()];
                                case 6:
                                    _a.sent();
                                    scheduleContents.id = Number.parseInt(scheduleContents.$uri.split('/')[4], 10);
                                    resolve(scheduleContents);
                                    return [3 /*break*/, 8];
                                case 7:
                                    error_3 = _a.sent();
                                    reject(error_3);
                                    return [3 /*break*/, 8];
                                case 8: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        }); };
        this.auth.token().then(function (token) { return (_this.token = token); });
    }
    RecipesService.prototype.goBackFromEditRecipe = function () {
        var redirectUrl = this.navigationHistory.redirectUrl;
        if (redirectUrl) {
            this.router.navigateByUrl(redirectUrl);
        }
        else {
            this.router.navigate(['../../..'], { relativeTo: this.route });
        }
    };
    RecipesService.prototype.editPhOrEcRecipeWithoutResolvedView = function (farmingUnitId, recipeType, payload) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var unitView;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, FarmingUnit.view({ farmingUnit: farmingUnitId })];
                    case 1:
                        unitView = _a.sent();
                        return [2 /*return*/, recipeType === 'pH'
                                ? this.editRecipe(unitView.recipes.water.ph.id, recipeType, payload)
                                : this.editRecipe(unitView.recipes.water.ec.id, recipeType, payload)];
                }
            });
        });
    };
    RecipesService.prototype.editRecipe = function (recipeId, recipeType, payload) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.firePatchRequest(recipeType, payload, Number.parseInt(recipeId.toString(), 10))];
            });
        });
    };
    RecipesService.prototype.updateScheduleUsingAjax = function (scheduleValues, scheduleId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var resourceValues, fetched, error_4;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    resourceValues = createScheduleData(scheduleValues);
                                    _a.label = 1;
                                case 1:
                                    _a.trys.push([1, 3, , 4]);
                                    return [4 /*yield*/, apiRequest(API_HOST + "/internal/api/recipe_schedule/" + scheduleId + "/set_from_time_array", this.token, 'POST', JSON.stringify({ values: resourceValues }))];
                                case 2:
                                    fetched = _a.sent();
                                    if (fetched.ok) {
                                        resolve(fetched);
                                    }
                                    else {
                                        reject(fetched.status);
                                    }
                                    return [3 /*break*/, 4];
                                case 3:
                                    error_4 = _a.sent();
                                    reject(error_4);
                                    return [3 /*break*/, 4];
                                case 4: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    RecipesService.prototype.createRecipe = function (recipeSet, recipeType, payload, recipe) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var recipeOnRecipeSet;
            return tslib_1.__generator(this, function (_a) {
                if (recipeSet) {
                    recipeOnRecipeSet = recipeType === RecipeType.TankFlush
                        ? { wflRecipe: recipe }
                        : { wrfRecipe: recipe };
                    Object.assign(recipeSet, recipeOnRecipeSet);
                }
                Object.assign(recipe, tslib_1.__assign({}, payload, { recipeSet: recipeSet }));
                return [2 /*return*/, recipe.save()];
            });
        });
    };
    RecipesService.prototype.saveRecipe = function (recipe, payload, config) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var action, recipeSet, recipeType, result, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        action = config.action, recipeSet = config.recipeSet, recipeType = config.recipeType;
                        if (!(action === Action.Edit)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.editRecipe(recipe.id, recipeType, payload)];
                    case 1:
                        _a = _b.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.createRecipe(recipeSet, recipeType, payload, recipe)];
                    case 3:
                        _a = _b.sent();
                        _b.label = 4;
                    case 4:
                        result = _a;
                        return [2 /*return*/, result];
                }
            });
        });
    };
    RecipesService.prototype.goBackFromWaterRefilling = function (farmId, farmingUnitId) {
        var redirectUrl = this.navigationHistory.redirectUrl;
        if (redirectUrl) {
            this.location.back();
        }
        else {
            this.router.navigate([
                '/farm/',
                farmId,
                'unit',
                farmingUnitId,
                'recipes'
            ]);
        }
    };
    return RecipesService;
}());
export { RecipesService };
export var createScheduleData = function (scheduledValues) {
    return scheduledValues.map(function (schedule) {
        var scheduledValue = schedule.value ? schedule.value : 0;
        return [schedule.startTime, convertBooleans(scheduledValue)];
    });
};
export var convertBooleans = function (scheduledValue) {
    if (scheduledValue === true) {
        return 1;
    }
    else if (scheduledValue === false) {
        return 0;
    }
    return scheduledValue;
};
export var RecipeType;
(function (RecipeType) {
    RecipeType["pH"] = "pH";
    RecipeType["EC"] = "EC";
    RecipeType["WaterRefilling"] = "WaterRefilling";
    RecipeType["TankFlush"] = "TankFlush";
    RecipeType["Climate"] = "Climate";
    RecipeType["Sanitizer"] = "Sanitizer";
})(RecipeType || (RecipeType = {}));
export function crudRecipePayload(recipe, recipeType) {
    if (recipeType === RecipeType.WaterRefilling) {
        return {
            interval: recipe.interval,
            upperPoint: recipe.upperPoint,
            lowerPoint: recipe.lowerPoint,
            tolerance: recipe.tolerance,
            enabled: !!recipe.enabled
        };
    }
    if (recipeType === RecipeType.TankFlush) {
        return {
            freq: recipe.freq,
            ecSp: recipe.ecSp,
            phSp: recipe.phSp,
            wlevSp: recipe.wlevSp,
            frmt: recipe.frmt,
            drmt: recipe.drmt,
            enabled: !!recipe.enabled
        };
    }
    if (recipeType === RecipeType.Climate) {
        return {
            temp_d: recipe.tempD,
            temp_n: recipe.tempN,
            hum_d: recipe.humD,
            hum_n: recipe.humN,
            mifs: recipe.mifs,
            enabled: !!recipe.enabled
        };
    }
    if (recipeType === RecipeType.pH) {
        return {
            setPoint: recipe.setPoint,
            tolerance: recipe.tolerance,
            interval: recipe.interval,
            pumpTime: recipe.pumpTime,
            enabled: !!recipe.enabled
        };
    }
    if (recipeType === RecipeType.EC) {
        return {
            setPoint: recipe.setPoint,
            tolerance: recipe.tolerance,
            interval: recipe.interval,
            parts: recipe.parts,
            pumpTime: recipe.pumpTime,
            sanitizerDosing: recipe.sanitizerDosing,
            enabled: !!recipe.enabled
        };
    }
}
export var findRecipeForSchedule = function (recipeType, scheduleId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
    var recipe, _a;
    return tslib_1.__generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!(recipeType.toLowerCase() === 'light')) return [3 /*break*/, 2];
                return [4 /*yield*/, LightRecipe.query({
                        where: { lightIntensitySchedule: scheduleId }
                    })];
            case 1:
                _a = _b.sent();
                return [3 /*break*/, 4];
            case 2: return [4 /*yield*/, FanRecipe.query({
                    where: { fanIntensitySchedule: scheduleId }
                })];
            case 3:
                _a = _b.sent();
                _b.label = 4;
            case 4:
                recipe = _a;
                return [2 /*return*/, recipe[0]];
        }
    });
}); };
