import gql from 'graphql-tag';
import { MAX_GRAPHQL_PAGE_SIZE } from '../utils';
export const visitDetailsQuery = gql`
    query Visit($id: ID!) {
        node(id: $id) {
            ... on Visit {
                id
                pk
                note
                startTime
                endTime
                date
                uuid
                shrinkageState
                farm {
                    name
                    category
                    locationGroup {
                        name
                        uuid
                    }
                }
                grower {
                    id
                    pk
                    name
                }
                images {
                    edges {
                        node {
                            imageUrl
                        }
                    }
                }
                visitRecord {
                    pk
                    checkIn
                    checkOut
                    note
                    uuid
                    visit {
                        id
                    }
                }
                missions {
                    edges {
                        node {
                            id
                            uuid
                            pk
                            note
                            farmingUnit {
                                name
                            }
                            images {
                                edges {
                                    node {
                                        imageUrl
                                    }
                                }
                            }
                            tasks(first: ${MAX_GRAPHQL_PAGE_SIZE}) {
                                edges {
                                    node {
                                        id
                                        pk
                                        endProduct {
                                            name
                                            internalCode
                                            pk
                                        }
                                        taskType
                                        plantNumber
                                        sectorId
                                        sector {
                                            number
                                        }
                                    }
                                }
                            }
                            missionRecord {
                                farmingUnitName
                                uuid
                                checkIn
                                checkOut
                                pk
                                harvestRecords(first: ${MAX_GRAPHQL_PAGE_SIZE}, sort: sector_number_desc) {
                                    edges {
                                        node {
                                            id
                                            pk
                                            uuid
                                            endProductInternalCode
                                            endProductName
                                            plantsToBeHarvested
                                            shrinkage
                                            note
                                            sectorNumber
                                            images {
                                                edges {
                                                    node {
                                                        imageUrl
                                                    }
                                                }
                                            }
                                            sectorTaskId
                                            shrinkageReasons {
                                                edges {
                                                    node {
                                                        reason
                                                        pk
                                                        uuid
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
export const visitsQuery = gql`
    query VisitConnection(
        $numberOfItems: Int!
        $cursorAfter: String
        $date: Date!
        $days: Int!
    ) {
        visits(
            first: $numberOfItems
            after: $cursorAfter
            date: $date
            days: $days
        ) {
            pageInfo {
                endCursor
                hasNextPage
            }
            edges {
                node {
                    id
                    pk
                    uuid
                    startTime
                    endTime
                    date
                    grower {
                        name
                        id
                        pk
                    }
                    farm {
                        name
                        category
                        locationGroup {
                            name
                            uuid
                        }
                    }
                    shrinkageState
                }
            }
        }
    }
`;

export const visitById = gql`
    query VisitById($id: ID!) {
        node(id: $id) {
            ... on Visit {
                uuid
                farm {
                    locationGroup {
                        uuid
                    }
                }
            }
        }
    }
`;
