import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad } from '@angular/router';
import { take } from 'rxjs/operators';

import { Auth } from 'infarm-core';
import { announceLogout, setLoginRedirect } from '@infarm/auth';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(private auth: Auth) {}

    canActivateChild(): Promise<boolean> {
        return this.canActivate();
    }
    canLoad(): Promise<boolean> {
        return this.canActivate();
    }

    async canActivate(): Promise<boolean> {
        const token = await this.auth.change.pipe(take(1)).toPromise();
        if (token) {
            return true;
        } else {
            setLoginRedirect(window.location.href);
            announceLogout();
            return false;
        }
    }
}
