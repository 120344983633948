import * as tslib_1 from "tslib";
import { DialogService } from '../dialog.service';
import { ConfirmDialogComponent } from './confirm-dialog.component';
var ConfirmationService = /** @class */ (function () {
    function ConfirmationService(dialog) {
        this.dialog = dialog;
    }
    ConfirmationService.prototype.show = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config, accept;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = { data: data, disableClose: true };
                        return [4 /*yield*/, this.dialog
                                .open(ConfirmDialogComponent, config)
                                .afterClosed()
                                .toPromise()];
                    case 1:
                        accept = _a.sent();
                        return [2 /*return*/, accept];
                }
            });
        });
    };
    return ConfirmationService;
}());
export { ConfirmationService };
