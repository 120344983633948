import { isString } from 'infarm-core';

export class FieldBase<T> {
    // Common field properties.
    controlType: string;
    key: string;
    label: string;
    value?: T;
    required?: boolean | null;
    hide?: boolean | null;
    type?: string;

    constructor(options: FieldBaseOptions<T> = {}) {
        this.controlType = options.controlType || ControlType.Text;

        // `[attr.required]` requires a value of `null` (not `false`) in order to not be rendered.
        this.required = options.required || null;
        this.hide = options.hide || null;
        this.label = options.label || '';
        this.key = options.key || '';
        this.value =
            this.controlType === ControlType.Text
                ? decodeHtmlEntities(options.value)
                : options.value;
    }
}

export function decodeHtmlEntities<T>(value: T): T {
    if (isString(value)) {
        const div = document.createElement('div');
        div.innerHTML = value;
        return div.innerText as any;
    }
    return value;
}

export enum ControlType {
    Date = 'date',
    Text = 'text',
    Checkbox = 'checkbox',
    Select = 'select'
}

export interface FieldBaseOptions<T> {
    [key: string]: any;
    controlType?: ControlType;
    key?: string;
    label?: string;
    value?: T;
    required?: boolean | null;
    hide?: boolean | null;
}
