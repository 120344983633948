import { PlantingPeriod } from './interfaces/farms';
import { DateSpan, maxDate } from '../utils';

export const MAX_GRAPHQL_PAGE_SIZE = 200;
export const GRAPHQL_PAGE_SIZE = 20;
export const MAX_GRAPHQL_RESULT_SIZE = 1000;

export interface FarmsListPaginatedParameters {
    numberOfItems: number;
    cursorAfter?: string;
}

export function plantingPeriodTimeSpanConverter(
    plantingPeriod: PlantingPeriod
): DateSpan {
    const startTime = plantingPeriod.lower
        ? new Date(plantingPeriod.lower)
        : undefined;
    const endTime = plantingPeriod.upper
        ? new Date(plantingPeriod.upper)
        : maxDate;
    const timeSpan: DateSpan = {
        startTime,
        endTime
    };
    return timeSpan;
}
