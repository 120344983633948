// tslint:disable: max-classes-per-file member-ordering no-this-assignment
import { NgZone } from '@angular/core';
import { FetchOptions, readonly, Route } from '@infarm/potion-client';
import { Observable } from 'rxjs';

import { streamify } from '../../utils/streamify';

import { Constructor } from './mixins/ctor';
import { ArchivableItem } from './mixins/archivable-item';
import { ItemWithAddresses } from './mixins/item-with-addresses';
import { ItemWithName, ItemWithNameType } from './mixins/item-with-name';
import { UpdatedAtType } from './mixins/updated-at';

import { Organization } from './organization';
import { AddressView } from './address';
import { HarvestRoundView } from './harvest-round';
import { FarmingUnit } from './farming-unit';
import { SeedingRecord } from './seeding-record';
import { User } from './user';
import {
    FarmingUnitView,
    PlantingPreparationView,
    SectorConfigurationsView
} from './schemas/farming';
import { RecipesView } from './schemas/recipes';

// TODO: Remove when https://github.com/Microsoft/TypeScript/issues/15870 is fixed
export const Base: Constructor<ItemWithNameType> &
    Constructor<UpdatedAtType> &
    typeof ItemWithAddresses = ItemWithName(ItemWithAddresses);

export class Farm extends ArchivableItem(Base) {
    /**
     * Get farm overview.
     */
    // TODO: Switch to web sockets
    static overview: (
        params?: any,
        options?: FetchOptions
    ) => Promise<GeneralOverview> = Route.GET<GeneralOverview>('/overview');
    static overviewStream(zone: NgZone): Observable<GeneralOverview> {
        const farm = this;
        return streamify<GeneralOverview>(overview, zone, 5);
        function overview() {
            return farm.overview();
        }
    }

    /**
     * Get farms preview.
     */
    // TODO: Switch to web sockets
    // TODO: This is actually the organization view, so maybe move the route to Organization resource instead
    static preview: (
        params?: any,
        options?: FetchOptions
    ) => Promise<OrganizationView[]> = Route.GET<OrganizationView[]>(
        '/preview'
    );
    static previewStream(zone: NgZone): Observable<OrganizationView[]> {
        const farm = this;
        return streamify<OrganizationView[], any[]>(preview, zone, 5, []);
        function preview() {
            return farm.preview();
        }
    }

    /**
     * Get a farm's view.
     */
    // TODO: Switch to web sockets
    static view: (
        params: FarmViewParams,
        options?: FetchOptions
    ) => Promise<FarmView> = Route.GET<FarmView>('/view');
    static viewStream(
        zone: NgZone,
        params: FarmViewParams
    ): Observable<FarmView> {
        const farm = this;
        return streamify<FarmView, any>(view, zone, 5);
        function view() {
            return farm.view(params);
        }
    }

    static manage: (
        params: FarmManageParams,
        options?: FetchOptions
    ) => Promise<FarmView> = Route.GET<FarmView>('/manage');

    static sectorConfigurations: (
        params: FarmRouteParams,
        options?: FetchOptions
    ) => Promise<SectorConfigurationsView> = Route.GET<
        SectorConfigurationsView
    >('/sector_configuration');

    static planSeedingRound: (
        params: PlanSeedingRoundParams,
        options?: FetchOptions
    ) => Promise<PlannedSeedingRound> = Route.POST<PlannedSeedingRound>(
        '/seeding_plan'
    );

    static plantingPreparation: (
        params: NewPlantingPreparationParams,
        options?: FetchOptions
    ) => Promise<PlantingPreparationView> = Route.GET<PlantingPreparationView>(
        '/planting_preparation'
    );

    @readonly
    farmingUnits?: FarmingUnit[];

    notes?: string;
    category: FarmCategory;

    timezone: string;

    organization: Organization;
    nursery?: Farm;

    @readonly
    isDeleted: boolean;

    @readonly
    createdAt: Date;
    @readonly
    updatedAt: Date;

    @readonly
    createdBy: User;
}

/**
 * Route params interfaces
 */
export type FarmViewParams = FarmRouteParams;

export type FarmManageParams = FarmRouteParams;

export interface FarmRouteParams {
    farm: Farm | string | number;
}

export interface PlanSeedingRoundParams {
    date?: Date;
    nursery?: Farm | string | number;
}

export interface NewPlantingPreparationParams {
    date?: Date;
    nursery: Farm;
}

export interface FarmNames {
    id: number;
    name: string;
}

export interface PlannedSeedingRound {
    date: Date;
    message: string;
    nurseryId: number;
    seedingDatesGroup: number[];
    seedingRecords: SeedingRecord[];
}

/**
 * General overview and farm preview route interfaces
 */
export interface GeneralOverview {
    assets: AssetPreview[];
    organizations: OrganizationView[];
}

// TODO: Be more descriptive with this interface
export interface AssetPreview {
    trackerId: number;
    name: string;
    type: string;
}

export class OrganizationView {
    id: number;
    name: string;
    farms: FarmPreview[];
}

export interface FarmPreview {
    id: number;
    name: string;
    nurseryId: number;
    isNursery: boolean;
    category: FarmCategory;
    units: number;
    farmingUnits: FarmingUnitPreview[];
}

/**
 * Farm view route interfaces
 */
export interface FarmView {
    id: number;
    name: string;
    notes: string;
    nurseryId: number;
    nurseryName: string;
    address: AddressView;
    harvestRounds: HarvestRoundView[];
    isNursery: boolean;
    category: FarmCategory;
    farmingUnits: FarmingUnitView[];
}

// TODO: Fix https://github.com/angular/angular-cli/issues/2034
export abstract class FarmingUnitPreview {
    id: number;
    name: string;
    firstPlantingDate: Date;
    numColumns: number;
    numLevels: number;
    recipes?: RecipesView;
}

export enum FarmCategory {
    Store = 'STORE',
    Restaurant = 'RESTAURANT',
    Showroom = 'SHOWROOM',
    Nursery = 'NURSERY',
    Production = 'PRODUCTION',
    Distribution = 'DISTRIBUTION',
    Other = 'OTHER'
}
