import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InfarmItem } from 'infarm-core';
import { QueryParams } from '@infarm/potion-client';
import { DialogService } from '../dialog.service';
import { ResourceType } from '../resources';
import { ResourcePickerDialogComponent } from './resource-picker-dialog.component';

@Component({
    selector: 'in-resource-picker',
    templateUrl: './resource-picker.component.html'
})
export class ResourcePickerComponent implements OnInit {
    @Input() color: string;

    @Input() title: string;
    @Input() resourceType: ResourceType;
    @Input() resource: InfarmItem;
    @Input() query: QueryParams;
    @Input() placeholder: string;
    @Input() required: boolean;
    @Input() multiple: boolean;

    @Output() select: EventEmitter<any> = new EventEmitter();

    items: InfarmItem[];

    item: InfarmItem | null;

    constructor(private dialog: DialogService) {}

    ngOnInit(): void {
        const resourceType = this.resourceType.type as typeof InfarmItem;

        if (this.resource instanceof InfarmItem) {
            this.item = this.resource;
        }

        resourceType.query(this.query).then((items: InfarmItem[]) => {
            this.items = items;
        });
    }

    async pick(): Promise<void> {
        const data = {
            resourceType: this.resourceType,
            resource: this.resource,
            title: this.title,
            options: {
                placeholder: this.placeholder,
                required: this.required,
                multiple: this.multiple
            }
        };
        const resource = await this.dialog
            .open(ResourcePickerDialogComponent, { data, disableClose: true })
            .afterClosed()
            .toPromise();
        this.select.emit(resource);
    }
}
