import * as tslib_1 from "tslib";
import { UpdateableInfarmItem } from './mixins/updated-at';
var Address = /** @class */ (function (_super) {
    tslib_1.__extends(Address, _super);
    function Address() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Address.prototype.toString = function () {
        return this.line1 + ",\n            " + this.city + ",\n            " + this.postcode + " " + this.country + "\n        ";
    };
    return Address;
}(UpdateableInfarmItem));
export { Address };
