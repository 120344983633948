import * as tslib_1 from "tslib";
import { Item, Route } from '@infarm/potion-client';
import { ArchivableItem } from './mixins/archivable-item';
import { ItemWithAddresses } from './mixins/item-with-addresses';
import { ItemWithName } from './mixins/item-with-name';
export var Base = ItemWithName(ItemWithAddresses);
var Visit = /** @class */ (function (_super) {
    tslib_1.__extends(Visit, _super);
    function Visit() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Visit.record = Route.POST('/visit-record');
    return Visit;
}(ArchivableItem(Base)));
export { Visit };
var MissionRecord = /** @class */ (function (_super) {
    tslib_1.__extends(MissionRecord, _super);
    function MissionRecord() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return MissionRecord;
}(Item));
export { MissionRecord };
var VisitRecord = /** @class */ (function (_super) {
    tslib_1.__extends(VisitRecord, _super);
    function VisitRecord() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return VisitRecord;
}(Item));
export { VisitRecord };
var VisitImage = /** @class */ (function (_super) {
    tslib_1.__extends(VisitImage, _super);
    function VisitImage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return VisitImage;
}(Item));
export { VisitImage };
var MissionImage = /** @class */ (function (_super) {
    tslib_1.__extends(MissionImage, _super);
    function MissionImage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return MissionImage;
}(Item));
export { MissionImage };
export var ShrinkageState;
(function (ShrinkageState) {
    ShrinkageState["NO_SHRINKAGE"] = "NO_SHRINKAGE";
    ShrinkageState["HAS_SHRINKAGE"] = "HAS_SHRINKAGE";
    ShrinkageState["NO_RECORDS"] = "NO_RECORDS";
})(ShrinkageState || (ShrinkageState = {}));
export var HarvestType;
(function (HarvestType) {
    HarvestType["NO_SHRINKAGE"] = "NO_SHRINKAGE";
    HarvestType["HAS_SHRINKAGE"] = "HAS_SHRINKAGE";
})(HarvestType || (HarvestType = {}));
export var SectorTaskType;
(function (SectorTaskType) {
    SectorTaskType["harvest"] = "harvest";
    SectorTaskType["planting"] = "planting";
})(SectorTaskType || (SectorTaskType = {}));
