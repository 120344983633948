import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
var DownloadService = /** @class */ (function () {
    function DownloadService(http) {
        this.http = http;
        this.fileNameRegEx = /(Planting-Preparation|seeding-records)_(.*?)_(.*?).xlsx/;
    }
    DownloadService.prototype.open = function (link, headers) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, fileName, url, a, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.callServerEndpoint(link, headers)];
                    case 1:
                        response = _a.sent();
                        fileName = this.getFileName(response);
                        url = window.URL.createObjectURL(response.body);
                        a = document.createElement('a');
                        document.body.appendChild(a);
                        a.setAttribute('style', 'display: none');
                        a.href = url;
                        a.download = fileName;
                        a.click();
                        window.URL.revokeObjectURL(url);
                        a.remove();
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        throw error_1;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DownloadService.prototype.callServerEndpoint = function (link, headers) {
        return this.http
            .get(link, {
            responseType: 'blob',
            headers: headers,
            observe: 'response'
        })
            .toPromise();
    };
    DownloadService.prototype.getFileName = function (response) {
        var fileNameInfo = response.headers.get('content-disposition');
        var matchInfo = this.fileNameRegEx.exec(fileNameInfo);
        var start = matchInfo.index;
        var end = start + matchInfo[0].length;
        var fileName = fileNameInfo.slice(start, end);
        return fileName;
    };
    return DownloadService;
}());
export { DownloadService };
