import * as tslib_1 from "tslib";
import { OnDestroy, Optional, Provider, SkipSelf } from '@angular/core';
import { BehaviorSubject, of as ofObservable } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { User } from '../api/resources/user';
import { Auth } from '../auth/auth';
import { isJsObject } from '../utils/guards';
var CurrentUser = /** @class */ (function () {
    function CurrentUser(auth) {
        var _a;
        var _this = this;
        this.changeSource = new BehaviorSubject(null);
        this.change = this.changeSource.asObservable(); // tslint:disable-line: member-ordering
        this.isSuperAdmin = this.change.pipe(filter(function (user) { return user !== null; }), map(function (user) { return isJsObject(user) && user.isSuperAdmin; })); // tslint:disable-line: member-ordering
        this.subs = [];
        (_a = this.subs).push.apply(_a, tslib_1.__spread([
            auth.change
                .pipe(switchMap(function (token) {
                return token ? User.me() : ofObservable(null);
            }), catchError(function () { return ofObservable(null); }))
                .subscribe(function (user) {
                if (user) {
                    _this.changeSource.next(user);
                }
            })
        ]));
    }
    CurrentUser.prototype.ngOnDestroy = function () {
        var e_1, _a;
        try {
            for (var _b = tslib_1.__values(this.subs), _c = _b.next(); !_c.done; _c = _b.next()) {
                var sub = _c.value;
                sub.unsubscribe();
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    return CurrentUser;
}());
export { CurrentUser };
export function CURRENT_USER_PROVIDER_FACTORY(parentFactory, auth) {
    return parentFactory || new CurrentUser(auth);
}
export var CURRENT_USER_PROVIDER = {
    // If there is already a CurrentUser available, use that.
    // Otherwise, provide a new one.
    provide: CurrentUser,
    useFactory: CURRENT_USER_PROVIDER_FACTORY,
    deps: [[new Optional(), new SkipSelf(), CurrentUser], Auth]
};
