import * as tslib_1 from "tslib";
import { UpdateableInfarmItem } from './mixins/updated-at';
var Controller = /** @class */ (function (_super) {
    tslib_1.__extends(Controller, _super);
    function Controller() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Controller;
}(UpdateableInfarmItem));
export { Controller };
