import { UpdateableInfarmItem } from './mixins/updated-at';
import { Farm } from './farm';

import { duration, Duration } from 'moment';

export class HarvestRound extends UpdateableInfarmItem {
    farm: Farm;
    startTime: number;
    endTime: number;
    dayOfWeek: DayOfWeek;

    toString(): string {
        return harvestRoundAsString(this);
    }
}

export type DayOfWeek =
    | 'Monday'
    | 'Tuesday'
    | 'Wednesday'
    | 'Thursday'
    | 'Friday'
    | 'Saturday'
    | 'Sunday';

export interface HarvestRoundView {
    id: number;
    dayOfWeek: DayOfWeek;
    startTime: number;
    endTime: number;
}

// TODO: Tests these
export function harvestRoundAsString(
    round: HarvestRound | HarvestRoundView
): string {
    const startTime = duration(round.startTime, 'seconds');
    const endTime = duration(round.endTime, 'seconds');
    return `${round.dayOfWeek}, from ${toTime(startTime)} to ${toTime(
        endTime
    )}`;
}

export function toTime(duration: Duration): string {
    const hours = duration.hours();
    const minutes = duration.minutes();
    return `${addPrefix(hours)}:${addPrefix(minutes)}`;
}
export function addPrefix(num: number): string {
    if (num < 10) {
        return `0${num}`;
    }
    return `${num}`;
}
