import * as tslib_1 from "tslib";
import { InfarmItem } from './infarm-item';
import { UpdatedAt } from './updated-at';
export var Base = UpdatedAt(InfarmItem);
var ItemWithAddresses = /** @class */ (function (_super) {
    tslib_1.__extends(ItemWithAddresses, _super);
    function ItemWithAddresses() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ItemWithAddresses;
}(UpdatedAt(Base)));
export { ItemWithAddresses };
