import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of as ofObservable } from 'rxjs';

export class PreloadSelectedModulesOnly implements PreloadingStrategy {
    preload(route: Route, load: () => Observable<any>): Observable<any> {
        const { data } = route;
        const { preload = null } = data || {};
        return preload ? load() : ofObservable(null);
    }
}
