import { NgModule } from '@angular/core';

import { ApiModule } from '../api/index';
import { AuthModule } from '../auth/index';

import { CURRENT_USER_PROVIDER } from './current-user';
import { ImageStorage } from './image-storage';

export { CurrentUser } from './current-user';
export {
    ImageUploadResponse,
    ImageUploadOptions,
    ImageStorage
} from './image-storage';

@NgModule({
    imports: [ApiModule, AuthModule],
    providers: [CURRENT_USER_PROVIDER, ImageStorage]
})
export class ServicesModule {}
