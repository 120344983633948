import { InfarmItem } from 'infarm-core';
import { ControlType, FieldBase, FieldBaseOptions } from './base';
import { Option } from './option';

export interface SelectFieldOptions
    extends FieldBaseOptions<InfarmItem | string> {
    multiple?: boolean | null;
    options?: Option[];
}

export class SelectField extends FieldBase<InfarmItem[] | InfarmItem | string> {
    multiple?: boolean | null;
    options: Option[] = [];

    constructor(options: SelectFieldOptions = {}) {
        super({
            ...options,
            controlType: ControlType.Select,
            value: value(options)
        });
        // `[attr.multiple]` requires a value of `null` (not `false`) in order to not be rendered.
        this.multiple = options.multiple || null;
        this.options = options.options || [];
    }
}

function value(options: SelectFieldOptions): any {
    let value = options.value as any;
    // Selects with 'multiple' attr need an array as the default value.
    if (options.multiple && !Array.isArray(value)) {
        value = [];
    }
    return value;
}
