import { isFunction, isJsObject } from './guards';

export type KeyMapFunction = (key: string) => string;
export type ValueMapFunction = (value: any) => any;
/**
 * Object.map()
 * NOTE: This is NOT a recursive fn.
 * NOTE: This will not work with anything that is NOT and Object.
 * @param obj
 * @param keyMapFunction - Transform operation to apply on the key.
 * @param [valueMapFunction] - Transform operation to apply on the value.
 */
export function omap(
    obj: any,
    keyMapFunction: KeyMapFunction,
    valueMapFunction?: ValueMapFunction
): any {
    if (isJsObject(obj)) {
        return Object.entries(obj)
            .map(([key, value]) => [
                isFunction(keyMapFunction) ? keyMapFunction(key) : key,
                isFunction(valueMapFunction) ? valueMapFunction(value) : value
            ])
            .reduce((a: {}, [key, value]) => ({ ...a, [key]: value }), {});
    }
    return obj;
}

/**
 * Convert object to UrlEncoded string
 */
export function toUrlEncoded(object: { [key: string]: any }): any {
    const pairs: string[] = [];
    for (const [key, value] of Object.entries(object)) {
        pairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
    return pairs.join('&');
}

/**
 * Create a new obj, excluding given keys, from another
 * @param obj
 * @param keys
 */
// TODO: Use better type when https://github.com/Microsoft/TypeScript/issues/12215 is implemented
export function omit<T = { [key: string]: any }>(
    obj: T,
    keys: Array<keyof T>
): { [key: string]: any } {
    return Object.entries(obj)
        .filter(([key]) => !keys.includes(key as any))
        .reduce(
            (a: {}, [key, value]) => ({
                ...a,
                [key]: value
            }),
            {}
        );
}
