import { Column } from './column';

export class Row {
    readonly columns: Column[] = [];

    constructor(keys: string[], readonly item: any) {
        this.columns = keys.map(key => new Column(key, item));
    }

    equals(row: Row): boolean {
        return this.item.equals(row.item);
    }

    get id(): string {
        return `${this.item.uri}`;
    }
}
