import * as tslib_1 from "tslib";
import { FarmingUnit } from 'infarm-core';
var FarmingUnitResolver = /** @class */ (function () {
    function FarmingUnitResolver() {
    }
    FarmingUnitResolver.prototype.resolve = function (route) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var unitId, unit, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        unitId = findUnitId(route);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, FarmingUnit.fetch(unitId, {
                                cache: route.routeConfig === null ||
                                    route.routeConfig.runGuardsAndResolvers !== 'always',
                                skip: ['createdBy', 'grower', 'farmingUnits', 'farm']
                            })];
                    case 2:
                        unit = _a.sent();
                        return [2 /*return*/, unit];
                    case 3:
                        err_1 = _a.sent();
                        return [2 /*return*/, null];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return FarmingUnitResolver;
}());
export { FarmingUnitResolver };
// TODO: Add tests an move to somewhere else (it's commonly used)
export function findUnitId(route) {
    var unitId = route.params.unitId;
    if (unitId === undefined) {
        var routeParent = route.parent;
        // Search for the unitId by looking up the route tree.
        while (routeParent) {
            var unitId_1 = routeParent.params.unitId;
            if (unitId_1 !== undefined) {
                return parseInt(unitId_1, 10);
            }
            routeParent = routeParent.parent;
        }
    }
    return parseInt(unitId, 10);
}
