import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'in-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData
    ) {}
}

export interface ConfirmationDialogData {
    title?: string;
    message?: string;
}
