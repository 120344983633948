import * as tslib_1 from "tslib";
import { InfarmItem } from './mixins/infarm-item';
var ShrinkageReason = /** @class */ (function (_super) {
    tslib_1.__extends(ShrinkageReason, _super);
    function ShrinkageReason() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ShrinkageReason;
}(InfarmItem));
export { ShrinkageReason };
