import { Component, NgZone, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Auth, HttpError, User, visible } from 'infarm-core';

import { distinctUntilChanged } from 'rxjs/operators';
import { isProd } from '../env';

import {
    GoogleAnalyticsService,
    NavigationHistoryService,
    ProgressService,
    staleUrlFragment
} from 'app-shared';

const ignored401Routes = ['/set_password', '/auth/token'];

@Component({
    selector: 'in-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [visible]
})
export class AppComponent implements OnDestroy {
    router: Router;
    // tslint:disable-next-line: member-ordering
    constructor(
        zone: NgZone,
        router: Router,
        httpError: HttpError,
        auth: Auth,
        ga: GoogleAnalyticsService,
        navigationHistory: NavigationHistoryService,
        public progress: ProgressService
    ) {
        this.router = router;
        /*
            TODO this "User.me" call is a workaround that calls a potion endpoint
            which in the case of an unathenticated user will trigger a redirect
            to the login page. It should be removed as soon as we have a better
            way of dealing with permission errors when hitting graphql resources.
        */
        User.me();
        // If the ApiGateway emits an error,
        // we want to handle that some way.
        // Subscribe to the errors stream coming from the ApiGateway.
        httpError.subscribe(error => {
            // If the user made a request that they were not authorized to,
            // it's possible that their session has expired.
            // NOTE: We may want to not act when certain routes triggered the 401.
            const url = error.url;
            if (
                error.status === 401 &&
                (!url ||
                    !ignored401Routes.some(route => url.indexOf(route) !== -1))
            ) {
                // Before redirect clear the auth token,
                // otherwise the Login will try to refresh it.
                // TODO: Go through all @infarm/core logic and use NgZone where necessary
                zone.run(async () => {
                    await auth.logout();
                    await router.navigateByUrl('/login');
                });
            }
        });

        router.events
            .pipe(
                distinctUntilChanged((previous: any, current: any) =>
                    current instanceof NavigationEnd
                        ? previous.url === current.url
                        : true
                )
            )
            .subscribe((event: NavigationEnd) => {
                const isStaleUrl = event.url.indexOf(staleUrlFragment) === 0;
                if (isStaleUrl) {
                    this.redirectStaleUrl(event.url);
                }

                if (isProd()) {
                    // Track page views.
                    // http://stackoverflow.com/a/39622860/1092007
                    ga.pageview(event.urlAfterRedirects);
                }
                // Store the event.
                // NOTE: we use this for handling redirects.
                navigationHistory.push(event);
            });

        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.progress.show();
            } else if (event instanceof NavigationEnd) {
                this.progress.hide();
            }
        });
    }

    ngOnDestroy(): void {
        // HACK: For some reason, the app doesn't remove its DOM elements from
        // the document after unmounting - we have to do it manually.
        const element = document.querySelector('in-app');
        element.remove();
    }

    redirectStaleUrl(staleUrl: string) {
        const redirect = staleUrl.replace('/farms', '');
        this.router.navigateByUrl(redirect);
    }
}
