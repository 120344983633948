import { InfarmItem } from 'infarm-core';

import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { Row } from './row';
import { Action, Schema, toLabel } from '../resources/schema';

export class ItemsTable {
    private schemaSource: ReplaySubject<Schema> = new ReplaySubject<Schema>();
    readonly schema: Observable<Schema> = this.schemaSource.asObservable(); // tslint:disable-line:member-ordering
    private keys: Observable<string[]> = this.schema.pipe(
        map(schema => schema.properties(Action.View).keys()),
        map(keys => Array.from(keys))
    );

    readonly headers: Observable<string[]> = this.keys.pipe(
        map(keys => keys.map(key => toLabel(key)))
    ); // tslint:disable-line:member-ordering

    private rowsSource: BehaviorSubject<Row[]> = new BehaviorSubject<Row[]>([]);
    readonly rows: Observable<Row[]> = this.rowsSource.asObservable(); // tslint:disable-line:member-ordering

    update(items: InfarmItem[]): void {
        this.keys
            .pipe(
                take(1),
                map(keys => items.map(item => new Row(keys, item)))
            )
            .subscribe(rows => {
                this.rowsSource.next(rows);
            });
    }
    setSchema(json: any): void {
        this.schemaSource.next(new Schema(json));
    }
}
