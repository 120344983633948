import { OnDestroy, Optional, Provider, SkipSelf } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
var NavigationHistoryService = /** @class */ (function () {
    function NavigationHistoryService(router) {
        var _this = this;
        this.router = router;
        this.events = [];
        // Observable history source
        this.historySource = new ReplaySubject();
        // Observable history stream
        this.history = this.historySource.asObservable(); // tslint:disable-line:member-ordering
        this.sub = this.history.subscribe(function (event) {
            // We only want to keep the last 2 events,
            // so we always remove one before we add a new one.
            // NOTE: The last is always the current navigation event,
            // and the 1st is always the prev.
            _this.events.splice(0, _this.events.length >= 2 ? 1 : 0); // tslint:disable-line:no-magic-numbers
            // Store current nav. event.
            _this.events.push(event);
        });
    }
    Object.defineProperty(NavigationHistoryService.prototype, "redirectUrl", {
        get: function () {
            var path = '/';
            var prevEvent = this.events[0];
            if ((prevEvent instanceof NavigationStart ||
                prevEvent instanceof NavigationEnd) &&
                !prevEvent.url.includes('login')) {
                path = prevEvent.url;
            }
            // If the prev. path is the same as the current one,
            // return null.
            if (this.router.url === path) {
                return null;
            }
            return path;
        },
        enumerable: true,
        configurable: true
    });
    NavigationHistoryService.prototype.ngOnDestroy = function () {
        this.sub.unsubscribe();
    };
    NavigationHistoryService.prototype.push = function (event) {
        this.historySource.next(event);
    };
    return NavigationHistoryService;
}());
export { NavigationHistoryService };
export function NAVIGATION_HISTORY_SERVICE_PROVIDER_FACTORY(parentFactory, router) {
    return parentFactory || new NavigationHistoryService(router);
}
export var NAVIGATION_HISTORY_SERVICE_PROVIDER = {
    // If there is already a NavigationHistoryService available, use that.
    // Otherwise, provide a new one.
    provide: NavigationHistoryService,
    useFactory: NAVIGATION_HISTORY_SERVICE_PROVIDER_FACTORY,
    deps: [[new Optional(), new SkipSelf(), NavigationHistoryService], Router]
};
