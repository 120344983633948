import * as tslib_1 from "tslib";
import { sort } from 'infarm-core';
export var RootLocationGroup = {
    name: 'infarm',
    id: 'TG9jYXRpb25Hcm91cDo1MDU0ODBlOC0xMGQ5LTQzNzUtYjhjNy0xMzIxY2M5ZDY3ZWU=',
    level: 1,
    farms: [],
    assignments: [],
    children: [],
    uuid: '505480e8-10d9-4375-b8c7-1321cc9d67ee'
};
export function getRootNodes(locationGroups) {
    var allGroupUuids = locationGroups.map(function (group) { return group.uuid; });
    var rootNodes = sort(locationGroups.filter(function (group) { return !group.parent || !allGroupUuids.includes(group.parent.uuid); }), function (group) { return group.name.toLowerCase(); });
    assignChildrenToLocationGroups(locationGroups);
    return rootNodes;
}
function assignChildrenToLocationGroups(locationGroups) {
    var e_1, _a;
    try {
        for (var locationGroups_1 = tslib_1.__values(locationGroups), locationGroups_1_1 = locationGroups_1.next(); !locationGroups_1_1.done; locationGroups_1_1 = locationGroups_1.next()) {
            var locationGroup = locationGroups_1_1.value;
            var identifiedChildren = sort(identifyChildren(locationGroup.uuid, locationGroups), function (group) { return group.name; });
            locationGroup.children = identifiedChildren;
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (locationGroups_1_1 && !locationGroups_1_1.done && (_a = locationGroups_1.return)) _a.call(locationGroups_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
}
export function flattenTreeStructure(locationGroup) {
    var e_2, _a;
    var array = [];
    array.push(locationGroup);
    var hasChildren = locationGroup.children && locationGroup.children.length > 0;
    if (hasChildren) {
        try {
            for (var _b = tslib_1.__values(locationGroup.children), _c = _b.next(); !_c.done; _c = _b.next()) {
                var child = _c.value;
                array.push.apply(array, tslib_1.__spread(flattenTreeStructure(child)));
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
    }
    return array;
}
/**
 * Function that scans an array of location groups and returns children of given locationGroupId
 */
function identifyChildren(parentId, locationGroups) {
    return locationGroups.filter(function (group) { return group.parent && group.parent.uuid === parentId; });
}
/*
including inherited roles from groups higher in the location groups tree
*/
export function rolesApplicableForLocationGroup(asignments, locationGroup, allLocationGroups) {
    return asignments
        .filter(function (assignment) {
        return hasDistantChild(assignment.locationGroup, locationGroup, allLocationGroups);
    })
        .map(function (assignment) { return assignment.role; });
}
function unassignedRolesForCurrentGroup(candidateRoles, allRoles) {
    return allRoles.filter(function (role) {
        return !candidateRoles.some(function (currentRole) { return currentRole.uuid === role.uuid; });
    });
}
export function getRelevantUserAssignments(users, currentLocationGroup, allRoles, allLocationGroups) {
    return users.map(function (user) {
        var rolesForCurrentGroup = rolesApplicableForLocationGroup(user.assignments, currentLocationGroup, allLocationGroups);
        var unassignedRolesForUser = unassignedRolesForCurrentGroup(rolesForCurrentGroup, allRoles);
        user.unassignedRoles = sort(unassignedRolesForUser.map(function (role) { return ({ role: role }); }), function (assignment) { return assignment.role.name; });
        user.assignments = getUniqueRoleAssignmentsForUser(user, rolesForCurrentGroup, currentLocationGroup, allLocationGroups);
        return user;
    });
}
/**
 * This function first filters assignments property of user to contain only those assignments
 * that are relevant for current location group being selected (i.e. either assignments defined
 * for this location group of for some group in its supertree)
 */
export function getUniqueRoleAssignmentsForUser(user, userRolesInGroup, currentLocationGroup, allLocationGroups) {
    var applicableAssignmentsForGroup = assignmentsApplicableForLocationGroup(currentLocationGroup, user, allLocationGroups);
    var usersAssignmentsWithinGroup = applicableAssignmentsForGroup.filter(function (assignment) {
        return userRolesInGroup
            .map(function (role) { return role.uuid; })
            .includes(assignment.role.uuid);
    });
    var uniqueAssignments = createUniqueRoleAssignmentsForGroup(usersAssignmentsWithinGroup);
    return sort(uniqueAssignments, function (assignment) {
        return assignment.role.name.toLowerCase();
    });
}
export function assignmentsApplicableForLocationGroup(currentLocationGroup, user, allLocationGroups) {
    return user.assignments.filter(function (assignment) {
        return hasDistantChild(assignment.locationGroup, currentLocationGroup, allLocationGroups) || assignment.locationGroup.uuid === currentLocationGroup.uuid;
    });
}
/**
 * This method removes assignment duplication in case there are multiple assignments defined with the same role
 * within one subtree (e.g. when having passed assignments for grower in infarm and Germany groups,
 * returned array will only consist of one assignment for grower role in infarm group).
 */
function createUniqueRoleAssignmentsForGroup(usersAssignmentsWithinGroup) {
    return usersAssignmentsWithinGroup.reduce(function (accumulator, value) {
        var alreadyStoredAssignment = accumulator.find(function (assignment) { return assignment.role.uuid === value.role.uuid; });
        if (!alreadyStoredAssignment) {
            accumulator.push(value);
        }
        else {
            if (alreadyStoredAssignment.locationGroup.level >
                value.locationGroup.level) {
                var indexOfAssignment = accumulator.indexOf(alreadyStoredAssignment);
                accumulator[indexOfAssignment] = value;
            }
        }
        return accumulator;
    }, []);
}
/**
 * Function to identify whether some location group ( @locationGroup ) is a (possibly distant) child of another location group ( @candidateParent )
 * So say we have Infarm > Germany > Berlin > West, then Infarm is a parent of Germany, Berlin and West groups.
 * @param candidateParent represents a location group that is possibly a parent of @locationGroup parameter
 * @param locationGroup is an instance of LocationGroup for which we want to test if @candidateParent is its distant parent or not
 * @param allLocationGroups lookup array for all available location groups
 */
export function hasDistantChild(candidateParent, locationGroup, allLocationGroups) {
    var foundInTree = false;
    var investigatedLocationGroup = locationGroup;
    while (investigatedLocationGroup && !foundInTree) {
        foundInTree = investigatedLocationGroup.uuid === candidateParent.uuid;
        if (!foundInTree) {
            var matchingGroup = (investigatedLocationGroup = allLocationGroups.find(function (group) { return group.uuid === investigatedLocationGroup.uuid; }));
            investigatedLocationGroup = matchingGroup
                ? matchingGroup.parent
                : null;
        }
    }
    return foundInTree;
}
