import { InfarmItem } from './mixins/infarm-item';

export class SeedingRecord extends InfarmItem {
    seedingDate: Date;
    seedlingNumber: number;
    seedsBatch: string;
    userFirstName: string;
    userLastName: string;
    userEmail: string;
    endProductInternalCode: string;
    endProductName: string;
}
